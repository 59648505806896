import { Layout } from '@features/layout';
import useStoreContext from '@hooks/useStoreContext';
import MainView from '@pages/3dStore/MainView';
import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router';

import { AppProvider } from './providers/AppProvider';

export default function App() {
  return (
    <AppProvider>
      <AppContent />
    </AppProvider>
  );
}

function AppContent() {
  const { storeId, setStoreId } = useStoreContext();

  useEffect(() => {
    setStoreId(process.env.REACT_APP_STORE_ID);
  }, []);

  return (
    <>
      {storeId ? (
        <Layout>
          <Routes>
            <Route path="/" element={<MainView />} />
            <Route path="/:locationId" element={<MainView />} />
          </Routes>
        </Layout>
      ) : (
        <></>
      )}
    </>
  );
}
