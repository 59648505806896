import DataStorage from '@apis/data/DataStorage';
import { Vector3 } from 'three';

class LocationsRepository {
  constructor() {
    this.locations = [];
    this.refreshAt;
    this.vec3 = new Vector3();
    this.vec3 = new Vector3();
  }

  _convertToLocationPointData = (locationData, storeSettings) => {
    const temp = locationData.map((item) => {
      let position = this.vec3.clone();
      let rotation = this.vec3.clone();

      let location = {
        id: item.id,
        img: `${storeSettings.panoImageBaseUrl}${item.id}${storeSettings.panoImageFormat}`,
        position: position.set(item.position.x, item.position.y, item.position.z),
        rotation: rotation.set(item.rotation.x, item.rotation.y, item.rotation.z),
        degrees: item.degrees || storeSettings.rotationDegrees,
      };
      return location;
    });

    return temp;
  };

  reloadLocationsIfNeeded = () => {
    if (this.refreshAt != DataStorage.refreshAt) {
      // console.log('reloadLocationsIfNeeded');
      this.locations = this._convertToLocationPointData(DataStorage.locations, DataStorage.store);
      this.refreshAt = DataStorage.refreshAt;
    }
  };

  getLocations = () => {
    this.reloadLocationsIfNeeded();
    return this.locations;
  };

  getLocationById = (locationId) => {
    this.reloadLocationsIfNeeded();
    return this.locations.find((x) => x.id == locationId);
  };
}

export default new LocationsRepository();
