import tagService from '@apis/services/tag.service';
import React, { memo, useMemo } from 'react';

import Tag from './Tag';

export const Tags = memo((props) => {
  const tagMockData = useMemo(() => tagService.getTags(), []);

  return (
    <>
      {tagMockData &&
        tagMockData.map((tag) => {
          return (
            <group
              key={`tag-${tag.id}`}
              position={[tag.position.x, tag.position.y, tag.position.z]}
            >
              <Tag tagData={tag} />
            </group>
          );
        })}
    </>
  );
});
