import storesService from '@apis/services/stores.service';
import useStoreContext from '@hooks/useStoreContext';
import React, { useMemo, useState } from 'react';

import { makeStyles } from '@material-ui/core';

import { BottomControls } from '../bottom-control';
import { ModalContainer } from '../modal';
import { SidebarNavigation } from '../sidebar-navigation';
import { SplashScreen } from './SplashScreen';
import { StoreLogo } from './StoreLogo';

const useStyles = makeStyles((theme) => ({
  container: {
    background: 'black',
    position: 'relative',
  },
}));

export const Layout = (props) => {
  const classes = useStyles();
  const [animateLogo, setAnimateLogo] = useState(false);
  const { storeId } = useStoreContext();
  const storeData = useMemo(() => storesService.getStore(), [storeId]);

  return (
    <div className={classes.container} style={{ width: '100%', height: '100%' }}>
      {props.children}
      <StoreLogo animateLogo={animateLogo} />
      <SidebarNavigation />
      <SplashScreen setAnimateLogo={setAnimateLogo} />
      <BottomControls />
      <ModalContainer modalAlignment={storeData.layoutSettings.modalAlignment} />
    </div>
  );
};
