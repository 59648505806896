const store = {
  id: 'prestige-car-connect', //prestige-car-connect
  name: 'Prestige Car Connect',
  model:
    'https://fidelitystorageprod-cdn.azureedge.net/prestige-car-connect/default/store-model/cardealer-d.glb',
  modelId: '010e5352e3ae4884a153bf22e4abe86f',
  storeFrontId: 'e18aa41763244f06a3e938ecb6e89f96',
  storeLogo:
    'https://fidelitystorageprod-cdn.azureedge.net/prestige-car-connect/default/store-images/prestige-logo.png',
  storeWelcomeText: 'Welcome to the Prestige Car Connect Experience.',
  storeFavIcon: '',
  splashImage:
    'https://fidelitystorageprod-cdn.azureedge.net/prestige-car-connect/default/store-images/splash-bg.jpg',
  cameraRotation: {
    azimuthal: -180,
    polar: 90,
  },
  rotationDegrees: 61,
  menus: [
    {
      title: 'Entrance',
      locationId: 'e18aa41763244f06a3e938ecb6e89f96',
      options: {
        rotation: {
          azimuthal: -3.141592653589793,
          polar: 1.5707963267948966,
        },
      },
    },
  ],
  panoImageBaseUrl:
    'https://fidelitystorageprod-cdn.azureedge.net/prestige-car-connect/default/location-images-equi/',
  panoImageFormat: '.webp',
  tiledImageSettings: {
    baseUrl2k:
      'https://fidelitystorageprod-cdn.azureedge.net/prestige-car-connect/default/location-images-tiled-2k/',
    baseUrl4k:
      'https://fidelitystorageprod-cdn.azureedge.net/prestige-car-connect/default/location-images-tiled-4k/',
  },
  layoutSettings: {
    // modalAlignment: 'right',
    modalAlignment: 'fullscreen',
  },
};

export default store;
