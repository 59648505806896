const products = [
  {
    sku: '2053326-23',
    photo:
      'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-vr-store-3d/cotton-on-chadstone/product-images/2053326-23.jpg',
    productName: 'Carpenter Jean',
    price: '69.99',
    link:
      'https://cottonon.com/AU/carpenter-jean/2053326-23.html?dwvar_2053326-23_color=2053326-23',
    brand: 'cotton-on',
  },
  {
    sku: '2054868-02',
    photo:
      'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-vr-store-3d/cotton-on-chadstone/product-images/2054868-02.jpg',
    productName: 'The Bomber Jacket',
    price: '79.99',
    link:
      'https://cottonon.com/AU/the-bomber-jacket/2054868-02.html?dwvar_2054868-02_color=2054868-02',
    brand: 'cotton-on',
  },
  {
    sku: '2054319-01',
    photo:
      'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-vr-store-3d/cotton-on-chadstone/product-images/2054319-01.jpg',
    productName: 'Bobbie Cargo Pant',
    price: '59.99',
    link:
      'https://cottonon.com/AU/bobbie-cargo-pant/2054319-01.html?dwvar_2054319-01_color=2054319-01',
    brand: 'cotton-on',
  },
  {
    sku: '2054850-03',
    photo:
      'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-vr-store-3d/cotton-on-chadstone/product-images/2054850-03.jpg',
    productName: 'Low Rise Straight Jean',
    price: '59.99',
    link:
      'https://cottonon.com/AU/low-rise-straight-jean/2054850-03.html?dwvar_2054850-03_color=2054850-03',
    brand: 'cotton-on',
  },
  {
    sku: '2054995-04',
    photo:
      'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-vr-store-3d/cotton-on-chadstone/product-images/2054995-04.jpg',
    productName: 'The 91 Tank',
    price: '19.99',
    link: 'https://cottonon.com/AU/the-91-tank/2054995-04.html?dwvar_2054995-04_color=2054995-04',
    brand: 'cotton-on',
  },
  {
    sku: '2054906-02',
    photo:
      'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-vr-store-3d/cotton-on-chadstone/product-images/2054906-02.jpg',
    productName: 'Utility Denim Long Overall',
    price: '89.99',
    link:
      'https://cottonon.com/AU/utility-denim-long-overall/2054906-02.html?dwvar_2054906-02_color=2054906-02',
    brand: 'cotton-on',
  },
  {
    sku: '2001752-37',
    photo:
      'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-vr-store-3d/cotton-on-chadstone/product-images/2001752-37.jpg',
    productName: 'Girlfriend Denim Jacket',
    price: '59.99',
    link:
      'https://cottonon.com/AU/girlfriend-denim-jacket/2001752-37.html?dwvar_2001752-37_color=2001752-37',
    brand: 'cotton-on',
  },
  {
    sku: '2054888-01',
    photo:
      'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-vr-store-3d/cotton-on-chadstone/product-images/2054888-01.jpg',
    productName: 'Cargo Denim Low Rise Mini Skirt',
    price: '44.99',
    link:
      'https://cottonon.com/AU/cargo-denim-low-rise-mini-skirt/2054888-01.html?dwvar_2054888-01_color=2054888-01',
    brand: 'cotton-on',
  },
  {
    sku: '2051567-19',
    photo:
      'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-vr-store-3d/cotton-on-chadstone/product-images/2051567-19.jpg',
    productName: 'Original Flare Jean',
    price: '59.99',
    link:
      'https://cottonon.com/AU/original-flare-jean/2051567-19.html?dwvar_2051567-19_color=2051567-19',
    brand: 'cotton-on',
  },
  {
    sku: '2051452-04',
    photo:
      'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-vr-store-3d/cotton-on-chadstone/product-images/2051452-04.jpg',
    productName: 'Dad Shirt',
    price: '39.99',
    link: 'https://cottonon.com/AU/dad-shirt/2051452-04.html?dwvar_2051452-04_color=2051452-04',
    brand: 'cotton-on',
  },
  {
    sku: '2054857-20',
    photo:
      'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-vr-store-3d/cotton-on-chadstone/product-images/2054857-20.jpg',
    productName: 'Scout Cargo Pant',
    price: '59.99',
    link:
      'https://cottonon.com/AU/scout-cargo-pant/2054857-20.html?dwvar_2054857-20_color=2054857-20',
    brand: 'cotton-on',
  },
  {
    sku: '2054185-28',
    photo:
      'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-vr-store-3d/cotton-on-chadstone/product-images/2054185-28.jpg',
    productName: 'Boyfriend Fit Band Tee',
    price: '29.99',
    link:
      'https://cottonon.com/AU/boyfriend-fit-band-tee/2054185-28.html?dwvar_2054185-28_color=2054185-28',
    brand: 'cotton-on',
  },
  {
    sku: '2055421-02',
    photo:
      'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-vr-store-3d/cotton-on-chadstone/product-images/2055421-02.jpg',
    productName: 'Amalfi Cowl Mini Dress',
    price: '49.99',
    link:
      'https://cottonon.com/AU/amalfi-cowl-mini-dress/2055421-02.html?dwvar_2055421-02_color=2055421-02',
    brand: 'cotton-on',
  },
  {
    sku: '2055199-04',
    photo:
      'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-vr-store-3d/cotton-on-chadstone/product-images/2055199-04.jpg',
    productName: 'Haven Wide Leg Pant',
    price: '49.99',
    link:
      'https://cottonon.com/AU/haven-wide-leg-pant/2055199-04.html?dwvar_2055199-04_color=2055199-04',
    brand: 'cotton-on',
  },
  {
    sku: '2054243-01',
    photo:
      'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-vr-store-3d/cotton-on-chadstone/product-images/2054243-01.jpg',
    productName: 'Rib Short Sleeve Split Midi Dress',
    price: '39.99',
    link:
      'https://cottonon.com/AU/rib-short-sleeve-split-midi-dress/2054243-01.html?dwvar_2054243-01_color=2054243-01',
    brand: 'cotton-on',
  },
  {
    sku: '2055083-03',
    photo:
      'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-vr-store-3d/cotton-on-chadstone/product-images/2055083-03.jpg',
    productName: 'Utility Denim Short',
    price: '49.99',
    link:
      'https://cottonon.com/AU/utility-denim-short/2055083-03.html?dwvar_2055083-03_color=2055083-03',
    brand: 'cotton-on',
  },
  {
    sku: '2052716-15',
    photo:
      'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-vr-store-3d/cotton-on-chadstone/product-images/2052716-15.jpg',
    productName: 'A-Line Denim Short',
    price: '39.99',
    link:
      'https://cottonon.com/AU/a-line-denim-short/2052716-15.html?dwvar_2052716-15_color=2052716-15',
    brand: 'cotton-on',
  },
  {
    sku: '2054468-07',
    photo:
      'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-vr-store-3d/cotton-on-chadstone/product-images/2054468-07.jpg',
    productName: 'Blake Keyhole Long Sleeve Top',
    price: '34.99',
    link:
      'https://cottonon.com/AU/blake-keyhole-long-sleeve-top/2054468-07.html?dwvar_2054468-07_color=2054468-07',
    brand: 'cotton-on',
  },
  {
    sku: '2054848-08',
    photo:
      'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-vr-store-3d/cotton-on-chadstone/product-images/2054848-08.jpg',
    productName: 'The 91 Tee',
    price: '19.99',
    link: 'https://cottonon.com/AU/the-91-tee/2054848-08.html?dwvar_2054848-08_color=2054848-08',
    brand: 'cotton-on',
  },
  {
    sku: '3611427-10',
    photo:
      'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-vr-store-3d/cotton-on-chadstone/product-images/3611427-10.jpg',
    productName: 'Heavy Overshirt',
    price: '79.99',
    link:
      'https://cottonon.com/AU/heavy-overshirt/3611427-10.html?dwvar_3611427-10_color=3611427-10',
    brand: 'cotton-on',
  },
  {
    sku: '3611853-22',
    photo:
      'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-vr-store-3d/cotton-on-chadstone/product-images/3611853-22.jpg',
    productName: 'Special Edition T-Shirt',
    price: '39.99',
    link:
      'https://cottonon.com/AU/special-edition-t-shirt/3611853-22.html?dwvar_3611853-22_color=3611853-22',
    brand: 'cotton-on',
  },
  {
    sku: '3611774-08',
    photo:
      'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-vr-store-3d/cotton-on-chadstone/product-images/3611774-08.jpg',
    productName: 'Baggy Jean',
    price: '79.99',
    link: 'https://cottonon.com/AU/baggy-jean/3611774-08.html?dwvar_3611774-08_color=3611774-08',
    brand: 'cotton-on',
  },
  {
    sku: '3610563-44',
    photo:
      'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-vr-store-3d/cotton-on-chadstone/product-images/3610563-44.jpg',
    productName: 'Cuban Short Sleeve Shirt',
    price: '39.99',
    link:
      'https://cottonon.com/AU/cuban-short-sleeve-shirt/3610563-44.html?dwvar_3610563-44_color=3610563-44',
    brand: 'cotton-on',
  },
  {
    sku: '3611185-02',
    photo:
      'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-vr-store-3d/cotton-on-chadstone/product-images/3611185-02.jpg',
    productName: 'Relaxed Tapered Jean',
    price: '69.99',
    link:
      'https://cottonon.com/AU/relaxed-tapered-jean/3611185-02.html?dwvar_3611185-02_color=3611185-02',
    brand: 'cotton-on',
  },
  {
    sku: '3611470-16',
    photo:
      'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-vr-store-3d/cotton-on-chadstone/product-images/3611470-16.jpg',
    productName: 'Ashby Long Sleeve Shirt',
    price: '49.99',
    link:
      'https://cottonon.com/AU/ashby-long-sleeve-shirt/3611470-16.html?dwvar_3611470-16_color=3611470-16',
    brand: 'cotton-on',
  },
  {
    sku: '3611288-04',
    photo:
      'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-vr-store-3d/cotton-on-chadstone/product-images/3611288-04.jpg',
    productName: 'Loose Fit T-Shirt',
    price: '24.99',
    link:
      'https://cottonon.com/AU/loose-fit-t-shirt/3611288-04.html?dwvar_3611288-04_color=3611288-04',
    brand: 'cotton-on',
  },
  {
    sku: '3611220-02',
    photo:
      'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-vr-store-3d/cotton-on-chadstone/product-images/3611220-02.jpg',
    productName: 'Organic Regular Fit Crew T-Shirt',
    price: '24.99',
    link:
      'https://cottonon.com/AU/organic-regular-fit-crew-t-shirt/3611220-02.html?dwvar_3611220-02_color=3611220-02',
    brand: 'cotton-on',
  },
  {
    sku: '3611149-03',
    photo:
      'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-vr-store-3d/cotton-on-chadstone/product-images/3611149-03.jpg',
    productName: 'Loose Fit Pant',
    price: '69.99',
    link:
      'https://cottonon.com/AU/loose-fit-pant/3611149-03.html?dwvar_3611149-03_color=3611149-03',
    brand: 'cotton-on',
  },
  {
    sku: '3610902-108',
    photo:
      'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-vr-store-3d/cotton-on-chadstone/product-images/3610902-108.jpg',
    productName: 'Rugby Long Sleeve Polo',
    price: '59.99',
    link:
      'https://cottonon.com/AU/rugby-long-sleeve-polo/3610902-108.html?dwvar_3610902-108_color=3610902-108',
    brand: 'cotton-on',
  },
  {
    sku: '3611777-22',
    photo:
      'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-vr-store-3d/cotton-on-chadstone/product-images/3611777-22.jpg',
    productName: 'Slim Straight Jean',
    price: '79.99',
    link:
      'https://cottonon.com/AU/slim-straight-jean/3611777-22.html?dwvar_3611777-22_color=3611777-22',
    brand: 'cotton-on',
  },
  {
    sku: '3611260-12',
    photo:
      'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-vr-store-3d/cotton-on-chadstone/product-images/3611260-12.jpg',
    productName: 'Essential Fleece Pullover',
    price: '39.99',
    link:
      'https://cottonon.com/AU/essential-fleece-pullover/3611260-12.html?dwvar_3611260-12_color=3611260-12',
    brand: 'cotton-on',
  },
  {
    sku: '3610964-19',
    photo:
      'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-vr-store-3d/cotton-on-chadstone/product-images/3610964-19.jpg',
    productName: 'Essential Active Sock',
    price: '6.99',
    link:
      'https://cottonon.com/AU/essential-active-sock/3610964-19.html?dwvar_3610964-19_color=3610964-19',
    brand: 'cotton-on',
  },
  {
    sku: '3611213-53',
    photo:
      'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-vr-store-3d/cotton-on-chadstone/product-images/3611213-53.jpg',
    productName: 'Loose Fit T-Shirt',
    price: '24.99',
    link:
      'https://cottonon.com/AU/loose-fit-t-shirt/3611213-53.html?dwvar_3611213-53_color=3611213-53',
    brand: 'cotton-on',
  },
  {
    sku: '3611997-04',
    photo:
      'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-vr-store-3d/cotton-on-chadstone/product-images/3611997-04.jpg',
    productName: 'Active Nba Oversized Pullover',
    price: '69.99',
    link:
      'https://cottonon.com/AU/active-nba-oversized-pullover/3611997-04.html?dwvar_3611997-04_color=3611997-04',
    brand: 'cotton-on',
  },
  {
    sku: '3610577-01',
    photo:
      'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-vr-store-3d/cotton-on-chadstone/product-images/3610577-01.jpg',
    productName: 'Mens Organic Cotton Trunks',
    price: '19.99',
    link:
      'https://cottonon.com/AU/mens-organic-cotton-trunks/3610577-01.html?dwvar_3610577-01_color=3610577-01',
    brand: 'cotton-on',
  },
  {
    sku: '3611755-07',
    photo:
      'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-vr-store-3d/cotton-on-chadstone/product-images/3611755-07.jpg',
    productName: 'Active (Trippy) Track Pant',
    price: '39.99',
    link:
      'https://cottonon.com/AU/active-%28trippy%29-track-pant/3611755-07.html?dwvar_3611755-07_color=3611755-07',
    brand: 'cotton-on',
  },
  {
    sku: '3610331-05',
    photo:
      'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-vr-store-3d/cotton-on-chadstone/product-images/3610331-05.jpg',
    productName: 'Urban Jogger',
    price: '69.99',
    link: 'https://cottonon.com/AU/urban-jogger/3610331-05.html?dwvar_3610331-05_color=3610331-05',
    brand: 'cotton-on',
  },
  {
    sku: '4590316-07',
    photo:
      'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-vr-store-3d/cotton-on-chadstone/product-images/4590316-07.jpg',
    productName: 'Rex Double Buckle Slide',
    price: '29.99',
    link:
      'https://cottonon.com/AU/rex-double-buckle-slide/4590316-07.html?dwvar_4590316-07_color=4590316-07',
    brand: 'rubi',
  },
  {
    sku: '422833-120',
    photo:
      'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-vr-store-3d/cotton-on-chadstone/product-images/422833-120.jpg',
    productName: 'Carrie Crew Sock',
    price: '6.99',
    link:
      'https://cottonon.com/AU/carrie-crew-sock/422833-120.html?dwvar_422833-120_color=422833-120',
    brand: 'rubi',
  },
  {
    sku: '4588909-24',
    photo:
      'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-vr-store-3d/cotton-on-chadstone/product-images/4588909-24.jpg',
    productName: 'Cabin Moulded Slide',
    price: '24.99',
    link:
      'https://cottonon.com/AU/cabin-moulded-slide/4588909-24.html?dwvar_4588909-24_color=4588909-24',
    brand: 'rubi',
  },
  {
    sku: '4589763-16',
    photo:
      'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-vr-store-3d/cotton-on-chadstone/product-images/4589763-16.jpg',
    productName: 'Isla Hair Claw',
    price: '12.99',
    link:
      'https://cottonon.com/AU/isla-hair-claw/4589763-16.html?dwvar_4589763-16_color=4589763-16',
    brand: 'rubi',
  },
  {
    sku: '4255639-07',
    photo:
      'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-vr-store-3d/cotton-on-chadstone/product-images/4255639-07.jpg',
    productName: 'Lexi Underarm Bag',
    price: '24.99',
    link:
      'https://cottonon.com/AU/lexi-underarm-bag/4255639-07.html?dwvar_4255639-07_color=4255639-07',
    brand: 'rubi',
  },
  {
    sku: '4589889-32',
    photo:
      'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-vr-store-3d/cotton-on-chadstone/product-images/4589889-32.jpg',
    productName: 'Hanna Platform Mule',
    price: '69.99',
    link:
      'https://cottonon.com/AU/hanna-platform-mule/4589889-32.html?dwvar_4589889-32_color=4589889-32',
    brand: 'rubi',
  },
  {
    sku: '4590319-01',
    photo:
      'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-vr-store-3d/cotton-on-chadstone/product-images/4590319-01.jpg',
    productName: 'Premium Bracelet Stack Silver Plated',
    price: '19.99',
    link:
      'https://cottonon.com/AU/premium-bracelet-stack-silver-plated/4590319-01.html?dwvar_4590319-01_color=4590319-01',
    brand: 'rubi',
  },
  {
    sku: '4590089-01',
    photo:
      'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-vr-store-3d/cotton-on-chadstone/product-images/4590089-01.jpg',
    productName: 'Premium Beaded Necklace Gold Plated',
    price: '24.99',
    link:
      'https://cottonon.com/AU/premium-beaded-necklace-gold-plated/4590089-01.html?dwvar_4590089-01_color=4590089-01',
    brand: 'rubi',
  },
  {
    sku: '6334440-04',
    photo:
      'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-vr-store-3d/cotton-on-chadstone/product-images/6334440-04.jpg',
    productName: 'Active Woven Pant',
    price: '49.99',
    link:
      'https://cottonon.com/AU/active-woven-pant/6334440-04.html?dwvar_6334440-04_color=6334440-04',
    brand: 'body',
  },
  {
    sku: '6333834-03',
    photo:
      'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-vr-store-3d/cotton-on-chadstone/product-images/6333834-03.jpg',
    productName: 'Woven Cargo Pant',
    price: '49.99',
    link:
      'https://cottonon.com/AU/woven-cargo-pant/6333834-03.html?dwvar_6333834-03_color=6333834-03',
    brand: 'body',
  },
  {
    sku: '6333009-13',
    photo:
      'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-vr-store-3d/cotton-on-chadstone/product-images/6333009-13.jpg',
    productName: 'Lifestyle Strappy Crop',
    price: '24.99',
    link:
      'https://cottonon.com/AU/lifestyle-strappy-crop/6333009-13.html?dwvar_6333009-13_color=6333009-13',
    brand: 'body',
  },
  {
    sku: '6334586-01',
    photo:
      'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-vr-store-3d/cotton-on-chadstone/product-images/6334586-01.jpg',
    productName: 'Ultra Soft Booty Lift Pocket Full Length Tight',
    price: '39.99',
    link:
      'https://cottonon.com/AU/ultra-soft-booty-lift-pocket-full-length-tight/6334586-01.html?dwvar_6334586-01_color=6334586-01',
    brand: 'body',
  },
  {
    sku: '6334193-10',
    photo:
      'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-vr-store-3d/cotton-on-chadstone/product-images/6334193-10.jpg',
    productName: 'Pocket Move Jogger',
    price: '34.99',
    link:
      'https://cottonon.com/AU/pocket-move-jogger/6334193-10.html?dwvar_6334193-10_color=6334193-10',
    brand: 'body',
  },
  {
    sku: '6334442-02',
    photo:
      'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-vr-store-3d/cotton-on-chadstone/product-images/6334442-02.jpg',
    productName: 'Active Bomber Jacket',
    price: '69.99',
    link:
      'https://cottonon.com/AU/active-bomber-jacket/6334442-02.html?dwvar_6334442-02_color=6334442-02',
    brand: 'body',
  },
  {
    sku: '6334690-01',
    photo:
      'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-vr-store-3d/cotton-on-chadstone/product-images/6334690-01.jpg',
    productName: 'Seamless Full Length Tight',
    price: '44.99',
    link:
      'https://cottonon.com/AU/seamless-full-length-tight/6334690-01.html?dwvar_6334690-01_color=6334690-01',
    brand: 'body',
  },
  {
    sku: '669332-29',
    photo:
      'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-vr-store-3d/cotton-on-chadstone/product-images/669332-29.jpg',
    productName: 'Highwaisted Shortie Short',
    price: '24.99',
    link:
      'https://cottonon.com/AU/highwaisted-shortie-short/669332-29.html?dwvar_669332-29_color=669332-29',
    brand: 'body',
  },
  {
    sku: '6333008-12',
    photo:
      'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-vr-store-3d/cotton-on-chadstone/product-images/6333008-12.jpg',
    productName: 'Lifestyle Cut Out Crop',
    price: '24.99',
    link:
      'https://cottonon.com/AU/lifestyle-cut-out-crop/6333008-12.html?dwvar_6333008-12_color=6333008-12',
    brand: 'body',
  },
  {
    sku: '6334512-01',
    photo:
      'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-vr-store-3d/cotton-on-chadstone/product-images/6334512-01.jpg',
    productName: 'Plush Fleece Short',
    price: '29.99',
    link:
      'https://cottonon.com/AU/plush-fleece-short/6334512-01.html?dwvar_6334512-01_color=6334512-01',
    brand: 'body',
  },
  {
    sku: '6333862-28',
    photo:
      'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-vr-store-3d/cotton-on-chadstone/product-images/6333862-28.jpg',
    productName: 'Plush Boyfriend Oversized Graphic Crew',
    price: '49.99',
    link:
      'https://cottonon.com/AU/plush-boyfriend-oversized-graphic-crew/6333862-28.html?dwvar_6333862-28_color=6333862-22',
    brand: 'body',
  },
  {
    sku: '426358-02',
    photo:
      'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-vr-store-3d/cotton-on-chadstone/product-images/426358-02.jpg',
    productName: 'Yoga Mat',
    price: '29.99',
    link: 'https://cottonon.com/AU/yoga-mat/426358-02.html?dwvar_426358-02_color=426358-02',
    brand: 'body',
  },
  {
    sku: '6334654-08',
    photo:
      'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-vr-store-3d/cotton-on-chadstone/product-images/6334654-08.jpg',
    productName: 'Sleep Recovery Cropped Long Sleeve Top',
    price: '139.3',
    link: 'https://cottonon.com/AU/sleep-recovery-cropped-long-sleeve-top/6334654-08.html',
    brand: 'body',
  },
  {
    sku: '6335042-08',
    photo:
      'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-vr-store-3d/cotton-on-chadstone/product-images/6335042-08.jpg',
    productName: 'Rib Lace Short',
    price: '24.99',
    link:
      'https://cottonon.com/AU/rib-lace-short/6335042-08.html?dwvar_6335042-08_color=6335042-08&cgid=co&originalPid=6335042-08#q=rib%20lace&start=1&sz=60',
    brand: 'body',
  },
  {
    sku: '6332798-142',
    photo:
      'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-vr-store-3d/cotton-on-chadstone/product-images/6332798-142.jpg',
    productName: '90S T-Shirt Nightie',
    price: '34.99',
    link:
      'https://cottonon.com/AU/90s-t-shirt-nightie/6332798-142.html?dwvar_6332798-142_color=6332798-142&cgid=co&originalPid=6332798-142#q=90s&start=1&sz=60',
    brand: 'body',
  },
  {
    sku: '668186-09',
    photo:
      'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-vr-store-3d/cotton-on-chadstone/product-images/668186-09.jpg',
    productName: 'Ultimate Comfort T-Shirt Bra',
    price: '29.99',
    link:
      'https://cottonon.com/AU/ultimate-comfort-t-shirt-bra/668186-09.html?dwvar_668186-09_color=668186-09&cgid=bras&originalPid=668186-09#start=1&sz=60',
    brand: 'body',
  },
  {
    sku: '661763-02',
    photo:
      'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-vr-store-3d/cotton-on-chadstone/product-images/661763-02.jpg',
    productName: 'Party Pants Seamless Bikini Brief',
    price: '11.95',
    link:
      'https://cottonon.com/AU/party-pants-seamless-bikini-brief/661763-02.html?dwvar_661763-02_color=661763-02&cgid=&originalPid=661763-02',
    brand: 'body',
  },
  {
    sku: '6332831-39',
    photo:
      'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-vr-store-3d/cotton-on-chadstone/product-images/6332831-39.jpg',
    productName: 'Seamless Chunky Triangle Bralette',
    price: '19.99',
    link:
      'https://cottonon.com/AU/seamless-chunky-triangle-bralette/6332831-39.html?dwvar_6332831-39_color=6332831-39&cgid=unlined-bras&originalPid=6332831-39#start=20&sz=60',
    brand: 'body',
  },
  {
    sku: '6334601-01',
    photo:
      'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-vr-store-3d/cotton-on-chadstone/product-images/6334601-01.jpg',
    productName: 'Meadow Floral Lace Push Up2 Bra',
    price: '34.99',
    link:
      'https://cottonon.com/AU/meadow-floral-lace-push-up2-bra/6334601-01.html?dwvar_6334601-01_color=6334601-01&cgid=push-up-bras&originalPid=6334601-01#start=14&sz=60',
    brand: 'body',
  },
  {
    sku: '6334558-13',
    photo:
      'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-vr-store-3d/cotton-on-chadstone/product-images/6334558-13.jpg',
    productName: 'Organic Cotton Triangle Padded Bralette',
    price: '24.99',
    link:
      'https://cottonon.com/AU/organic-cotton-triangle-padded-bralette/6334558-13.html?dwvar_6334558-13_color=6334558-13&cgid=unlined-bras&originalPid=6334558-13#start=8&sz=60',
    brand: 'body',
  },
  {
    sku: '6333443-11',
    photo:
      'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-vr-store-3d/cotton-on-chadstone/product-images/6333443-11.jpg',
    productName: 'Square Toe Slide Slipper',
    price: '19.99',
    link:
      'https://cottonon.com/AU/square-toe-slide-slipper/6333443-11.html?dwvar_6333443-11_color=6333443-11&cgid=womens-sleepwear-accessories&originalPid=6333443-11#start=50&sz=60',
    brand: 'body',
  },
  {
    sku: '6333250-13',
    photo:
      'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-vr-store-3d/cotton-on-chadstone/product-images/6333250-13.jpg',
    productName: 'Satin Pillow Slip Duo',
    price: '24.99',
    link:
      'https://cottonon.com/AU/satin-pillow-slip-duo/6333250-13.html?dwvar_6333250-13_color=6333250-13&cgid=womens-sleepwear-accessories&originalPid=6333250-13#start=47&sz=60',
    brand: 'body',
  },
];

export default products;
