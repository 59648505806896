import locationService from '@apis/services/location.service';
import useModal from '@features/layout/modal/hooks/useModal';
import { MediaModalBody } from '@features/media';
import { ProductFullScreenBody, ProductModalBody } from '@features/products';
import useCameraTarget from '@hooks/useCameraTarget';
import { animated, config, useSpring } from '@react-spring/three';
import navigationManager from '@utils/navigation.manager';
import React, { memo, useEffect, useMemo, useState } from 'react';
import { Vector3 } from 'three';

import { makeStyles } from '@material-ui/core';

import { Billboard, Html, Line, Text, useCursor } from '@react-three/drei';

import useTagsStatus from '../hooks/useTagsStatus';

const useStyles = makeStyles((theme) => ({
  tagTitle: {
    fontSize: '16px',
    lineHeight: 1.5,
    letterSpacing: '-0.017em',
    textTransform: 'uppercase',
    color: '#fff',
    padding: '0px 4px',
    textShadow: '1px 1px 1px rgba(0, 0, 0, 0.25)',
  },
  tagDescription: {
    fontSize: '13px',
    letterSpacing: '-0.017em',
    textShadow: '1px 1px 1px rgba(0, 0, 0, 0.25)',
  },
}));

const Tag = (props) => {
  const { tagData } = props;
  const classes = useStyles();
  const [hovered, setHovered] = useState(false);
  const [active, setActive] = useState(false);
  const [focus, setFocus] = useState(false);
  const { activeTagIds, focusTagIds, setCurrentActiveTag, checkIsInFocusArea } = useTagsStatus();
  useCursor(hovered);
  const vec3 = useMemo(() => new Vector3(), []);

  const { setCameraTargetPosition } = useCameraTarget();
  const { open } = useModal();

  useEffect(() => {
    if (focusTagIds && focusTagIds.includes(tagData.id)) {
      setFocus(true);
    } else {
      setFocus(false);
    }
  }, [focusTagIds]);

  useEffect(() => {
    if (activeTagIds && activeTagIds.includes(tagData.id)) {
      setActive(true);
    } else {
      setActive(false);
    }
  }, [activeTagIds]);

  const { scale, rotation, borderScale, dotScale } = useSpring({
    scale: hovered || active ? 1 : 0,
    rotation: hovered || active ? [0, 0, Math.PI / 8] : [0, 0, 0],
    borderScale: focus ? 1 : 1,
    dotScale: focus ? 1 : 1,
    config: config.gentle,
  });

  const handleActiveTag = (e, tag) => {
    e.stopPropagation();
    let inFocusArea = checkIsInFocusArea(tag.id);
    const modalBody = tag?.type === 'price' ? <ProductFullScreenBody /> : <MediaModalBody />;
    if (inFocusArea) {
      setCurrentActiveTag(tag.id);
      setCameraTargetPosition(tag.position);
      open(modalBody);
    } else {
      const handleOnceRest = () => {
        setCurrentActiveTag(tag.id);
        setCameraTargetPosition(tag.position);
        open(modalBody);
      };

      let position = vec3.set(tag.position.x, tag.position.y, tag.position.z);
      let nearestLocation = locationService.getNearestLocation(position);
      navigationManager.onceRest(handleOnceRest);
      navigationManager.requestNewLocation(nearestLocation, true);
    }
  };

  const handlePointerOver = () => {
    setHovered(true);
  };

  const handlePointerOut = () => {
    setHovered(false);
  };

  const [showTagDescription, setShowTagDescription] = useState(false);

  useEffect(() => {
    if (tagData.seriesName && tagData.name && tagData.description) {
      setShowTagDescription(true);
    }
  }, [tagData]);

  return (
    <group
      position={[0, 0, 0]}
      scale={0.07}
      onClick={(e) => {
        handleActiveTag(e, tagData);
      }}
      onPointerOver={handlePointerOver}
      onPointerOut={handlePointerOut}
    >
      <Billboard follow={true} position={[0, 1, 0]}>
        <group scale={1.5} renderOrder={1}>
          <animated.group rotation={rotation} renderOrder={4}>
            <Text
              font={
                'https://fonts.gstatic.com/s/materialicons/v70/flUhRq6tzZclQEJ-Vdg-IuiaDsNa.woff'
              }
              text={tagData.type === 'price' ? 'drive_eta' : 'assignment'}
              fontSize={0.75}
              anchorX="center"
              anchorY="middle"
              color={focus ? '#fff' : '#fff'}
              renderOrder={3}
            />
          </animated.group>
          <animated.mesh renderOrder={3} scale={dotScale}>
            <circleGeometry args={[0.65, 32]} />
            <meshStandardMaterial
              color={'#000'}
              depthWrite={true}
              transparent
              opacity={1}
              alphaToCoverage
            />
          </animated.mesh>
          <animated.mesh renderOrder={2} scale={dotScale}>
            <circleGeometry args={[0.75, 32]} />
            <meshStandardMaterial
              color={'#00BA3D'}
              depthWrite={true}
              transparent
              opacity={1}
              alphaToCoverage
            />
          </animated.mesh>
          <animated.mesh renderOrder={1} scale={borderScale}>
            <circleGeometry args={[1, 32]} />
            <meshStandardMaterial
              color={'#000'}
              depthWrite={true}
              transparent
              opacity={0.5}
              alphaToCoverage
            />
          </animated.mesh>
        </group>
        <animated.group scale={scale} renderOrder={1} visible={showTagDescription}>
          <Line
            points={[
              [0, 0, 0],
              [0, 1.5, 0],
              [4, 1.5, 0],
            ]}
            color="#fff"
            opacity={1}
            lineWidth={3}
            transparent
            depthWrite={false}
          />
          <Html
            position={[0, 2.75, 0]}
            transform
            zIndexRange={[0, 0]}
            scale={2}
            renderOrder={3}
            style={{
              // width: '100%',
              pointerEvents: 'none',
              opacity: showTagDescription ? 1 : 0,
            }}
          >
            <div style={{ position: 'relative', left: '50%' }}>
              <div>
                <span
                  className={classes.tagTitle}
                  style={{
                    background: '#000000',
                    boxShadow: '1px 1px 2px rgba(0, 0, 0, 0.25)',
                    fontWeight: '700',
                  }}
                >
                  {tagData.seriesName}
                </span>
                <span className={classes.tagTitle}>{tagData.name}</span>
              </div>
              <div>
                <span className={classes.tagDescription}>{tagData.description}</span>
              </div>
            </div>
          </Html>
        </animated.group>
      </Billboard>
    </group>
  );
};

export default memo(Tag);
