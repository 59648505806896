const locations = [
  {
    sid: 'dbf8215e795441e3b2a49b3e51867643',
    id: 'dbf8215e795441e3b2a49b3e51867643',
    position: {
      x: 1.919808268547058,
      y: 1.5760042667388916,
      z: 0.850849986076355,
    },
    rotation: {
      x: -0.8822372255232477,
      y: -85.23943729637169,
      z: 0.43086428832808676,
    },
  },
  {
    sid: '9a3d677d1abf410cb6da3ebc79a07a6e',
    id: '9a3d677d1abf410cb6da3ebc79a07a6e',
    position: {
      x: 5.493325233459473,
      y: 1.5776126384735107,
      z: 0.8266534209251404,
    },
    rotation: {
      x: 0.18487243704953307,
      y: 99.81292340937567,
      z: 0.5902372786991844,
    },
  },
  {
    sid: 'e9a9b40394ad4da8a8a6273f14ea6a93',
    id: 'e9a9b40394ad4da8a8a6273f14ea6a93',
    position: {
      x: 9.186874389648438,
      y: 1.5793542861938477,
      z: 0.8810105323791504,
    },
    rotation: {
      x: 0.3957992608985196,
      y: 106.04243837287048,
      z: 0.7648233861492092,
    },
  },
  {
    sid: '31987f69802e4dfcba68caec01d618ae',
    id: '31987f69802e4dfcba68caec01d618ae',
    position: {
      x: 12.779796600341797,
      y: 1.583815336227417,
      z: 0.8682946562767029,
    },
    rotation: {
      x: 0.13871929812511394,
      y: 107.68443866451656,
      z: 0.8338346615683156,
    },
  },
  {
    sid: '2fa07509fb5b438e83b480e162a7b55d',
    id: '2fa07509fb5b438e83b480e162a7b55d',
    position: {
      x: 13.17204761505127,
      y: 1.562070608139038,
      z: 3.5294747352600098,
    },
    rotation: {
      x: -0.6723798893989575,
      y: -93.22106960552368,
      z: 0.29967679851526197,
    },
  },
  {
    sid: '549b7d66d0e54a94ae8226f33b3e41cb',
    id: '549b7d66d0e54a94ae8226f33b3e41cb',
    position: {
      x: 9.783974647521973,
      y: 1.570878267288208,
      z: 3.4547243118286133,
    },
    rotation: {
      x: 0.8051620253851034,
      y: -99.20840276958059,
      z: 0.7371636260966354,
    },
  },
  {
    sid: '42b24083118e4ea7a645fe3c10ade766',
    id: '42b24083118e4ea7a645fe3c10ade766',
    position: {
      x: 8.012661933898926,
      y: 1.5608692169189453,
      z: 3.526804208755493,
    },
    rotation: {
      x: -0.5494704445797676,
      y: -89.91456859619409,
      z: 0.6594786737293848,
    },
  },
  {
    sid: 'dd6c40e741b24f2da069c8e4ae6ef5f1',
    id: 'dd6c40e741b24f2da069c8e4ae6ef5f1',
    position: {
      x: 6.083571434020996,
      y: 1.566833257675171,
      z: 3.511000394821167,
    },
    rotation: {
      x: -0.8980342363294631,
      y: -90.11614181629773,
      z: 0.9126648844280244,
    },
  },
  {
    sid: 'd7c1c461878f48ddb3f614649b6cf52f',
    id: 'd7c1c461878f48ddb3f614649b6cf52f',
    position: {
      x: 3.443751811981201,
      y: 1.5796778202056885,
      z: 3.46018648147583,
    },
    rotation: {
      x: 0.28619725961049636,
      y: -88.73662988498099,
      z: 0.9119166744615876,
    },
  },
  {
    sid: '47e6923919a84299a8db219ffb51f4f0',
    id: '47e6923919a84299a8db219ffb51f4f0',
    position: {
      x: 1.7737929821014404,
      y: 1.572145938873291,
      z: 3.453563928604126,
    },
    rotation: {
      x: -0.1435149863518368,
      y: -86.87460798679946,
      z: 0.25927042800571215,
    },
  },
  {
    sid: '4f8bda1f9dfe4be9a0193f9c1a0b3ea6',
    id: '4f8bda1f9dfe4be9a0193f9c1a0b3ea6',
    position: {
      x: 1.9064801931381226,
      y: 1.5768203735351562,
      z: 6.386734962463379,
    },
    rotation: {
      x: 0.055715985158993495,
      y: -88.32067302171619,
      z: 0.6948968196186038,
    },
  },
  {
    sid: 'f80a690eab8f43238d03b5588a68a856',
    id: 'f80a690eab8f43238d03b5588a68a856',
    position: {
      x: 3.748828411102295,
      y: 1.5825822353363037,
      z: 6.412437915802002,
    },
    rotation: {
      x: -0.5748441831187433,
      y: -94.74755529948966,
      z: 0.47687910602043815,
    },
  },
  {
    sid: '07984332bfef4d5986fbfb05266547fb',
    id: '07984332bfef4d5986fbfb05266547fb',
    position: {
      x: 5.912014007568359,
      y: 1.582238793373108,
      z: 6.431298732757568,
    },
    rotation: {
      x: -0.323286219137845,
      y: -100.26340570686607,
      z: 0.36633132791767264,
    },
  },
  {
    sid: 'cdf21bc1638d4a3bbdf5162ff6ce772c',
    id: 'cdf21bc1638d4a3bbdf5162ff6ce772c',
    position: {
      x: 8.004226684570312,
      y: 1.5776563882827759,
      z: 6.364441871643066,
    },
    rotation: {
      x: -0.17701313149576808,
      y: -92.0164133682407,
      z: 0.7573848889400165,
    },
  },
  {
    sid: 'd364a4ca3f174ca383c50ebc3ee730ef',
    id: 'd364a4ca3f174ca383c50ebc3ee730ef',
    position: {
      x: 9.891580581665039,
      y: 1.5626393556594849,
      z: 6.2907633781433105,
    },
    rotation: {
      x: -0.6930178090642913,
      y: -132.07342911072854,
      z: 0.37689277663488446,
    },
  },
  {
    sid: 'c8a097f3c0394c7c929f9f383a6ccf28',
    id: 'c8a097f3c0394c7c929f9f383a6ccf28',
    position: {
      x: 11.510669708251953,
      y: 1.5589234828948975,
      z: 7.17088508605957,
    },
    rotation: {
      x: -0.7466314943902689,
      y: -108.76825748000532,
      z: 0.701540563507848,
    },
  },
  {
    sid: '12c3aa47974b41928cb7f3da8b344536',
    id: '12c3aa47974b41928cb7f3da8b344536',
    position: {
      x: 13.224457740783691,
      y: 1.5576322078704834,
      z: 7.077054977416992,
    },
    rotation: {
      x: -0.4278722063994312,
      y: -109.53950022548756,
      z: 1.088523495408847,
    },
  },
  {
    sid: '700dc2b6af884dde9a12a0095b2708d2',
    id: '700dc2b6af884dde9a12a0095b2708d2',
    position: {
      x: 13.069323539733887,
      y: 1.5717980861663818,
      z: 11.398191452026367,
    },
    rotation: {
      x: -0.32826432597909294,
      y: -112.68339137137441,
      z: 0.08152921135267273,
    },
  },
  {
    sid: 'c724b151754045a9ab1b9faea84c642f',
    id: 'c724b151754045a9ab1b9faea84c642f',
    position: {
      x: 9.757262229919434,
      y: 1.5732172727584839,
      z: 11.121028900146484,
    },
    rotation: {
      x: -0.26568748492181604,
      y: -77.26858859176848,
      z: 1.3220158820768935,
    },
  },
  {
    sid: '854eac9894e54373b7bbb89f98a253b1',
    id: '854eac9894e54373b7bbb89f98a253b1',
    position: {
      x: 9.852178573608398,
      y: 1.574681282043457,
      z: 8.479914665222168,
    },
    rotation: {
      x: -0.8425685396825343,
      y: -96.86964220409737,
      z: 1.4495286967873167,
    },
  },
  {
    sid: '43c0f4d71a5b4a5487030d70cf679f27',
    id: '43c0f4d71a5b4a5487030d70cf679f27',
    position: {
      x: 7.960993766784668,
      y: 1.563416838645935,
      z: 11.622036933898926,
    },
    rotation: {
      x: -0.07872367922520031,
      y: 67.12504020745344,
      z: -1.3972957657232032,
    },
  },
  {
    sid: '5c6e87648e484d18949214feca133940',
    id: '5c6e87648e484d18949214feca133940',
    position: {
      x: 7.987011909484863,
      y: 1.568363904953003,
      z: 8.98849105834961,
    },
    rotation: {
      x: -0.31370945395415295,
      y: 69.11217567543167,
      z: -1.2821184443053817,
    },
  },
  {
    sid: '19ca6dd80faf4c90b33ac0b3927d6c8a',
    id: '19ca6dd80faf4c90b33ac0b3927d6c8a',
    position: {
      x: 5.692027568817139,
      y: 1.5670747756958008,
      z: 9.174652099609375,
    },
    rotation: {
      x: -0.2423599326030714,
      y: 93.9157370129275,
      z: -0.8085933814755473,
    },
  },
  {
    sid: '805e1cb3acc244768c50f648ff5a6783',
    id: '805e1cb3acc244768c50f648ff5a6783',
    position: {
      x: 4.106128692626953,
      y: 1.5680421590805054,
      z: 8.55897045135498,
    },
    rotation: {
      x: -0.46525410549271495,
      y: 44.69835009592132,
      z: -0.9129917842714059,
    },
  },
  {
    sid: 'cfad4ed1726f47ba8b474089110f762f',
    id: 'cfad4ed1726f47ba8b474089110f762f',
    position: {
      x: 4.953307628631592,
      y: 1.569177269935608,
      z: 7.761742115020752,
    },
    rotation: {
      x: 0.24821393554146212,
      y: 45.54991509307334,
      z: -1.258237439681029,
    },
  },
  {
    sid: '57d38fb0b10c4c4993e5396152c72009',
    id: '57d38fb0b10c4c4993e5396152c72009',
    position: {
      x: 1.737694263458252,
      y: 1.5696170330047607,
      z: 8.335036277770996,
    },
    rotation: {
      x: -0.3536971408118846,
      y: 38.48453447914243,
      z: -1.395084626112721,
    },
  },
  {
    sid: '85a4b51986164a6fb3560a433d86e15d',
    id: '85a4b51986164a6fb3560a433d86e15d',
    position: {
      x: 1.62241792678833,
      y: 1.557931661605835,
      z: 12.024914741516113,
    },
    rotation: {
      x: -0.8945680225818369,
      y: 84.23658739847733,
      z: -1.363045181748045,
    },
  },
  {
    sid: '227cea60df7c479b936207bf341b6136',
    id: '227cea60df7c479b936207bf341b6136',
    position: {
      x: 4.2576141357421875,
      y: 1.5662133693695068,
      z: 11.866446495056152,
    },
    rotation: {
      x: -0.006941024907610361,
      y: 108.08941341975108,
      z: -0.7610049431637189,
    },
  },
  {
    sid: 'f787c763c98b4806ad2583103a0ebcac',
    id: 'f787c763c98b4806ad2583103a0ebcac',
    position: {
      x: 6.011338710784912,
      y: 1.5605859756469727,
      z: 11.656539916992188,
    },
    rotation: {
      x: 0.09953147499839762,
      y: 107.83355265259436,
      z: -1.1217200261848537,
    },
  },
  {
    sid: 'b1b4d5e1c5664417bc1bc1fd87ed557a',
    id: 'b1b4d5e1c5664417bc1bc1fd87ed557a',
    position: {
      x: 5.952056407928467,
      y: 1.5635478496551514,
      z: 14.243124008178711,
    },
    rotation: {
      x: -0.007259208487770244,
      y: 108.98199362369259,
      z: -1.4366394626000174,
    },
  },
  {
    sid: 'c0f6b43cadca461da318108a6612f3ec',
    id: 'c0f6b43cadca461da318108a6612f3ec',
    position: {
      x: 8.201044082641602,
      y: 1.561171293258667,
      z: 14.205694198608398,
    },
    rotation: {
      x: -0.8012717808165922,
      y: 93.97758828730723,
      z: -1.2869921960501702,
    },
  },
  {
    sid: 'ab5696b37ad94b5ea333814481f57aa0',
    id: 'ab5696b37ad94b5ea333814481f57aa0',
    position: {
      x: 9.879683494567871,
      y: 1.5632973909378052,
      z: 13.868934631347656,
    },
    rotation: {
      x: -0.17005416370516818,
      y: -6.2433617965389345,
      z: -1.553935971326652,
    },
  },
  {
    sid: 'bfcaee30db334b06a7c1e437e79d07b1',
    id: 'bfcaee30db334b06a7c1e437e79d07b1',
    position: {
      x: 9.907947540283203,
      y: 1.5645132064819336,
      z: 16.665660858154297,
    },
    rotation: {
      x: -0.4432470565747075,
      y: -4.581328694492834,
      z: -1.2154459974497858,
    },
  },
  {
    sid: 'ee0eb41fb3cf41a1bea39a99753c4f25',
    id: 'ee0eb41fb3cf41a1bea39a99753c4f25',
    position: {
      x: 7.750629425048828,
      y: 1.5617352724075317,
      z: 16.700542449951172,
    },
    rotation: {
      x: -0.19373553407505734,
      y: -7.074507347483408,
      z: -1.2234863462559085,
    },
  },
  {
    sid: '9c6a4f4148d1408db07c0b7fb0201d8b',
    id: '9c6a4f4148d1408db07c0b7fb0201d8b',
    position: {
      x: 7.802964210510254,
      y: 1.563910722732544,
      z: 18.75164222717285,
    },
    rotation: {
      x: 0.12672085327980315,
      y: -13.655607893998154,
      z: -1.4996648772244907,
    },
  },
  {
    sid: '88d181507a074ee582339f445e3ea156',
    id: '88d181507a074ee582339f445e3ea156',
    position: {
      x: 4.526036739349365,
      y: 1.5704975128173828,
      z: 18.641185760498047,
    },
    rotation: {
      x: -0.7988917344556604,
      y: 8.217002717858376,
      z: -1.1554313929848519,
    },
  },
  {
    sid: 'eda3b894abec4bea99c3fdb3d6c44694',
    id: 'eda3b894abec4bea99c3fdb3d6c44694',
    position: {
      x: 4.6131720542907715,
      y: 1.5693532228469849,
      z: 16.616411209106445,
    },
    rotation: {
      x: -0.6402614798295377,
      y: -8.175150418375743,
      z: -1.5142337255723928,
    },
  },
  {
    sid: '606ae889830a43f9a70165b803a1e36f',
    id: '606ae889830a43f9a70165b803a1e36f',
    position: {
      x: 1.7794911861419678,
      y: 1.5686630010604858,
      z: 16.645496368408203,
    },
    rotation: {
      x: -0.4700377529597624,
      y: -13.827271585914014,
      z: -1.1502928545530589,
    },
  },
  {
    sid: 'c9e1a7b09ced41fb8c18c9ca0135c994',
    id: 'c9e1a7b09ced41fb8c18c9ca0135c994',
    position: {
      x: -1.5957987308502197,
      y: 1.5792181491851807,
      z: 16.596681594848633,
    },
    rotation: {
      x: -0.579234323292481,
      y: 1.0413572658665113,
      z: -1.0385761148367152,
    },
  },
  {
    sid: '64d5bafb04774fd695b0e95d5501afc1',
    id: '64d5bafb04774fd695b0e95d5501afc1',
    position: {
      x: -3.5445053577423096,
      y: 1.5834364891052246,
      z: 16.560951232910156,
    },
    rotation: {
      x: -0.6828634750925331,
      y: 6.256644832303872,
      z: -0.9189219366683009,
    },
  },
  {
    sid: '71500544c59a481eb135cc35b1dd7b04',
    id: '71500544c59a481eb135cc35b1dd7b04',
    position: {
      x: -7.175246715545654,
      y: 1.5778675079345703,
      z: 16.500890731811523,
    },
    rotation: {
      x: -0.6314160649057814,
      y: 14.715671648408138,
      z: -1.1364276602380823,
    },
  },
  {
    sid: 'fcd7f01a5309418d9eb9b8b4bd978690',
    id: 'fcd7f01a5309418d9eb9b8b4bd978690',
    position: {
      x: -7.242856025695801,
      y: 1.5709887742996216,
      z: 19.18598175048828,
    },
    rotation: {
      x: -0.6894739074068086,
      y: 51.03489257605024,
      z: -0.9841321651704619,
    },
  },
  {
    sid: 'a317e59de5d94a1ab4bc801a14519bd7',
    id: 'a317e59de5d94a1ab4bc801a14519bd7',
    position: {
      x: -7.375294208526611,
      y: 1.552900791168213,
      z: 22.55550765991211,
    },
    rotation: {
      x: -0.5900359750684923,
      y: 79.7709460771412,
      z: -1.2198524986372528,
    },
  },
  {
    sid: '7c235836da5d4a08bc1b29845f8868fa',
    id: '7c235836da5d4a08bc1b29845f8868fa',
    position: {
      x: -6.742221355438232,
      y: 1.5518983602523804,
      z: 22.378860473632812,
    },
    rotation: {
      x: -0.7318473018731275,
      y: 8.25753274893003,
      z: -1.3945044025458322,
    },
  },
  {
    sid: 'ae81ee6081574edfa6205eea91b4462c',
    id: 'ae81ee6081574edfa6205eea91b4462c',
    position: {
      x: -6.999390125274658,
      y: 1.5680522918701172,
      z: 25.323741912841797,
    },
    rotation: {
      x: -0.18864645017742188,
      y: -37.804458785473756,
      z: -1.0381554473589056,
    },
  },
  {
    sid: '5015fa1915f941e98aead49e59f5d7de',
    id: '5015fa1915f941e98aead49e59f5d7de',
    position: {
      x: -7.237658977508545,
      y: 1.5706865787506104,
      z: 27.0725040435791,
    },
    rotation: {
      x: -0.012532292284885758,
      y: -72.00917633799004,
      z: -1.1308473754100266,
    },
  },
  {
    sid: 'c6fb37bd34bf4afba99d8ca3840ce580',
    id: 'c6fb37bd34bf4afba99d8ca3840ce580',
    position: {
      x: -4.263131141662598,
      y: 1.5639493465423584,
      z: 27.096834182739258,
    },
    rotation: {
      x: -0.4905966167329607,
      y: -61.763558660282335,
      z: -0.9560618858533177,
    },
  },
  {
    sid: 'ac342399908f43cfb19183294135974f',
    id: 'ac342399908f43cfb19183294135974f',
    position: {
      x: -4.091457843780518,
      y: 1.5613993406295776,
      z: 24.531980514526367,
    },
    rotation: {
      x: -0.6882780962071581,
      y: -67.86287452308964,
      z: -1.2110798945868406,
    },
  },
  {
    sid: '228bdad574ff40a28f30f9405d345b95',
    id: '228bdad574ff40a28f30f9405d345b95',
    position: {
      x: -4.211728572845459,
      y: 1.5576071739196777,
      z: 22.404314041137695,
    },
    rotation: {
      x: -0.13821268548004104,
      y: -67.35043012333418,
      z: -0.3722439230980264,
    },
  },
  {
    sid: '8184e30f8ec547c78518991e9f876de8',
    id: '8184e30f8ec547c78518991e9f876de8',
    position: {
      x: -4.085206508636475,
      y: 1.5712146759033203,
      z: 19.054237365722656,
    },
    rotation: {
      x: -0.6381936550677281,
      y: -66.29467073562095,
      z: -1.5825962066534944,
    },
  },
  {
    sid: '782152a22430431ba31fb72b3988c578',
    id: '782152a22430431ba31fb72b3988c578',
    position: {
      x: -2.946516990661621,
      y: 1.5758594274520874,
      z: 19.00483512878418,
    },
    rotation: {
      x: -0.4362892330613303,
      y: -104.06979239985466,
      z: -1.40857299067625,
    },
  },
  {
    sid: '1ae6114c5a544e209e492549e3427fd9',
    id: '1ae6114c5a544e209e492549e3427fd9',
    position: {
      x: -1.1795101165771484,
      y: 1.5791747570037842,
      z: 19.101932525634766,
    },
    rotation: {
      x: -0.06618934151099616,
      y: -107.10979215399776,
      z: -1.4226497831958171,
    },
  },
  {
    sid: '4c9c35a14e1647f3ab2d10b8432a19c3',
    id: '4c9c35a14e1647f3ab2d10b8432a19c3',
    position: {
      x: 1.4967283010482788,
      y: 1.5753498077392578,
      z: 19.16111946105957,
    },
    rotation: {
      x: -0.2952859961838614,
      y: -129.98531020959834,
      z: -1.0622216269961493,
    },
  },
  {
    sid: 'ee6c2c9227f44f568ebaa05e935134d4',
    id: 'ee6c2c9227f44f568ebaa05e935134d4',
    position: {
      x: 1.3980848789215088,
      y: 1.5767314434051514,
      z: 21.64824867248535,
    },
    rotation: {
      x: -0.663650243337154,
      y: -175.23282828128075,
      z: -1.3437223785309755,
    },
  },
  {
    sid: '4845aab9d2e549abbe5280bd070f25d8',
    id: '4845aab9d2e549abbe5280bd070f25d8',
    position: {
      x: 1.969732403755188,
      y: 1.5769037008285522,
      z: 22.812440872192383,
    },
    rotation: {
      x: -0.7368854827592909,
      y: -174.96829826555816,
      z: -1.0159982844248052,
    },
  },
  {
    sid: '3a9a9571fee7471d94a2ecb6afd2a98f',
    id: '3a9a9571fee7471d94a2ecb6afd2a98f',
    position: {
      x: 1.9659614562988281,
      y: 1.574538230895996,
      z: 24.409563064575195,
    },
    rotation: {
      x: -0.5838118390896692,
      y: 166.39251332720056,
      z: -0.9066234891365177,
    },
  },
  {
    sid: '3aa9372e570742c58d135825e3433b6c',
    id: '3aa9372e570742c58d135825e3433b6c',
    position: {
      x: -1.4296765327453613,
      y: 1.5665626525878906,
      z: 24.28667449951172,
    },
    rotation: {
      x: 0.2290288073393923,
      y: 172.1162796634362,
      z: -1.420483580069348,
    },
  },
  {
    sid: 'e9124a2fb461457893573526b405ade9',
    id: 'e9124a2fb461457893573526b405ade9',
    position: {
      x: -1.5231268405914307,
      y: 1.5623886585235596,
      z: 22.502708435058594,
    },
    rotation: {
      x: -0.04268650816658548,
      y: -170.54070156696676,
      z: -1.2768566115108662,
    },
  },
  {
    sid: 'c057d6c0a04e4ea0a03f7684f8669351',
    id: 'c057d6c0a04e4ea0a03f7684f8669351',
    position: {
      x: -1.1845064163208008,
      y: 1.5714750289916992,
      z: 28.546632766723633,
    },
    rotation: {
      x: -0.2309343185298014,
      y: 15.405134098664318,
      z: -1.147797711593667,
    },
  },
  {
    sid: '57e9b6ed65d0418cb073fb5ee05451b6',
    id: '57e9b6ed65d0418cb073fb5ee05451b6',
    position: {
      x: 2.1578943729400635,
      y: 1.5732817649841309,
      z: 28.649608612060547,
    },
    rotation: {
      x: 0.17307204435896917,
      y: 7.341826446942374,
      z: -1.3242949311983157,
    },
  },
  {
    sid: '90587d7950e34869bc9617b6cd7a6904',
    id: '90587d7950e34869bc9617b6cd7a6904',
    position: {
      x: -4.334458827972412,
      y: 1.5681204795837402,
      z: 28.554153442382812,
    },
    rotation: {
      x: -0.5785148463368456,
      y: 11.64807724401816,
      z: -0.6023617434408173,
    },
  },
  {
    sid: '88214e79c1c1406fac87f9c14cd4e2fd',
    id: '88214e79c1c1406fac87f9c14cd4e2fd',
    position: {
      x: -4.410405158996582,
      y: 1.5741357803344727,
      z: 31.98790168762207,
    },
    rotation: {
      x: 0.22063089062219668,
      y: 12.318610436228592,
      z: -1.0484896320426005,
    },
  },
  {
    sid: 'c4fb53472838481483a9fa2feda4ce5c',
    id: 'c4fb53472838481483a9fa2feda4ce5c',
    position: {
      x: -7.479637145996094,
      y: 1.5636084079742432,
      z: 32.02794647216797,
    },
    rotation: {
      x: -0.394598590702106,
      y: -0.752758216621279,
      z: -0.5310193457500949,
    },
  },
  {
    sid: 'b159df481f104438bd372d42bea0704e',
    id: 'b159df481f104438bd372d42bea0704e',
    position: {
      x: -7.623317241668701,
      y: 1.5645748376846313,
      z: 33.97555160522461,
    },
    rotation: {
      x: 0.22282068014793535,
      y: 2.217143434323482,
      z: -0.7512826628059671,
    },
  },
  {
    sid: '8fc2927b6ca04c739dfee90eb7c2d046',
    id: '8fc2927b6ca04c739dfee90eb7c2d046',
    position: {
      x: -9.107776641845703,
      y: 1.5713562965393066,
      z: 32.24126434326172,
    },
    rotation: {
      x: -0.008026554069626754,
      y: -67.29326407183665,
      z: -1.7541113231478285,
    },
  },
  {
    sid: '538662ffcad34e7a926fe0c1a28e609b',
    id: '538662ffcad34e7a926fe0c1a28e609b',
    position: {
      x: -11.3910551071167,
      y: 1.581330418586731,
      z: 32.17350769042969,
    },
    rotation: {
      x: -0.36496629627294247,
      y: -19.44427450306726,
      z: -0.8070348632607394,
    },
  },
  {
    sid: '17a0093774cb4e7195ef63bdc94c2da5',
    id: '17a0093774cb4e7195ef63bdc94c2da5',
    position: {
      x: -10.577874183654785,
      y: 1.5761237144470215,
      z: 30.810949325561523,
    },
    rotation: {
      x: -0.437925406072772,
      y: -43.4846742962307,
      z: -1.1714901049419444,
    },
  },
  {
    sid: 'f8db529a29f34f5b956d0fd4b66054a0',
    id: 'f8db529a29f34f5b956d0fd4b66054a0',
    position: {
      x: -9.361566543579102,
      y: 1.5752663612365723,
      z: 30.721235275268555,
    },
    rotation: {
      x: -1.0185623091340785,
      y: -55.822046012991116,
      z: -1.608422049899961,
    },
  },
  {
    sid: '552f50ee223e4d018457f7fa61f1bcbc',
    id: '552f50ee223e4d018457f7fa61f1bcbc',
    position: {
      x: -12.243668556213379,
      y: 1.5892045497894287,
      z: 30.589269638061523,
    },
    rotation: {
      x: -0.45715411693157926,
      y: -24.692349944786834,
      z: -0.6441716163701555,
    },
  },
  {
    sid: '72c6d28868cc4f5ebc2995c861b2a7dc',
    id: '72c6d28868cc4f5ebc2995c861b2a7dc',
    position: {
      x: -12.345999717712402,
      y: 1.583237886428833,
      z: 33.18977737426758,
    },
    rotation: {
      x: -0.020165791202882765,
      y: -30.358887817787714,
      z: -0.8583618823829937,
    },
  },
  {
    sid: 'bee8f190003c447ba9768330c9610779',
    id: 'bee8f190003c447ba9768330c9610779',
    position: {
      x: -10.362857818603516,
      y: 1.573992133140564,
      z: 33.843971252441406,
    },
    rotation: {
      x: 0.13362351877099032,
      y: -22.192418719921953,
      z: -1.9905572650220973,
    },
  },
  {
    sid: '4cd9dffa6e624ba2a253d559fb2752cf',
    id: '4cd9dffa6e624ba2a253d559fb2752cf',
    position: {
      x: -11.974347114562988,
      y: 1.5834226608276367,
      z: 35.72209548950195,
    },
    rotation: {
      x: 0.07376249915653975,
      y: 22.66493856839654,
      z: -0.5109874255350094,
    },
  },
  {
    sid: 'a30824da14034b2a94824b90744645b0',
    id: 'a30824da14034b2a94824b90744645b0',
    position: {
      x: -12.002683639526367,
      y: 1.5783456563949585,
      z: 37.7163200378418,
    },
    rotation: {
      x: -0.10025652342964114,
      y: 28.93008437000838,
      z: -0.7646032909674932,
    },
  },
  {
    sid: '0ca0e733f8874bba8521a7a035a102e8',
    id: '0ca0e733f8874bba8521a7a035a102e8',
    position: {
      x: -12.017950057983398,
      y: 1.5751371383666992,
      z: 39.647769927978516,
    },
    rotation: {
      x: -0.059347643203677934,
      y: 37.29007793816769,
      z: -0.36829157822466785,
    },
  },
  {
    sid: 'a3af8af947d84e7a9ec5885eb742834b',
    id: 'a3af8af947d84e7a9ec5885eb742834b',
    position: {
      x: -8.812365531921387,
      y: 1.571195363998413,
      z: 39.67374038696289,
    },
    rotation: {
      x: 0.20257932906932596,
      y: 35.42879248611427,
      z: -1.2221141131604685,
    },
  },
  {
    sid: 'fbd3181b4a7a41958264cca7770510c5',
    id: 'fbd3181b4a7a41958264cca7770510c5',
    position: {
      x: -8.791168212890625,
      y: 1.5701103210449219,
      z: 37.47067642211914,
    },
    rotation: {
      x: -0.937547533341372,
      y: -51.55606881921947,
      z: -1.253880928921311,
    },
  },
  {
    sid: '57e64562c7d945148932443ae9bafd25',
    id: '57e64562c7d945148932443ae9bafd25',
    position: {
      x: -7.698664665222168,
      y: 1.563467025756836,
      z: 35.48729705810547,
    },
    rotation: {
      x: -1.2745621462574734,
      y: -81.42102075836239,
      z: 0.298993005780326,
    },
  },
  {
    sid: 'ef63745893f947f694dd8526f5491250',
    id: 'ef63745893f947f694dd8526f5491250',
    position: {
      x: -7.602407455444336,
      y: 1.5673882961273193,
      z: 38.57010269165039,
    },
    rotation: {
      x: -1.560828675802904,
      y: -102.22523458256019,
      z: -0.20094483188485565,
    },
  },
  {
    sid: '8bb16b39b330446c8bf6ab43074db33c',
    id: '8bb16b39b330446c8bf6ab43074db33c',
    position: {
      x: -5.890370845794678,
      y: 1.564515233039856,
      z: 38.62540054321289,
    },
    rotation: {
      x: -0.9215736374700576,
      y: -97.07877663841755,
      z: -0.8885826566128014,
    },
  },
  {
    sid: '82feccb21151465c8e9fd41c2898f8ea',
    id: '82feccb21151465c8e9fd41c2898f8ea',
    position: {
      x: -5.9297637939453125,
      y: 1.5664372444152832,
      z: 41.27482223510742,
    },
    rotation: {
      x: -1.1069931383254497,
      y: -113.54721986507431,
      z: -0.4641175977078182,
    },
  },
  {
    sid: '5135c7d83384457192d12ca1472f1240',
    id: '5135c7d83384457192d12ca1472f1240',
    position: {
      x: -8.900215148925781,
      y: 1.5701441764831543,
      z: 41.057037353515625,
    },
    rotation: {
      x: -0.8314093760218519,
      y: -88.84576779770644,
      z: -0.5162263722899436,
    },
  },
  {
    sid: 'e634212849044ec5a7442487ddb21edb',
    id: 'e634212849044ec5a7442487ddb21edb',
    position: {
      x: -8.84713077545166,
      y: 1.564432978630066,
      z: 43.396575927734375,
    },
    rotation: {
      x: -1.1602058364407986,
      y: -119.96073999546603,
      z: -0.8602778248593256,
    },
  },
  {
    sid: 'a7153e5a97e64bd2810576f23998cd16',
    id: 'a7153e5a97e64bd2810576f23998cd16',
    position: {
      x: -10.237006187438965,
      y: 1.563984751701355,
      z: 43.371212005615234,
    },
    rotation: {
      x: -1.0172177621021514,
      y: -109.82886604368827,
      z: -0.37574769251976725,
    },
  },
  {
    sid: '3c118a995abd4868975faf0c69cf38f6',
    id: '3c118a995abd4868975faf0c69cf38f6',
    position: {
      x: -12.298035621643066,
      y: 1.5623955726623535,
      z: 43.372215270996094,
    },
    rotation: {
      x: -0.5222443774986905,
      y: -98.89507534917439,
      z: -0.16109889647851489,
    },
  },
  {
    sid: '7927e6cd204944c9b5c0b159a11a9152',
    id: '7927e6cd204944c9b5c0b159a11a9152',
    position: {
      x: -12.380086898803711,
      y: 1.5704007148742676,
      z: 46.474754333496094,
    },
    rotation: {
      x: -1.2196996221852994,
      y: -89.35412440408233,
      z: -0.5721725872185,
    },
  },
  {
    sid: '3ee61a36867e40fbb5b8b922fad1f30f',
    id: '3ee61a36867e40fbb5b8b922fad1f30f',
    position: {
      x: -10.215538024902344,
      y: 1.5705180168151855,
      z: 46.51655197143555,
    },
    rotation: {
      x: -1.2371967697040835,
      y: 91.40156136344125,
      z: -0.22522095594607094,
    },
  },
  {
    sid: '445fe10f399f4798945087da4e2e61bf',
    id: '445fe10f399f4798945087da4e2e61bf',
    position: {
      x: -6.798192501068115,
      y: 1.5747908353805542,
      z: 46.45203399658203,
    },
    rotation: {
      x: 0.8380891724433986,
      y: 98.30890880854011,
      z: 0.3688021895572272,
    },
  },
  {
    sid: 'ddf0842dd50e47fca333c8573fdd5c81',
    id: 'ddf0842dd50e47fca333c8573fdd5c81',
    position: {
      x: -6.680397033691406,
      y: 1.563446044921875,
      z: 43.913604736328125,
    },
    rotation: {
      x: 1.417893030147886,
      y: 104.130723953201,
      z: 0.15515186812910936,
    },
  },
  {
    sid: 'b1aec1a794ac4d4c9f75b054d39f7545',
    id: 'b1aec1a794ac4d4c9f75b054d39f7545',
    position: {
      x: -2.57844614982605,
      y: 1.580918788909912,
      z: 46.504554748535156,
    },
    rotation: {
      x: 1.3407037010293221,
      y: 110.2796671195011,
      z: 2.0558349281796504,
    },
  },
  {
    sid: '849946a61c534a8eac45b23d0376f5ea',
    id: '849946a61c534a8eac45b23d0376f5ea',
    position: {
      x: 0.9656553864479065,
      y: 1.5866587162017822,
      z: 46.517433166503906,
    },
    rotation: {
      x: 0.888175595661495,
      y: 125.48857695092178,
      z: 1.6378405835234657,
    },
  },
  {
    sid: '2b74b445a5514083921d79b2fd047a58',
    id: '2b74b445a5514083921d79b2fd047a58',
    position: {
      x: 4.3074259757995605,
      y: 1.6023218631744385,
      z: 46.66215896606445,
    },
    rotation: {
      x: 0.5513452991195317,
      y: 125.37982804909215,
      z: 0.9097850851483905,
    },
  },
  {
    sid: '73242bd1b7db45fdac7d1084819f068a',
    id: '73242bd1b7db45fdac7d1084819f068a',
    position: {
      x: 7.146092891693115,
      y: 1.625415325164795,
      z: 46.6102180480957,
    },
    rotation: {
      x: 0.8345423061975288,
      y: 117.52100821879382,
      z: 1.1684520679627535,
    },
  },
  {
    sid: 'e6ddecac27454c38a8dba38de89c00ef',
    id: 'e6ddecac27454c38a8dba38de89c00ef',
    position: {
      x: -2.3972387313842773,
      y: 1.596325397491455,
      z: 44.0682373046875,
    },
    rotation: {
      x: 1.1667098094259192,
      y: 95.43430125125056,
      z: 0.32415403358162653,
    },
  },
  {
    sid: '8b8d76fd1e2d41cb8a4534faa41a4c71',
    id: '8b8d76fd1e2d41cb8a4534faa41a4c71',
    position: {
      x: -0.3241931200027466,
      y: 1.5819499492645264,
      z: 44.012794494628906,
    },
    rotation: {
      x: 0.8657376494368088,
      y: 37.928521163313896,
      z: 0.4180108576706401,
    },
  },
  {
    sid: '629b330801ff4099afbd5e2a0a95a046',
    id: '629b330801ff4099afbd5e2a0a95a046',
    position: {
      x: 1.7578487396240234,
      y: 1.583207130432129,
      z: 44.05084991455078,
    },
    rotation: {
      x: 1.6417373896475387,
      y: 30.34836674423301,
      z: 0.402190152440655,
    },
  },
  {
    sid: '2ba2a884c44b4a0caaab97ebce17b37f',
    id: '2ba2a884c44b4a0caaab97ebce17b37f',
    position: {
      x: 3.8176403045654297,
      y: 1.5849673748016357,
      z: 44.03739547729492,
    },
    rotation: {
      x: 0.7373458002407555,
      y: 20.475522174900735,
      z: 0.0239377565111227,
    },
  },
  {
    sid: '4f8927c62633488bafdbf3ddffff3338',
    id: '4f8927c62633488bafdbf3ddffff3338',
    position: {
      x: 5.214983940124512,
      y: 1.5799376964569092,
      z: 44.07141876220703,
    },
    rotation: {
      x: 0.959894587576305,
      y: 25.897703952149634,
      z: -0.20891640015930504,
    },
  },
  {
    sid: 'bf353a41d4174839a15c2021c92e04ed',
    id: 'bf353a41d4174839a15c2021c92e04ed',
    position: {
      x: 7.30110502243042,
      y: 1.5801267623901367,
      z: 44.0063362121582,
    },
    rotation: {
      x: 1.0589510252588838,
      y: 41.40082086305258,
      z: -0.1928619331090128,
    },
  },
  {
    sid: 'aeb2dd1d83294a4b8011ccb9fe1f8f1e',
    id: 'aeb2dd1d83294a4b8011ccb9fe1f8f1e',
    position: {
      x: 7.292994499206543,
      y: 1.5762109756469727,
      z: 41.59446334838867,
    },
    rotation: {
      x: 0.8578699959841152,
      y: 30.084460842914883,
      z: 0.15462319460727886,
    },
  },
  {
    sid: '53432f20e9db42d180b0537630f3d040',
    id: '53432f20e9db42d180b0537630f3d040',
    position: {
      x: 7.251689434051514,
      y: 1.579033374786377,
      z: 39.984619140625,
    },
    rotation: {
      x: 0.017336599267430405,
      y: 37.25906713912277,
      z: -0.11078975587401411,
    },
  },
  {
    sid: 'a15830593bee43409069961646d2a116',
    id: 'a15830593bee43409069961646d2a116',
    position: {
      x: 5.091184139251709,
      y: 1.5745751857757568,
      z: 40.200557708740234,
    },
    rotation: {
      x: 0.3732108441918309,
      y: 48.934911679883335,
      z: -0.3347187344031266,
    },
  },
  {
    sid: 'c716588b0fa24c3499e3534911dc03d9',
    id: 'c716588b0fa24c3499e3534911dc03d9',
    position: {
      x: 5.1745710372924805,
      y: 1.5782718658447266,
      z: 41.608848571777344,
    },
    rotation: {
      x: 1.1056087908682632,
      y: -173.21513838646578,
      z: 0.4486412664920769,
    },
  },
  {
    sid: '79c41e477ab74041bfc0502ab7830f1b',
    id: '79c41e477ab74041bfc0502ab7830f1b',
    position: {
      x: 3.7033932209014893,
      y: 1.580155849456787,
      z: 41.85475158691406,
    },
    rotation: {
      x: 1.2822300339446713,
      y: -168.8466781465336,
      z: 0.1313988500406859,
    },
  },
  {
    sid: '98b1ff16af8441f9841228d70683c1b2',
    id: '98b1ff16af8441f9841228d70683c1b2',
    position: {
      x: 3.015718698501587,
      y: 1.568419098854065,
      z: 40.137733459472656,
    },
    rotation: {
      x: 0.8868276466438763,
      y: 168.5504907132087,
      z: 0.2720011447050612,
    },
  },
  {
    sid: '31984829b8c84e01bf494ac1d41e0e01',
    id: '31984829b8c84e01bf494ac1d41e0e01',
    position: {
      x: 1.3660048246383667,
      y: 1.5757200717926025,
      z: 40.599090576171875,
    },
    rotation: {
      x: 0.7239144689721675,
      y: -177.6277265912573,
      z: 0.5073269278188483,
    },
  },
  {
    sid: '89fb0a6d71a9472489249e61db8bd016',
    id: '89fb0a6d71a9472489249e61db8bd016',
    position: {
      x: 1.2821804285049438,
      y: 1.58943510055542,
      z: 41.94198226928711,
    },
    rotation: {
      x: 0.9008529711582152,
      y: -161.54999512739403,
      z: 0.3510823384766046,
    },
  },
  {
    sid: 'fac7d29d7bbc4476ad4b0210e24026db',
    id: 'fac7d29d7bbc4476ad4b0210e24026db',
    position: {
      x: 0.08860969543457031,
      y: 1.5792254209518433,
      z: 37.861392974853516,
    },
    rotation: {
      x: 0.797051375037314,
      y: 139.89622943920935,
      z: -0.16467016707203314,
    },
  },
  {
    sid: '16ace777780c47088c596412401d17a1',
    id: '16ace777780c47088c596412401d17a1',
    position: {
      x: -1.7338250875473022,
      y: 1.575961709022522,
      z: 39.47315216064453,
    },
    rotation: {
      x: 0.8092127841509436,
      y: -172.04372422735645,
      z: -0.6217851971815351,
    },
  },
  {
    sid: 'b31ebe671e5549f29b254e91aa265e93',
    id: 'b31ebe671e5549f29b254e91aa265e93',
    position: {
      x: -4.2424798011779785,
      y: 1.5708104372024536,
      z: 39.347530364990234,
    },
    rotation: {
      x: 1.1450258337233021,
      y: -178.61363761416376,
      z: -0.3313896410674085,
    },
  },
  {
    sid: 'ce377afeab5049938bc4bf79970c8c26',
    id: 'ce377afeab5049938bc4bf79970c8c26',
    position: {
      x: -4.175163745880127,
      y: 1.5714712142944336,
      z: 42.13147735595703,
    },
    rotation: {
      x: 0.9905516794182156,
      y: -146.61061934702835,
      z: -0.45627882337159126,
    },
  },
  {
    sid: '6ecf00f427fc40b68b662fef51b3b24e',
    id: '6ecf00f427fc40b68b662fef51b3b24e',
    position: {
      x: -0.47819915413856506,
      y: 1.5816395282745361,
      z: 42.223236083984375,
    },
    rotation: {
      x: 1.130345551067232,
      y: -123.61380090098217,
      z: 0.2081630050331817,
    },
  },
  {
    sid: 'ab532faf77ac4079a02499fe3de3e13a',
    id: 'ab532faf77ac4079a02499fe3de3e13a',
    position: {
      x: -4.1432294845581055,
      y: 1.5773533582687378,
      z: 36.452396392822266,
    },
    rotation: {
      x: 1.2263362958530317,
      y: 138.65933377485854,
      z: 0.5319456037387817,
    },
  },
  {
    sid: 'f87dbad6cda442cbabf344d98f407da0',
    id: 'f87dbad6cda442cbabf344d98f407da0',
    position: {
      x: -5.832640647888184,
      y: 1.5725431442260742,
      z: 35.43785095214844,
    },
    rotation: {
      x: 1.1506356850021515,
      y: -128.04717796910444,
      z: 0.3414033387168246,
    },
  },
  {
    sid: 'a1ec0dd05fd24d568b92d627b6e3803b',
    id: 'a1ec0dd05fd24d568b92d627b6e3803b',
    position: {
      x: -3.446732997894287,
      y: 1.5759177207946777,
      z: 34.78839111328125,
    },
    rotation: {
      x: 0.6196140716875991,
      y: -73.07314239516698,
      z: 0.5540486446947814,
    },
  },
  {
    sid: '6618c646f48248668d181b873ab56c06',
    id: '6618c646f48248668d181b873ab56c06',
    position: {
      x: -3.5086777210235596,
      y: 1.5762704610824585,
      z: 33.29749298095703,
    },
    rotation: {
      x: 1.1021414135579595,
      y: -140.85973419367153,
      z: 0.3529977640579794,
    },
  },
  {
    sid: 'ec3f2834932045df9eed7e5b495f9518',
    id: 'ec3f2834932045df9eed7e5b495f9518',
    position: {
      x: -0.7759002447128296,
      y: 1.5818214416503906,
      z: 31.477876663208008,
    },
    rotation: {
      x: 1.1498758744321302,
      y: -146.80804109778953,
      z: 0.6039509379592373,
    },
  },
  {
    sid: '7207a4632ac14817a9d799395bc2b5bd',
    id: '7207a4632ac14817a9d799395bc2b5bd',
    position: {
      x: -0.6851330995559692,
      y: 1.5807194709777832,
      z: 33.62108612060547,
    },
    rotation: {
      x: 0.5602152553963793,
      y: -167.2312247271305,
      z: 0.10663476742324964,
    },
  },
  {
    sid: 'a5d57f19d903408e89771d437db79683',
    id: 'a5d57f19d903408e89771d437db79683',
    position: {
      x: 0.9768211841583252,
      y: 1.5802462100982666,
      z: 35.539710998535156,
    },
    rotation: {
      x: 0.8512730141592088,
      y: -148.823336872851,
      z: -0.4144269217292788,
    },
  },
  {
    sid: '46f4a12e385341bdb558289f71582075',
    id: '46f4a12e385341bdb558289f71582075',
    position: {
      x: 2.5317459106445312,
      y: 1.5696405172348022,
      z: 36.84353256225586,
    },
    rotation: {
      x: 0.9489716808339023,
      y: -103.7895052096816,
      z: 0.48596864901763737,
    },
  },
  {
    sid: '160cf2528ceb49e48b53954412ec7980',
    id: '160cf2528ceb49e48b53954412ec7980',
    position: {
      x: 4.616922855377197,
      y: 1.5683882236480713,
      z: 38.0261116027832,
    },
    rotation: {
      x: 1.1175560999718688,
      y: -48.641679850009034,
      z: 0.21882311748762956,
    },
  },
  {
    sid: '6972baf62dd34037825c4922bd00e435',
    id: '6972baf62dd34037825c4922bd00e435',
    position: {
      x: 7.140012741088867,
      y: 1.5684993267059326,
      z: 37.99652099609375,
    },
    rotation: {
      x: 0.7677558443629243,
      y: -10.549226329850843,
      z: -0.32844577406686276,
    },
  },
  {
    sid: '57796f6237134467b78223257f4b43a1',
    id: '57796f6237134467b78223257f4b43a1',
    position: {
      x: 7.060050010681152,
      y: 1.5631252527236938,
      z: 35.93010711669922,
    },
    rotation: {
      x: 0.35363483534303625,
      y: 23.789377145852168,
      z: 0.499397592023469,
    },
  },
  {
    sid: '72e0cd7012bd4d1c89c81a2da3c04dd0',
    id: '72e0cd7012bd4d1c89c81a2da3c04dd0',
    position: {
      x: 4.208206653594971,
      y: 1.57223641872406,
      z: 35.985877990722656,
    },
    rotation: {
      x: 0.7843032386169462,
      y: 32.513309990426,
      z: 0.38982029576978405,
    },
  },
  {
    sid: '14ee3c31f9c042bfbe0037d39f7e6fdc',
    id: '14ee3c31f9c042bfbe0037d39f7e6fdc',
    position: {
      x: 4.326924800872803,
      y: 1.5683412551879883,
      z: 33.082359313964844,
    },
    rotation: {
      x: 1.2954013331842293,
      y: 54.34105132369884,
      z: -0.34358783011845667,
    },
  },
  {
    sid: '93e751652566497ca99edd382f7759a6',
    id: '93e751652566497ca99edd382f7759a6',
    position: {
      x: 1.900281548500061,
      y: 1.5751084089279175,
      z: 33.047828674316406,
    },
    rotation: {
      x: 1.0326654021443902,
      y: 118.49818271847717,
      z: -0.16066877539432117,
    },
  },
  {
    sid: '02d666f4bed5475c9f7d4cf725f90162',
    id: '02d666f4bed5475c9f7d4cf725f90162',
    position: {
      x: 4.362577438354492,
      y: 1.5681533813476562,
      z: 30.76335906982422,
    },
    rotation: {
      x: 1.2650932015935124,
      y: 85.59285687413161,
      z: -0.2928662330965927,
    },
  },
  {
    sid: 'e23f23e178a745d9b7945fdb0651274f',
    id: 'e23f23e178a745d9b7945fdb0651274f',
    position: {
      x: 4.526359558105469,
      y: 1.5653607845306396,
      z: 28.51905632019043,
    },
    rotation: {
      x: 1.3371442554753308,
      y: 92.5321306881752,
      z: 0.37191143225044304,
    },
  },
  {
    sid: 'd15cf28e179a4219b5c4ec71eec117b4',
    id: 'd15cf28e179a4219b5c4ec71eec117b4',
    position: {
      x: 4.584639072418213,
      y: 1.5654542446136475,
      z: 25.725074768066406,
    },
    rotation: {
      x: 1.1191372142073892,
      y: 82.94662092072639,
      z: 0.07133391179865106,
    },
  },
  {
    sid: '18486e8b906c45daa07a799482decdf5',
    id: '18486e8b906c45daa07a799482decdf5',
    position: {
      x: 4.64047384262085,
      y: 1.5695526599884033,
      z: 22.566749572753906,
    },
    rotation: {
      x: 1.0545898276865648,
      y: 85.08482210230514,
      z: -0.017496347212863323,
    },
  },
  {
    sid: 'ffd0704a72d148f69b0a95beddaaa429',
    id: 'ffd0704a72d148f69b0a95beddaaa429',
    position: {
      x: 4.6151652336120605,
      y: 1.5712640285491943,
      z: 20.610017776489258,
    },
    rotation: {
      x: 0.9851272461653601,
      y: 77.37189201491955,
      z: -0.21688436711406248,
    },
  },
  {
    sid: 'b89876d1fbd540d5b101df125a1129fc',
    id: 'b89876d1fbd540d5b101df125a1129fc',
    position: {
      x: 4.479756832122803,
      y: 1.57437002658844,
      z: 18.919559478759766,
    },
    rotation: {
      x: 0.31244419447702304,
      y: 19.709027232131124,
      z: -1.1101210146190634,
    },
  },
  {
    sid: 'ce580ba46e824a30a97cd7ec9fe77e5d',
    id: 'ce580ba46e824a30a97cd7ec9fe77e5d',
    position: {
      x: 1.8072696924209595,
      y: 1.5754139423370361,
      z: 22.65620231628418,
    },
    rotation: {
      x: 0.4868110185564403,
      y: 43.591952358877705,
      z: -0.8309428773333798,
    },
  },
  {
    sid: '404a90ec52474348bf44045d8291c9d9',
    id: '404a90ec52474348bf44045d8291c9d9',
    position: {
      x: 1.952723503112793,
      y: 1.576906442642212,
      z: 24.562339782714844,
    },
    rotation: {
      x: 0.9643026786581382,
      y: 46.57012158967951,
      z: -0.5644197326967423,
    },
  },
  {
    sid: 'd04bd07a41af4fe992772b6e95eb39aa',
    id: 'd04bd07a41af4fe992772b6e95eb39aa',
    position: {
      x: 2.002814292907715,
      y: 1.576927661895752,
      z: 26.052099227905273,
    },
    rotation: {
      x: 0.6837038135990392,
      y: 54.644129281708864,
      z: -0.612031055058229,
    },
  },
  {
    sid: '1dd0d86c10954e65b46032c26d5b6781',
    id: '1dd0d86c10954e65b46032c26d5b6781',
    position: {
      x: 2.6223196983337402,
      y: 1.5744322538375854,
      z: 29.748231887817383,
    },
    rotation: {
      x: 1.3129546033758308,
      y: 64.11274108043808,
      z: -0.9745438563960068,
    },
  },
  {
    sid: 'be5f20a55add4b8daba12f84c15cf2b9',
    id: 'be5f20a55add4b8daba12f84c15cf2b9',
    position: {
      x: 7.820483207702637,
      y: 1.5636913776397705,
      z: 33.24403762817383,
    },
    rotation: {
      x: 0.624558277844662,
      y: 80.01924002437012,
      z: -0.26519319383548484,
    },
  },
  {
    sid: '288fc179422049adb2fd8ac2ea079671',
    id: '288fc179422049adb2fd8ac2ea079671',
    position: {
      x: 7.807953357696533,
      y: 1.5623011589050293,
      z: 30.980728149414062,
    },
    rotation: {
      x: 0.47338186358250156,
      y: 84.44145924060186,
      z: -0.6941040862641762,
    },
  },
  {
    sid: '3f26acaa18894fff9ef68d28d8d5df1b',
    id: '3f26acaa18894fff9ef68d28d8d5df1b',
    position: {
      x: 7.933671951293945,
      y: 1.5662516355514526,
      z: 29.944244384765625,
    },
    rotation: {
      x: 0.8413640671863141,
      y: 71.92998877637682,
      z: -0.7048887230496594,
    },
  },
  {
    sid: '569c2ca763c147b39ea9f748f036c3bf',
    id: '569c2ca763c147b39ea9f748f036c3bf',
    position: {
      x: 8.045001029968262,
      y: 1.565528154373169,
      z: 28.113576889038086,
    },
    rotation: {
      x: 0.9976782698386872,
      y: 62.80132250552208,
      z: -1.1027868657371072,
    },
  },
  {
    sid: 'd9576255452b42b2a840b3a772ce79aa',
    id: 'd9576255452b42b2a840b3a772ce79aa',
    position: {
      x: 11.480600357055664,
      y: 1.568617820739746,
      z: 28.178173065185547,
    },
    rotation: {
      x: 0.9352055911209392,
      y: 73.92176377338258,
      z: -0.47396400988640613,
    },
  },
  {
    sid: 'ccf782d4527148b9ab481aca5030b2ff',
    id: 'ccf782d4527148b9ab481aca5030b2ff',
    position: {
      x: 11.284283638000488,
      y: 1.5602130889892578,
      z: 30.16956329345703,
    },
    rotation: {
      x: 0.36450010502275787,
      y: 62.53087580473826,
      z: -0.9002390088048083,
    },
  },
  {
    sid: '12fe1493ecbc48859653f463cad8cb7b',
    id: '12fe1493ecbc48859653f463cad8cb7b',
    position: {
      x: 10.71481990814209,
      y: 1.5701736211776733,
      z: 31.36310577392578,
    },
    rotation: {
      x: 0.6847343861876345,
      y: 81.6772985840027,
      z: -0.9589889753613626,
    },
  },
  {
    sid: '507b2efd3da64c36b22f06097021e64a',
    id: '507b2efd3da64c36b22f06097021e64a',
    position: {
      x: 11.39267635345459,
      y: 1.5707536935806274,
      z: 33.15443801879883,
    },
    rotation: {
      x: 0.6424974719560563,
      y: 121.07601810398378,
      z: -0.6254214893149636,
    },
  },
  {
    sid: '202ca083b1be476587bb53964c558145',
    id: '202ca083b1be476587bb53964c558145',
    position: {
      x: 13.193510055541992,
      y: 1.5751674175262451,
      z: 33.098228454589844,
    },
    rotation: {
      x: 0.6418541650533707,
      y: 116.68391500971077,
      z: -0.19427134166255458,
    },
  },
  {
    sid: '55c2ac72d98b4153b1f1ddc3483bfd27',
    id: '55c2ac72d98b4153b1f1ddc3483bfd27',
    position: {
      x: 12.999785423278809,
      y: 1.568034052848816,
      z: 30.566455841064453,
    },
    rotation: {
      x: 0.9283299981099331,
      y: 94.53165798274723,
      z: -0.8609134115790893,
    },
  },
  {
    sid: 'fdf23fd25ec24db989c63f65fc05cfe6',
    id: 'fdf23fd25ec24db989c63f65fc05cfe6',
    position: {
      x: 13.077631950378418,
      y: 1.5676370859146118,
      z: 28.12928009033203,
    },
    rotation: {
      x: 0.6582990403288973,
      y: 83.72856820658156,
      z: -1.0456407757115842,
    },
  },
  {
    sid: '874a547b6a3e45c2adfbd1f819ada23c',
    id: '874a547b6a3e45c2adfbd1f819ada23c',
    position: {
      x: 15.681562423706055,
      y: 1.5645055770874023,
      z: 28.233911514282227,
    },
    rotation: {
      x: 0.855653711204852,
      y: 74.28561161460361,
      z: -0.6481622213810027,
    },
  },
  {
    sid: 'b262bcc00c9047a8a75290a9b7ae69d9',
    id: 'b262bcc00c9047a8a75290a9b7ae69d9',
    position: {
      x: 18.693326950073242,
      y: 1.563610553741455,
      z: 28.435026168823242,
    },
    rotation: {
      x: 1.1692248046480331,
      y: 48.024152216417995,
      z: -0.6533570484697194,
    },
  },
  {
    sid: '38dadec4c87e4fe8868cfd649f630f2b',
    id: '38dadec4c87e4fe8868cfd649f630f2b',
    position: {
      x: 18.230134963989258,
      y: 1.56302809715271,
      z: 30.930620193481445,
    },
    rotation: {
      x: 0.6687885679926058,
      y: 104.5266231727984,
      z: -1.1681815876624153,
    },
  },
  {
    sid: '0c4e880b6f7a494bbf7ce616f95fbd81',
    id: '0c4e880b6f7a494bbf7ce616f95fbd81',
    position: {
      x: 15.235211372375488,
      y: 1.566760778427124,
      z: 30.778522491455078,
    },
    rotation: {
      x: 0.7171656260262629,
      y: -148.47344715050437,
      z: -0.3297093956870917,
    },
  },
  {
    sid: 'a71011f9b9cd4629b7311bb8ca51ab8b',
    id: 'a71011f9b9cd4629b7311bb8ca51ab8b',
    position: {
      x: 18.15717124938965,
      y: 1.572015404701233,
      z: 33.24482345581055,
    },
    rotation: {
      x: 0.5251327509545948,
      y: -26.563749249107868,
      z: -1.200274370727877,
    },
  },
  {
    sid: '3ab2c969efc44dd5a77e00f865ec04a2',
    id: '3ab2c969efc44dd5a77e00f865ec04a2',
    position: {
      x: 22.038166046142578,
      y: 1.5780003070831299,
      z: 33.502655029296875,
    },
    rotation: {
      x: 0.4353695231985477,
      y: -12.031718206999605,
      z: -0.6822223100502184,
    },
  },
  {
    sid: 'd015e1e9754f4a31aded10f916869faf',
    id: 'd015e1e9754f4a31aded10f916869faf',
    position: {
      x: 24.61325454711914,
      y: 1.574948787689209,
      z: 33.59353256225586,
    },
    rotation: {
      x: 0.4189203011451237,
      y: -6.795288742665586,
      z: -1.1995415359788242,
    },
  },
  {
    sid: '668092bfd43c44bca3e1a989565e38df',
    id: '668092bfd43c44bca3e1a989565e38df',
    position: {
      x: 28.042518615722656,
      y: 1.5721118450164795,
      z: 33.6649169921875,
    },
    rotation: {
      x: 0.6688669386047773,
      y: -3.604822695580751,
      z: -0.9433496379915246,
    },
  },
  {
    sid: '1e348ed3ba6448c8a58bf91e1f3122f2',
    id: '1e348ed3ba6448c8a58bf91e1f3122f2',
    position: {
      x: 22.00955581665039,
      y: 1.5674469470977783,
      z: 30.25025177001953,
    },
    rotation: {
      x: 1.3056479783777022,
      y: 142.38871940587413,
      z: -0.9784017252478819,
    },
  },
  {
    sid: '1d5ed3decf584e4e9acfedd6f8fb0dad',
    id: '1d5ed3decf584e4e9acfedd6f8fb0dad',
    position: {
      x: 22.096158981323242,
      y: 1.5683934688568115,
      z: 28.279062271118164,
    },
    rotation: {
      x: 1.1609453213703063,
      y: 132.47884468308806,
      z: -0.22859162319788887,
    },
  },
  {
    sid: '17b4327e3d89498f8b9dd843477e6acb',
    id: '17b4327e3d89498f8b9dd843477e6acb',
    position: {
      x: 24.859420776367188,
      y: 1.5700132846832275,
      z: 28.39213752746582,
    },
    rotation: {
      x: 0.6458239872038594,
      y: 144.8027038331431,
      z: -0.18502304909006065,
    },
  },
  {
    sid: 'e4878d73df7849c78265daf05438d633',
    id: 'e4878d73df7849c78265daf05438d633',
    position: {
      x: 24.460683822631836,
      y: 1.5679512023925781,
      z: 31.271474838256836,
    },
    rotation: {
      x: 0.27646949229364637,
      y: 165.1510511355456,
      z: -0.969710122008782,
    },
  },
  {
    sid: '5e41be0b071a452890de9f75a10ec6a3',
    id: '5e41be0b071a452890de9f75a10ec6a3',
    position: {
      x: 27.321502685546875,
      y: 1.5676100254058838,
      z: 31.098960876464844,
    },
    rotation: {
      x: 0.7839720799358729,
      y: -177.2039663991219,
      z: -0.3513267052814227,
    },
  },
  {
    sid: '16b7a10077bc45a196bbaa15777e6341',
    id: '16b7a10077bc45a196bbaa15777e6341',
    position: {
      x: 27.134485244750977,
      y: 1.569706678390503,
      z: 33.48716735839844,
    },
    rotation: {
      x: 0.45611677295557784,
      y: -165.23082082241797,
      z: -1.025338417744554,
    },
  },
  {
    sid: 'fa8d9ee89c064e3986e7d0ad306bdb93',
    id: 'fa8d9ee89c064e3986e7d0ad306bdb93',
    position: {
      x: 26.927242279052734,
      y: 1.5696831941604614,
      z: 28.250885009765625,
    },
    rotation: {
      x: 0.446666596772611,
      y: 151.8719940511794,
      z: -0.2893878484623352,
    },
  },
  {
    sid: 'ac686746b6ca4c9f8a9edd0d3d174680',
    id: 'ac686746b6ca4c9f8a9edd0d3d174680',
    position: {
      x: 29.545209884643555,
      y: 1.5733907222747803,
      z: 28.88502311706543,
    },
    rotation: {
      x: 0.2849061153514999,
      y: -166.40577911636578,
      z: -0.8503827901018901,
    },
  },
  {
    sid: 'bbd1070b47604d20a0cc1f5bbdd43957',
    id: 'bbd1070b47604d20a0cc1f5bbdd43957',
    position: {
      x: 29.63614845275879,
      y: 1.570622205734253,
      z: 31.351131439208984,
    },
    rotation: {
      x: 0.15082043371701961,
      y: -114.30146353618792,
      z: -0.681239259137956,
    },
  },
  {
    sid: 'cb1e14a621ed41da94893ce84eefc7a5',
    id: 'cb1e14a621ed41da94893ce84eefc7a5',
    position: {
      x: 29.70170783996582,
      y: 1.5730400085449219,
      z: 33.35537338256836,
    },
    rotation: {
      x: 1.4754746393364984,
      y: 116.33065782325065,
      z: -0.6554836038027461,
    },
  },
  {
    sid: 'a428c69540af479a865a53326ddab4b8',
    id: 'a428c69540af479a865a53326ddab4b8',
    position: {
      x: 32.49198913574219,
      y: 1.5793535709381104,
      z: 29.26969337463379,
    },
    rotation: {
      x: 0.42103627638699737,
      y: -163.95100956704746,
      z: -0.9072265663306559,
    },
  },
  {
    sid: '336e29c6a93047cda587223973994e86',
    id: '336e29c6a93047cda587223973994e86',
    position: {
      x: 32.90324783325195,
      y: 1.5684278011322021,
      z: 26.20754623413086,
    },
    rotation: {
      x: 0.6070384038690858,
      y: 123.52291586987461,
      z: -1.1450491959053328,
    },
  },
  {
    sid: '7b2439b2935f4a489fa27e531869b98c',
    id: '7b2439b2935f4a489fa27e531869b98c',
    position: {
      x: 33.35531997680664,
      y: 1.573106050491333,
      z: 22.562294006347656,
    },
    rotation: {
      x: 0.39365998193105695,
      y: 168.89820707379005,
      z: -0.6993127436790427,
    },
  },
  {
    sid: '2b97b0f0dc19465490adbdd83748a2d7',
    id: '2b97b0f0dc19465490adbdd83748a2d7',
    position: {
      x: 33.32368469238281,
      y: 1.5777370929718018,
      z: 20.744911193847656,
    },
    rotation: {
      x: -0.08846795514556784,
      y: 136.5135477126421,
      z: -0.6654299745494142,
    },
  },
  {
    sid: '84a53dd5579b459ca1e6faec07c2b8fd',
    id: '84a53dd5579b459ca1e6faec07c2b8fd',
    position: {
      x: 32.78289794921875,
      y: 1.5602753162384033,
      z: 31.03508758544922,
    },
    rotation: {
      x: -0.4329575544858414,
      y: -175.27873972305952,
      z: -0.3859838226906353,
    },
  },
  {
    sid: '757cb6544d2b470b8966dd5569a508cf',
    id: '757cb6544d2b470b8966dd5569a508cf',
    position: {
      x: 35.72479248046875,
      y: 1.5718977451324463,
      z: 25.35300636291504,
    },
    rotation: {
      x: -0.2645302112850928,
      y: -85.27394212517655,
      z: -0.5953986382451794,
    },
  },
  {
    sid: 'e8d54ef378c0410fb5d6e4c66f9c61be',
    id: 'e8d54ef378c0410fb5d6e4c66f9c61be',
    position: {
      x: 30.145965576171875,
      y: 1.5694389343261719,
      z: 26.00220489501953,
    },
    rotation: {
      x: 0.22779931444570872,
      y: -73.90081431124887,
      z: -0.6009285500464893,
    },
  },
  {
    sid: '0da05a388a8d433a95c86242493be2d3',
    id: '0da05a388a8d433a95c86242493be2d3',
    position: {
      x: 28.050350189208984,
      y: 1.5595226287841797,
      z: 25.02579689025879,
    },
    rotation: {
      x: 0.0825468035078015,
      y: -97.20378257384705,
      z: -0.31738409241342475,
    },
  },
  {
    sid: 'e15c24ec1fa748ee9191ea0652a5ae20',
    id: 'e15c24ec1fa748ee9191ea0652a5ae20',
    position: {
      x: 26.783348083496094,
      y: 1.5658825635910034,
      z: 24.99863052368164,
    },
    rotation: {
      x: 0.5763843969965371,
      y: -101.82445171366352,
      z: -0.7227169416477206,
    },
  },
  {
    sid: 'ba059578dfb64c46bc3da8f174ea1929',
    id: 'ba059578dfb64c46bc3da8f174ea1929',
    position: {
      x: 26.92635154724121,
      y: 1.562523365020752,
      z: 20.97672462463379,
    },
    rotation: {
      x: 0.11538954253433352,
      y: -54.740875679648475,
      z: -0.35070263095855264,
    },
  },
  {
    sid: 'b24813022900466180f012dd1e628060',
    id: 'b24813022900466180f012dd1e628060',
    position: {
      x: 30.385879516601562,
      y: 1.5586775541305542,
      z: 20.965505599975586,
    },
    rotation: {
      x: -0.6157732985213206,
      y: -147.0146873083709,
      z: -0.5009521183971087,
    },
  },
  {
    sid: '2002ece8cc50446081e50affd2511626',
    id: '2002ece8cc50446081e50affd2511626',
    position: {
      x: 30.706327438354492,
      y: 1.5757358074188232,
      z: 17.213321685791016,
    },
    rotation: {
      x: -0.5860762409074769,
      y: -108.96958996796306,
      z: 0.16219738108790208,
    },
  },
  {
    sid: '412f92ccba6645a2b647a76c1c1b015f',
    id: '412f92ccba6645a2b647a76c1c1b015f',
    position: {
      x: 28.07345962524414,
      y: 1.5714908838272095,
      z: 17.0181884765625,
    },
    rotation: {
      x: -0.9982575997686559,
      y: -86.4895976707039,
      z: 0.355535815414523,
    },
  },
  {
    sid: '953953e090e14b6f8643cb8ccd95a335',
    id: '953953e090e14b6f8643cb8ccd95a335',
    position: {
      x: 26.406633377075195,
      y: 1.564826488494873,
      z: 19.20545768737793,
    },
    rotation: {
      x: -0.33158495702935237,
      y: -10.995819469795762,
      z: 0.2731537325669903,
    },
  },
  {
    sid: '4b03c92d440e4f00a8013da8180a0efb',
    id: '4b03c92d440e4f00a8013da8180a0efb',
    position: {
      x: 34.816646575927734,
      y: 1.5848844051361084,
      z: 18.509918212890625,
    },
    rotation: {
      x: -0.8713908863095873,
      y: -160.42607271827669,
      z: 0.571280082656372,
    },
  },
  {
    sid: 'e26f2c399c9e441b8d383e4556c87d5c',
    id: 'e26f2c399c9e441b8d383e4556c87d5c',
    position: {
      x: 34.0916748046875,
      y: 1.573072910308838,
      z: 31.1815242767334,
    },
    rotation: {
      x: -0.5881536340568683,
      y: -88.20524428553556,
      z: 0.3265658879694047,
    },
  },
  {
    sid: 'f2e89dec2802497ba26844bb69b859f4',
    id: 'f2e89dec2802497ba26844bb69b859f4',
    position: {
      x: 33.20270538330078,
      y: 1.561910629272461,
      z: 33.67495346069336,
    },
    rotation: {
      x: -0.7485977535967681,
      y: -120.92873075756495,
      z: 0.27961238122512155,
    },
  },
  {
    sid: 'bd6f0b147ca349a687b69fbac5f8ea7c',
    id: 'bd6f0b147ca349a687b69fbac5f8ea7c',
    position: {
      x: 35.904319763183594,
      y: 1.5792129039764404,
      z: 24.484270095825195,
    },
    rotation: {
      x: -0.8997532527771982,
      y: -131.93960375241235,
      z: 0.8310209230339287,
    },
  },
  {
    sid: '8a7d765172a64af6afa4ced7bad4cc39',
    id: '8a7d765172a64af6afa4ced7bad4cc39',
    position: {
      x: 27.848880767822266,
      y: 1.5619691610336304,
      z: 21.13990020751953,
    },
    rotation: {
      x: -1.0135875518252504,
      y: -40.350730625413185,
      z: 0.1745501552680815,
    },
  },
  {
    sid: '02461c45c9d7486f82440009ef812221',
    id: '02461c45c9d7486f82440009ef812221',
    position: {
      x: 24.40634536743164,
      y: 1.5676853656768799,
      z: 21.1351261138916,
    },
    rotation: {
      x: -0.6654682758866465,
      y: -35.28376630276711,
      z: 0.27857964035874133,
    },
  },
  {
    sid: 'a038f0156ccc4f75b3655f188dc52fbe',
    id: 'a038f0156ccc4f75b3655f188dc52fbe',
    position: {
      x: 24.340530395507812,
      y: 1.5739073753356934,
      z: 24.99547576904297,
    },
    rotation: {
      x: -0.616389301654811,
      y: 1.4780916749766795,
      z: 0.5137737121209983,
    },
  },
  {
    sid: '340c0993975b4b24a9dc9e915f497f44',
    id: '340c0993975b4b24a9dc9e915f497f44',
    position: {
      x: 21.561458587646484,
      y: 1.575395941734314,
      z: 24.77716064453125,
    },
    rotation: {
      x: -1.0189077157388882,
      y: -19.623205493441073,
      z: 0.5677266668781208,
    },
  },
  {
    sid: '6f0334171d60428e92a2c319ba1e1e77',
    id: '6f0334171d60428e92a2c319ba1e1e77',
    position: {
      x: 20.157228469848633,
      y: 1.563199520111084,
      z: 25.703643798828125,
    },
    rotation: {
      x: -0.9818548155235204,
      y: -14.928030671231584,
      z: 0.6721806514972941,
    },
  },
  {
    sid: '619f2ce85efb4f6fb959eb0dfef1ff90',
    id: '619f2ce85efb4f6fb959eb0dfef1ff90',
    position: {
      x: 18.33804702758789,
      y: 1.5695621967315674,
      z: 24.8361759185791,
    },
    rotation: {
      x: -0.9919987277284708,
      y: -5.040921061614859,
      z: 0.13908800652820644,
    },
  },
  {
    sid: '25e99f39d86d490d991be2830fdfac7a',
    id: '25e99f39d86d490d991be2830fdfac7a',
    position: {
      x: 17.658496856689453,
      y: 1.5731269121170044,
      z: 26.027484893798828,
    },
    rotation: {
      x: -0.69234932250252,
      y: 15.564219144782678,
      z: 1.1445479449482907,
    },
  },
  {
    sid: '69c94364a35d4284aea0ee7a0aa2fe88',
    id: '69c94364a35d4284aea0ee7a0aa2fe88',
    position: {
      x: 18.768495559692383,
      y: 1.5736252069473267,
      z: 27.363418579101562,
    },
    rotation: {
      x: -0.46068755765732994,
      y: 24.192048958643657,
      z: 0.7581318480769507,
    },
  },
  {
    sid: '2560c9e91f3049f588ff5ab6751d573b',
    id: '2560c9e91f3049f588ff5ab6751d573b',
    position: {
      x: 15.637764930725098,
      y: 1.5660741329193115,
      z: 26.0270938873291,
    },
    rotation: {
      x: -0.29904249633511,
      y: -62.35057642468542,
      z: 0.7475262448675519,
    },
  },
  {
    sid: 'e10df4d8cec348a3af0aada4cd59442f',
    id: 'e10df4d8cec348a3af0aada4cd59442f',
    position: {
      x: 13.214351654052734,
      y: 1.5580329895019531,
      z: 25.948444366455078,
    },
    rotation: {
      x: -0.7361862468709585,
      y: -51.6660017829721,
      z: 0.8020659267990636,
    },
  },
  {
    sid: '6b35d305f7344c4d97e517acd0705fe1',
    id: '6b35d305f7344c4d97e517acd0705fe1',
    position: {
      x: 9.997549057006836,
      y: 1.5610936880111694,
      z: 25.962059020996094,
    },
    rotation: {
      x: -0.6051403794391125,
      y: -48.19133791390414,
      z: 0.17962413793139606,
    },
  },
  {
    sid: '6728b64032b34abb8cb6183d8befa1d8',
    id: '6728b64032b34abb8cb6183d8befa1d8',
    position: {
      x: 7.270455837249756,
      y: 1.56288743019104,
      z: 25.925247192382812,
    },
    rotation: {
      x: -0.5501567989987345,
      y: -47.70204173001628,
      z: 0.46532461346421494,
    },
  },
  {
    sid: '4757d03882344b6cb9f11aba90421c35',
    id: '4757d03882344b6cb9f11aba90421c35',
    position: {
      x: 9.131352424621582,
      y: 1.5607390403747559,
      z: 25.246505737304688,
    },
    rotation: {
      x: -0.6190796813273425,
      y: -59.74864797849261,
      z: 0.34818732222602944,
    },
  },
  {
    sid: '6e43e42d1bbb498c921251860276cc2b',
    id: '6e43e42d1bbb498c921251860276cc2b',
    position: {
      x: 7.560285568237305,
      y: 1.5638948678970337,
      z: 22.732105255126953,
    },
    rotation: {
      x: -1.2311754992778514,
      y: -114.71891092516215,
      z: 0.601517656448432,
    },
  },
  {
    sid: '3438c30d197649c4b7b38b8b39aabbb5',
    id: '3438c30d197649c4b7b38b8b39aabbb5',
    position: {
      x: 10.042012214660645,
      y: 1.5640735626220703,
      z: 22.828720092773438,
    },
    rotation: {
      x: -0.496098960375034,
      y: -126.20445623457718,
      z: 0.6290783468288303,
    },
  },
  {
    sid: 'c1cb4ff73c254c128a4cc42f9b0ff247',
    id: 'c1cb4ff73c254c128a4cc42f9b0ff247',
    position: {
      x: 13.342754364013672,
      y: 1.56172513961792,
      z: 23.14978790283203,
    },
    rotation: {
      x: -0.15967985110686783,
      y: 174.15815880113527,
      z: 1.2148379156247153,
    },
  },
  {
    sid: 'e04c0c96788b41298e60224b739ee888',
    id: 'e04c0c96788b41298e60224b739ee888',
    position: {
      x: 16.074142456054688,
      y: 1.5665557384490967,
      z: 23.92186737060547,
    },
    rotation: {
      x: -0.668419471801657,
      y: -175.9270694735917,
      z: 0.7772635823460589,
    },
  },
  {
    sid: '0e8595251d75416f92e9f4a95c628e78',
    id: '0e8595251d75416f92e9f4a95c628e78',
    position: {
      x: 18.448278427124023,
      y: 1.5643153190612793,
      z: 23.59904670715332,
    },
    rotation: {
      x: -0.3236668037521591,
      y: 148.41244985226888,
      z: 0.9806636861213637,
    },
  },
  {
    sid: '115587900dde48cb83a1ce059ff7f3ac',
    id: '115587900dde48cb83a1ce059ff7f3ac',
    position: {
      x: 18.483192443847656,
      y: 1.562227487564087,
      z: 20.97894859313965,
    },
    rotation: {
      x: -0.20743937798827272,
      y: -167.70946846169954,
      z: 1.1030761988253572,
    },
  },
  {
    sid: '45700f11bb79457c96004a514986aa33',
    id: '45700f11bb79457c96004a514986aa33',
    position: {
      x: 21.89499855041504,
      y: 1.5594532489776611,
      z: 21.41426658630371,
    },
    rotation: {
      x: -0.5135935041289589,
      y: 179.5185344175587,
      z: 0.826732418603594,
    },
  },
  {
    sid: '02a922a361bd4759a6191606d4caa9e7',
    id: '02a922a361bd4759a6191606d4caa9e7',
    position: {
      x: 23.09678840637207,
      y: 1.5619601011276245,
      z: 19.018165588378906,
    },
    rotation: {
      x: -0.831721526976977,
      y: -145.21094348441517,
      z: 0.37196801121421047,
    },
  },
  {
    sid: '7d830b48de2b4ea28371ce2b41486ea8',
    id: '7d830b48de2b4ea28371ce2b41486ea8',
    position: {
      x: 23.13011360168457,
      y: 1.5642924308776855,
      z: 21.49309730529785,
    },
    rotation: {
      x: -0.6412666053327833,
      y: -144.15921865031999,
      z: 0.3230150707785789,
    },
  },
  {
    sid: 'c18b812526dc4ff18ddffa856f7c490d',
    id: 'c18b812526dc4ff18ddffa856f7c490d',
    position: {
      x: 19.592063903808594,
      y: 1.5571835041046143,
      z: 21.100753784179688,
    },
    rotation: {
      x: -0.49316122754252306,
      y: -76.13764088007136,
      z: 0.639978224805799,
    },
  },
  {
    sid: '19211739f6c847b984f0dfda95f1c6d8',
    id: '19211739f6c847b984f0dfda95f1c6d8',
    position: {
      x: 17.729679107666016,
      y: 1.5639373064041138,
      z: 21.091028213500977,
    },
    rotation: {
      x: -0.10641119450914438,
      y: -76.10452479675058,
      z: 0.7884307465415434,
    },
  },
  {
    sid: '860c2921e565403ba111bb5e51985e4a',
    id: '860c2921e565403ba111bb5e51985e4a',
    position: {
      x: 19.624876022338867,
      y: 1.5603996515274048,
      z: 19.12256622314453,
    },
    rotation: {
      x: -0.6058695504233463,
      y: 21.878795100787254,
      z: 0.4090230941982083,
    },
  },
  {
    sid: 'c2be6cf8f0754042b130273682bc25a3',
    id: 'c2be6cf8f0754042b130273682bc25a3',
    position: {
      x: 15.596322059631348,
      y: 1.5617424249649048,
      z: 20.900299072265625,
    },
    rotation: {
      x: -1.080239473116114,
      y: -9.938289339692473,
      z: 0.4093242051206547,
    },
  },
  {
    sid: 'f97e2930ead64a6da13828c52269f998',
    id: 'f97e2930ead64a6da13828c52269f998',
    position: {
      x: 13.073683738708496,
      y: 1.5635144710540771,
      z: 20.81584930419922,
    },
    rotation: {
      x: -0.4548641439409675,
      y: -24.98702444583302,
      z: -0.1073330205294903,
    },
  },
  {
    sid: '28d5c11815254a79b589b20ef028d3e8',
    id: '28d5c11815254a79b589b20ef028d3e8',
    position: {
      x: 9.663946151733398,
      y: 1.5707480907440186,
      z: 20.841909408569336,
    },
    rotation: {
      x: -0.3143160557015717,
      y: -29.045219817751182,
      z: 0.77382483671899,
    },
  },
  {
    sid: 'ead4e36eddec40f093523c37c2fc6bcf',
    id: 'ead4e36eddec40f093523c37c2fc6bcf',
    position: {
      x: 7.8903069496154785,
      y: 1.5709348917007446,
      z: 20.85572624206543,
    },
    rotation: {
      x: -0.5637274638284672,
      y: 0.8445233437061086,
      z: 0.2855600749818433,
    },
  },
  {
    sid: '9f6f2a29709e4b95a510c983688adc65',
    id: '9f6f2a29709e4b95a510c983688adc65',
    position: {
      x: 7.794301986694336,
      y: 1.5627840757369995,
      z: 18.410097122192383,
    },
    rotation: {
      x: -0.6305444014742229,
      y: -74.49931540224141,
      z: 0.37572564716694234,
    },
  },
  {
    sid: '6d58c5b26f5142158c372cc3c5ca26e7',
    id: '6d58c5b26f5142158c372cc3c5ca26e7',
    position: {
      x: 9.774384498596191,
      y: 1.5636978149414062,
      z: 18.4373836517334,
    },
    rotation: {
      x: -0.5818266297427575,
      y: -88.90823518268947,
      z: 0.318008059887858,
    },
  },
  {
    sid: '0e76a90ff23846d7b4f10d4e588a4651',
    id: '0e76a90ff23846d7b4f10d4e588a4651',
    position: {
      x: 13.061903953552246,
      y: 1.5595245361328125,
      z: 18.548675537109375,
    },
    rotation: {
      x: -0.7709064313494758,
      y: -88.53258631283505,
      z: 0.44312155288482985,
    },
  },
  {
    sid: '52fabb2cfd77441e832595cb2778437e',
    id: '52fabb2cfd77441e832595cb2778437e',
    position: {
      x: 13.043238639831543,
      y: 1.5589022636413574,
      z: 16.612565994262695,
    },
    rotation: {
      x: -0.463159250313808,
      y: -88.13113456959249,
      z: 0.5834340962544768,
    },
  },
  {
    sid: '2c2aa8289695440685d9ca0add218c92',
    id: '2c2aa8289695440685d9ca0add218c92',
    position: {
      x: 15.679676055908203,
      y: 1.5563404560089111,
      z: 16.684162139892578,
    },
    rotation: {
      x: -0.9483550376644758,
      y: -44.67543318795367,
      z: 0.5454600612958617,
    },
  },
  {
    sid: 'd709cf278e714e9aa953d46f61995075',
    id: 'd709cf278e714e9aa953d46f61995075',
    position: {
      x: 17.817726135253906,
      y: 1.561035394668579,
      z: 17.060441970825195,
    },
    rotation: {
      x: -0.42481104409068343,
      y: 22.810954154917344,
      z: 0.7380037156378864,
    },
  },
  {
    sid: 'd48500a126964aceb8ffe4371cb99aac',
    id: 'd48500a126964aceb8ffe4371cb99aac',
    position: {
      x: 19.593961715698242,
      y: 1.565438151359558,
      z: 17.108171463012695,
    },
    rotation: {
      x: -0.8698483403605628,
      y: 62.35628390266087,
      z: 0.975384757920424,
    },
  },
  {
    sid: 'd78eb069c22b442dbeaafa3684dd01b9',
    id: 'd78eb069c22b442dbeaafa3684dd01b9',
    position: {
      x: 22.997278213500977,
      y: 1.5662295818328857,
      z: 16.984216690063477,
    },
    rotation: {
      x: -0.7276208300728799,
      y: 90.42303880643864,
      z: 0.6658935859178285,
    },
  },
  {
    sid: 'ed59b14bdf6840deac91bd9abcf80653',
    id: 'ed59b14bdf6840deac91bd9abcf80653',
    position: {
      x: 26.612804412841797,
      y: 1.5658900737762451,
      z: 17.133583068847656,
    },
    rotation: {
      x: -0.3194116204832022,
      y: 102.98878146219022,
      z: 0.761960683825454,
    },
  },
  {
    sid: '57d88251af224a6890a9cef37750235b',
    id: '57d88251af224a6890a9cef37750235b',
    position: {
      x: 30.726350784301758,
      y: 1.576347827911377,
      z: 17.21463966369629,
    },
    rotation: {
      x: -0.9456873909823089,
      y: 112.1188145042903,
      z: 0.47406433041630924,
    },
  },
  {
    sid: '4a72191e3aa542629aa25279f7094b6e',
    id: '4a72191e3aa542629aa25279f7094b6e',
    position: {
      x: 15.609646797180176,
      y: 1.5580590963363647,
      z: 18.791297912597656,
    },
    rotation: {
      x: -0.6609451130808458,
      y: 56.58862778478347,
      z: 0.22464881248006535,
    },
  },
  {
    sid: '2f5fdbe4954a4faa8b45032f8fbe0ad3',
    id: '2f5fdbe4954a4faa8b45032f8fbe0ad3',
    position: {
      x: 13.299346923828125,
      y: 1.5614087581634521,
      z: 18.2454833984375,
    },
    rotation: {
      x: -0.5458152596549742,
      y: 43.48718728741587,
      z: 0.6103099401137992,
    },
  },
  {
    sid: 'c424ec9616b146a8b27e036a34cbf7e8',
    id: 'c424ec9616b146a8b27e036a34cbf7e8',
    position: {
      x: 11.5926513671875,
      y: 1.5685853958129883,
      z: 21.253345489501953,
    },
    rotation: {
      x: -0.8185934429058447,
      y: 150.9371659803103,
      z: 0.9263798033488095,
    },
  },
  {
    sid: 'bba25e2c48b6424eb6d35793e828aa75',
    id: 'bba25e2c48b6424eb6d35793e828aa75',
    position: {
      x: 8.475643157958984,
      y: 1.5607552528381348,
      z: 25.074562072753906,
    },
    rotation: {
      x: -1.4260336603145676,
      y: 154.8384956379588,
      z: 0.1095550423741955,
    },
  },
  {
    sid: 'eb2a8f3f58cc457e9350ecfc8d7762d6',
    id: 'eb2a8f3f58cc457e9350ecfc8d7762d6',
    position: {
      x: 10.159504890441895,
      y: 1.5680885314941406,
      z: 32.97211837768555,
    },
    rotation: {
      x: -0.5140516365951724,
      y: 48.33177540696186,
      z: 0.9467763093183093,
    },
  },
  {
    sid: '68cb15a7a8cc47aba565535beb345e79',
    id: '68cb15a7a8cc47aba565535beb345e79',
    position: {
      x: 21.801719665527344,
      y: 1.5725679397583008,
      z: 32.022212982177734,
    },
    rotation: {
      x: -0.14650140703943634,
      y: 173.9364054201678,
      z: 0.8690242752649237,
    },
  },
  {
    sid: 'c167576b5eb54295a2b59f580c509723',
    id: 'c167576b5eb54295a2b59f580c509723',
    position: {
      x: 24.63957977294922,
      y: 1.565134048461914,
      z: 30.705223083496094,
    },
    rotation: {
      x: -1.0074278144191786,
      y: -129.03175772706226,
      z: 0.7957793390378166,
    },
  },
  {
    sid: 'ce10adcbd4f64ccab2acf291c1476cc7',
    id: 'ce10adcbd4f64ccab2acf291c1476cc7',
    position: {
      x: -2.8766236305236816,
      y: 1.5828511714935303,
      z: 36.464759826660156,
    },
    rotation: {
      x: -0.9148256542236008,
      y: 41.471670542988306,
      z: 1.0810771002076782,
    },
  },
  {
    sid: '4f2b910a51464bf2ae6d5341c0b98023',
    id: '4f2b910a51464bf2ae6d5341c0b98023',
    position: {
      x: -0.21975956857204437,
      y: 1.581594705581665,
      z: 36.65420150756836,
    },
    rotation: {
      x: -0.3340902654938571,
      y: 78.02008986873287,
      z: 1.0491615023835121,
    },
  },
];

export default locations;
