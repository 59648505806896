const products = [
  //Main Products
  // {
  //   id: 'Wat-Van',
  //   seriesName: 'WATTLEBIRD',
  //   name: 'VANILLA',
  //   description:
  //     'Admire the sun-soaked wattle and bursts of wildflowers within this whimsical hand-painted Aussie bush scenery. Native birds are perched upon cascading flowering gum and eucalyptus. A palette of warm neutrals, creams and toasty neutrals bring the warmth to this detailed summer classic. Printed on soft cotton sateen and best styled with the coordinating European pillowcases and cushions. Cotton Sateen',
  //   price: '',
  //   image: 'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-vr-store-3d/linen-house/products/Wat-Van.jpg',
  //   link: '',
  //   itemGroups: [
  //     {
  //       name: 'Quilt Cover & Pillowcase/s Set',
  //       items: [
  //         {
  //           name: 'DB',
  //           description: '180x210cm + 48x73cm(2)',
  //           price: 90.0,
  //           priceRRP: 219.99,
  //         },
  //         {
  //           name: 'QB',
  //           description: '210x210cm + 48x73cm(2)',
  //           price: 95.0,
  //           priceRRP: 229.99,
  //         },
  //         {
  //           name: 'KB',
  //           description: '245x210cm + 48x73cm(2)',
  //           price: 105.0,
  //           priceRRP: 249.99,
  //         },
  //         {
  //           name: 'SKB',
  //           description: '270x240cm + 48x73cm(2)',
  //           price: 125.0,
  //           priceRRP: 299.99,
  //         },
  //       ],
  //     },
  //     {
  //       name: 'Coordinating Accessories',
  //       items: [
  //         {
  //           name: '65x65cm European pillowcase',
  //           description: '',
  //           price: 24.0,
  //           priceRRP: 59.99,
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   id: 'Ori-Pow',
  //   seriesName: 'ORISSA',
  //   name: 'POWDER BLUE',
  //   description:
  //     'A hand-painted marquetry style geometric, harboring a calming energy and commanding attention with its grand scale. Serene blue and clay hues foster an oceanic quality, which perfectly encapsulates summer getaway vibes. Pair this cotton percale set with its coordinating European pillowcases and cushions to complete the look. Cotton Percale',
  //   price: '',
  //   image: 'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-vr-store-3d/linen-house/products/Ori-Pow.jpg',
  //   link: '',
  //   itemGroups: [
  //     {
  //       name: 'Quilt Cover & Pillowcase/s Set',
  //       items: [
  //         {
  //           name: 'DB',
  //           description: '180x210cm + 48x73cm(2)',
  //           price: 90.0,
  //           priceRRP: 219.99,
  //         },
  //         {
  //           name: 'QB',
  //           description: '210x210cm + 48x73cm(2)',
  //           price: 95.0,
  //           priceRRP: 229.99,
  //         },
  //         {
  //           name: 'KB',
  //           description: '245x210cm + 48x73cm(2)',
  //           price: 105.0,
  //           priceRRP: 249.99,
  //         },
  //         {
  //           name: 'SKB',
  //           description: '270x240cm + 48x73cm(2)',
  //           price: 125.0,
  //           priceRRP: 299.99,
  //         },
  //       ],
  //     },
  //     {
  //       name: 'Coordinating Accessories',
  //       items: [
  //         {
  //           name: '65x65cm European pillowcase',
  //           description: '',
  //           price: 24.0,
  //           priceRRP: 59.99,
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   id: 'Vis-Sea',
  //   seriesName: 'VISAGE',
  //   name: 'SEAFOAM',
  //   description:
  //     'Bring the feeling of a Mediterranean summer escape into your bedroom with Visage. This relaxed, playful, hand painted print features a central sun like motif with soft wavy and shell like borders for added detail. Using a palette of soft, watery hues with sandy neutrals and evanescent yellow combine together to create a modern, beachy mood. Printed on cotton slub, complete the look with coordinating cushions for added texture. Textured Cotton Slub',
  //   price: '',
  //   image:
  //     'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-vr-store-3d/linen-house/products/Vis-Sea.jpg',
  //   link: '',
  //   itemGroups: [
  //     {
  //       name: 'Quilt Cover & Pillowcase/s Set',
  //       items: [
  //         {
  //           name: 'DB',
  //           description: '180x210cm + 48x73cm(2)',
  //           price: 90.0,
  //           priceRRP: 219.99,
  //         },
  //         {
  //           name: 'QB',
  //           description: '210x210cm + 48x73cm(2)',
  //           price: 95.0,
  //           priceRRP: 229.99,
  //         },
  //         {
  //           name: 'KB',
  //           description: '245x210cm + 48x73cm(2)',
  //           price: 105.0,
  //           priceRRP: 249.99,
  //         },
  //         {
  //           name: 'SKB',
  //           description: '270x240cm + 48x73cm(2)',
  //           price: 125.0,
  //           priceRRP: 299.99,
  //         },
  //       ],
  //     },
  //     {
  //       name: 'Coordinating Accessories',
  //       items: [
  //         {
  //           name: '65x65cm European pillowcase',
  //           description: '',
  //           price: 24.0,
  //           priceRRP: 59.99,
  //         },
  //         {
  //           name: '50x50cm Cushion',
  //           description: '',
  //           price: 24.0,
  //           priceRRP: 59.99,
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   id: 'Gee-Sky',
  //   seriesName: 'GEENA',
  //   name: 'SKYLIGHT',
  //   description:
  //     'Geena skylight is your contemporary check with a twist: woven cotton waffle in a fresh palette across a off-white ground. Elevate this lively and colourful ensemble with its coordinating European pillowcases and cushion for added texture and style. Cotton Waffle',
  //   price: '',
  //   image:
  //     'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-vr-store-3d/linen-house/products/Gee-Sky.jpg',
  //   link: '',
  //   itemGroups: [
  //     {
  //       name: 'Quilt Cover & Pillowcase/s Set',
  //       items: [
  //         {
  //           name: 'SB',
  //           description: ' 140x210cm + 48x73cm',
  //           price: 80.0,
  //           priceRRP: 189.99,
  //         },
  //         {
  //           name: 'DB',
  //           description: '180x210cm + 48x73cm(2)',
  //           price: 100.0,
  //           priceRRP: 239.99,
  //         },
  //         {
  //           name: 'QB',
  //           description: '210x210cm + 48x73cm(2)',
  //           price: 110.0,
  //           priceRRP: 269.99,
  //         },
  //         {
  //           name: 'KB',
  //           description: '245x210cm + 48x73cm(2)',
  //           price: 120.0,
  //           priceRRP: 289.99,
  //         },
  //         {
  //           name: 'SKB',
  //           description: '270x240cm + 48x73cm(2)',
  //           price: 140.0,
  //           priceRRP: 339.99,
  //         },
  //       ],
  //     },
  //     {
  //       name: 'Coordinating Accessories',
  //       items: [
  //         {
  //           name: '65x65cm European pillowcase',
  //           description: '',
  //           price: 24.0,
  //           priceRRP: 59.99,
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   id: 'Mil-Sal',
  //   seriesName: 'MILA',
  //   name: 'SALTWATER',
  //   description:
  //     'Welcome our newest plain dye design Mila. Mila saltwater is perfect for those after a minimalist design in a timeless style. Pair this cotton seersucker set with its coordinating European pillowcases and cushions to complete the look. Cotton Seersucker',
  //   price: '',
  //   image:
  //     'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-vr-store-3d/linen-house/products/Mil-Sal.jpg',
  //   link: '',
  //   itemGroups: [
  //     {
  //       name: 'Quilt Cover & Pillowcase/s Set',
  //       items: [
  //         {
  //           name: 'SB',
  //           description: '140x210cm + 48x73cm',
  //           price: 65.0,
  //           priceRRP: 159.99,
  //         },
  //         {
  //           name: 'DB',
  //           description: '180x210cm + 48x73cm(2)',
  //           price: 80.0,
  //           priceRRP: 189.99,
  //         },
  //         {
  //           name: 'QB',
  //           description: '210x210cm + 48x73cm(2)',
  //           price: 85.0,
  //           priceRRP: 199.99,
  //         },
  //         {
  //           name: 'KB',
  //           description: '245x210cm + 48x73cm(2)',
  //           price: 95.0,
  //           priceRRP: 229.99,
  //         },
  //         {
  //           name: 'SKB',
  //           description: '270x240cm + 48x73cm(2)',
  //           price: 105.0,
  //           priceRRP: 249.99,
  //         },
  //       ],
  //     },
  //     {
  //       name: 'Coordinating Accessories',
  //       items: [
  //         {
  //           name: '65x65cm European pillowcase',
  //           description: '',
  //           price: 20.0,
  //           priceRRP: 49.99,
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   id: 'Elo-Blu',
  //   seriesName: 'ELOISE',
  //   name: 'BLUE',
  //   description:
  //     'A delicate ditsy floral printed on the softest micro gauze cotton ground invokes the sweetest dreams. Finished with a soft 1 cm raw edge, and a plain dye reverse. The coordinating European pillowcases and cushion tie in with the quilt cover perfectly, or can be styled with other bedlinen for just a touch of feminine romance. The tufted cotton cushion adds texture and would look great in the bedroom or living room. Cotton Layered Gauze',
  //   price: '',
  //   image:
  //     'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-vr-store-3d/linen-house/products/Elo-Blu.jpg',
  //   link: '',
  //   itemGroups: [
  //     {
  //       name: 'Quilt Cover & Pillowcase/s Set',
  //       items: [
  //         {
  //           name: 'DB',
  //           description: '180x210cm + 48x73cm(2)',
  //           price: 90.0,
  //           priceRRP: 219.99,
  //         },
  //         {
  //           name: 'QB',
  //           description: '210x210cm + 48x73cm(2)',
  //           price: 100.0,
  //           priceRRP: 239.99,
  //         },
  //         {
  //           name: 'KB',
  //           description: '245x210cm + 48x73cm(2)',
  //           price: 110.0,
  //           priceRRP: 269.99,
  //         },
  //         {
  //           name: 'SKB',
  //           description: '270x240cm + 48x73cm(2)',
  //           price: 125.0,
  //           priceRRP: 299.99,
  //         },
  //       ],
  //     },
  //     {
  //       name: 'Coordinating Accessories',
  //       items: [
  //         {
  //           name: '65x65cm European pillowcase',
  //           description: '',
  //           price: 24.0,
  //           priceRRP: 59.99,
  //         },
  //         {
  //           name: '45x45cm Cushion',
  //           description: '',
  //           price: 35.0,
  //           priceRRP: 84.99,
  //         },
  //         {
  //           name: '48x48cm Cushion',
  //           description: '',
  //           price: 24.0,
  //           priceRRP: 59.99,
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   id: 'Spl-Mul',
  //   seriesName: 'SPLASH',
  //   name: 'MULTI',
  //   description:
  //     'Bring the joy of summer inside this season with Splash. Inspired by Beach days and seaside landscapes, Splash is a hand rendered showstopper. Featuring a vibrant multicoloured palette, this nautical beauty is complete with the coordinating bed cushions. Cotton Percale',
  //   price: '',
  //   image:
  //     'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-vr-store-3d/linen-house/products/Spl-Mul.jpg',
  //   link: '',
  //   itemGroups: [
  //     {
  //       name: 'Quilt Cover & Pillowcase/s Set',
  //       items: [
  //         {
  //           name: 'DB',
  //           description: '180x210cm + 48x73cm(2)',
  //           price: 90.0,
  //           priceRRP: 219.99,
  //         },
  //         {
  //           name: 'QB',
  //           description: '210x210cm + 48x73cm(2)',
  //           price: 95.0,
  //           priceRRP: 229.99,
  //         },
  //         {
  //           name: 'KB',
  //           description: '245x210cm + 48x73cm(2)',
  //           price: 105.0,
  //           priceRRP: 249.99,
  //         },
  //         {
  //           name: 'SKB',
  //           description: '270x240cm + 48x73cm(2)',
  //           price: 125.0,
  //           priceRRP: 299.99,
  //         },
  //       ],
  //     },
  //     {
  //       name: 'Coordinating Accessories',
  //       items: [
  //         {
  //           name: '65x65cm European pillowcase',
  //           description: '',
  //           price: 24.0,
  //           priceRRP: 59.99,
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   id: 'Gee-Gre',
  //   seriesName: 'GEENA',
  //   name: 'GREEN APPLE',
  //   description:
  //     'Geena green apple is your contemporary check with a twist: woven cotton waffle in a fresh palette across a cream ground. Elevate this lively and colourful ensemble with its coordinating European pillowcases and cushion for added texture and style. Cotton Waffle',
  //   price: '',
  //   image:
  //     'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-vr-store-3d/linen-house/products/Gee-Gre.jpg',
  //   link: '',
  //   itemGroups: [
  //     {
  //       name: 'Quilt Cover & Pillowcase/s Set',
  //       items: [
  //         {
  //           name: 'SB',
  //           description: '140x210cm + 48x73cm',
  //           price: 80.0,
  //           priceRRP: 189.99,
  //         },
  //         {
  //           name: 'DB',
  //           description: '180x210cm + 48x73cm(2)',
  //           price: 100.0,
  //           priceRRP: 239.99,
  //         },
  //         {
  //           name: 'QB',
  //           description: '210x210cm + 48x73cm(2)',
  //           price: 110.0,
  //           priceRRP: 269.99,
  //         },
  //         {
  //           name: 'KB',
  //           description: '245x210cm + 48x73cm(2)',
  //           price: 120.0,
  //           priceRRP: 289.99,
  //         },
  //         {
  //           name: 'SKB',
  //           description: '270x240cm + 48x73cm(2)',
  //           price: 140.0,
  //           priceRRP: 339.99,
  //         },
  //       ],
  //     },
  //     {
  //       name: 'Coordinating Accessories',
  //       items: [
  //         {
  //           name: '65x65cm European pillowcase',
  //           description: '',
  //           price: 24.0,
  //           priceRRP: 59.99,
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   id: 'Fer-San',
  //   seriesName: 'FERRARA',
  //   name: 'SAND',
  //   description:
  //     'A chunky seersucker woven check, in a calming neutral sand coloured cotton. This design is finished with self-tie enclosures on the quilt, pillowcase European pillowcases and cushion, with a knife edge finish. Can easily be used as a mix-and-match look by teaming it up with your other bedlinen. Cotton Seersucker',
  //   price: '',
  //   image:
  //     'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-vr-store-3d/linen-house/products/Fer-San.jpg',
  //   link: '',
  //   itemGroups: [
  //     {
  //       name: 'Quilt Cover & Pillowcase/s Set',
  //       items: [
  //         {
  //           name: 'DB',
  //           description: '180x210cm + 48x73cm(2)',
  //           price: 80.0,
  //           priceRRP: 199.99,
  //         },
  //         {
  //           name: 'QB',
  //           description: '210x210cm + 48x73cm(2)',
  //           price: 90.0,
  //           priceRRP: 229.99,
  //         },
  //         {
  //           name: 'KB',
  //           description: '245x210cm + 48x73cm(2)',
  //           price: 100.0,
  //           priceRRP: 249.99,
  //         },
  //         {
  //           name: 'SKB',
  //           description: '270x240cm + 48x73cm(2)',
  //           price: 115.0,
  //           priceRRP: 289.99,
  //         },
  //       ],
  //     },
  //     {
  //       name: 'Coordinating Accessories',
  //       items: [
  //         {
  //           name: '65x65cm European pillowcase',
  //           description: '',
  //           price: 24.0,
  //           priceRRP: 69.99,
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   id: 'Mar-Gua',
  //   seriesName: 'MARISOL',
  //   name: 'GUAVA',
  //   description:
  //     'This tropical delight is simply the essence of summer. Lush greens, pops of coral and amber sing together on the coral ground. Add freshness to your space with Marisol’s vintage style motifs of lush leaves, florals and fruit. Printed on cotton sateen, style this design with the coordinating European pillowcases and cushions. Cotton Sateen',
  //   price: '',
  //   image:
  //     'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-vr-store-3d/linen-house/products/Mar-Gua.jpg',
  //   link: '',
  //   itemGroups: [
  //     {
  //       name: 'Quilt Cover & Pillowcase/s Set',
  //       items: [
  //         {
  //           name: 'DB',
  //           description: '180x210cm + 48x73cm(2)',
  //           price: 90.0,
  //           priceRRP: 219.99,
  //         },
  //         {
  //           name: 'QB',
  //           description: '210x210cm + 48x73cm(2)',
  //           price: 95.0,
  //           priceRRP: 229.99,
  //         },
  //         {
  //           name: 'KB',
  //           description: '245x210cm + 48x73cm(2)',
  //           price: 105.0,
  //           priceRRP: 249.99,
  //         },
  //         {
  //           name: 'SKB',
  //           description: '270x240cm + 48x73cm(2)',
  //           price: 125.0,
  //           priceRRP: 299.99,
  //         },
  //       ],
  //     },
  //     {
  //       name: 'Coordinating Accessories',
  //       items: [
  //         {
  //           name: '65x65cm European pillowcase',
  //           description: '',
  //           price: 24.0,
  //           priceRRP: 59.99,
  //         },
  //         {
  //           name: '45x45cm Cushion',
  //           description: '',
  //           price: 28.0,
  //           priceRRP: 69.99,
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   id: 'Fer-Gre',
  //   seriesName: 'FERRARA',
  //   name: 'GREEN APPLE',
  //   description:
  //     'A chunky seersucker woven check, in a fun minty green cotton. This design is finished with self-tie enclosures on the quilt, pillowcase, European pillowcases and cushion, with a knife edge finish. Can easily be used as a mix-and-match look by teaming it up with your other bedlinen. Cotton Seersucker',
  //   price: '',
  //   image:
  //     'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-vr-store-3d/linen-house/products/Fer-Gre.jpg',
  //   link: '',
  //   itemGroups: [
  //     {
  //       name: 'Quilt Cover & Pillowcase/s Set',
  //       items: [
  //         {
  //           name: 'DB',
  //           description: '180x210cm + 48x73cm(2)',
  //           price: 80.0,
  //           priceRRP: 199.99,
  //         },
  //         {
  //           name: 'QB',
  //           description: '210x210cm + 48x73cm(2)',
  //           price: 90.0,
  //           priceRRP: 229.99,
  //         },
  //         {
  //           name: 'KB',
  //           description: '245x210cm + 48x73cm(2)',
  //           price: 100.0,
  //           priceRRP: 249.99,
  //         },
  //         {
  //           name: 'SKB',
  //           description: '270x240cm + 48x73cm(2)',
  //           price: 115.0,
  //           priceRRP: 289.99,
  //         },
  //       ],
  //     },
  //     {
  //       name: 'Coordinating Accessories',
  //       items: [
  //         {
  //           name: '65x65cm European pillowcase',
  //           description: '',
  //           price: 24.0,
  //           priceRRP: 69.99,
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   id: 'Pas-Nec',
  //   seriesName: 'PASSIONFLOWER',
  //   name: 'NECTAR',
  //   description:
  //     'Usher in Summer with this gorgeous print featuring a refreshing mix of both tropical and Australian Native flora. Passionfruit flowers, waratahs, orchids and proteas bloom amongst tropical leaves and eucalyptus to create a dense forest of colour and pattern. Hand-painted brush strokes overlay inky washes to create lush summery textures. Printed on soft cotton sateen and finished with a delicate cotton tassel trim, Passionflower is best paired with the coordinating European pillowcases and cushions. Cotton Sateen',
  //   price: '',
  //   image:
  //     'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-vr-store-3d/linen-house/products/Pas-Nec.jpg',
  //   link: '',
  //   itemGroups: [
  //     {
  //       name: 'Quilt Cover & Pillowcase/s Set',
  //       items: [
  //         {
  //           name: 'DB',
  //           description: '180x210cm + 48x73cm(2)',
  //           price: 90.0,
  //           priceRRP: 219.99,
  //         },
  //         {
  //           name: 'QB',
  //           description: '210x210cm + 48x73cm(2)',
  //           price: 95.0,
  //           priceRRP: 229.99,
  //         },
  //         {
  //           name: 'KB',
  //           description: '245x210cm + 48x73cm(2)',
  //           price: 105.0,
  //           priceRRP: 249.99,
  //         },
  //         {
  //           name: 'SKB',
  //           description: '270x240cm + 48x73cm(2)',
  //           price: 125.0,
  //           priceRRP: 299.99,
  //         },
  //       ],
  //     },
  //     {
  //       name: 'Coordinating Accessories',
  //       items: [
  //         {
  //           name: '65x65cm European pillowcase',
  //           description: '',
  //           price: 24.0,
  //           priceRRP: 59.99,
  //         },
  //         {
  //           name: '48x48cm Cushion',
  //           description: '',
  //           price: 24.0,
  //           priceRRP: 59.99,
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   id: 'Raq-Van',
  //   seriesName: 'RAQUELLE',
  //   name: 'VANILLA',
  //   description:
  //     'Welcome our newest plain dye design Raquelle. With a crochet like look and feel, Raquelle features a honeycomb weave giving your space a relaxed vintage feel. In three summer colourways. Cotton Waffle',
  //   price: '',
  //   image:
  //     'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-vr-store-3d/linen-house/products/Raq-Van.jpg',
  //   link: '',
  //   itemGroups: [
  //     {
  //       name: 'Quilt Cover & Pillowcase/s Set',
  //       items: [
  //         {
  //           name: 'DB',
  //           description: '180x210cm + 48x73cm(2)',
  //           price: 110.0,
  //           priceRRP: 269.99,
  //         },
  //         {
  //           name: 'QB',
  //           description: '210x210cm + 48x73cm(2)',
  //           price: 120.0,
  //           priceRRP: 289.99,
  //         },
  //         {
  //           name: 'KB',
  //           description: '245x210cm + 48x73cm(2)',
  //           price: 130.0,
  //           priceRRP: 319.99,
  //         },
  //         {
  //           name: 'SKB',
  //           description: '270x240cm + 48x73cm(2)',
  //           price: 150.0,
  //           priceRRP: 359.99,
  //         },
  //       ],
  //     },
  //     {
  //       name: 'Coordinating Accessories',
  //       items: [
  //         {
  //           name: '65x65cm European pillowcase',
  //           description: '',
  //           price: 26.0,
  //           priceRRP: 64.99,
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   id: 'Sel-Clo',
  //   seriesName: 'SELENA',
  //   name: 'CLOUD',
  //   description:
  //     'This scenery of woven cotton matelassé banana palms, will bring the classic vibes of summer indoors. These iconic summery motifs depicted in lush, fresh tones are best styled with the coordinating European pillowcases and cushions that feature beautiful tufted textures and cotton fringing. Cotton Jacquard',
  //   price: '',
  //   image:
  //     'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-vr-store-3d/linen-house/products/Sel-Clo.jpg',
  //   link: '',
  //   itemGroups: [
  //     {
  //       name: 'Quilt Cover & Pillowcase/s Set',
  //       items: [
  //         {
  //           name: 'DB',
  //           description: '180x210cm + 48x73cm(2)',
  //           price: 110.0,
  //           priceRRP: 269.99,
  //         },
  //         {
  //           name: 'QB',
  //           description: '210x210cm + 48x73cm(2)',
  //           price: 120.0,
  //           priceRRP: 289.99,
  //         },
  //         {
  //           name: 'KB',
  //           description: '245x210cm + 48x73cm(2)',
  //           price: 130.0,
  //           priceRRP: 319.99,
  //         },
  //         {
  //           name: 'SKB',
  //           description: '270x240cm + 48x73cm(2)',
  //           price: 160.0,
  //           priceRRP: 384.99,
  //         },
  //       ],
  //     },
  //     {
  //       name: 'Coordinating Accessories',
  //       items: [
  //         {
  //           name: '65x65cm European pillowcase',
  //           description: '',
  //           price: 35.0,
  //           priceRRP: 84.99,
  //         },
  //         {
  //           name: '48x48cm Cushion',
  //           description: '',
  //           price: 35.0,
  //           priceRRP: 84.99,
  //         },
  //       ],
  //     },
  //   ],
  // },
  {
    id: 'Ama-Oat',
    seriesName: 'AMAYA',
    name: 'OATMEAL',
    description:
      "A first for Linen House! This quilted coverlet has been lovingly wood carved and block printed by hand by Indian artisans. Watch our socials for videos of this unique process. Based on a vintage 1970's wallpaper look, and coloured in soft greens and pastels, this design is given texture with knotted tassels, and finished with a bound edge. Coordinating European pillowcases and cushions will easily mix and match with many other bed-linen styles. Quilted Cotton Voile",
    price: '',
    image:
      'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-vr-store-3d/linen-house/products/Ama-Oat.jpg',
    link: '',
    itemGroups: [
      {
        name: 'Items Available',
        items: [
          {
            name: 'Bed Cover',
            description: '260x240cm',
            price: 160.0,
            priceRRP: 384.99,
          },
          {
            name: 'Pillow Sham Pair',
            description: '50x75cm (2)',
            price: 50.0,
            priceRRP: 119.99,
          },
        ],
      },
      {
        name: 'Coordinating Accessories',
        items: [
          {
            name: '65x65cm European pillowcase',
            description: '',
            price: 30.0,
            priceRRP: 74.99,
          },
          {
            name: '48x48cm Cushion',
            description: '',
            price: 30.0,
            priceRRP: 74.99,
          },
        ],
      },
    ],
  },
  // {
  //   id: 'Not-Pap',
  //   seriesName: 'NOTO',
  //   name: 'PAPAYA',
  //   description:
  //     'Inspired by sun drenched Mediterranean, this design uses motifs, in a tiled pattern reminiscent of an Italian coastal holiday home. Uplifting, bright colours are teamed with a knife edge finish and a plain dye reverse. Coordinating European pillowcases feature a simple checkerboard pattern, and finished with a gorgeous red cotton ric rac ribbon border. The embroidered cotton sun cushion is a fun highlight for the bedroom or the lounge. Cotton Percale',
  //   price: '',
  //   image:
  //     'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-vr-store-3d/linen-house/products/Not-Pap.jpg',
  //   link: '',
  //   itemGroups: [
  //     {
  //       name: 'Quilt Cover & Pillowcase/s Set',
  //       items: [
  //         {
  //           name: 'DB',
  //           description: '180x210cm + 48x73cm(2)',
  //           price: 90.0,
  //           priceRRP: 219.99,
  //         },
  //         {
  //           name: 'QB',
  //           description: '210x210cm + 48x73cm(2)',
  //           price: 95.0,
  //           priceRRP: 229.99,
  //         },
  //         {
  //           name: 'KB',
  //           description: '245x210cm + 48x73cm(2)',
  //           price: 105.0,
  //           priceRRP: 249.99,
  //         },
  //         {
  //           name: 'SKB',
  //           description: '270x240cm + 48x73cm(2)',
  //           price: 125.0,
  //           priceRRP: 299.99,
  //         },
  //       ],
  //     },
  //     {
  //       name: 'Coordinating Accessories',
  //       items: [
  //         {
  //           name: '65x65cm European pillowcase',
  //           description: '',
  //           price: 24.0,
  //           priceRRP: 59.99,
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   id: 'Kat-Gre',
  //   seriesName: 'KATHERINE',
  //   name: 'GREEN',
  //   description:
  //     'This hand-painted Aussie twist on a classic toile pattern is sure to become a beloved piece when styling any bedroom. The combination of delicate blossoms, citrus and juicy stone fruit combine to depict the ideal Australian summer garden. Native birds are set against iconic scenery of the Australian Bush, simply adding to the dreaminess of it all. Printed on beautifully soft cotton sateen and edged with delicate trim, this design is waiting to be styled with the coordinating European pillowcases and cushions.Cotton Sateen',
  //   price: '',
  //   image:
  //     'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-vr-store-3d/linen-house/products/Kat-Gre.jpg',
  //   link: '',
  //   itemGroups: [
  //     {
  //       name: 'Quilt Cover & Pillowcase/s Set',
  //       items: [
  //         {
  //           name: 'DB',
  //           description: '180x210cm + 48x73cm(2)',
  //           price: 90.0,
  //           priceRRP: 219.99,
  //         },
  //         {
  //           name: 'QB',
  //           description: '210x210cm + 48x73cm(2)',
  //           price: 95.0,
  //           priceRRP: 229.99,
  //         },
  //         {
  //           name: 'KB',
  //           description: '245x210cm + 48x73cm(2)',
  //           price: 105.0,
  //           priceRRP: 249.99,
  //         },
  //         {
  //           name: 'SKB',
  //           description: '270x240cm + 48x73cm(2)',
  //           price: 125.0,
  //           priceRRP: 299.99,
  //         },
  //       ],
  //     },
  //     {
  //       name: 'Coordinating Accessories',
  //       items: [
  //         {
  //           name: '65x65cm European pillowcase',
  //           description: '',
  //           price: 24.0,
  //           priceRRP: 59.99,
  //         },
  //         {
  //           name: '48x48cm Cushion',
  //           description: '',
  //           price: 26.0,
  //           priceRRP: 64.99,
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   id: 'Ort-Cha',
  //   seriesName: 'ORTENSIA',
  //   name: 'CHAMBRAY',
  //   description:
  //     'This modern floral is based on the hydrangea, and uses hand painted collage to create this contemporary look. Fashion forward colours and coordinating European pillowcases will entice a wide range of customers. Embroidered and beautifully tufted cushions add texture to the look. Cotton Sateen',
  //   price: '',
  //   image:
  //     'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-vr-store-3d/linen-house/products/Ort-Cha.jpg',
  //   link: '',
  //   itemGroups: [
  //     {
  //       name: 'Quilt Cover & Pillowcase/s Set',
  //       items: [
  //         {
  //           name: 'DB',
  //           description: '180x210cm + 48x73cm(2)',
  //           price: 90.0,
  //           priceRRP: 219.99,
  //         },
  //         {
  //           name: 'QB',
  //           description: '210x210cm + 48x73cm(2)',
  //           price: 95.0,
  //           priceRRP: 229.99,
  //         },
  //         {
  //           name: 'KB',
  //           description: '245x210cm + 48x73cm(2)',
  //           price: 105.0,
  //           priceRRP: 249.99,
  //         },
  //         {
  //           name: 'SKB',
  //           description: '270x240cm + 48x73cm(2)',
  //           price: 125.0,
  //           priceRRP: 299.99,
  //         },
  //       ],
  //     },
  //     {
  //       name: 'Coordinating Accessories',
  //       items: [
  //         {
  //           name: '65x65cm European pillowcase',
  //           description: '',
  //           price: 24.0,
  //           priceRRP: 59.99,
  //         },
  //         {
  //           name: '48x48cm Cushion',
  //           description: '',
  //           price: 30.0,
  //           priceRRP: 74.99,
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   id: 'Mes-Rus',
  //   seriesName: 'MESSINA',
  //   name: 'RUST',
  //   description:
  //     'This bold, tropical print combines graphic, art deco style motifs together with a classic scarf like print layout that is both vibrant and contemporary. Featured in a fashion inspired palette of rust, dusty pink and a sandy neutral, Messina will add a stylish, modern statement to your space. Printed on cotton sateen, complete the look with coordinating European pillowcases and an embroidered cushion for added texture. Cotton Sateen',
  //   price: '',
  //   image:
  //     'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-vr-store-3d/linen-house/products/Mes-Rus.jpg',
  //   link: '',
  //   itemGroups: [
  //     {
  //       name: 'Quilt Cover & Pillowcase/s Set',
  //       items: [
  //         {
  //           name: 'DB',
  //           description: '180x210cm + 48x73cm(2)',
  //           price: 90.0,
  //           priceRRP: 219.99,
  //         },
  //         {
  //           name: 'QB',
  //           description: '210x210cm + 48x73cm(2)',
  //           price: 95.0,
  //           priceRRP: 229.99,
  //         },
  //         {
  //           name: 'KB',
  //           description: '245x210cm + 48x73cm(2)',
  //           price: 105.0,
  //           priceRRP: 249.99,
  //         },
  //         {
  //           name: 'SKB',
  //           description: '270x240cm + 48x73cm(2)',
  //           price: 125.0,
  //           priceRRP: 299.99,
  //         },
  //       ],
  //     },
  //     {
  //       name: 'Coordinating Accessories',
  //       items: [
  //         {
  //           name: '65x65cm European pillowcase',
  //           description: '',
  //           price: 24.0,
  //           priceRRP: 59.99,
  //         },
  //         {
  //           name: '45x45cm Cushion',
  //           description: '',
  //           price: 30.0,
  //           priceRRP: 74.99,
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   id: 'Raq-Pin',
  //   seriesName: 'RAQUELLE',
  //   name: 'PINK CLAY',
  //   description:
  //     'Welcome our newest plain dye design Raquelle. With a crochet like look and feel, Raquelle features a honeycomb weave giving your space a relaxed vintage feel. In three summer colourways. Cotton Waffle',
  //   price: '',
  //   image:
  //     'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-vr-store-3d/linen-house/products/Raq-Pin.jpg',
  //   link: '',
  //   itemGroups: [
  //     {
  //       name: 'Quilt Cover & Pillowcase/s Set',
  //       items: [
  //         {
  //           name: 'DB',
  //           description: '180x210cm + 48x73cm(2)',
  //           price: 110.0,
  //           priceRRP: 269.99,
  //         },
  //         {
  //           name: 'QB',
  //           description: '210x210cm + 48x73cm(2)',
  //           price: 120.0,
  //           priceRRP: 289.99,
  //         },
  //         {
  //           name: 'KB',
  //           description: '245x210cm + 48x73cm(2)',
  //           price: 130.0,
  //           priceRRP: 319.99,
  //         },
  //         {
  //           name: 'SKB',
  //           description: '270x240cm + 48x73cm(2)',
  //           price: 150.0,
  //           priceRRP: 359.99,
  //         },
  //       ],
  //     },
  //     {
  //       name: 'Coordinating Accessories',
  //       items: [
  //         {
  //           name: '65x65cm European pillowcase',
  //           description: '',
  //           price: 26.0,
  //           priceRRP: 64.99,
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   id: 'Cal-Sea',
  //   seriesName: 'CALIFORNIA',
  //   name: 'SEASHELL',
  //   description:
  //     'California is sure to be showstopper this season. Inspired by vintage tropical wallpaper in an array of lush greens and subtle pink hues foster a tropical quality, which perfectly encapsulates the summer getaway. This cotton sateen ensemble looks sublime paired with its matching European pillowcases and cushion adorned with self-flange finish. Cotton Sateen',
  //   price: '',
  //   image:
  //     'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-vr-store-3d/linen-house/products/Cal-Sea.jpg',
  //   link: '',
  //   itemGroups: [
  //     {
  //       name: 'Quilt Cover & Pillowcase/s Set',
  //       items: [
  //         {
  //           name: 'DB',
  //           description: '180x210cm + 48x73cm(2)',
  //           price: 90.0,
  //           priceRRP: 219.99,
  //         },
  //         {
  //           name: 'QB',
  //           description: '210x210cm + 48x73cm(2)',
  //           price: 95.0,
  //           priceRRP: 229.99,
  //         },
  //         {
  //           name: 'KB',
  //           description: '245x210cm + 48x73cm(2)',
  //           price: 105.0,
  //           priceRRP: 249.99,
  //         },
  //         {
  //           name: 'SKB',
  //           description: '270x240cm + 48x73cm(2)',
  //           price: 125.0,
  //           priceRRP: 299.99,
  //         },
  //       ],
  //     },
  //     {
  //       name: 'Coordinating Accessories',
  //       items: [
  //         {
  //           name: '65x65cm European pillowcase',
  //           description: '',
  //           price: 24.0,
  //           priceRRP: 59.99,
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   id: 'Cas-Mul',
  //   seriesName: 'CASA',
  //   name: 'MULTI',
  //   description:
  //     'This hand painted beauty is a new take on the much loved patchwork style. Featuring a combination of stripes and checks in soft pastel shades. Casa is printed on a cotton percale with a neat knife edge finish. Style with the coordinating European pillowcases and bed cushion along with the range of accessories. Cotton Percale',
  //   price: '',
  //   image:
  //     'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-vr-store-3d/linen-house/products/Cas-Mul.jpg',
  //   link: '',
  //   itemGroups: [
  //     {
  //       name: 'Quilt Cover & Pillowcase/s Set',
  //       items: [
  //         {
  //           name: 'DB',
  //           description: '180x210cm + 48x73cm(2)',
  //           price: 90.0,
  //           priceRRP: 219.99,
  //         },
  //         {
  //           name: 'QB',
  //           description: '210x210cm + 48x73cm(2)',
  //           price: 95.0,
  //           priceRRP: 229.99,
  //         },
  //         {
  //           name: 'KB',
  //           description: '245x210cm + 48x73cm(2)',
  //           price: 105.0,
  //           priceRRP: 249.99,
  //         },
  //         {
  //           name: 'SKB',
  //           description: '270x240cm + 48x73cm(2)',
  //           price: 125.0,
  //           priceRRP: 299.99,
  //         },
  //       ],
  //     },
  //     {
  //       name: 'Coordinating Accessories',
  //       items: [
  //         {
  //           name: '65x65cm European pillowcase',
  //           description: '',
  //           price: 24.0,
  //           priceRRP: 59.99,
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   id: 'Pri-Pis',
  //   seriesName: 'PRISCILLA',
  //   name: 'PISTACHIO',
  //   description:
  //     'A tribute to Australia’s native flora, this modern floral features a dreamlike photographic style in earthy shades of green, sky and peach. Complete this design with coordinating European pillowcases and cushion both finished with fringe detailing.Cotton Sateen',
  //   price: '',
  //   image:
  //     'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-vr-store-3d/linen-house/products/Pri-Pis.jpg',
  //   link: '',
  //   itemGroups: [
  //     {
  //       name: 'Quilt Cover & Pillowcase/s Set',
  //       items: [
  //         {
  //           name: 'DB',
  //           description: '180x210cm + 48x73cm(2)',
  //           price: 90.0,
  //           priceRRP: 219.99,
  //         },
  //         {
  //           name: 'QB',
  //           description: '210x210cm + 48x73cm(2)',
  //           price: 95.0,
  //           priceRRP: 229.99,
  //         },
  //         {
  //           name: 'KB',
  //           description: '245x210cm + 48x73cm(2)',
  //           price: 105.0,
  //           priceRRP: 249.99,
  //         },
  //         {
  //           name: 'SKB',
  //           description: '270x240cm + 48x73cm(2)',
  //           price: 125.0,
  //           priceRRP: 299.99,
  //         },
  //       ],
  //     },
  //     {
  //       name: 'Coordinating Accessories',
  //       items: [
  //         {
  //           name: '65x65cm European pillowcase',
  //           description: '',
  //           price: 24.0,
  //           priceRRP: 59.99,
  //         },
  //         {
  //           name: '48x48cm Cushion',
  //           description: '',
  //           price: 24.0,
  //           priceRRP: 59.99,
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   id: 'Fer-Sun',
  //   seriesName: 'FERRARA',
  //   name: 'SUN',
  //   description:
  //     'A chunky seersucker woven check, in a fun sunny yellow and rust cotton. This design is finished with self-tie enclosures on the quilt, pillowcase, European pillowcases and cushion, with a knife edge finish. Can easily be used as a mix-and-match look by teaming it up with your other bedlinen. Cotton Seersucker',
  //   price: '',
  //   image:
  //     'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-vr-store-3d/linen-house/products/Fer-Sun.jpg',
  //   link: '',
  //   itemGroups: [
  //     {
  //       name: 'Quilt Cover & Pillowcase/s Set',
  //       items: [
  //         {
  //           name: 'DB',
  //           description: '180x210cm + 48x73cm(2)',
  //           price: 80.0,
  //           priceRRP: 199.99,
  //         },
  //         {
  //           name: 'QB',
  //           description: '210x210cm + 48x73cm(2)',
  //           price: 90.0,
  //           priceRRP: 229.99,
  //         },
  //         {
  //           name: 'KB',
  //           description: '245x210cm + 48x73cm(2)',
  //           price: 100.0,
  //           priceRRP: 249.99,
  //         },
  //         {
  //           name: 'SKB',
  //           description: '270x240cm + 48x73cm(2)',
  //           price: 115.0,
  //           priceRRP: 289.99,
  //         },
  //       ],
  //     },
  //     {
  //       name: 'Coordinating Accessories',
  //       items: [
  //         {
  //           name: '65x65cm European pillowcase',
  //           description: '',
  //           price: 24.0,
  //           priceRRP: 69.99,
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   id: 'Hel-Van',
  //   seriesName: 'HELIOS',
  //   name: 'VANILLA',
  //   description:
  //     'A vision of the Greek Islands in all their glory, this design uses a low loop chenille pile - similar to toweling - to create this textural look. Ocean waves on the European pillowcases and coordinating cushion are finished with fun tassels. A simple knife edge finish and plain dye reverse complete this European coastal vibe. Cotton Tufted',
  //   price: '',
  //   image:
  //     'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-vr-store-3d/linen-house/products/Hel-Van.jpg',
  //   link: '',
  //   itemGroups: [
  //     {
  //       name: 'Quilt Cover & Pillowcase/s Set',
  //       items: [
  //         {
  //           name: 'DB',
  //           description: '180x210cm + 48x73cm(2)',
  //           price: 100.0,
  //           priceRRP: 239.99,
  //         },
  //         {
  //           name: 'QB',
  //           description: '210x210cm + 48x73cm(2)',
  //           price: 110.0,
  //           priceRRP: 269.99,
  //         },
  //         {
  //           name: 'KB',
  //           description: '245x210cm + 48x73cm(2)',
  //           price: 120.0,
  //           priceRRP: 289.99,
  //         },
  //         {
  //           name: 'SKB',
  //           description: '270x240cm + 48x73cm(2)',
  //           price: 135.0,
  //           priceRRP: 324.99,
  //         },
  //       ],
  //     },
  //     {
  //       name: 'Coordinating Accessories',
  //       items: [
  //         {
  //           name: '65x65cm European pillowcase',
  //           description: '',
  //           price: 24.0,
  //           priceRRP: 59.99,
  //         },
  //         {
  //           name: '48x48cm Cushion',
  //           description: '',
  //           price: 30.0,
  //           priceRRP: 74.99,
  //         },
  //       ],
  //     },
  //   ],
  // },
  {
    id: 'Tel-Oat',
    seriesName: 'TELLARO',
    name: 'OATMEAL',
    description:
      'This neutral quilted bedcover will suit many spaces. A peaceful printed, quilted and stitched check, on a creamy textured cotton slub ground is finished with a 1 cm bound finish. Complete the look with its coordinating cushion and European pillowcases which would also sit well in your living room. Quilted Cotton',
    price: '',
    image:
      'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-vr-store-3d/linen-house/products/Tel-Oat.jpg',
    link: '',
    itemGroups: [
      {
        name: 'Items Available',
        items: [
          {
            name: 'Bed Cover',
            description: '260x240cm',
            price: 140.0,
            priceRRP: 339.99,
          },
          {
            name: 'Pillow Sham Pair',
            description: '50x75cm (2)',
            price: 40.0,
            priceRRP: 99.99,
          },
        ],
      },
      {
        name: 'Coordinating Accessories',
        items: [
          {
            name: '65x65cm European pillowcase',
            description: '',
            price: 28.0,
            priceRRP: 69.99,
          },
          {
            name: '48x48cm Cushion',
            description: '',
            price: 28.0,
            priceRRP: 69.99,
          },
        ],
      },
    ],
  },
  // {
  //   id: 'Rev-Sal',
  //   seriesName: 'REVERIE',
  //   name: 'SALTWATER',
  //   description:
  //     'Another addition to our yarn dyed collection, welcome Reverie. This striped beauty features soft shades of saltwater, sky and praline. Completed with coordinating stripe as the reverse. This in a woven linen/cotton blend is the perfect choice for those seeking a classic look in fresh colours. Washed Linen Cotton',
  //   price: '',
  //   image:
  //     'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-vr-store-3d/linen-house/products/Rev-Sal.jpg',
  //   link: '',
  //   itemGroups: [
  //     {
  //       name: 'Quilt Cover & Pillowcase/s Set',
  //       items: [
  //         {
  //           name: 'SB',
  //           description: '140x210cm + 48x73cm',
  //           price: 100.0,
  //           priceRRP: 239.99,
  //         },
  //         {
  //           name: 'DB',
  //           description: '180x210cm + 48x73cm(2)',
  //           price: 120.0,
  //           priceRRP: 289.99,
  //         },
  //         {
  //           name: 'QB',
  //           description: '210x210cm + 48x73cm(2)',
  //           price: 130.0,
  //           priceRRP: 319.99,
  //         },
  //         {
  //           name: 'KB',
  //           description: '245x210cm + 48x73cm(2)',
  //           price: 140.0,
  //           priceRRP: 339.99,
  //         },
  //         {
  //           name: 'SKB',
  //           description: '270x240cm + 48x73cm(2)',
  //           price: 160.0,
  //           priceRRP: 384.99,
  //         },
  //       ],
  //     },
  //     {
  //       name: 'Coordinating Accessories',
  //       items: [
  //         {
  //           name: '65x65cm European pillowcase',
  //           description: '',
  //           price: 24.0,
  //           priceRRP: 59.99,
  //         },
  //         {
  //           name: '48x48cm Cushion',
  //           description: '',
  //           price: 24.0,
  //           priceRRP: 59.99,
  //         },
  //       ],
  //     },
  //   ],
  // },
  //Related Products
  // {
  //   id: 'Lof-Clo-Cus',
  //   seriesName: 'LOFT',
  //   name: 'CLOUD',
  //   description: '48x48cm Cushion. Front: Cotton Velvet, Reverse: Cotton/Linen ',
  //   price: '',
  //   image:
  //     'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-vr-store-3d/linen-house/products/Lof-Clo-Cus.jpg',
  //   link: '',
  //   itemGroups: [
  //     {
  //       name: 'Items Available',
  //       items: [
  //         {
  //           name: '48x48cm Cushion',
  //           description: '',
  //           price: 26.0,
  //           priceRRP: 64.99,
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   id: 'Tav-Gol-Cus',
  //   seriesName: 'TAVOLA',
  //   name: 'GOLD',
  //   description: '45cm Round Cushion. Cotton with fringing',
  //   price: '',
  //   image:
  //     'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-vr-store-3d/linen-house/products/Tav-Gol-Cus.jpg',
  //   link: '',
  //   itemGroups: [
  //     {
  //       name: 'Items Available',
  //       items: [
  //         {
  //           name: '45cm Round Cushion',
  //           description: '',
  //           price: 25.0,
  //           priceRRP: 59.99,
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   id: 'Lof-Nec-Cus',
  //   seriesName: 'LOFT',
  //   name: 'NECTAR',
  //   description: '48x48cm Cushion. Front: Cotton Velvet, Reverse: Cotton/Linen',
  //   price: '',
  //   image:
  //     'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-vr-store-3d/linen-house/products/Lof-Nec-Cus.jpg',
  //   link: '',
  //   itemGroups: [
  //     {
  //       name: 'Items Available',
  //       items: [
  //         {
  //           name: '48x48cm Cushion',
  //           description: '',
  //           price: 26.0,
  //           priceRRP: 64.99,
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   id: 'Wad-Sky-Thr',
  //   seriesName: 'WADE',
  //   name: 'SKY',
  //   description: '140x180cm Throw, Cotton with fringing',
  //   price: '',
  //   image:
  //     'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-vr-store-3d/linen-house/products/Wad-Sky-Thr.jpg',
  //   link: '',
  //   itemGroups: [
  //     {
  //       name: 'Items Available',
  //       items: [
  //         {
  //           name: '140x180cm Throw',
  //           description: '',
  //           price: 55.0,
  //           priceRRP: 129.99,
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   id: 'Vis-Sea-Cus',
  //   seriesName: 'VISAGE',
  //   name: 'SEAFOAM',
  //   description: '50x50cm Cushion. Cotton slub printed',
  //   price: '',
  //   image:
  //     'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-vr-store-3d/linen-house/products/Vis-Sea-Cus.jpg',
  //   link: '',
  //   itemGroups: [
  //     {
  //       name: 'Items Available',
  //       items: [
  //         {
  //           name: '50x50cm Cushion',
  //           description: '',
  //           price: 24.0,
  //           priceRRP: 59.99,
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   id: 'Sal-Oce-Thr',
  //   seriesName: 'SALTWATER',
  //   name: 'OCEAN',
  //   description: '150x200cm Throw. Cotton waffle with fringing',
  //   price: '',
  //   image:
  //     'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-vr-store-3d/linen-house/products/Sal-Oce-Thr.jpg',
  //   link: '',
  //   itemGroups: [
  //     {
  //       name: 'Items Available',
  //       items: [
  //         {
  //           name: '150x200cm Throw',
  //           description: '',
  //           price: 60.0,
  //           priceRRP: 149.99,
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   id: 'Coa-Van-Cus',
  //   seriesName: 'COASTAL',
  //   name: 'VANILLA',
  //   description: '50x50cm Cushion. Cotton with fringing',
  //   price: '',
  //   image:
  //     'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-vr-store-3d/linen-house/products/Coa-Van-Cus.jpg',
  //   link: '',
  //   itemGroups: [
  //     {
  //       name: 'Items Available',
  //       items: [
  //         {
  //           name: '50x50cm Cushion',
  //           description: '',
  //           price: 33.0,
  //           priceRRP: 79.99,
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   id: 'Wad-San-Thr',
  //   seriesName: 'WADE',
  //   name: 'SAND',
  //   description: '140x180cm Throw. Cotton with fringing',
  //   price: '',
  //   image:
  //     'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-vr-store-3d/linen-house/products/Wad-San-Thr.jpg',
  //   link: '',
  //   itemGroups: [
  //     {
  //       name: 'Items Available',
  //       items: [
  //         {
  //           name: '140x180cm Throw',
  //           description: '',
  //           price: 55.0,
  //           priceRRP: 129.99,
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   id: 'Bay-Sky-Thr',
  //   seriesName: 'BAY',
  //   name: 'SKYLIGHT',
  //   description: '130x170cm Throw. Cotton with fringing',
  //   price: '',
  //   image:
  //     'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-vr-store-3d/linen-house/products/Bay-Sky-Thr.jpg',
  //   link: '',
  //   itemGroups: [
  //     {
  //       name: 'Items Available',
  //       items: [
  //         {
  //           name: '130x170cm Throw',
  //           description: '',
  //           price: 60.0,
  //           priceRRP: 149.99,
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   id: 'Elo-Blu-Cus-45',
  //   seriesName: 'ELOISE',
  //   name: 'BLUE',
  //   description: '45x45cm Cushion. Cotton with loop tufting & tassels',
  //   price: '',
  //   image:
  //     'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-vr-store-3d/linen-house/products/Elo-Blu-Cus-45.jpg',
  //   link: '',
  //   itemGroups: [
  //     {
  //       name: 'Items Available',
  //       items: [
  //         {
  //           name: '45x45cm Cushion',
  //           description: '',
  //           price: 35.0,
  //           priceRRP: 84.99,
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   id: 'Elo-Blu-Cus-48',
  //   seriesName: 'ELOISE',
  //   name: 'BLUE',
  //   description: '48x48cm Cushion. Cotton layered gauze with raw edge trim',
  //   price: '',
  //   image:
  //     'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-vr-store-3d/linen-house/products/Elo-Blu-Cus-48.jpg',
  //   link: '',
  //   itemGroups: [
  //     {
  //       name: 'Items Available',
  //       items: [
  //         {
  //           name: '48x48cm Cushion',
  //           description: '',
  //           price: 24.0,
  //           priceRRP: 59.99,
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   id: 'Ali-Clo-Cus',
  //   seriesName: 'ALIA',
  //   name: 'CLOUD',
  //   description: '40x60cm Cushion. Cotton',
  //   price: '',
  //   image:
  //     'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-vr-store-3d/linen-house/products/Ali-Clo-Cus.jpg',
  //   link: '',
  //   itemGroups: [
  //     {
  //       name: 'Items Available',
  //       items: [
  //         {
  //           name: '40x60cm Cushion',
  //           description: '',
  //           price: 25.0,
  //           priceRRP: 59.99,
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   id: 'Cal-Oat-Cus',
  //   seriesName: 'CALDER',
  //   name: 'OATMEAL',
  //   description: '48x48cm Cushion. Cotton with tufting',
  //   price: '',
  //   image:
  //     'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-vr-store-3d/linen-house/products/Cal-Oat-Cus.jpg',
  //   link: '',
  //   itemGroups: [
  //     {
  //       name: 'Items Available',
  //       items: [
  //         {
  //           name: '48x48cm Cushion',
  //           description: '',
  //           price: 30.0,
  //           priceRRP: 74.99,
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   id: 'Tid-Pin-Cus',
  //   seriesName: 'TIDE',
  //   name: 'PINK CLAY',
  //   description: '40x60cm Cushion. Cotton with tufting',
  //   price: '',
  //   image:
  //     'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-vr-store-3d/linen-house/products/Tid-Pin-Cus.jpg',
  //   link: '',
  //   itemGroups: [
  //     {
  //       name: 'Items Available',
  //       items: [
  //         {
  //           name: '40x60cm Cushion',
  //           description: '',
  //           price: 25.0,
  //           priceRRP: 59.99,
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   id: 'Sal-Gum-Thr',
  //   seriesName: 'SALTWATER',
  //   name: 'GUMTREE',
  //   description: '150x200cm Throw. Cotton waffle with fringing',
  //   price: '',
  //   image:
  //     'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-vr-store-3d/linen-house/products/Sal-Gum-Thr.jpg',
  //   link: '',
  //   itemGroups: [
  //     {
  //       name: 'Items Available',
  //       items: [
  //         {
  //           name: '150x200cm Throw',
  //           description: '',
  //           price: 60.0,
  //           priceRRP: 149.99,
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   id: 'Slo-Toa-Cus',
  //   seriesName: 'SLOAN',
  //   name: 'TOAST',
  //   description: '50x50cm Cushion. Cotton with fringing',
  //   price: '',
  //   image:
  //     'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-vr-store-3d/linen-house/products/Slo-Toa-Cus.jpg',
  //   link: '',
  //   itemGroups: [
  //     {
  //       name: 'Items Available',
  //       items: [
  //         {
  //           name: '50x50cm Cushion',
  //           description: '',
  //           price: 33.0,
  //           priceRRP: 74.99,
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   id: 'Ade-Van-Cus',
  //   seriesName: 'ADESSA',
  //   name: 'VANILLA',
  //   description: '50x50cm Cushion. Cotton. Basket Weave',
  //   price: '',
  //   image:
  //     'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-vr-store-3d/linen-house/products/Ade-Van-Cus.jpg',
  //   link: '',
  //   itemGroups: [
  //     {
  //       name: 'Items Available',
  //       items: [
  //         {
  //           name: '50x50cm Cushion',
  //           description: '',
  //           price: 27.0,
  //           priceRRP: 64.99,
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   id: 'Mar-Gua-Cus',
  //   seriesName: 'MARISOL',
  //   name: 'GUAVA',
  //   description: '45x45cm Cushion. Cotton slub with ric rac borders',
  //   price: '',
  //   image:
  //     'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-vr-store-3d/linen-house/products/Mar-Gua-Cus.jpg',
  //   link: '',
  //   itemGroups: [
  //     {
  //       name: 'Items Available',
  //       items: [
  //         {
  //           name: '45x45cm Cushion',
  //           description: '',
  //           price: 28.0,
  //           priceRRP: 69.99,
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   id: 'Slo-Van-Cus',
  //   seriesName: 'SLOAN',
  //   name: 'VANILLA',
  //   description: '50x50cm Cushion. Cotton with fringing',
  //   price: '',
  //   image:
  //     'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-vr-store-3d/linen-house/products/Slo-Van-Cus.jpg',
  //   link: '',
  //   itemGroups: [
  //     {
  //       name: 'Items Available',
  //       items: [
  //         {
  //           name: '50x50cm Cushion',
  //           description: '',
  //           price: 33.0,
  //           priceRRP: 74.99,
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   id: 'Tid-Pea-Cus',
  //   seriesName: 'TIDE',
  //   name: 'PEACH',
  //   description: '40x60cm Cushion. Cotton with tufting',
  //   price: '',
  //   image:
  //     'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-vr-store-3d/linen-house/products/Tid-Pea-Cus.jpg',
  //   link: '',
  //   itemGroups: [
  //     {
  //       name: 'Items Available',
  //       items: [
  //         {
  //           name: '40x60cm Cushion',
  //           description: '',
  //           price: 25.0,
  //           priceRRP: 59.99,
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   id: 'Coa-Spe-Cus',
  //   seriesName: 'COASTAL',
  //   name: 'SPEARMINT',
  //   description: '50x50cm Cushion. Cotton with fringing',
  //   price: '',
  //   image:
  //     'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-vr-store-3d/linen-house/products/Coa-Spe-Cus.jpg',
  //   link: '',
  //   itemGroups: [
  //     {
  //       name: 'Items Available',
  //       items: [
  //         {
  //           name: '50x50cm Cushion',
  //           description: '',
  //           price: 33.0,
  //           priceRRP: 79.99,
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   id: 'Pas-Nec-Cus',
  //   seriesName: 'PASSIONFLOWER',
  //   name: 'NECTAR',
  //   description: '48x48cm Cushion. Cotton slub with velvet wall',
  //   price: '',
  //   image:
  //     'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-vr-store-3d/linen-house/products/Pas-Nec-Cus.jpg',
  //   link: '',
  //   itemGroups: [
  //     {
  //       name: 'Items Available',
  //       items: [
  //         {
  //           name: '48x48cm Cushion',
  //           description: '',
  //           price: 24.0,
  //           priceRRP: 59.99,
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   id: 'Ade-Hon-Cus',
  //   seriesName: 'ADESSA',
  //   name: 'HONEY',
  //   description: '50x50cm Cushion. Cotton. Basket Weave',
  //   price: '',
  //   image:
  //     'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-vr-store-3d/linen-house/products/Ade-Hon-Cus.jpg',
  //   link: '',
  //   itemGroups: [
  //     {
  //       name: 'Items Available',
  //       items: [
  //         {
  //           name: '50x50cm Cushion',
  //           description: '',
  //           price: 27.0,
  //           priceRRP: 64.99,
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   id: 'Tav-Sal-Cus',
  //   seriesName: 'TAVOLA',
  //   name: 'SALTWATER',
  //   description: '45cm Round Cushion. Cotton with fringing',
  //   price: '',
  //   image:
  //     'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-vr-store-3d/linen-house/products/Tav-Sal-Cus.jpg',
  //   link: '',
  //   itemGroups: [
  //     {
  //       name: 'Items Available',
  //       items: [
  //         {
  //           name: '45cm Round Cushion',
  //           description: '',
  //           price: 25.0,
  //           priceRRP: 59.99,
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   id: 'Sel-Clo-Cus',
  //   seriesName: 'SELENA',
  //   name: 'CLOUD',
  //   description: '48x48cm Cushion. Cotton jacquard with tufting',
  //   price: '',
  //   image:
  //     'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-vr-store-3d/linen-house/products/Sel-Clo-Cus.jpg',
  //   link: '',
  //   itemGroups: [
  //     {
  //       name: 'Items Available',
  //       items: [
  //         {
  //           name: '48x48cm Cushion',
  //           description: '',
  //           price: 35.0,
  //           priceRRP: 84.99,
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   id: 'Coa-Sea-Cus',
  //   seriesName: 'COASTAL',
  //   name: 'SEA SHELL',
  //   description: '50x50cm Cushion. Cotton with fringing',
  //   price: '',
  //   image:
  //     'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-vr-store-3d/linen-house/products/Coa-Sea-Cus.jpg',
  //   link: '',
  //   itemGroups: [
  //     {
  //       name: 'Items Available',
  //       items: [
  //         {
  //           name: '50x50cm Cushion',
  //           description: '',
  //           price: 33.0,
  //           priceRRP: 79.99,
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   id: 'Ama-Oat-Cus',
  //   seriesName: 'AMAYA',
  //   name: 'OATMEAL',
  //   description: '48x48cm Cushion. Quilted cotton voile with knotted tassels',
  //   price: '',
  //   image:
  //     'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-vr-store-3d/linen-house/products/Ama-Oat-Cus.jpg',
  //   link: '',
  //   itemGroups: [
  //     {
  //       name: 'Items Available',
  //       items: [
  //         {
  //           name: '48x48cm Cushion',
  //           description: '',
  //           price: 30.0,
  //           priceRRP: 74.99,
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   id: 'Lof-Oat-Cus',
  //   seriesName: 'LOFT',
  //   name: 'OATMEAL',
  //   description: '48x48cm Cushion. Front: Cotton Velvet, Reverse: Cotton/Linen',
  //   price: '',
  //   image:
  //     'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-vr-store-3d/linen-house/products/Lof-Oat-Cus.jpg',
  //   link: '',
  //   itemGroups: [
  //     {
  //       name: 'Items Available',
  //       items: [
  //         {
  //           name: '48x48cm Cushion',
  //           description: '',
  //           price: 26.0,
  //           priceRRP: 64.99,
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   id: 'Ade-Pow-Cus',
  //   seriesName: 'ADESSA',
  //   name: 'POWDER BLUE',
  //   description: '50x50cm Cushion. Cotton. Basket Weave',
  //   price: '',
  //   image:
  //     'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-vr-store-3d/linen-house/products/Ade-Pow-Cus.jpg',
  //   link: '',
  //   itemGroups: [
  //     {
  //       name: 'Items Available',
  //       items: [
  //         {
  //           name: '50x50cm Cushion',
  //           description: '',
  //           price: 27.0,
  //           priceRRP: 64.99,
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   id: 'Kat-Gre-Cus',
  //   seriesName: 'KATHERINE',
  //   name: 'GREEN',
  //   description: '48x48cm Cushion. Printed cotton slub with pleat fringe',
  //   price: '',
  //   image:
  //     'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-vr-store-3d/linen-house/products/Kat-Gre-Cus.jpg',
  //   link: '',
  //   itemGroups: [
  //     {
  //       name: 'Items Available',
  //       items: [
  //         {
  //           name: '48x48cm Cushion',
  //           description: '',
  //           price: 26.0,
  //           priceRRP: 64.99,
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   id: 'Ort-Cha-Cus',
  //   seriesName: 'ORTENSIA',
  //   name: 'CHAMBRAY',
  //   description: '48x48cm Cushion. Cotton with crewel stitch embroidery',
  //   price: '',
  //   image:
  //     'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-vr-store-3d/linen-house/products/Ort-Cha-Cus.jpg',
  //   link: '',
  //   itemGroups: [
  //     {
  //       name: 'Items Available',
  //       items: [
  //         {
  //           name: '48x48cm Cushion',
  //           description: '',
  //           price: 30.0,
  //           priceRRP: 74.99,
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   id: 'Mes-Rus-Cus',
  //   seriesName: 'MESSINA',
  //   name: 'RUST',
  //   description: '45x45cm Cushion. Cotton with chain stitch embroidery',
  //   price: '',
  //   image:
  //     'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-vr-store-3d/linen-house/products/Mes-Rus-Cus.jpg',
  //   link: '',
  //   itemGroups: [
  //     {
  //       name: 'Items Available',
  //       items: [
  //         {
  //           name: '45x45cm Cushion',
  //           description: '',
  //           price: 30.0,
  //           priceRRP: 74.99,
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   id: 'Ali-Sea-Cus',
  //   seriesName: 'ALIA',
  //   name: 'SEASHELL',
  //   description: '40x60cm Cushion. Cotton',
  //   price: '',
  //   image:
  //     'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-vr-store-3d/linen-house/products/Ali-Sea-Cus.jpg',
  //   link: '',
  //   itemGroups: [
  //     {
  //       name: 'Items Available',
  //       items: [
  //         {
  //           name: '40x60cm Cushion',
  //           description: '',
  //           price: 25.0,
  //           priceRRP: 59.99,
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   id: 'Lof-Pra-Cus',
  //   seriesName: 'LOFT',
  //   name: 'PRALINE',
  //   description: '48x48cm Cushion. Front: Cotton Velvet, Reverse: Cotton/Linen',
  //   price: '',
  //   image:
  //     'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-vr-store-3d/linen-house/products/Lof-Pra-Cus.jpg',
  //   link: '',
  //   itemGroups: [
  //     {
  //       name: 'Items Available',
  //       items: [
  //         {
  //           name: '48x48cm Cushion',
  //           description: '',
  //           price: 26.0,
  //           priceRRP: 64.99,
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   id: 'Wad-Pra-Thr',
  //   seriesName: 'WADE',
  //   name: 'PRALINE',
  //   description: '140x180cm Throw, Cotton with fringing',
  //   price: '',
  //   image:
  //     'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-vr-store-3d/linen-house/products/Wad-Pra-Thr.jpg',
  //   link: '',
  //   itemGroups: [
  //     {
  //       name: 'Items Available',
  //       items: [
  //         {
  //           name: '140x180cm Throw',
  //           description: '',
  //           price: 55.0,
  //           priceRRP: 129.99,
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   id: 'Tav-Pin-Cus',
  //   seriesName: 'TAVOLA',
  //   name: 'PINK CLAY',
  //   description: '45cm Round Cushion. Cotton with fringing',
  //   price: '',
  //   image:
  //     'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-vr-store-3d/linen-house/products/Tav-Pin-Cus.jpg',
  //   link: '',
  //   itemGroups: [
  //     {
  //       name: 'Items Available',
  //       items: [
  //         {
  //           name: '45cm Round Cushion',
  //           description: '',
  //           price: 25.0,
  //           priceRRP: 59.99,
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   id: 'Pri-Pis-Cus',
  //   seriesName: 'PRISCILLA',
  //   name: 'PISTACHIO',
  //   description: '48x48cm Cushion. Cotton slub with fringing',
  //   price: '',
  //   image:
  //     'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-vr-store-3d/linen-house/products/Pri-Pis-Cus.jpg',
  //   link: '',
  //   itemGroups: [
  //     {
  //       name: 'Items Available',
  //       items: [
  //         {
  //           name: '48x48cm Cushion',
  //           description: '',
  //           price: 24.0,
  //           priceRRP: 59.99,
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   id: 'Wad-San-Cus',
  //   seriesName: 'WADE',
  //   name: 'SAND',
  //   description: '50x50cm Cushion. Cotton with fringing',
  //   price: '',
  //   image:
  //     'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-vr-store-3d/linen-house/products/Wad-San-Cus.jpg',
  //   link: '',
  //   itemGroups: [
  //     {
  //       name: 'Items Available',
  //       items: [
  //         {
  //           name: '50x50cm Cushion',
  //           description: '',
  //           price: 27.0,
  //           priceRRP: 64.99,
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   id: 'Hel-Van-Cus',
  //   seriesName: 'HELIOS',
  //   name: 'VANILLA',
  //   description: '48x48cm Cushion. Cotton with terry chenille tufting & tassels',
  //   price: '',
  //   image:
  //     'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-vr-store-3d/linen-house/products/Hel-Van-Cus.jpg',
  //   link: '',
  //   itemGroups: [
  //     {
  //       name: 'Items Available',
  //       items: [
  //         {
  //           name: '48x48cm Cushion',
  //           description: '',
  //           price: 30.0,
  //           priceRRP: 74.99,
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   id: 'Tel-Oat-Cus',
  //   seriesName: 'TELLARO',
  //   name: 'OATMEAL',
  //   description: '48x48cm Cushion. Quilted cotton slub with stitching',
  //   price: '',
  //   image:
  //     'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-vr-store-3d/linen-house/products/Tel-Oat-Cus.jpg',
  //   link: '',
  //   itemGroups: [
  //     {
  //       name: 'Items Available',
  //       items: [
  //         {
  //           name: '48x48cm Cushion',
  //           description: '',
  //           price: 28.0,
  //           priceRRP: 69.99,
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   id: 'Rev-Sal-Cus',
  //   seriesName: 'REVERIE',
  //   name: 'SALTWATER',
  //   description: '48x48cm Cushion. Washed linen cotton',
  //   price: '',
  //   image:
  //     'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-vr-store-3d/linen-house/products/Rev-Sal-Cus.jpg',
  //   link: '',
  //   itemGroups: [
  //     {
  //       name: 'Items Available',
  //       items: [
  //         {
  //           name: '48x48cm Cushion',
  //           description: '',
  //           price: 24.0,
  //           priceRRP: 59.99,
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   id: 'Lof-Sal-Cus',
  //   seriesName: 'LOFT',
  //   name: 'SALTWATER',
  //   description: '48x48cm Cushion. Front: Cotton Velvet, Reverse: Cotton/Linen',
  //   price: '',
  //   image:
  //     'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-vr-store-3d/linen-house/products/Lof-Sal-Cus.jpg',
  //   link: '',
  //   itemGroups: [
  //     {
  //       name: 'Items Available',
  //       items: [
  //         {
  //           name: '48x48cm Cushion',
  //           description: '',
  //           price: 26.0,
  //           priceRRP: 64.99,
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   id: 'Tav-Oat-Cus',
  //   seriesName: 'TAVOLA',
  //   name: 'OATMEAL',
  //   description: '45cm Round Cushion. Cotton with fringing',
  //   price: '',
  //   image:
  //     'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-vr-store-3d/linen-house/products/Tav-Oat-Cus.jpg',
  //   link: '',
  //   itemGroups: [
  //     {
  //       name: 'Items Available',
  //       items: [
  //         {
  //           name: '45cm Round Cushion',
  //           description: '',
  //           price: 25.0,
  //           priceRRP: 59.99,
  //         },
  //       ],
  //     },
  //   ],
  // },
];

export default products;
