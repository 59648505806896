const medias = [
  {
    id: 1,
    mediaType: 'video',
    rotation: { x: -Math.PI / 30, y: 0, z: 0 },
    position: { x: 27.065716687987454, y: 1.3521545688804174, z: 16.417792778524613 },
    source:
      'https://fidelitystorageprod-cdn.azureedge.net/cottonon-vr-store-3d/cotton-on-chadstone/media/cotton-on-denim.mp4',
    scale: 0.7,
    color: '',
  },
  {
    id: 2,
    mediaType: 'video',
    rotation: { x: -Math.PI / 30, y: Math.PI, z: 0 },
    position: { x: 21.454931140124447, y: 1.3547518238779595, z: 34.100031702635126 },
    source:
      'https://fidelitystorageprod-cdn.azureedge.net/cottonon-vr-store-3d/cotton-on-chadstone/media/cotton-on_sunnyday.mp4',
    scale: 0.7,
    color: '',
  },
  {
    id: 3,
    mediaType: 'video',
    rotation: { x: -Math.PI / 30, y: Math.PI, z: 0 },
    position: { x: 13.29057736641882, y: 0.8590882609409784, z: 33.879800858224706 },
    source:
      'https://fidelitystorageprod-cdn.azureedge.net/cottonon-vr-store-3d/cotton-on-chadstone/media/cotton-on-body_sleep.mp4',
    scale: 0.44,
    color: '',
  },
  {
    id: 4,
    mediaType: 'video',
    rotation: { x: -Math.PI / 30, y: Math.PI / 2, z: 0 },
    position: { x: 1.0551365209774174, y: 1.354285833861266, z: 7.7449490202343805 },
    source:
      'https://fidelitystorageprod-cdn.azureedge.net/cottonon-vr-store-3d/cotton-on-chadstone/media/cotton-on-body_active_dance.mp4',
    scale: 0.7,
    color: '',
  },
  {
    id: 5,
    mediaType: 'video',
    rotation: { x: -Math.PI / 30, y: 0, z: 0 },
    position: { x: 8.449061270984647, y: 1.3527743951236946, z: 2.5981544482598155 },
    source:
      'https://fidelitystorageprod-cdn.azureedge.net/cottonon-vr-store-3d/cotton-on-chadstone/media/cotton-on-body_active_puffers.mp4',
    scale: 0.7,
    color: '',
  },
  {
    id: 6,
    mediaType: 'video',
    rotation: { x: -Math.PI / 30, y: -Math.PI / 2, z: 0 },
    position: { x: 13.885975565293327, y: 1.355242208382596, z: 10.644484003056191 },
    source:
      'https://fidelitystorageprod-cdn.azureedge.net/cottonon-vr-store-3d/cotton-on-chadstone/media/cotton-on-body_swim.mp4',
    scale: 0.7,
    color: '',
  },
  {
    id: 7,
    mediaType: 'video',
    rotation: { x: -Math.PI / 30, y: Math.PI / 2, z: 0 },
    position: { x: -7.772612500147617, y: 1.3830845202408374, z: 18.329120259003627 },
    source:
      'https://fidelitystorageprod-cdn.azureedge.net/cottonon-vr-store-3d/cotton-on-chadstone/media/cotton-on_denim-fit.mp4',
    scale: 0.7,
    color: '',
  },
  {
    id: 8,
    mediaType: 'video',
    rotation: { x: -Math.PI / 30, y: Math.PI / 2, z: 0 },
    position: { x: -7.857272463468149, y: 1.354940096487789, z: 30.162792628585706 },
    source:
      'https://fidelitystorageprod-cdn.azureedge.net/cottonon-vr-store-3d/cotton-on-chadstone/media/cotton-on_mens.mp4',
    scale: 0.7,
    color: '',
  },
  {
    id: 9,
    mediaType: 'video',
    rotation: { x: -Math.PI / 30, y: Math.PI / 2, z: 0 },
    position: { x: -12.587233808054607, y: 0.897222494542044, z: 38.254776439900278 },
    source:
      'https://fidelitystorageprod-cdn.azureedge.net/cottonon-vr-store-3d/cotton-on-chadstone/media/cotton-on_hemp.mp4',
    scale: 0.44,
    color: '',
  },
  {
    id: 10,
    mediaType: 'video',
    rotation: { x: -Math.PI / 30, y: -Math.PI / 2, z: 0 },
    position: { x: 7.784206571132305, y: 0.8597196147889259, z: 39.00282444923054 },
    source:
      'https://fidelitystorageprod-cdn.azureedge.net/cottonon-vr-store-3d/cotton-on-chadstone/media/cotton-on-body_intimates.mp4',
    scale: 0.44,
    color: '',
  },
];

export default medias;
