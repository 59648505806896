import locationService from '@apis/services/location.service';
import storesService from '@apis/services/stores.service';
import useStoreContext from '@hooks/useStoreContext';
import navigationManager from '@utils/navigation.manager';
import clsx from 'clsx';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';

import {
  Box,
  IconButton,
  ListItemIcon,
  MenuItem,
  MenuList,
  Paper,
  Slide,
  Typography,
  makeStyles,
} from '@material-ui/core';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import MenuRoundedIcon from '@material-ui/icons/MenuRounded';

import { SocialMediaButtons } from './SocialMediaButtons';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 250,
    // height: '100%',
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    borderRadius: 0,
    zIndex: 101,
  },
  headerSection: {
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: theme.spacing(3),
    userSelect: 'none',
  },
  menuPaper: {
    padding: 24,
    height: 'calc( 100% - 48px)',
  },
  menuRoot: {
    position: 'relative',
    minHeight: 320,
    // overflowY: 'scroll',
    // '& .MuiMenuItem-root': {
    //   paddingLeft: 24,
    // },
  },
  department: {
    padding: 0,
    '&:first-child li': {
      marginTop: theme.spacing(0),
    },
    '&:nth-child(2) li': {
      marginTop: theme.spacing(0.5),
    },
  },
  subHeader: {
    marginTop: theme.spacing(1.5),
  },
  fontSubHeader: {
    fontSize: '1rem',
    fontWeight: 700,
    fontFamily: 'CircularBook, sans-serif',
    textTransform: 'uppercase',
  },
  fontHeader: {
    fontSize: '1.2rem',
    fontWeight: 700,
    color: '#212121',
    fontFamily: 'CircularBook, sans-serif',
    textTransform: 'uppercase',
    '& span': {
      color: 'black',
    },
  },
  menuIconContainer: {
    position: 'absolute',
    top: 40,
    right: 40,
    zIndex: 100,
  },
  menuIconButton: {
    background: 'transparent',
    // borderRadius: '25%',
    padding: 0,
    border: 'none',
    color: '#fff',
    '& .MuiSvgIcon-root': {
      width: 32,
      height: 32,
    },
    '&:hover': {
      background: 'transparent',
    },
  },
  animatedIconClose: {
    animation: `$myEffect 400ms ${theme.transitions.easing.easeInOut}`,
  },
  animatedIconMenu: {
    animation: `$myEffectExit 400ms ${theme.transitions.easing.easeInOut}`,
  },
  '@keyframes myEffect': {
    '0%': {
      transform: 'rotateZ(90deg)',
      opacity: 0,
    },
    '100%': {
      opacity: 1,
      transform: 'rotateZ(0deg)',
    },
  },
  '@keyframes myEffectExit': {
    '0%': {
      opacity: 0,
      transform: 'rotateZ(-90deg)',
    },
    '100%': {
      opacity: 1,
      transform: 'rotateZ(0deg)',
    },
  },
  formControl: {
    paddingLeft: theme.spacing(2),
    // paddingBottom: theme.spacing(0.5),
    // width: '100%',
  },
  select: {
    width: 146,
    fontSize: 14,
    fontWeight: 'bold',
    '&.MuiSelect-select:focus': {
      backgroundColor: '#fff',
    },
  },
  closeIconButton: {
    background: 'transparent',
    borderRadius: '50%',
    padding: 0,
    border: '1px solid #464646',
    color: '#464646',
    width: 26,
    height: 26,
    '& .MuiSvgIcon-root': {
      width: 18,
      height: 18,
    },
    '&:hover': {
      background: '#464646',
      color: '#fff',
    },
  },
  bottomContainer: {
    position: 'absolute',
    bottom: 24,
    left: 0,
    // background: '#000',
    width: '100%',
    textAlign: 'center',
  },
  menuListIcon: {
    minWidth: 32,
    color: 'inherit',
  },
  menuItem: {
    borderBottom: '1px solid #AAA',
    // paddingLeft: '0 !import',
    // paddingTop: theme.spacing(2),
    // paddingBottom: theme.spacing(2),
    padding: theme.spacing(1.5),
    '&:hover': {
      background: 'transparent',
      color: '#00BA3D',
      borderColor: '#00BA3D',
    },
  },
}));

export const SidebarNavigation = (props) => {
  const classes = useStyles();
  const { storeId } = useStoreContext();
  const storeData = useMemo(() => storesService.getStore(storeId), [storeId]);

  const [openMenu, setOpenMenu] = useState(false);

  const handleToggleMenu = () => {
    setOpenMenu((prevState) => !prevState);
  };

  const handleMenuItemClick = (locationId, options) => {
    let location = locationService.getLocationById(locationId);
    if (location) {
      navigationManager.requestNewLocation(location, false, options);
    }
  };

  return (
    <>
      <Slide direction="left" in={openMenu} timeout={500} mountOnEnter unmountOnExit>
        <Paper className={classes.root}>
          <MenuList className={classes.menuPaper}>
            <Box style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
              <IconButton
                disableRipple
                className={classes.closeIconButton}
                onClick={handleToggleMenu}
              >
                <CloseRoundedIcon className={classes.animatedIconClose} />
              </IconButton>
            </Box>
            <Box className={clsx(classes.fontHeader, classes.headerSection)}>
              <span>MENU</span>
            </Box>
            <MenuList className={classes.menuRoot}>
              <PerfectScrollbar>
                {storeData?.menus?.length > 0 &&
                  storeData.menus.map((menuList, index) => {
                    return (
                      <MenuList key={index} className={classes.department}>
                        <MenuItem
                          {...(!menuList.locationId && { button: false })}
                          classes={{ root: classes.subHeader }}
                          {...(menuList.locationId && {
                            onClick: () =>
                              handleMenuItemClick(menuList.locationId, menuList.options),
                          })}
                          className={classes.menuItem}
                        >
                          <ListItemIcon
                            className={classes.menuListIcon}
                            style={{
                              visibility: menuList.title != 'Entrance' ? 'hidden' : 'visible',
                            }}
                          >
                            <HomeOutlinedIcon fontSize="small" />
                          </ListItemIcon>
                          <Typography variant="body1" className={classes.fontSubHeader}>
                            {menuList.title}
                          </Typography>
                        </MenuItem>
                        {menuList.subMenus &&
                          menuList.subMenus.map((item) => {
                            return (
                              <MenuItem
                                onClick={() => handleMenuItemClick(item.locationId, item.options)}
                                key={`${item.locationId}`}
                              >
                                <Typography variant="subtitle2">{item.title}</Typography>
                              </MenuItem>
                            );
                          })}
                      </MenuList>
                    );
                  })}
                {/* <MenuItem classes={{ root: classes.subHeader }}>
                  <ListItemIcon className={classes.menuListIcon}>
                    <PanToolOutlinedIcon fontSize="small" />
                  </ListItemIcon>
                  <Typography variant="body1" className={classes.fontSubHeader}>
                    {`About Us`}
                  </Typography>
                </MenuItem> */}
              </PerfectScrollbar>
            </MenuList>
            <div className={classes.bottomContainer}>
              <SocialMediaButtons></SocialMediaButtons>
            </div>
          </MenuList>
        </Paper>
      </Slide>
      <Box className={classes.menuIconContainer}>
        <IconButton disableRipple className={classes.menuIconButton} onClick={handleToggleMenu}>
          <MenuRoundedIcon className={classes.animatedIconMenu} />
        </IconButton>
      </Box>
    </>
  );
};
