import DataStorage from '@apis/data/DataStorage';
import React, { createContext, useCallback, useContext, useState } from 'react';

const StoreContext = createContext({
  storeId: '',
  setStoreId: (storeId) => {},
});

export const StoreContextProvider = ({ children }) => {
  const [currentStoreId, setCurrentStoreId] = useState('');

  const setStoreId = useCallback(async (storeId) => {
    await DataStorage.initialize(storeId);
    setCurrentStoreId(storeId);
  }, []);

  let contextValue = {
    storeId: currentStoreId,
    setStoreId,
  };

  return <StoreContext.Provider value={contextValue}>{children}</StoreContext.Provider>;
};

const useStoreContext = () => {
  const context = useContext(StoreContext);

  if (context === undefined) {
    throw new Error('useStoreContext must be used within StoreContext');
  }

  return context;
};

export default useStoreContext;
