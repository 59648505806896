import useStoreContext from '@hooks/useStoreContext';
import { animated, config, useSpring } from '@react-spring/three';
import resourceManager from '@utils/resource.manager';
import React, { forwardRef, useMemo, useRef, useState } from 'react';
import { useEffect } from 'react';
import * as THREE from 'three';
import { TextureLoader } from 'three/src/loaders/TextureLoader';

import '../utils/ImageShader';

const deg2rad = (degrees) => degrees * (Math.PI / 180);

export const LocationImageDome = forwardRef((props, ref) => {
  const rotationOffset = -90;
  const { locationData, showImage = false, enabledTransition = true, onReady } = props;
  const { storeId } = useStoreContext();
  const [texture, setTexture] = useState();
  const imgSrcRef = useRef();
  const [rotate, setRotate] = useState([]);
  const textureLoader = useMemo(() => new TextureLoader(), []);

  useEffect(() => {
    let cancelled = false;

    const createLocationData = async () => {
      const locationImg = locationData.id
        ? await resourceManager.getResource(storeId, locationData.img)
        : locationData.img;

      console.log(
        'LocationImageDome:',
        'storeId',
        storeId,
        'locationData',
        locationData,
        'locationImg',
        locationImg
      );

      if (imgSrcRef.current == locationImg) {
        onReady(locationData.id);
      } else {
        imgSrcRef.current = locationImg;
        const loadedTexture = await textureLoader.loadAsync(locationImg);
        if (loadedTexture) {
          if (!cancelled && imgSrcRef.current == locationImg) {
            setTexture(loadedTexture);
          }
          onReady(locationData.id);
        }
      }
    };

    if (locationData && locationData.id) {
      let rotateX = deg2rad(0);
      let rotateY = deg2rad(locationData?.degrees - locationData?.rotation?.y);
      let rotateZ = deg2rad(0);
      let rotate = [rotateX, rotateY, rotateZ];
      setRotate(rotate);

      createLocationData();
    }

    return () => {
      cancelled = true;
    };
  }, [storeId, locationData]);

  const spring = useSpring({
    config: { ...config.stiff, duration: 400 },
    to: {
      visible: showImage,
      imageDisplayFactor: showImage ? 1 : 0.25,
    },
    delay: enabledTransition ? 400 : 0,
    immediate: !enabledTransition,
  });

  const ImageMaterial = animated(({ ...props }) => {
    return <imageShader texture1={props.map} attach="material" {...props} />;
  });

  useEffect(() => {
    const dispose = () => {
      if (texture) {
        texture.dispose();
        console.log('dispose LocationImageDome texture');
      }
    };
    window.addEventListener('beforeunload', dispose);

    return () => {
      window.removeEventListener('beforeunload', dispose);
      dispose();
    };
  }, [texture]);

  return (
    <group ref={ref} rotation={rotate}>
      {locationData && (
        <animated.mesh
          visible={spring.visible}
          rotation={[0, deg2rad(rotationOffset), 0]}
          renderOrder={50}
        >
          <sphereBufferGeometry attach="geometry" args={[20, 64, 64]} />
          <ImageMaterial
            map={texture}
            side={THREE.BackSide}
            dispFactor={spring.imageDisplayFactor}
            depthWrite={true}
          />
        </animated.mesh>
      )}
    </group>
  );
});
