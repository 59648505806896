import { CameraContext } from '@contexts/CameraContext';
import { CurrentPositionContext } from '@contexts/CurrentPositionContext';
import { TransitionContext } from '@contexts/TransitionContext';
import { TagProvider } from '@features/3d-store/tags';
import { ModalProvider } from '@features/layout/modal';
import { BackgroundMusicProvider } from '@hooks/useBackgroundMusic';
import { CameraTargetProvider } from '@hooks/useCameraTarget';
import { StoreContextProvider } from '@hooks/useStoreContext';
import React, { useState } from 'react';

export const AppProvider = ({ children }) => {
  const [transitionContext, setTransitionContext] = useState();
  const [cameraContext, setCameraContext] = useState();
  const [currentPositionContext, setCurrentPositionContext] = useState();
  return (
    <StoreContextProvider>
      <BackgroundMusicProvider>
        <ModalProvider>
          <CurrentPositionContext.Provider
            value={[currentPositionContext, setCurrentPositionContext]}
          >
            <TagProvider>
              <TransitionContext.Provider value={[transitionContext, setTransitionContext]}>
                <CameraContext.Provider value={[cameraContext, setCameraContext]}>
                  <CameraTargetProvider>{children}</CameraTargetProvider>
                </CameraContext.Provider>
              </TransitionContext.Provider>
            </TagProvider>
          </CurrentPositionContext.Provider>
        </ModalProvider>
      </BackgroundMusicProvider>
    </StoreContextProvider>
  );
};
