const locations = [
  {
    sid: 'e18aa41763244f06a3e938ecb6e89f96',
    id: 'e18aa41763244f06a3e938ecb6e89f96',
    position: { x: -0.0016124618705362082, y: 1.4847208261489868, z: 0.10701603442430496 },
    rotation: { x: -1.2533308823651248, y: 6.7591565720499664, z: -0.4209029896479817 },
  },
  {
    sid: '04c8d09b1f714e4b82f52ec1ec15326c',
    id: '04c8d09b1f714e4b82f52ec1ec15326c',
    position: { x: 0.08169513940811157, y: 1.5493172407150269, z: 2.654404401779175 },
    rotation: { x: -1.1126994880540708, y: 7.927233423341787, z: -0.2807891615169766 },
  },
  {
    sid: '5c76f55b5b5146c5aa77d2cf39aac9ac',
    id: '5c76f55b5b5146c5aa77d2cf39aac9ac',
    position: { x: 0.09554838389158249, y: 1.6028193235397339, z: 5.2836222648620605 },
    rotation: { x: 0.06396524396986328, y: 14.956354004085604, z: 0.34696871608272295 },
  },
  {
    sid: '4aa45d67a8864e7692a2021c5adf0d33',
    id: '4aa45d67a8864e7692a2021c5adf0d33',
    position: { x: 0.9391251802444458, y: 1.6101819276809692, z: 6.353543758392334 },
    rotation: { x: -0.38262471017173333, y: 57.525004247827894, z: -0.16347213584465667 },
  },
  {
    sid: '46e5a4684d5b41c8b8617d9aa9d2faac',
    id: '46e5a4684d5b41c8b8617d9aa9d2faac',
    position: { x: 3.701354503631592, y: 1.6088117361068726, z: 6.300746917724609 },
    rotation: { x: -0.003629776078289221, y: 39.47467663800932, z: -0.15619681608254 },
  },
  {
    sid: '63c3d432265c4d6dbac793d567967c82',
    id: '63c3d432265c4d6dbac793d567967c82',
    position: { x: 7.2519612312316895, y: 1.61232328414917, z: 6.300598621368408 },
    rotation: { x: 0.35559107036412824, y: 94.27190130587371, z: 0.05237718993162899 },
  },
  {
    sid: '63a00312257c4e5486f4effaecaa305c',
    id: '63a00312257c4e5486f4effaecaa305c',
    position: { x: 9.300031661987305, y: 1.6122386455535889, z: 6.30344820022583 },
    rotation: { x: 0.5245899370780709, y: 104.60791812005563, z: 0.3985370468677725 },
  },
  {
    sid: '60e300e22d6342178a673d9182305909',
    id: '60e300e22d6342178a673d9182305909',
    position: { x: 11.643363952636719, y: 1.6030997037887573, z: 6.150533199310303 },
    rotation: { x: -0.22635274141488773, y: 87.90405443506297, z: 0.5243585894123964 },
  },
  {
    sid: '0bf879cbde5d4061b50f0c8e13870b96',
    id: '0bf879cbde5d4061b50f0c8e13870b96',
    position: { x: 9.310864448547363, y: 1.6159828901290894, z: 12.269309043884277 },
    rotation: { x: 0.2826993298039572, y: 29.867635156360013, z: -0.6085388392864624 },
  },
  {
    sid: '8a6067e65dd64585b6cf3e5ea11c48d8',
    id: '8a6067e65dd64585b6cf3e5ea11c48d8',
    position: { x: 9.234700202941895, y: 1.6116389036178589, z: 9.463337898254395 },
    rotation: { x: -0.052911258381418344, y: -16.466756457360766, z: -0.6864207735924698 },
  },
  {
    sid: '73ca08c5483c461d859c77c20b9d5e8b',
    id: '73ca08c5483c461d859c77c20b9d5e8b',
    position: { x: 11.804251670837402, y: 1.6085877418518066, z: 12.201183319091797 },
    rotation: { x: -0.04647958378140165, y: -6.1044078021454276, z: 0.0148909234268046 },
  },
  {
    sid: '7d5132c599c247c3833f216abd3000fc',
    id: '7d5132c599c247c3833f216abd3000fc',
    position: { x: 13.072029113769531, y: 1.6109923124313354, z: 13.447998046875 },
    rotation: { x: -0.5241181524643638, y: -60.85198769012954, z: 0.20759312618476794 },
  },
  {
    sid: '7094c528082a4be3a43c35aadcea2d31',
    id: '7094c528082a4be3a43c35aadcea2d31',
    position: { x: 13.17420482635498, y: 1.6253536939620972, z: 7.6687798500061035 },
    rotation: { x: -2.1697704117826646, y: -83.6249197973376, z: -0.040535183427674176 },
  },
  {
    sid: 'fbae98fde53a4e898bd06765b793b223',
    id: 'fbae98fde53a4e898bd06765b793b223',
    position: { x: 12.775552749633789, y: 1.60788094997406, z: 5.270196914672852 },
    rotation: { x: -0.7239892506576638, y: -38.678944505368555, z: -0.2972229636511288 },
  },
  {
    sid: 'fd9682fcd54a405d8df4d91cd7300405',
    id: 'fd9682fcd54a405d8df4d91cd7300405',
    position: { x: 14.036617279052734, y: 1.6339603662490845, z: 15.676904678344727 },
    rotation: { x: -1.7219876014136588, y: -0.2889675305776921, z: -0.8719440049371477 },
  },
  {
    sid: '29179019f27b4efb923b0fb6f36f1c64',
    id: '29179019f27b4efb923b0fb6f36f1c64',
    position: { x: 13.844983100891113, y: 1.6069363355636597, z: 17.867109298706055 },
    rotation: { x: 0.502217511231318, y: -179.1039609311942, z: -4.351310606899891 },
  },
  {
    sid: '5b2e72fecc52447f9f0ecef055a5cdbd',
    id: '5b2e72fecc52447f9f0ecef055a5cdbd',
    position: { x: 11.805388450622559, y: 1.6049693822860718, z: 17.84695053100586 },
    rotation: { x: 0.3102357580336746, y: -144.21265314724766, z: -4.754290101607981 },
  },
  {
    sid: 'd4fd002e226a444ab4940a9e0a3c4db8',
    id: 'd4fd002e226a444ab4940a9e0a3c4db8',
    position: { x: 9.636768341064453, y: 1.607319951057434, z: 18.05625343322754 },
    rotation: { x: -0.10953325069429755, y: 163.6681690499159, z: -0.16869330396356905 },
  },
  {
    sid: '368438e54e234a57bed47640808090bd',
    id: '368438e54e234a57bed47640808090bd',
    position: { x: 9.509303092956543, y: 1.6146340370178223, z: 19.9489803314209 },
    rotation: { x: -0.14290835962739237, y: -144.5980723016696, z: -0.1357114938702798 },
  },
  {
    sid: 'c9b557ea91604196854815b40c242a2f',
    id: 'c9b557ea91604196854815b40c242a2f',
    position: { x: 6.69075345993042, y: 1.6110289096832275, z: 19.949037551879883 },
    rotation: { x: -0.3776997225067567, y: -146.20441614644017, z: 0.1271365262416116 },
  },
  {
    sid: '34550d5b7c684463969969163dcf581c',
    id: '34550d5b7c684463969969163dcf581c',
    position: { x: -0.8759468793869019, y: 1.6028164625167847, z: 19.60920524597168 },
    rotation: { x: -0.5637686729713256, y: 69.97249634519768, z: 0.3066961119764444 },
  },
  {
    sid: '6b81c4ca1bf84bc08c4f12dfa3eb19d1',
    id: '6b81c4ca1bf84bc08c4f12dfa3eb19d1',
    position: { x: -3.9767730236053467, y: 1.6093056201934814, z: 19.71980094909668 },
    rotation: { x: -0.627489516081718, y: 94.96979101652849, z: 0.5211700730273803 },
  },
  {
    sid: '1a8342feacba4d5b8d70ed41850df18c',
    id: '1a8342feacba4d5b8d70ed41850df18c',
    position: { x: -7.536714553833008, y: 1.6077988147735596, z: 19.773035049438477 },
    rotation: { x: -0.2850325380934044, y: 89.97514142881519, z: 0.2779528871609074 },
  },
  {
    sid: '683afb6f5c8f48e3baec3339590f7c71',
    id: '683afb6f5c8f48e3baec3339590f7c71',
    position: { x: -8.092612266540527, y: 1.6074848175048828, z: 18.576107025146484 },
    rotation: { x: -0.42360111878427315, y: 74.31917725004497, z: 0.13403750443998697 },
  },
  {
    sid: 'd69d9533fcda45e297f28d1e6d5f23fa',
    id: 'd69d9533fcda45e297f28d1e6d5f23fa',
    position: { x: -6.661401748657227, y: 1.6067841053009033, z: 16.361249923706055 },
    rotation: { x: -0.40024105121436127, y: 63.12808466076475, z: 0.3115852412590281 },
  },
  {
    sid: '95c6601814b247cdad37dd89e701f363',
    id: '95c6601814b247cdad37dd89e701f363',
    position: { x: -10.272274017333984, y: 1.600167155265808, z: 17.842449188232422 },
    rotation: { x: -0.2300377083575398, y: 145.02735142219925, z: -0.04526736556001524 },
  },
  {
    sid: '7e54faee9e6e4bcb8aec2d963ff12b62',
    id: '7e54faee9e6e4bcb8aec2d963ff12b62',
    position: { x: -11.88333797454834, y: 1.603774070739746, z: 19.878068923950195 },
    rotation: { x: -0.05640175180250973, y: 68.26452365052859, z: -0.04752510362229826 },
  },
  {
    sid: 'e1258b8a43dc465a8be31564a5c6a71f',
    id: 'e1258b8a43dc465a8be31564a5c6a71f',
    position: { x: -11.871501922607422, y: 1.6056995391845703, z: 22.981807708740234 },
    rotation: { x: -0.2009923825865054, y: 48.50750865332999, z: 0.19986030206894123 },
  },
  {
    sid: '67d3ac65dd444e3f9c7431fd15ad8776',
    id: '67d3ac65dd444e3f9c7431fd15ad8776',
    position: { x: -14.756993293762207, y: 1.6047463417053223, z: 23.996814727783203 },
    rotation: { x: -0.30360937934474475, y: 89.8242633622196, z: 0.21134474397638447 },
  },
  {
    sid: '6f8df230a89747f68211f4998b3a994f',
    id: '6f8df230a89747f68211f4998b3a994f',
    position: { x: -17.347753524780273, y: 1.6021398305892944, z: 23.967533111572266 },
    rotation: { x: -0.4474484807748915, y: 142.8671492768793, z: -0.19348622737604596 },
  },
  {
    sid: '4ce4a88e9dd54ec1964fc3374609f452',
    id: '4ce4a88e9dd54ec1964fc3374609f452',
    position: { x: -17.314842224121094, y: 1.6067794561386108, z: 22.0009708404541 },
    rotation: { x: -0.4125164629056059, y: -73.7108259854781, z: -0.5134783700477717 },
  },
  {
    sid: '3edf74dde101408aa7d198791710e5bc',
    id: '3edf74dde101408aa7d198791710e5bc',
    position: { x: -15.03971004486084, y: 1.6043733358383179, z: 20.93927574157715 },
    rotation: { x: -0.42477165939910494, y: 167.68711867578278, z: 0.04209818743228529 },
  },
  {
    sid: '7e132b634b99442480528fc2d9de9c89',
    id: '7e132b634b99442480528fc2d9de9c89',
    position: { x: -15.807696342468262, y: 1.6028820276260376, z: 17.9561824798584 },
    rotation: { x: -0.7830538977965698, y: -90.38878178647523, z: -0.2761464659100178 },
  },
  {
    sid: 'b96868458711420ab8e9cd540de7dcb1',
    id: 'b96868458711420ab8e9cd540de7dcb1',
    position: { x: -13.567069053649902, y: 1.5981073379516602, z: 17.20730209350586 },
    rotation: { x: -0.5549181958771576, y: -167.43519692465628, z: -0.19445293902882949 },
  },
  {
    sid: 'c74f041b76b646e6a43790c207ac6d71',
    id: 'c74f041b76b646e6a43790c207ac6d71',
    position: { x: -10.459270477294922, y: 1.599009394645691, z: 15.113192558288574 },
    rotation: { x: -0.771853400171617, y: -156.22901487823296, z: 0.11688289746487292 },
  },
  {
    sid: 'f74abae14905401ca5612aae2ef124e1',
    id: 'f74abae14905401ca5612aae2ef124e1',
    position: { x: -13.068170547485352, y: 1.6014622449874878, z: 11.275765419006348 },
    rotation: { x: -0.39438302723696084, y: 168.08858263531317, z: -0.35016082807088406 },
  },
  {
    sid: '5c2782bf558e44fca8fd221b36189f6b',
    id: '5c2782bf558e44fca8fd221b36189f6b',
    position: { x: -14.442838668823242, y: 1.6093082427978516, z: 13.82614517211914 },
    rotation: { x: -0.06416058439021187, y: 124.07905921339956, z: 0.26491343600553185 },
  },
  {
    sid: 'de4714c50a5f4e378d3882c394dc6a5c',
    id: 'de4714c50a5f4e378d3882c394dc6a5c',
    position: { x: -12.084066390991211, y: 1.5941975116729736, z: 7.988210201263428 },
    rotation: { x: -1.324566132087547, y: 17.67938631292625, z: 0.1459791205278606 },
  },
  {
    sid: 'a39b095b8bc545e4a93e6d2d646bfdd4',
    id: 'a39b095b8bc545e4a93e6d2d646bfdd4',
    position: { x: -11.325742721557617, y: 1.6012825965881348, z: 5.093692779541016 },
    rotation: { x: -0.6566230207234406, y: 81.58906187001892, z: 0.21262512037486753 },
  },
  {
    sid: 'bab669f145a64270b6dcdfdae18f6989',
    id: 'bab669f145a64270b6dcdfdae18f6989',
    position: { x: -7.706521987915039, y: 1.5996603965759277, z: 5.172053813934326 },
    rotation: { x: -1.1362956858946147, y: 82.59499146879398, z: -0.5150367831947491 },
  },
  {
    sid: 'aae239ee36bc42d8b81c024e289e93a8',
    id: 'aae239ee36bc42d8b81c024e289e93a8',
    position: { x: -6.796462535858154, y: 1.6030117273330688, z: 8.325839042663574 },
    rotation: { x: -1.2820709585608818, y: -31.79893075537341, z: -0.21396149239554707 },
  },
  {
    sid: '31588f46242143a1ac2ef6aed9b2c83f',
    id: '31588f46242143a1ac2ef6aed9b2c83f',
    position: { x: -6.583837032318115, y: 1.6097990274429321, z: 11.085792541503906 },
    rotation: { x: -0.9561552805403295, y: -41.04886896558997, z: -0.07648756343768108 },
  },
  {
    sid: '2408d51e6cda46ac8a07218751efdf71',
    id: '2408d51e6cda46ac8a07218751efdf71',
    position: { x: -3.193542957305908, y: 1.6035290956497192, z: 11.198644638061523 },
    rotation: { x: -1.4640687384747428, y: -46.417956326662335, z: -0.4541145510208171 },
  },
  {
    sid: 'c7a97fc3520f412fbc15743f2782b3a2',
    id: 'c7a97fc3520f412fbc15743f2782b3a2',
    position: { x: -3.0328762531280518, y: 1.598272681236267, z: 7.594559192657471 },
    rotation: { x: -0.986624143004581, y: -57.67363566291535, z: 0.3011777475965796 },
  },
  {
    sid: '092165112fc344e4b42e8f1088b3366a',
    id: '092165112fc344e4b42e8f1088b3366a',
    position: { x: -4.009093284606934, y: 1.5963516235351562, z: 5.265095233917236 },
    rotation: { x: -1.3442014227203432, y: -128.2417731935186, z: 0.09036877213023489 },
  },
  {
    sid: 'dc997f658622404d8be945867d9bb1b9',
    id: 'dc997f658622404d8be945867d9bb1b9',
    position: { x: -2.265789270401001, y: 1.592816948890686, z: 5.456365585327148 },
    rotation: { x: -1.1868950226022068, y: -145.11689912794697, z: 0.31908066028672916 },
  },
  {
    sid: '47cdccc697744efba524350cc8837454',
    id: '47cdccc697744efba524350cc8837454',
    position: { x: -2.9440793991088867, y: 1.6061921119689941, z: 15.787249565124512 },
    rotation: { x: -1.5577285803563536, y: 139.20558542247994, z: -0.5048481483007058 },
  },
  {
    sid: '8f65ca4a5f854d7792a99fea895d08e6',
    id: '8f65ca4a5f854d7792a99fea895d08e6',
    position: { x: 0.8299238681793213, y: 1.6040860414505005, z: 11.362747192382812 },
    rotation: { x: -1.3859261675934225, y: 150.92584579785677, z: -0.015242993091115025 },
  },
  {
    sid: 'a99ca25ac8864e4c9988586215faf145',
    id: 'a99ca25ac8864e4c9988586215faf145',
    position: { x: 0.6064019799232483, y: 1.5997756719589233, z: 8.580333709716797 },
    rotation: { x: -1.419781101532863, y: 146.83658977163, z: -0.039930063854035885 },
  },
  {
    sid: '86c0068f44e54bac87f81cbb8eabf707',
    id: '86c0068f44e54bac87f81cbb8eabf707',
    position: { x: 1.1459286212921143, y: 1.6059867143630981, z: 15.468836784362793 },
    rotation: { x: -1.570305102953933, y: -65.0712149977026, z: -0.2815623656198113 },
  },
  {
    sid: '769855facba748149d073587be72b8e9',
    id: '769855facba748149d073587be72b8e9',
    position: { x: 3.2502048015594482, y: 1.6024112701416016, z: 19.839418411254883 },
    rotation: { x: -1.484649828910572, y: -103.69143145036723, z: -0.4561053228057196 },
  },
  {
    sid: 'bc871237b834423cb6f8b3f5378526e4',
    id: 'bc871237b834423cb6f8b3f5378526e4',
    position: { x: 1.5893272161483765, y: 1.6047779321670532, z: 13.475214958190918 },
    rotation: { x: -1.2071453934002025, y: 45.86486883194266, z: -0.2727006162280128 },
  },
  {
    sid: '88dfae0ca5d046aa92c64e9eef69f8cf',
    id: '88dfae0ca5d046aa92c64e9eef69f8cf',
    position: { x: -0.8394730687141418, y: 1.6020638942718506, z: 13.309070587158203 },
    rotation: { x: -1.6200080822289908, y: 71.59699524714117, z: 0.015265600884735069 },
  },
  {
    sid: '992b332bf61048c18fcec4968f234908',
    id: '992b332bf61048c18fcec4968f234908',
    position: { x: -3.1876578330993652, y: 1.6062215566635132, z: 13.554716110229492 },
    rotation: { x: -0.9413386650177422, y: 89.62013820219427, z: -0.34149083700649874 },
  },
  {
    sid: '24ad553e25054d1c8c7686ce5b515466',
    id: '24ad553e25054d1c8c7686ce5b515466',
    position: { x: 4.350610256195068, y: 1.6088885068893433, z: 14.070432662963867 },
    rotation: { x: -1.1228104027080636, y: 45.54704213290612, z: -0.05561459876317698 },
  },
  {
    sid: 'd26e7e883dd0463796d13e6a1afcf930',
    id: 'd26e7e883dd0463796d13e6a1afcf930',
    position: { x: 7.697458267211914, y: 1.61188542842865, z: 14.285358428955078 },
    rotation: { x: 0.04388879758082724, y: 45.0584931742437, z: 0.21053237920814652 },
  },
  {
    sid: '858abc0a92eb41c6912d5686f77793d7',
    id: '858abc0a92eb41c6912d5686f77793d7',
    position: { x: 6.377324104309082, y: 1.6097197532653809, z: 11.91736125946045 },
    rotation: { x: -0.9051051011882876, y: 145.62759509952232, z: -0.013500934691241865 },
  },
  {
    sid: '14bbbf9099a4433fa06a621f5f58ee91',
    id: '14bbbf9099a4433fa06a621f5f58ee91',
    position: { x: 4.952883720397949, y: 1.613139033317566, z: 9.281768798828125 },
    rotation: { x: -0.9056384710246078, y: 133.77884625554813, z: -0.14581565105987296 },
  },
];
export default locations;
