import navigationManager from '@utils/navigation.manager';
import React, { useCallback } from 'react';

import { LocationImageDome } from './LocationImageDome';

const deg2rad = (degrees) => degrees * (Math.PI / 180);

export const SplashLocation = (props) => {
  const { locationData, showImage = false, enabledTransition = true } = props;

  const handleSplashReady = useCallback((locationId) => {
    navigationManager.locationReady(locationId);
  }, []);

  return (
    <group
      scale={[-1, 1, 1]}
      rotation={[0, deg2rad(locationData?.degrees | 0), 0]}
      position={locationData?.position}
    >
      <LocationImageDome
        locationData={locationData}
        showImage={showImage}
        enabledTransition={enabledTransition}
        onReady={handleSplashReady}
      />
    </group>
  );
};
