import locationsRepository from '@apis/repositories/locations.repository';

class LocationService {
  constructor() {}

  _compareLocationsByDistance = (position, a, b) => {
    let distanceToA = position.distanceTo(a.position);
    let distanceToB = position.distanceTo(b.position);

    return distanceToA - distanceToB;
  };

  getLocations = () => {
    return locationsRepository.getLocations();
  };

  getLocationById = (locationId) => {
    return locationsRepository.getLocationById(locationId);
  };

  getNearestLocation = (position, exceptLocationId) => {
    let lookingRadius = 2;
    let maxLookingRadius = 10;
    let locations = locationsRepository.getLocations();
    if (locations && position) {
      let inRadiusLocations;
      do {
        let maxX = position.x + lookingRadius;
        let minX = position.x - lookingRadius;
        let maxZ = position.z + lookingRadius;
        let minZ = position.z - lookingRadius;
        inRadiusLocations = locations.filter((location) => {
          if (
            location.position.x < maxX &&
            location.position.x > minX &&
            location.position.z < maxZ &&
            location.position.z > minZ &&
            (!exceptLocationId || location.id != exceptLocationId)
          ) {
            return true;
          } else {
            return false;
          }
        });
        lookingRadius++;
      } while (
        (inRadiusLocations == null || inRadiusLocations.length == 0) &&
        lookingRadius < maxLookingRadius
      );
      if (inRadiusLocations && inRadiusLocations.length) {
        inRadiusLocations = inRadiusLocations.sort((a, b) => {
          return this._compareLocationsByDistance(position, a, b);
        });
        return inRadiusLocations[0];
      }
    }
    return null;
  };

  getInRadiusLocations = (position, radius) => {
    let locations = locationsRepository.getLocations();

    if (position && locations && locations.length && radius > 0) {
      let maxX = position.x + radius;
      let minX = position.x - radius;
      let maxZ = position.z + radius;
      let minZ = position.z - radius;

      let inRadiusLocations = locations.filter((location) => {
        if (
          location.position.x < maxX &&
          location.position.x > minX &&
          location.position.z < maxZ &&
          location.position.z > minZ &&
          position.distanceTo(location.position) < radius
        ) {
          return true;
        } else {
          return false;
        }
      });

      return inRadiusLocations;
    }
    return [];
  };
}

export default new LocationService();
