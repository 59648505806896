import useStoreContext from '@hooks/useStoreContext';
import resourceManager from '@utils/resource.manager';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import * as THREE from 'three';
import { TextureLoader } from 'three/src/loaders/TextureLoader';

import { useIntersect } from '@react-three/drei';

import '../utils/ImageShader';
import geometryHelper from '../utils/geometry.helper';

function ImageTile(props) {
  const { minPosition, maxPosition, textureUrl } = props;
  const [texture, setTexture] = useState();
  const { storeId } = useStoreContext();
  const hasVisible = useRef(false);
  const geom = useMemo(() => geometryHelper.createPlaneGeometry(minPosition, maxPosition), []);
  const textureLoader = useMemo(() => new TextureLoader(), []);

  const ref = useIntersect((visible) => {
    if (!hasVisible.current && visible) {
      hasVisible.current = true;
      loadTexture();
    }
  });

  // useEffect(() => {
  //   console.log('new ImageTile');
  // }, []);

  useEffect(() => {
    hasVisible.current = false;
    setTexture(undefined);
  }, [textureUrl]);

  async function createTexture(textureUrl) {
    const locationImg = await resourceManager.getResource(storeId, textureUrl);
    const texture = await textureLoader.loadAsync(locationImg);

    texture.minFilter = THREE.LinearFilter;
    texture.flipY = true;
    texture.needsUpdate = true;
    return texture;
  }

  const loadTexture = useCallback(async () => {
    // console.log('ImageTile loadTexture');
    let texture = await createTexture(textureUrl);
    setTexture(texture);
  }, [textureUrl]);

  useEffect(() => {
    const dispose = () => {
      if (texture) {
        texture.dispose();
        // console.log('dispose ImageTile texture');
      }
    };
    window.addEventListener('beforeunload', () => dispose());

    return () => {
      window.removeEventListener('beforeunload', () => dispose());
      dispose();
    };
  }, [texture]);

  return (
    <mesh geometry={geom} renderOrder={100} ref={ref} dispose={null}>
      {texture ? (
        <imageShader texture1={texture} side={THREE.DoubleSide} dispFactor={1} dispose={null} />
      ) : (
        <meshBasicMaterial transparent opacity={0} />
      )}
    </mesh>
  );
}
export default ImageTile;
