import useWindowLandscape from '@hooks/useWindowLandscape';
import React, { Fragment } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';

import { Box, Divider, Grid, Typography, makeStyles } from '@material-ui/core';
import { Check, Close } from '@material-ui/icons';

import ImageSelector from './ImageSelector';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: 'relative',
    height: '100%',
  },
  imageBox: {
    height: '100%',
    width: '100%',
    objectFit: 'contain',
  },
  contentSection: {
    // height: 'unset',
    padding: 0,
    paddingRight: theme.spacing(2),
  },
}));

export const ProductFullScreenBody = () => {
  const classes = useStyles();
  const isLandscape = useWindowLandscape();

  const imageList = [
    {
      id: 1,
      source:
        'https://fidelitystorageprod-cdn.azureedge.net/prestige-car-connect/default/store-images/rr-cullinan-resize/Prestige%20Car%20Dealership-1.jpg',
    },
    {
      id: 2,
      source:
        'https://fidelitystorageprod-cdn.azureedge.net/prestige-car-connect/default/store-images/rr-cullinan-resize/Prestige%20Car%20Dealership-2.jpg',
    },
    {
      id: 3,
      source:
        'https://fidelitystorageprod-cdn.azureedge.net/prestige-car-connect/default/store-images/rr-cullinan-resize/Prestige%20Car%20Dealership-3.jpg',
    },
    {
      id: 4,
      source:
        'https://fidelitystorageprod-cdn.azureedge.net/prestige-car-connect/default/store-images/rr-cullinan-resize/Prestige%20Car%20Dealership-4.jpg',
    },
    {
      id: 5,
      source:
        'https://fidelitystorageprod-cdn.azureedge.net/prestige-car-connect/default/store-images/rr-cullinan-resize/Prestige%20Car%20Dealership-5.jpg',
    },
    {
      id: 6,
      source:
        'https://fidelitystorageprod-cdn.azureedge.net/prestige-car-connect/default/store-images/rr-cullinan-resize/Prestige%20Car%20Dealership-6.jpg',
    },
    {
      id: 7,
      source:
        'https://fidelitystorageprod-cdn.azureedge.net/prestige-car-connect/default/store-images/rr-cullinan-resize/Prestige%20Car%20Dealership-7.jpg',
    },
    {
      id: 8,
      source:
        'https://fidelitystorageprod-cdn.azureedge.net/prestige-car-connect/default/store-images/rr-cullinan-resize/Prestige%20Car%20Dealership-8.jpg',
    },
    {
      id: 9,
      source:
        'https://fidelitystorageprod-cdn.azureedge.net/prestige-car-connect/default/store-images/rr-cullinan-resize/Prestige%20Car%20Dealership-9.jpg',
    },
    {
      id: 10,
      source:
        'https://fidelitystorageprod-cdn.azureedge.net/prestige-car-connect/default/store-images/rr-cullinan-resize/Prestige%20Car%20Dealership-10.jpg',
    },
    {
      id: 11,
      source:
        'https://fidelitystorageprod-cdn.azureedge.net/prestige-car-connect/default/store-images/rr-cullinan-resize/Prestige%20Car%20Dealership-11.jpg',
    },
    {
      id: 12,
      source:
        'https://fidelitystorageprod-cdn.azureedge.net/prestige-car-connect/default/store-images/rr-cullinan-resize/Prestige%20Car%20Dealership-12.jpg',
    },
    {
      id: 13,
      source:
        'https://fidelitystorageprod-cdn.azureedge.net/prestige-car-connect/default/store-images/rr-cullinan-resize/Prestige%20Car%20Dealership-13.jpg',
    },
    {
      id: 14,
      source:
        'https://fidelitystorageprod-cdn.azureedge.net/prestige-car-connect/default/store-images/rr-cullinan-resize/Prestige%20Car%20Dealership-14.jpg',
    },
    {
      id: 15,
      source:
        'https://fidelitystorageprod-cdn.azureedge.net/prestige-car-connect/default/store-images/rr-cullinan-resize/Prestige%20Car%20Dealership-15.jpg',
    },
    {
      id: 16,
      source:
        'https://fidelitystorageprod-cdn.azureedge.net/prestige-car-connect/default/store-images/rr-cullinan-resize/Prestige%20Car%20Dealership-16.jpg',
    },
    {
      id: 17,
      source:
        'https://fidelitystorageprod-cdn.azureedge.net/prestige-car-connect/default/store-images/rr-cullinan-resize/Prestige%20Car%20Dealership-17.jpg',
    },
    {
      id: 18,
      source:
        'https://fidelitystorageprod-cdn.azureedge.net/prestige-car-connect/default/store-images/rr-cullinan-resize/Prestige%20Car%20Dealership-18.jpg',
    },
  ];

  const spec = [
    {
      key: 'ARAI Mileage',
      value: '9.5 kmpl',
    },
    {
      key: 'Engine Displacement (cc)',
      value: '6750',
    },
    {
      key: 'Max Power (bhp@rpm)',
      value: '563bhp@5000rpm',
    },
    {
      key: 'Seating Capacity',
      value: '5',
    },
    {
      key: 'Boot Space (Litres)',
      value: '560',
    },
    {
      key: 'Fuel Type',
      value: 'Petrol',
    },
    {
      key: 'No. of cylinder',
      value: '12',
    },
    {
      key: 'Max Torque (nm@rpm)',
      value: '850Nm@1600rpm',
    },
    {
      key: 'TransmissionType',
      value: 'Automatic',
    },
    {
      key: 'Body Type',
      value: 'SUV',
    },
  ];

  const features = [
    {
      key: 'Power Steering',
      value: true,
    },
    {
      key: 'Anti Lock Braking System',
      value: true,
    },
    {
      key: 'Automatic Climate Control',
      value: true,
    },
    {
      key: 'Alloy Wheels',
      value: true,
    },
    {
      key: 'Power Windows Front',
      value: true,
    },
    {
      key: 'Air Conditioner',
      value: true,
    },
    {
      key: 'Passenger Airbag',
      value: true,
    },
    {
      key: 'Fog Lights - Front',
      value: true,
    },
  ];

  return (
    <Grid container className={classes.wrapper}>
      {isLandscape ? (
        <Grid item container sm={7} style={{ height: '100%' }}>
          <ImageSelector imageList={imageList} />
        </Grid>
      ) : (
        <></>
      )}
      <Grid item container sm={isLandscape ? 5 : 12} style={{ height: '100%' }}>
        <Box
          display="flex"
          flexDirection="column"
          mx={isLandscape ? 1 : 0}
          p={isLandscape ? 2 : 0}
          sx={{ height: isLandscape ? 'calc(100% - 32px)' : '100%', width: '100%' }}
        >
          <Box
            sx={{
              paddingRight: 16,
              width: 'calc(100% - 16px)',
            }}
          >
            <Typography variant="h3" gutterBottom style={{ fontSize: 28, fontWeight: '600' }}>
              Rolls-Royce Cullinan Specifications
            </Typography>
            <Box my={1}>
              <Divider style={{ backgroundColor: '#000' }} />
            </Box>
          </Box>
          <PerfectScrollbar>
            <Box
              className={classes.contentSection}
              sx={{
                height: isLandscape ? 'calc(100% - 32px)' : '100%',
                width: 'calc(100% - 16px)',
              }}
            >
              {!isLandscape ? (
                <Box sx={{ height: '100%', maxHeight: '65%' }}>
                  <ImageSelector imageList={imageList} />
                </Box>
              ) : (
                <></>
              )}
              <Box my={2}>
                <Typography variant="body1" paragraph>
                  The Rolls-Royce Cullinan has 1 Petrol Engine on offer. The Petrol engine is 6750
                  cc . It is available with Automatic transmission.Depending upon the variant and
                  fuel type the Cullinan has a mileage of 9.5 kmpl . The Cullinan is a 5 seater 12
                  cylinder car and has length of 5341mm, width of 2000mm and a wheelbase of 3295 mm.
                </Typography>
              </Box>
              <Box my={2}>
                <Box>
                  <Typography variant="h5" gutterBottom style={{ fontSize: 18, fontWeight: '600' }}>
                    Key Specifications of Rolls-Royce Cullinan
                  </Typography>
                </Box>
                <Grid container>
                  {spec.map((item, index) => (
                    <Fragment key={`${index}${item.key}${item.value}`}>
                      <Grid
                        item
                        xs={12}
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          margin: '8px 0',
                        }}
                      >
                        <Typography variant="body1" style={{ fontSize: 14, fontWeight: '400' }}>
                          {item.key}
                        </Typography>
                        <Typography variant="subtitle2" style={{ fontSize: 14, fontWeight: '600' }}>
                          {item.value}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                    </Fragment>
                  ))}
                </Grid>
              </Box>
              <Box my={2}>
                <Box>
                  <Typography variant="h5" gutterBottom style={{ fontSize: 18, fontWeight: '600' }}>
                    Key Features of Rolls-Royce Cullinan
                  </Typography>
                </Box>
                <Grid container>
                  {features.map((item, index) => (
                    <Fragment key={`${index}-${item.key}`}>
                      <Grid
                        item
                        xs={12}
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          margin: '8px 0',
                        }}
                      >
                        <Typography variant="body1" style={{ fontSize: 14, fontWeight: '400' }}>
                          {item.key}
                        </Typography>
                        <Typography variant="subtitle2" style={{ fontSize: 14, fontWeight: '600' }}>
                          {item.value ? (
                            <Check style={{ color: '#00B998' }} />
                          ) : (
                            <Close style={{ color: '#B3261E' }} />
                          )}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                    </Fragment>
                  ))}
                </Grid>
              </Box>
            </Box>
          </PerfectScrollbar>
        </Box>
      </Grid>
    </Grid>
  );
};
