import { myEmitter } from '../seed-works/myEmitter';

class ResourceManager {
  constructor() {}

  _getCachedData = async (cacheName, url) => {
    const cacheStorage = await caches.open(cacheName);
    const cachedResponse = await cacheStorage.match(url);

    if (!cachedResponse || !cachedResponse.ok) {
      return false;
    } else {
      const blob = await cachedResponse.blob();
      const objectURL = URL.createObjectURL(blob);
      return objectURL;
    }
  };

  _cacheUrl = async (storeId, url) => {
    const cacheName = storeId;

    const response = await fetch(url);

    if ('caches' in window && response) {
      const cacheStorage = await caches.open(cacheName);
      await cacheStorage.put(url, response);
      return url;
    }
  };

  getResource = async (storeId, url) => {
    const cacheUrl = await this._getCachedData(storeId, url);
    // console.log('cacheUrl', cacheUrl);

    if (cacheUrl) {
      return cacheUrl;
    } else {
      await this._cacheUrl(storeId, url);
      return url;
    }
  };

  cacheAllResource = async (storeId, urls, onUpdateProgress) => {
    var progress = 0;
    const cacheUrlWithProgress = async (storeId, url) => {
      const result = await this._cacheUrl(storeId, url);
      if (result) {
        this.updateUrlList(result);
        if (onUpdateProgress) {
          onUpdateProgress(totalRequests, ++progress);
        }
      }
    };

    const paginate = (array, pageSize, pageNumber) => {
      return array.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);
    };

    const noCacheUrls = [];
    for (const url of urls) {
      const cacheExistUrl = await this._getCachedData(storeId, url);
      if (!cacheExistUrl && cacheExistUrl !== null && cacheExistUrl !== undefined) {
        // pendings.push(cacheUrlWithProgress(storeId, url));
        // await cacheUrlWithProgress(storeId, url);
        noCacheUrls.push(url);
      }
    }

    var totalRequests = noCacheUrls.length;

    let pageSize = 200;
    let maxPageNumber = noCacheUrls.length / pageSize + 1;
    for (let pageNumber = 1; pageNumber <= maxPageNumber; pageNumber++) {
      let page = paginate(noCacheUrls, pageSize, pageNumber);
      let pendings = [];
      for (let item of page) {
        pendings.push(cacheUrlWithProgress(storeId, item));
      }
      await Promise.all(pendings);
    }

    // await Promise.all(pendings);

    return true;
  };

  checkUrlsCached = async (storeId, urls) => {
    let tmpUrls = [];

    for (const url of urls) {
      const cacheExistUrl = await this._getCachedData(storeId, url);
      if (!cacheExistUrl && cacheExistUrl !== null && cacheExistUrl !== undefined) {
        tmpUrls.push(url);
      }
    }

    return tmpUrls;
  };

  updateUrlList = (url) => {
    myEmitter.emit(UrlListEventName.UpdateUrlList, url);
  };

  subscribeEvents = (onUrlListUpdate) => {
    if (onUrlListUpdate) {
      myEmitter.on(UrlListEventName.UpdateUrlList, onUrlListUpdate);
    }
  };

  unsubscribeEvents = () => {
    myEmitter.removeAllListeners(UrlListEventName.UpdateUrlList);
  };
}

const UrlListEventName = {
  UpdateUrlList: 'UpdateUrlList',
};

export default new ResourceManager();
