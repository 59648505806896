import useTagsStatus from '@features/3d-store/tags/hooks/useTagsStatus';
import { useBreakpoint } from '@hooks/useBreakpoint';
import { useOnClickOutside } from '@hooks/useOnClickOutside';
import useWindowLandscape from '@hooks/useWindowLandscape';
import pointerManager from '@utils/pointer.manager';
import React, { useEffect, useRef } from 'react';

import { Box, Fade, IconButton, Slide, makeStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import useModal from '../hooks/useModal';
import FullScreenContainer from './FullScreenContainer';

const useStyles = makeStyles((theme) => ({
  landscapeRoot: (props) => ({
    width: props.width,
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    borderRadius: 0,
    zIndex: 101,
    padding: '40px 40px 0 40px',
    color: '#000',
    backgroundColor: '#fff',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    transition: 'width 0.5s',
  }),
  portraitRoot: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    borderRadius: 0,
    zIndex: 101,
    padding: '40px 40px 0 40px',
    color: '#000',
    backgroundColor: '#fff',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  wrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    zIndex: 101,
  },
  contentRoot: (props) => ({
    width: props.width,
    borderRadius: 8,
    padding: '40px 40px 20px 40px',
    color: '#000',
    backgroundColor: '#fff',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    transition: `height 0.5s`,
    height: '10%',
    position: 'relative',
  }),
  closeIconButton: {
    zIndex: 100,
    position: 'absolute',
    top: 10,
    right: 10,
    background: 'transparent',
    padding: 0,
    border: 'none',
    color: '#000',
    '& .MuiSvgIcon-root': {
      width: 26,
      height: 26,
    },
    '&:hover': {
      background: 'transparent',
    },
  },
}));

const SlideContainer = ({ isOpen, close, children, width }) => {
  const ref = useRef();
  const isLandscape = useWindowLandscape();
  const classes = useStyles({ width: width });

  return (
    <Slide
      direction={isLandscape ? 'left' : 'up'}
      in={isOpen}
      timeout={500}
      mountOnEnter
      unmountOnExit
    >
      <Box
        className={isLandscape ? classes.landscapeRoot : classes.portraitRoot}
        height={isLandscape ? 'auto' : '55%'}
        ref={ref}
      >
        <IconButton disableRipple className={classes.closeIconButton} onClick={close}>
          <CloseIcon />
        </IconButton>
        {children}
      </Box>
    </Slide>
  );
};

const FadeContainer = ({ isOpen, close, children, width }) => {
  const ref = useRef();
  const contentBoxRef = useRef();
  const classes = useStyles({ width: width });
  useOnClickOutside(contentBoxRef, close);

  useEffect(() => {
    if (contentBoxRef.current) {
      contentBoxRef.current.style.height = isOpen ? '75%' : '10%';
    }
  }, [isOpen]);

  return (
    <Fade in={isOpen} timeout={500} mountOnEnter unmountOnExit>
      <Box ref={ref} className={classes.wrapper}>
        <Box ref={contentBoxRef} className={classes.contentRoot}>
          <IconButton disableRipple className={classes.closeIconButton} onClick={close}>
            <CloseIcon />
          </IconButton>
          {children}
        </Box>
      </Box>
    </Fade>
  );
};

export const ModalContainer = ({ modalAlignment }) => {
  const { close, isOpen, body } = useModal();
  const breakpoint = useBreakpoint();
  const getWidth = (breakpoint) => {
    switch (breakpoint) {
      case 'xl':
        return 530;
      case 'lg':
        return 520;
      case 'md':
        return 440;
      case 'sm':
        return 300;
      case 'xs':
        return 300;
      default:
        return 500;
    }
  };

  const { clearActiveTag } = useTagsStatus();

  const handleClose = () => {
    close();
    clearActiveTag();
  };

  useEffect(() => {
    pointerManager.onClickWithoutMove(handleClose);
    return () => {
      pointerManager.offClickWithoutMove(handleClose);
    };
  }, []);

  const getModalContainer = (alignment) => {
    switch (alignment) {
      case 'right':
        return (
          <SlideContainer
            isOpen={isOpen}
            close={handleClose}
            children={body}
            width={getWidth(breakpoint)}
          />
        );
      case 'center':
        return (
          <FadeContainer
            isOpen={isOpen}
            close={handleClose}
            children={body}
            width={getWidth(breakpoint)}
          />
        );
      case 'fullscreen':
        return (
          <FullScreenContainer
            isOpen={isOpen}
            close={handleClose}
            children={body}
            width={getWidth(breakpoint)}
          />
        );

      default:
        return (
          <FadeContainer
            isOpen={isOpen}
            close={handleClose}
            children={body}
            width={getWidth(breakpoint)}
          />
        );
    }
  };

  return <>{body && getModalContainer(modalAlignment)}</>;
};
FullScreenContainer;
