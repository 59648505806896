const locations = [
  {
    sid: '40904ae6cb56444c978851456fdf1aa8',
    id: '40904ae6cb56444c978851456fdf1aa8',
    position: { x: -0.23269788920879364, y: 1.5356638431549072, z: -0.031050365418195724 },
    rotation: { x: 0.20998220236321183, y: 22.603613573478132, z: 0.4809827601031728 },
  },
  {
    sid: '8d570d76225b495b819e20907cd20402',
    id: '8d570d76225b495b819e20907cd20402',
    position: { x: -0.30442720651626587, y: 1.5268038511276245, z: -2.4062979221343994 },
    rotation: { x: -0.12799243845119823, y: 51.104984246899136, z: 0.24676303450587397 },
  },
  {
    sid: '8e581fff69274d11b0d0b80d58a6e3f0',
    id: '8e581fff69274d11b0d0b80d58a6e3f0',
    position: { x: -2.5689213275909424, y: 1.5349289178848267, z: -2.235454797744751 },
    rotation: { x: 0.5438314780479391, y: 64.09796618832128, z: 1.050851424157832 },
  },
  {
    sid: '50efa79a98f7471ca3b964db6eca1039',
    id: '50efa79a98f7471ca3b964db6eca1039',
    position: { x: -2.4687695503234863, y: 1.539471983909607, z: 0.06958755105733871 },
    rotation: { x: -0.6020315096380838, y: 46.3212721786731, z: 0.821720371259522 },
  },
  {
    sid: '3950cc9c731742f194ef764d4bf570f5',
    id: '3950cc9c731742f194ef764d4bf570f5',
    position: { x: -4.564660549163818, y: 1.5306787490844727, z: -0.03926292806863785 },
    rotation: { x: 0.007181733682992723, y: 45.254023480442925, z: 1.1239531343544102 },
  },
  {
    sid: 'fa6480f39e8242f5b71fc1bd2a63c245',
    id: 'fa6480f39e8242f5b71fc1bd2a63c245',
    position: { x: -4.573877811431885, y: 1.535170078277588, z: -2.544621706008911 },
    rotation: { x: 0.1305326195539492, y: 44.24369071227189, z: 0.5728396981786449 },
  },
  {
    sid: '1b0b0c12295140a395b44a49d68e4778',
    id: '1b0b0c12295140a395b44a49d68e4778',
    position: { x: -7.529708385467529, y: 1.5305873155593872, z: 0.3778834640979767 },
    rotation: { x: 0.1421660807549308, y: 5.854363823256285, z: 1.1868347326281756 },
  },
  {
    sid: 'dd5267a70ddc4e00a10492f63d4c2fb0',
    id: 'dd5267a70ddc4e00a10492f63d4c2fb0',
    position: { x: -7.439745903015137, y: 1.5330719947814941, z: -1.3355474472045898 },
    rotation: { x: -0.42225088154628615, y: 12.139676889873783, z: 1.1024730397762028 },
  },
  {
    sid: 'e07f5547f6134e9e8ae1f90cca86e3c0',
    id: 'e07f5547f6134e9e8ae1f90cca86e3c0',
    position: { x: -9.324666976928711, y: 1.5301108360290527, z: -1.9126101732254028 },
    rotation: { x: 0.25116992150856754, y: 27.047623129752505, z: 1.1055169600810402 },
  },
  {
    sid: 'c2231c9facaa44829e984d5c89425c51',
    id: 'c2231c9facaa44829e984d5c89425c51',
    position: { x: -4.896824836730957, y: 1.5332088470458984, z: -0.8784046769142151 },
    rotation: { x: -0.2965898694328627, y: -31.56706147334316, z: 0.6099177949020667 },
  },
  {
    sid: 'ec0de96c9e014af9b1c3c31c59ef7be8',
    id: 'ec0de96c9e014af9b1c3c31c59ef7be8',
    position: { x: -7.027334690093994, y: 1.5293292999267578, z: 0.4462851583957672 },
    rotation: { x: 0.23871279160032668, y: -75.49785530906847, z: 0.2312853417249022 },
  },
  {
    sid: '8b1c775229bd497e81ed548ace5d639a',
    id: '8b1c775229bd497e81ed548ace5d639a',
    position: { x: -6.950986862182617, y: 1.5317175388336182, z: 0.0027039749547839165 },
    rotation: { x: -0.24708560335815452, y: -64.41757093280319, z: 0.14562265177206665 },
  },
  {
    sid: '97acc5ddd2b14ecabf3b255d5274c25c',
    id: '97acc5ddd2b14ecabf3b255d5274c25c',
    position: { x: -6.970775127410889, y: 1.531446099281311, z: 1.7528356313705444 },
    rotation: { x: -1.1151977277719851, y: -61.25321355593783, z: 0.8777126038462315 },
  },
  {
    sid: 'a157b9ab3fc44c8196b44c3462043efa',
    id: 'a157b9ab3fc44c8196b44c3462043efa',
    position: { x: -7.000096797943115, y: 1.540930151939392, z: 3.1754724979400635 },
    rotation: { x: 0.17841927842254324, y: -50.50447326882398, z: 0.5129304121313558 },
  },
  {
    sid: '32b70fa11e064d10a903b37175e9561d',
    id: '32b70fa11e064d10a903b37175e9561d',
    position: { x: -7.045316696166992, y: 1.5402168035507202, z: 4.994036674499512 },
    rotation: { x: -0.2629910981241715, y: -54.51837303775773, z: 1.0437393493536733 },
  },
  {
    sid: '20b86de65147406780d78a9b09d64dbd',
    id: '20b86de65147406780d78a9b09d64dbd',
    position: { x: -6.52449893951416, y: 1.544959306716919, z: 6.493590354919434 },
    rotation: { x: 0.037076555494763255, y: -55.101904630677325, z: 0.40511427359041563 },
  },
  {
    sid: '5d110cadf2bd467da99dedef49904914',
    id: '5d110cadf2bd467da99dedef49904914',
    position: { x: -3.4380791187286377, y: 1.531863808631897, z: 6.435707092285156 },
    rotation: { x: -0.3428666476143768, y: -74.48486880866281, z: 0.7324197157981095 },
  },
  {
    sid: '54fab0b82a9542ce83bcbeb950513f34',
    id: '54fab0b82a9542ce83bcbeb950513f34',
    position: { x: -3.242563009262085, y: 1.5334982872009277, z: 8.856270790100098 },
    rotation: { x: -0.3116084659068685, y: -123.37808739797218, z: 0.49115909647411093 },
  },
  {
    sid: '92870bba92d04055954266d4ed60dcdf',
    id: '92870bba92d04055954266d4ed60dcdf',
    position: { x: -3.3323800563812256, y: 1.5283430814743042, z: 11.33229923248291 },
    rotation: { x: -0.01212875230808327, y: 126.28175921173397, z: 0.6588620106100341 },
  },
  {
    sid: '7bb626f015ce481da94f9d0718649395',
    id: '7bb626f015ce481da94f9d0718649395',
    position: { x: -6.565654754638672, y: 1.531631588935852, z: 11.400362968444824 },
    rotation: { x: -0.3125132929995993, y: 30.868693908040886, z: 0.7537578787438126 },
  },
  {
    sid: '88d8a55bbba84a7f9aa01adcc2a940e0',
    id: '88d8a55bbba84a7f9aa01adcc2a940e0',
    position: { x: -6.6161980628967285, y: 1.5362643003463745, z: 8.960593223571777 },
    rotation: { x: -0.08568126182080894, y: 12.600060936683754, z: 0.38891170866487285 },
  },
  {
    sid: 'd30523744f744968b204503219d5a260',
    id: 'd30523744f744968b204503219d5a260',
    position: { x: -5.994501113891602, y: 1.5313352346420288, z: 14.25561809539795 },
    rotation: { x: -0.1579427599911873, y: -9.344526148297568, z: 0.7828607169478027 },
  },
  {
    sid: '5b1a4ad5bd874fa99489c5af66e0136b',
    id: '5b1a4ad5bd874fa99489c5af66e0136b',
    position: { x: -6.317562103271484, y: 1.5318762063980103, z: 13.479456901550293 },
    rotation: { x: -0.13338964036801695, y: -28.399378240199812, z: 0.6630631873964906 },
  },
  {
    sid: '8061c1433d4a423e928607b0d474f43e',
    id: '8061c1433d4a423e928607b0d474f43e',
    position: { x: -5.212304592132568, y: 1.534180998802185, z: 15.165241241455078 },
    rotation: { x: 0.12787288079141745, y: 10.634140040876114, z: 0.5812365512636031 },
  },
  {
    sid: '4f1df73a88a943218b3216990b609532',
    id: '4f1df73a88a943218b3216990b609532',
    position: { x: -7.284102439880371, y: 1.5326099395751953, z: 14.932964324951172 },
    rotation: { x: -0.4033059056893814, y: -18.487762039182137, z: 0.7025966689031132 },
  },
  {
    sid: '1cac59a46f15463f9247a0449c1af7bd',
    id: '1cac59a46f15463f9247a0449c1af7bd',
    position: { x: -9.887113571166992, y: 1.5369668006896973, z: 14.863850593566895 },
    rotation: { x: 0.0019093879273196362, y: -18.7052000113047, z: 1.1421271177030619 },
  },
  {
    sid: 'ee9e06cec9924be8b5036d9eafc06df3',
    id: 'ee9e06cec9924be8b5036d9eafc06df3',
    position: { x: -12.485363960266113, y: 1.5318013429641724, z: 14.889900207519531 },
    rotation: { x: -0.05463618216136099, y: -19.067397314924914, z: 0.6576802431261872 },
  },
  {
    sid: '20540dc1bbe8402b8de62c6db33da716',
    id: '20540dc1bbe8402b8de62c6db33da716',
    position: { x: -14.918516159057617, y: 1.5333824157714844, z: 14.880350112915039 },
    rotation: { x: -0.18338191263743642, y: -36.18556147455429, z: 0.38859175774088756 },
  },
  {
    sid: 'bc75af49b003409a995663fe3df9ebe8',
    id: 'bc75af49b003409a995663fe3df9ebe8',
    position: { x: -17.241247177124023, y: 1.537842035293579, z: 15.050796508789062 },
    rotation: { x: 0.2806479068565576, y: -31.329972192022073, z: 0.1958257622256103 },
  },
  {
    sid: 'f81e3cc00587454c88875d0d131604de',
    id: 'f81e3cc00587454c88875d0d131604de',
    position: { x: -17.342327117919922, y: 1.5414862632751465, z: 12.886759757995605 },
    rotation: { x: 0.07188060570958119, y: -31.092862882740878, z: 0.5071529097640609 },
  },
  {
    sid: '8f43a136fee644239c2fc8f96498919e',
    id: '8f43a136fee644239c2fc8f96498919e',
    position: { x: -17.357521057128906, y: 1.5420655012130737, z: 11.369157791137695 },
    rotation: { x: -0.06834656017524601, y: -33.56809494371369, z: 0.9525918859146029 },
  },
  {
    sid: 'dfed94c0570941318f378f17d1eb2f7a',
    id: 'dfed94c0570941318f378f17d1eb2f7a',
    position: { x: -19.35118293762207, y: 1.5350065231323242, z: 11.333524703979492 },
    rotation: { x: -0.3281793816145189, y: -55.028098254492534, z: 0.8920964399166671 },
  },
  {
    sid: 'e4fe9f0ffbb346b7a63cc5cc818b990d',
    id: 'e4fe9f0ffbb346b7a63cc5cc818b990d',
    position: { x: -21.170494079589844, y: 1.5353896617889404, z: 11.392678260803223 },
    rotation: { x: 0.11287316705871112, y: -70.07125116852048, z: 0.6412290293739815 },
  },
  {
    sid: 'c974753d235e4adcbe9371b1892ebf4d',
    id: 'c974753d235e4adcbe9371b1892ebf4d',
    position: { x: -23.132308959960938, y: 1.5327529907226562, z: 11.438790321350098 },
    rotation: { x: 0.022905274485321755, y: -86.99850995240762, z: 0.8374002469984486 },
  },
  {
    sid: '1afedcf16f6a4b1f82d4f6f848c5aae2',
    id: '1afedcf16f6a4b1f82d4f6f848c5aae2',
    position: { x: -23.105484008789062, y: 1.5320775508880615, z: 9.353312492370605 },
    rotation: { x: -0.31456953300789675, y: -92.89573918920678, z: 1.0087587496893364 },
  },
  {
    sid: '1fd13449ccc7449486942a9e8e3a427f',
    id: '1fd13449ccc7449486942a9e8e3a427f',
    position: { x: -23.13509178161621, y: 1.534935474395752, z: 7.546838760375977 },
    rotation: { x: 0.08721938833483056, y: -101.44662475445355, z: 0.7202915937685739 },
  },
  {
    sid: '3751ec40c09d4497b0964b9500576026',
    id: '3751ec40c09d4497b0964b9500576026',
    position: { x: -23.21159553527832, y: 1.5374915599822998, z: 4.962219715118408 },
    rotation: { x: -0.16829458322550547, y: -94.57510470095394, z: 1.1989090208241577 },
  },
  {
    sid: '487027a02e0a4894a6e6ba9cf5dcb81b',
    id: '487027a02e0a4894a6e6ba9cf5dcb81b',
    position: { x: -21.469669342041016, y: 1.5378434658050537, z: 4.9917497634887695 },
    rotation: { x: -0.5850158617257849, y: -112.66365302606064, z: 1.1882554785552255 },
  },
  {
    sid: '9de0d1809b8645ac882823c4783dadfe',
    id: '9de0d1809b8645ac882823c4783dadfe',
    position: { x: -21.630983352661133, y: 1.5451182126998901, z: 6.1134209632873535 },
    rotation: { x: 0.0019733510767907005, y: -118.00766446114541, z: 0.8075355020153077 },
  },
  {
    sid: '37ad47ba9417492bbbd05dc01dc3eae0',
    id: '37ad47ba9417492bbbd05dc01dc3eae0',
    position: { x: -21.162107467651367, y: 1.5334795713424683, z: 13.45426082611084 },
    rotation: { x: -0.2745938664704307, y: -141.17315019548425, z: 0.48208524253859003 },
  },
  {
    sid: 'f40533fd00844c26858f1c8b74e5e4a7',
    id: 'f40533fd00844c26858f1c8b74e5e4a7',
    position: { x: -20.43701934814453, y: 1.5360080003738403, z: 15.164729118347168 },
    rotation: { x: -0.08353155515920149, y: -33.68316680559577, z: 0.7891466320958266 },
  },
  {
    sid: 'f45878dc2a264dc49c19ba16265eaeb0',
    id: 'f45878dc2a264dc49c19ba16265eaeb0',
    position: { x: -21.877704620361328, y: 1.5368069410324097, z: 14.285445213317871 },
    rotation: { x: -0.5615013382321045, y: -13.698275174504323, z: 0.8561501341236529 },
  },
  {
    sid: '3ee327dc28184fa89502b36657c1d70c',
    id: '3ee327dc28184fa89502b36657c1d70c',
    position: { x: -23.375947952270508, y: 1.538744568824768, z: 16.8642578125 },
    rotation: { x: -0.5317166034252064, y: -166.30615060852605, z: 1.2392255362820954 },
  },
  {
    sid: '986c11fd57db486f9295a82dd30b819e',
    id: '986c11fd57db486f9295a82dd30b819e',
    position: { x: -25.10921859741211, y: 1.536211609840393, z: 16.91309928894043 },
    rotation: { x: -0.5976997818876161, y: 132.45531653348544, z: 1.1723820093870023 },
  },
  {
    sid: '96744bf871de4a66aa6bb09bbf59a928',
    id: '96744bf871de4a66aa6bb09bbf59a928',
    position: { x: -24.941476821899414, y: 1.5374470949172974, z: 13.938226699829102 },
    rotation: { x: -0.8309904252150269, y: -21.79340251083382, z: 0.8175184695954131 },
  },
  {
    sid: '4c318192d8724c00af4590b4497fc619',
    id: '4c318192d8724c00af4590b4497fc619',
    position: { x: -21.49662208557129, y: 1.5341854095458984, z: 9.173894882202148 },
    rotation: { x: -0.22290073218786094, y: -148.57937986951293, z: 0.5033164999964036 },
  },
  {
    sid: '0d4fb9ac12934a97bcc1551f11bacc3c',
    id: '0d4fb9ac12934a97bcc1551f11bacc3c',
    position: { x: -16.09978675842285, y: 1.5364962816238403, z: 11.672761917114258 },
    rotation: { x: -0.2118695102488393, y: 120.98094831690727, z: 0.8627726549714171 },
  },
  {
    sid: '37f5d4b11404409f9f1fe6e776baf700',
    id: '37f5d4b11404409f9f1fe6e776baf700',
    position: { x: -17.303211212158203, y: 1.5367581844329834, z: 7.9176459312438965 },
    rotation: { x: -0.0878913888776866, y: -170.62696315272078, z: 0.8016200004158222 },
  },
  {
    sid: '1d305fc8810e40489e4464472609d4ab',
    id: '1d305fc8810e40489e4464472609d4ab',
    position: { x: -15.586823463439941, y: 1.5373352766036987, z: 8.441374778747559 },
    rotation: { x: -0.21208958034444095, y: 161.1806831597612, z: 1.3143263357109933 },
  },
  {
    sid: '8d49d3e693554a4cb503ef35e590c6a9',
    id: '8d49d3e693554a4cb503ef35e590c6a9',
    position: { x: -17.34148597717285, y: 1.5401099920272827, z: 9.895610809326172 },
    rotation: { x: 0.15111381835499024, y: 64.35488545561319, z: 0.6133060467195345 },
  },
  {
    sid: '1499a9a4c257428986228c1a24b813f2',
    id: '1499a9a4c257428986228c1a24b813f2',
    position: { x: -17.235828399658203, y: 1.5375429391860962, z: 5.736241340637207 },
    rotation: { x: -0.15305332023364684, y: 80.65565395701144, z: 0.9023231253825331 },
  },
  {
    sid: 'fb4d49c8acf34ef28290ccdbb957168b',
    id: 'fb4d49c8acf34ef28290ccdbb957168b',
    position: { x: -17.477657318115234, y: 1.5429614782333374, z: 4.072869300842285 },
    rotation: { x: -0.19514687639969636, y: 81.07633982565456, z: 0.8230831101635222 },
  },
  {
    sid: '09c6b9b308354ecb9f71f18b89b8c144',
    id: '09c6b9b308354ecb9f71f18b89b8c144',
    position: { x: -18.917598724365234, y: 1.5346366167068481, z: 2.5611190795898438 },
    rotation: { x: -0.10159261503165447, y: 157.86230398142666, z: 0.7147979327731445 },
  },
  {
    sid: '66f85306b3874f21ad348aebb9ebab8f',
    id: '66f85306b3874f21ad348aebb9ebab8f',
    position: { x: -15.138847351074219, y: 1.5350470542907715, z: 5.0691819190979 },
    rotation: { x: -0.1583174899050502, y: 96.27871810275347, z: 0.6086130483298976 },
  },
  {
    sid: '161e71964a76458ab7097df2f1dd8a44',
    id: '161e71964a76458ab7097df2f1dd8a44',
    position: { x: -12.929062843322754, y: 1.5344135761260986, z: 4.973227500915527 },
    rotation: { x: 0.1881864362006275, y: 97.16269586408991, z: 0.40059885900567843 },
  },
  {
    sid: '5bafa17f96e34e128a4772e70fe2c65f',
    id: '5bafa17f96e34e128a4772e70fe2c65f',
    position: { x: -10.615623474121094, y: 1.5327677726745605, z: 4.950076103210449 },
    rotation: { x: -0.2299744057469637, y: 43.33310979089467, z: 0.2768393513193625 },
  },
  {
    sid: '1bc164e7f8c24105b4935f3dc9f1f11d',
    id: '1bc164e7f8c24105b4935f3dc9f1f11d',
    position: { x: -8.628988265991211, y: 1.5334383249282837, z: 4.924769878387451 },
    rotation: { x: -0.243040843452877, y: 20.61068443040495, z: 0.5974982244007314 },
  },
  {
    sid: 'c45a5207e8854d18b110d68df4a8bbd3',
    id: 'c45a5207e8854d18b110d68df4a8bbd3',
    position: { x: -9.28819465637207, y: 1.5360065698623657, z: 8.815884590148926 },
    rotation: { x: -0.49680110987349635, y: -148.46163204406196, z: 0.8177045061495036 },
  },
  {
    sid: 'dcc2c295579c470ebf44c6c3caecb9f4',
    id: 'dcc2c295579c470ebf44c6c3caecb9f4',
    position: { x: -11.427682876586914, y: 1.5359063148498535, z: 8.846070289611816 },
    rotation: { x: -0.3358508761101991, y: -148.31470486682574, z: 0.5419706978117804 },
  },
  {
    sid: '88bdda301935401da5fdf805dc2d3803',
    id: '88bdda301935401da5fdf805dc2d3803',
    position: { x: -13.775604248046875, y: 1.5373852252960205, z: 8.633115768432617 },
    rotation: { x: 0.14130022079844085, y: -148.9171082815746, z: 0.4283042593816872 },
  },
  {
    sid: '9c35fdc984614fd6a65ef94e713a4daa',
    id: '9c35fdc984614fd6a65ef94e713a4daa',
    position: { x: -13.913901329040527, y: 1.534669280052185, z: 11.00722599029541 },
    rotation: { x: -0.12317142986951925, y: 161.1325821030675, z: 0.6328990695799595 },
  },
  {
    sid: '3cfbd0fa907e4b9ca1a5e2be46202366',
    id: '3cfbd0fa907e4b9ca1a5e2be46202366',
    position: { x: -15.372140884399414, y: 1.5381863117218018, z: 10.970396041870117 },
    rotation: { x: -0.23152696956901297, y: 134.1227572221736, z: 0.4782505234877746 },
  },
  {
    sid: 'cb985162d8b74be6bbd0ac26c8d89765',
    id: 'cb985162d8b74be6bbd0ac26c8d89765',
    position: { x: -12.818258285522461, y: 1.5381698608398438, z: 11.109808921813965 },
    rotation: { x: -0.17566841048003973, y: -116.98720462213855, z: 0.8510770802994867 },
  },
  {
    sid: 'da1ea3dd1a1c477d8ada2fcda4c4514e',
    id: 'da1ea3dd1a1c477d8ada2fcda4c4514e',
    position: { x: -11.257912635803223, y: 1.5404738187789917, z: 10.98019027709961 },
    rotation: { x: -0.7181068713953774, y: -116.53487696908577, z: 0.8796624172932556 },
  },
];

export default locations;
