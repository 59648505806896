import { useOnClickOutside } from '@hooks/useOnClickOutside';
import { useEffect, useRef } from 'react';

import { Box, Fade, IconButton, makeStyles } from '@material-ui/core';
import { Close } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    zIndex: 1302,
  },
  contentRoot: (props) => ({
    // width: props.width,
    width: '90%',
    borderRadius: 16,
    padding: 20,
    color: '#000',
    backgroundColor: '#fff',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    transition: `height 0.5s`,
    height: '10%',
    position: 'relative',
  }),
  closeIconButton: {
    zIndex: 100,
    position: 'absolute',
    top: 8,
    right: 8,
    background: 'transparent',
    padding: 0,
    border: '1px solid #464646',
    color: '#464646',
    '& .MuiSvgIcon-root': {
      width: 26,
      height: 26,
    },
    '&:hover': {
      background: '#464646',
      color: '#fff',
    },
  },
}));

const FullScreenContainer = ({ isOpen, close, children, width }) => {
  const ref = useRef();
  const contentBoxRef = useRef();
  const classes = useStyles({ width: width });
  useOnClickOutside(contentBoxRef, close);

  useEffect(() => {
    if (contentBoxRef.current) {
      contentBoxRef.current.style.height = isOpen ? '90%' : '10%';
    }
  }, [isOpen]);

  return (
    <Fade in={isOpen} timeout={500} mountOnEnter unmountOnExit>
      <Box ref={ref} className={classes.wrapper}>
        <Box ref={contentBoxRef} className={classes.contentRoot}>
          <IconButton disableRipple className={classes.closeIconButton} onClick={close}>
            <Close />
          </IconButton>
          {children}
        </Box>
      </Box>
    </Fade>
  );
};

export default FullScreenContainer;
