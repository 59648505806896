import { CameraContext } from '@contexts/CameraContext';
import useCameraTarget from '@hooks/useCameraTarget';
import { useKeyboardControls } from '@hooks/useKeyboardControls';
import useStoreContext from '@hooks/useStoreContext';
import { cameraStateObserver } from '@utils/cameraState.observer';
import navigationManager from '@utils/navigation.manager';
import zoomObserver from '@utils/zoom.observer';
import CameraControls from 'camera-controls';
import { merge } from 'merge';
import React, { forwardRef, useContext, useEffect, useMemo, useRef } from 'react';
import * as THREE from 'three';

// import { Vector3 } from 'three';
import { extend, useFrame, useThree } from '@react-three/fiber';

CameraControls.install({ THREE: THREE });
extend({ CameraControls });

const deg2rad = (degrees) => degrees * (Math.PI / 180);

export const CustomControls = forwardRef((props, ref) => {
  const { cameraRotation } = props;
  const { storeId } = useStoreContext();
  const [cameraContext] = useContext(CameraContext);
  const cameraControls = useRef();
  const {
    camera,
    gl: { domElement },
  } = useThree();
  const firstLoadRef = useRef(true);
  // const polarAngleRef = useRef();
  // const azimuthalAngleRef = useRef();
  const { currentTarget } = useCameraTarget();

  const { rotateLeft, rotateRight, ...move } = useKeyboardControls();
  const cameraPositionRef = useRef();
  const cameraStateRef = useRef();
  // const cameraTargetRef = useRef();
  // const vec3 = useMemo(() => new Vector3(), []);

  useEffect(() => {
    if (rotateLeft || rotateRight) {
      let angle = (rotateLeft ? 0.45 : 0) - (rotateRight ? 0.45 : 0);
      cameraControls.current?.rotateAzimuthTo(cameraControls.current?.azimuthAngle + angle, true);
    }
  }, [rotateLeft, rotateRight, move]);

  useFrame((_, delta) => {
    //   // polarAngleRef.current = cameraControls.current?.polarAngle;
    //   // AzimuthalAngleRef.current = cameraControls.current?.azimuthAngle;
    //   // cameraControls.current?.update(delta);
    //   // console.log(polarAngleRef.current, AzimuthalAngleRef.current);
    cameraControls.current?.update(delta);
  });

  useEffect(() => {
    if (currentTarget?.x && cameraControls?.current) {
      cameraControls.current.saveState();
      // cameraTargetRef.current = cameraControls.current.getTarget();

      cameraControls.current.setLookAt(
        cameraPositionRef.current.x,
        cameraPositionRef.current.y,
        cameraPositionRef.current.z,
        currentTarget.x,
        currentTarget.y,
        currentTarget.z,
        true
      );
      // azimuthalAngleRef.current = cameraControls.current.azimuthAngle;
      // polarAngleRef.current = cameraControls.current.polarAngle;
      cameraControls.current.maxSpeed = 0;
      // console.log(`open az: ${azimuthalAngleRef.current}, pl: ${polarAngleRef.current}`);
      // cameraControls.current.setTarget(currentTarget.x, currentTarget.y, currentTarget.z, true);
    } else if (currentTarget === 'close' && cameraControls?.current) {
      // cameraControls.current.maxSpeed = 10;
      cameraControls.current.reset(true);
      // cameraControls.current.rotateTo(azimuthalAngleRef.current, polarAngleRef.current, false);
      // console.log(`close az: ${azimuthalAngleRef.current}, pl: ${polarAngleRef.current}`);
    }
  }, [currentTarget]);

  useEffect(() => {
    if (storeId && cameraContext?.azimuthal && cameraContext?.polar) {
      cameraControls.current.rotateTo(
        deg2rad(cameraContext.azimuthal),
        deg2rad(cameraContext.polar),
        false
      );
    }
  }, [storeId]);

  useEffect(() => {
    const handleOnNavigationArrived = (locationData, enabledTransition, cameraOptions) => {
      if (locationData && locationData.position) {
        cameraControls.current.maxSpeed = Infinity;
        let position = locationData.position;
        cameraPositionRef.current = position;
        if (enabledTransition === false) {
          camera.layers.disableAll();
        }
        // cameraControls.current.setTarget(position.x, position.y, position.z, enabledTransition);
        cameraControls.current.moveTo(position.x, position.y, position.z, enabledTransition);
        cameraControls.current.dolly(0);
        if (firstLoadRef.current) {
          cameraControls.current.rotateTo(
            deg2rad(cameraRotation.azimuthal),
            deg2rad(cameraRotation.polar),
            false
          );
          firstLoadRef.current = false;
        } else if (cameraOptions?.rotation?.azimuthal) {
          cameraControls.current.rotateTo(
            cameraOptions.rotation.azimuthal,
            cameraOptions.rotation.polar,
            false
          );
        } else {
          cameraControls.current.rotateTo(
            cameraControls.current?.azimuthAngle,
            cameraControls.current?.polarAngle,
            false
          );
        }
      }
    };

    if (cameraControls?.current) {
      cameraControls.current.mouseButtons.wheel = CameraControls.ACTION.ZOOM;
      cameraControls.current.touches.two = CameraControls.ACTION.TOUCH_ZOOM_TRUCK;

      cameraControls.current.addEventListener('rest', (e) => {
        // console.log('Rest!!!', cameraStateRef.current);
        navigationManager.rest();
        setTimeout(() => {
          // console.log('inside timeout!!!', cameraStateRef.current);
          if (cameraStateRef.current !== 'sleep') {
            // console.log('Before Change TO Sleep!!!', cameraStateRef.current);
            cameraStateObserver.changeStateToSleep();
          }
        }, 1500);
        cameraStateRef.current = e.type;
      });

      cameraControls.current.addEventListener('update', (camera) => {
        zoomObserver.updateZoomLevel(camera.target._zoom);
        cameraStateObserver.changeStateToUpdate();
      });

      cameraControls.current.addEventListener('sleep', (camera) => {
        // console.log('Sleep!!!', cameraStateRef.current);
        cameraStateObserver.changeStateToSleep();
        cameraStateRef.current = camera.type;
      });
    }

    navigationManager.onArrived(handleOnNavigationArrived);

    return () => {
      cameraControls.current?.dispose();
      navigationManager.offArrived(handleOnNavigationArrived);
    };
  }, []);

  return (
    <cameraControls
      ref={merge(cameraControls, ref)}
      args={[camera, domElement]}
      minPolarAngle={deg2rad(20)}
      maxPolarAngle={deg2rad(160)}
      minDistance={0.001}
      maxDistance={0.001}
      polarRotateSpeed={-0.25}
      azimuthRotateSpeed={-0.25}
      minZoom={0.75}
      maxZoom={2.5}
      smoothTime={0.25}
    />
  );
});
