import mediaService from '@apis/services/media.service';
import React, { memo, useEffect, useMemo, useState } from 'react';
import { Shape } from 'three';

import { ImagePlane } from './ImagePlane';
import { VideoPlane } from './VideoPlane';

export const Media = memo((props) => {
  const { storeId } = props;
  const [mediaMockData, setMediaMockData] = useState();
  const planeArgs = { width: 2, height: 3.75 };
  const roundedShape = useMemo(() => createRoundedShape(planeArgs), [storeId]);

  useEffect(() => {
    // console.log('Load Media Data, mount media component !!!', storeId);

    // const getMediaSource = async (mediaSource) => {
    //   const url = await resourceManager.getResource(storeId, mediaSource);
    //   // console.log('url>>>>>>', url);
    //   if (url) {
    //     return url;
    //   }
    // };

    const createMediaProps = async () => {
      const mediaData = mediaService.getMedias();
      // const roundedShape = createRoundedShape(planeArgs);
      // console.log('create Media Props !!!!');
      let tempMedias = [];

      if (mediaData && mediaData.length) {
        for (const item of mediaData) {
          let mediaProps = {
            key: `media-${item.id}`,
            rotation: item.rotation,
            position: item.position,
            mediaType: item.mediaType,
            source: item.source,
            scale: item.scale,
            color: item.color,
            shape: roundedShape,
            planeArgs: planeArgs,
            storeId: storeId,
          };
          tempMedias.push(mediaProps);
        }
      }
      setMediaMockData(tempMedias);
    };

    if (storeId) {
      createMediaProps();
    }
  }, [storeId]);

  return (
    <>
      {mediaMockData &&
        mediaMockData.map((media, index) => {
          if (media.mediaType == 'video') {
            return <VideoPlane {...media} />;
          } else {
            return <ImagePlane {...media} />;
          }
        })}
    </>
  );
});

const createRoundedShape = (planeArgs) => {
  // console.log('createRoundedShape!!');
  const _shape = new Shape();
  const x = -(planeArgs.width / 2);
  const y = -(planeArgs.height / 2);
  const radius = 0.08;
  const r = x + planeArgs.width;
  const b = y + planeArgs.height;

  _shape.moveTo(x + radius, y);
  _shape.lineTo(r - radius, y);
  _shape.quadraticCurveTo(r, y, r, y + radius);
  _shape.lineTo(r, y + planeArgs.height - radius);
  _shape.quadraticCurveTo(r, b, r - radius, b);
  _shape.lineTo(x + radius, b);
  _shape.quadraticCurveTo(x, b, x, b - radius);
  _shape.lineTo(x, y + radius);
  _shape.quadraticCurveTo(x, y, x + radius, y);

  return _shape;
};
