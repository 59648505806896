const tags = [
  {
    id: '1',
    type: 'video',
    face: { x: -0.3889538279419444, y: 0.910576294051389, z: 0.13987756232129148 },
    position: { x: -36.80337219484705, y: 0.5210180304586636, z: -1.2113240704643922 },
    media: {
      src: 'https://player.vimeo.com/video/810753457',
    },
  },
  {
    id: '2',
    type: 'video',
    face: { x: -0.4564436562257638, y: -0.05446138781376476, z: -0.8880839745928623 },
    position: { x: -35.34533081924283, y: 1.4393449162136758, z: 3.535883111206103 },
    media: {
      src: 'https://player.vimeo.com/video/810753583',
    },
  },
  {
    id: '3',
    type: 'video',
    face: { x: 0.03936076884426852, y: 0.13593476988658504, z: -0.9899355879105819 },
    position: { x: -35.5305619460763, y: 1.3561511982267604, z: 7.054526212685867 },
    media: {
      src: 'https://player.vimeo.com/video/810753619',
    },
  },
  {
    id: '4',
    type: 'video',
    face: { x: -0.1959028666591732, y: 0.8815691047773364, z: -0.4294857161033457 },
    position: { x: -33.83980340760538, y: 0.9258132207017474, z: 3.704316915335654 },
    media: {
      src: 'https://player.vimeo.com/video/810753619',
    },
  },
  {
    id: '5',
    type: 'video',
    face: { x: 0.03681609861929376, y: 0.09932237507124758, z: -0.9943739943766937 },
    position: { x: -25.607964312455234, y: 1.5050462620494076, z: 7.548746436623423 },
    media: {
      src: 'https://player.vimeo.com/video/810753619',
    },
  },
  {
    id: '6',
    type: 'video',
    face: { x: -0.8134153901774062, y: 0.11547467373746298, z: -0.5701061328803302 },
    position: { x: -24.88983995137887, y: 1.4755984544711864, z: 5.567655721703142 },
    media: {
      src: 'https://player.vimeo.com/video/810753583',
    },
  },
  {
    id: '7',
    type: 'video',
    face: { x: -0.7209314492046479, y: 0.5213746708457144, z: -0.4565372910816913 },
    position: { x: -17.048790520423132, y: 0.9863664771010443, z: 4.690119256439232 },
    media: {
      src: 'https://player.vimeo.com/video/810753674',
    },
  },
  {
    id: '8',
    type: 'video',
    face: { x: 0.016067814713952685, y: 0.9569978008853319, z: -0.2896498479733013 },
    position: { x: -26.13239033205544, y: 1.4985928297464528, z: -1.8227081262479996 },
    media: {
      src: 'https://player.vimeo.com/video/810753674',
    },
  },
  {
    id: '9',
    type: 'video',
    face: { x: -0.8333330975011399, y: 0.5490131092155366, z: 0.06434714071848785 },
    position: { x: -33.24682380024101, y: 1.6365297999862651, z: -6.339590687337173 },
    media: {
      src: 'https://player.vimeo.com/video/810753513',
    },
  },
  {
    id: '10',
    type: 'video',
    face: { x: -0.9953510097069794, y: -0.008830879127254866, z: -0.09590820115681646 },
    position: { x: -28.102960526268973, y: 1.633155618527055, z: -7.866213099029025 },
    media: {
      src: 'https://player.vimeo.com/video/810753513',
    },
  },
  {
    id: '11',
    type: 'video',
    face: { x: -0.6408545043390254, y: 0.12150529650629162, z: 0.7579854663443756 },
    position: { x: -28.016943252975842, y: 1.6409056774069362, z: -10.570512225462497 },
    media: {
      src: 'https://player.vimeo.com/video/810753513',
    },
  },
  {
    id: '12',
    type: 'video',
    face: { x: 0.8642434206652918, y: 0.03187237621223477, z: 0.502063204657881 },
    position: { x: -34.8088846167354, y: 1.768314916797813, z: -13.395301161329899 },
    media: {
      src: 'https://player.vimeo.com/video/810753740',
    },
  },
  {
    id: '13',
    type: 'video',
    face: { x: -0.01941542016165823, y: 0.9997993634684587, z: -0.0049268923279297445 },
    position: { x: -6.747265971262178, y: 0.5311284414133584, z: 1.8961239315272802 },
    media: {
      src: 'https://player.vimeo.com/video/810753674',
    },
  },
];

export default tags;
