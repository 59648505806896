import React from 'react';

import { IconButton, makeStyles } from '@material-ui/core';
import InstagramIcon from '@material-ui/icons/Instagram';

const useSocialMediaButtonStyles = makeStyles({
  root: {
    color: '#FFF',
    backgroundColor: '#464646',
    '&:hover': {
      backgroundColor: (props) => props.hoverBackgroundColor,
    },
  },
});

const SocialMediaButton = ({ hoverBackgroundColor, ...other }) => {
  var classes = useSocialMediaButtonStyles({ hoverBackgroundColor: hoverBackgroundColor });
  return <IconButton className={classes.root} {...other} />;
};

export const SocialMediaButtons = (props) => {
  const openInNewTab = (url) => {
    window.open(url, '_blank', 'noreferrer');
  };

  return (
    <>
      <SocialMediaButton
        hoverBackgroundColor="#C32AA3"
        onClick={() => openInNewTab('https://www.instagram.com/prestigecarconnect/')}
      >
        <InstagramIcon />
      </SocialMediaButton>
    </>
  );
};
