import { CameraState, cameraStateObserver } from '@utils/cameraState.observer';
import navigationManager from '@utils/navigation.manager';
import zoomObserver from '@utils/zoom.observer';
import { useEffect, useRef } from 'react';

export default function useLocationImageZoomDetails(lodRefs, title, zoomBreakPoints) {
  const currentLocationIdRef = useRef();
  const hasRequestShowDetailRef = useRef();
  const cameraStateRef = useRef(CameraState.Sleep);
  const lodReadyRefs = useRef([lodRefs.current.length]);
  const currentLODLayerRef = useRef();

  useEffect(() => {
    const handleOnZoomLevelUpdated = (zoomLevel) => {
      if (zoomBreakPoints && zoomBreakPoints.length) {
        let lodLayer = zoomBreakPoints.findIndex((x) => zoomLevel < x);
        if (lodLayer < 0) {
          lodLayer = zoomBreakPoints.length;
        }
        currentLODLayerRef.current = lodLayer;
        requestShowDetail();
      }
    };
    const handleCameraChangeToSleep = () => {
      cameraStateRef.current = CameraState.Sleep;
      if (hasRequestShowDetailRef.current) {
        requestShowDetail();
      }
    };
    const handleCameraChangeToUpdate = () => {
      cameraStateRef.current = CameraState.Update;
    };

    zoomObserver.onZoomLevelUpdated(handleOnZoomLevelUpdated);
    cameraStateObserver.onChangeToSleep(handleCameraChangeToSleep);
    cameraStateObserver.onChangeToUpdate(handleCameraChangeToUpdate);

    return () => {
      zoomObserver.offZoomLevelUpdated(handleOnZoomLevelUpdated);
      cameraStateObserver.offChangeToSleep(handleCameraChangeToSleep);
      cameraStateObserver.offChangeToUpdate(handleCameraChangeToUpdate);
    };
  }, []);

  const setCurrentLocationId = (locationId) => {
    if (currentLocationIdRef.current != locationId) {
      currentLocationIdRef.current = locationId;
      hasRequestShowDetailRef.current = null;
      lodReadyRefs.current.forEach((lodReady) => {
        lodReady = false;
      });
    }
  };

  const setLODReady = (lodLayer) => {
    lodReadyRefs.current[lodLayer] = true;
    if (hasRequestShowDetailRef.current) {
      requestShowDetail();
    } else if (lodLayer == 0) {
      //the last one, lowest resolution
      currentLODLayerRef.current = lodLayer;
      requestShowDetail();
    }
  };

  const requestShowDetail = () => {
    if (
      currentLODLayerRef.current != null &&
      navigationManager.currentLocationData.id == currentLocationIdRef.current
    ) {
      let lodLayer = currentLODLayerRef.current;
      if (cameraStateRef.current == CameraState.Sleep && lodReadyRefs.current[lodLayer]) {
        lodRefs.current.forEach((detail, index) => {
          if (index == lodLayer) {
            detail.visible = true;
          } else {
            detail.visible = false;
          }
        });
        // console.log('locationId', currentLocationIdRef.current, 'lodRefs.current', lodRefs.current);
      } else {
        hasRequestShowDetailRef.current = true;
      }
    }
  };

  return { currentLocationIdRef, setCurrentLocationId, requestShowDetail, setLODReady };
}
