const locations = [
  {
    sid: '7f8db83bab184d9eb38da0ea6e21b335',
    id: '7f8db83bab184d9eb38da0ea6e21b335',
    position: {
      x: 0.1846713125705719,
      y: 1.54518461227417,
      z: 0.005160553380846977,
    },
    rotation: {
      x: -0.4164712238192263,
      y: -82.46759528400455,
      z: 0.24918389638654456,
    },
  },
  {
    sid: '1d7289b221a3415aa47b60e787017c81',
    id: '1d7289b221a3415aa47b60e787017c81',
    position: {
      x: 1.5515276193618774,
      y: 1.5426503419876099,
      z: 0.11928384751081467,
    },
    rotation: {
      x: -0.4081700061289621,
      y: -16.36324991621336,
      z: 0.38946673716343694,
    },
  },
  {
    sid: '38745ce5baf84031ba39ae946056786f',
    id: '38745ce5baf84031ba39ae946056786f',
    position: {
      x: 3.0485458374023438,
      y: 1.5590893030166626,
      z: 0.02422092854976654,
    },
    rotation: {
      x: -1.2881466653115068,
      y: -11.583718774041323,
      z: 0.7091308574892566,
    },
  },
  {
    sid: 'e8160555b49943fa863aa4dd9f8c684e',
    id: 'e8160555b49943fa863aa4dd9f8c684e',
    position: {
      x: 3.0071849822998047,
      y: 1.5294673442840576,
      z: -1.9852062463760376,
    },
    rotation: {
      x: -1.0046144370087244,
      y: 15.716350733794858,
      z: 0.2922227316728804,
    },
  },
  {
    sid: '09d3edc37a3645769e1354ea4018ea79',
    id: '09d3edc37a3645769e1354ea4018ea79',
    position: {
      x: 2.959254741668701,
      y: 1.5392950773239136,
      z: -1.1179267168045044,
    },
    rotation: {
      x: -0.5683360289555339,
      y: -43.72931191518886,
      z: 0.5556760312177338,
    },
  },
  {
    sid: 'b4a6f39d3cef4eca862ebbf43c8cca8c',
    id: 'b4a6f39d3cef4eca862ebbf43c8cca8c',
    position: {
      x: 1.1984602212905884,
      y: 1.548198938369751,
      z: -1.909688115119934,
    },
    rotation: {
      x: -0.7479396125626644,
      y: -157.50203803236053,
      z: 0.2941709924855187,
    },
  },
  {
    sid: 'd769d849cf7348048014c6807838f304',
    id: 'd769d849cf7348048014c6807838f304',
    position: {
      x: 0.9850103259086609,
      y: 1.5432937145233154,
      z: -1.151774287223816,
    },
    rotation: {
      x: -0.5335192474237606,
      y: 65.09158426803792,
      z: 0.45706765424718804,
    },
  },
  {
    sid: '3f163532d6bf4530ae1a04d1362d4a33',
    id: '3f163532d6bf4530ae1a04d1362d4a33',
    position: {
      x: 1.4481972455978394,
      y: 1.5508819818496704,
      z: -4.085077285766602,
    },
    rotation: {
      x: -0.48096795611779986,
      y: 72.1091165489861,
      z: 0.7380246085559503,
    },
  },
  {
    sid: '2f4dad1cddfc41f8940a329dd79a29cf',
    id: '2f4dad1cddfc41f8940a329dd79a29cf',
    position: {
      x: -0.030455615371465683,
      y: 1.5529762506484985,
      z: -4.319604873657227,
    },
    rotation: {
      x: 0.47965177287415217,
      y: -55.38430292687715,
      z: 0.8474771907603015,
    },
  },
  {
    sid: 'a6b20b4aa6fc4ab1a2ef9cd9ea9af0e1',
    id: 'a6b20b4aa6fc4ab1a2ef9cd9ea9af0e1',
    position: {
      x: 1.5012177228927612,
      y: 1.5569872856140137,
      z: -4.723141193389893,
    },
    rotation: {
      x: 0.4096163110749611,
      y: -24.97696879613256,
      z: 0.3128976392829962,
    },
  },
  {
    sid: '71b6bfd7a5d24a3d894ca6782194c921',
    id: '71b6bfd7a5d24a3d894ca6782194c921',
    position: {
      x: 2.932896375656128,
      y: 1.551330327987671,
      z: -4.623142242431641,
    },
    rotation: {
      x: 0.1496165875819795,
      y: 125.0182198104212,
      z: 0.37325047292883556,
    },
  },
  {
    sid: 'c812c50c199f45c9ac4ae11ef737699d',
    id: 'c812c50c199f45c9ac4ae11ef737699d',
    position: {
      x: 1.607083797454834,
      y: 1.5554351806640625,
      z: -5.850727558135986,
    },
    rotation: {
      x: -0.6188290595544215,
      y: 36.581868684643126,
      z: 0.36548754361706315,
    },
  },
  {
    sid: 'bbcc3f1159f04a54a16c3f6640c9741b',
    id: 'bbcc3f1159f04a54a16c3f6640c9741b',
    position: {
      x: 0.10745299607515335,
      y: 1.5578385591506958,
      z: -5.806449890136719,
    },
    rotation: {
      x: -0.3180605022420702,
      y: 13.444166312031733,
      z: 0.5058732908285312,
    },
  },
  {
    sid: '03cbefa4d52c4cc682d4a8d3cc503cf6',
    id: '03cbefa4d52c4cc682d4a8d3cc503cf6',
    position: {
      x: 2.9738845825195312,
      y: 1.547495722770691,
      z: -5.907593250274658,
    },
    rotation: {
      x: -0.6360843114389528,
      y: -16.123441593740676,
      z: -0.4101128611067873,
    },
  },
  {
    sid: '4fc78032538c409abaf552783b0cd547',
    id: '4fc78032538c409abaf552783b0cd547',
    position: {
      x: 1.4847830533981323,
      y: 1.554628849029541,
      z: -7.150831699371338,
    },
    rotation: {
      x: -0.627944789643201,
      y: -143.58124363216382,
      z: 0.38355483534047446,
    },
  },
  {
    sid: '10347fecfccf42a49d7e51077c044fc2',
    id: '10347fecfccf42a49d7e51077c044fc2',
    position: {
      x: 0.05665307492017746,
      y: 1.5491043329238892,
      z: -7.323134899139404,
    },
    rotation: {
      x: -0.6218768419881181,
      y: 138.59671991199338,
      z: -0.05339048065747057,
    },
  },
  {
    sid: 'f9dafcf329a948f59d9296eea3ba840b',
    id: 'f9dafcf329a948f59d9296eea3ba840b',
    position: {
      x: 2.976832389831543,
      y: 1.5517834424972534,
      z: -7.219119071960449,
    },
    rotation: {
      x: 0.1705898430484125,
      y: 128.35795788128857,
      z: 0.36428623037174346,
    },
  },
  {
    sid: 'a6a0fa5d114d4a2fb11d19d11bb2b43a',
    id: 'a6a0fa5d114d4a2fb11d19d11bb2b43a',
    position: {
      x: 1.4724245071411133,
      y: 1.5570156574249268,
      z: -7.856450080871582,
    },
    rotation: {
      x: 0.23279618414874742,
      y: -46.34267508295246,
      z: -0.043847938947742304,
    },
  },
  {
    sid: '7e06b05a4acc4c3087cdf6d85a01a672',
    id: '7e06b05a4acc4c3087cdf6d85a01a672',
    position: {
      x: 1.5381982326507568,
      y: 1.5566681623458862,
      z: -8.62060260772705,
    },
    rotation: {
      x: -0.37566457734637765,
      y: -53.20440854192966,
      z: 0.21885665168222537,
    },
  },
  {
    sid: '763971b5708e42f199592b43760312f0',
    id: '763971b5708e42f199592b43760312f0',
    position: {
      x: 0.00868596974760294,
      y: 1.5550696849822998,
      z: -8.595062255859375,
    },
    rotation: {
      x: 0.11779152179987094,
      y: -151.98093649966037,
      z: -0.015425233452278203,
    },
  },
  {
    sid: '0ffcd2294e114b46a3a2a2a600594854',
    id: '0ffcd2294e114b46a3a2a2a600594854',
    position: {
      x: 3.0404560565948486,
      y: 1.5543147325515747,
      z: -8.500336647033691,
    },
    rotation: {
      x: 0.3968859716260576,
      y: 166.21664452469716,
      z: 0.8553605211663421,
    },
  },
  {
    sid: '83d00b42796048b48c5e8d17b9d90076',
    id: '83d00b42796048b48c5e8d17b9d90076',
    position: {
      x: 1.4289757013320923,
      y: 1.5485037565231323,
      z: -3.2018544673919678,
    },
    rotation: {
      x: 0.49819366234164675,
      y: -174.0888242361308,
      z: -0.06870377554195424,
    },
  },
  {
    sid: '15a372a5c71b44cead2cd363c04da9a6',
    id: '15a372a5c71b44cead2cd363c04da9a6',
    position: {
      x: 2.997545003890991,
      y: 1.5531456470489502,
      z: 0.9101870656013489,
    },
    rotation: {
      x: 0.06360157955099606,
      y: 66.55549827104416,
      z: 1.602776730669283,
    },
  },
  {
    sid: '7bb07b26d824498ca2eb1ab0de700557',
    id: '7bb07b26d824498ca2eb1ab0de700557',
    position: {
      x: -0.8842885494232178,
      y: 1.5394299030303955,
      z: -2.379196882247925,
    },
    rotation: {
      x: 0.5934415630447492,
      y: 107.79709613184788,
      z: 0.284775088383912,
    },
  },
  {
    sid: '0f6e6fcd84d54fe88fe68d93c1fdece3',
    id: '0f6e6fcd84d54fe88fe68d93c1fdece3',
    position: {
      x: -0.2704092264175415,
      y: 1.536087989807129,
      z: -2.1157240867614746,
    },
    rotation: {
      x: -0.21994372710939136,
      y: 136.73688978213,
      z: 0.23948043151051218,
    },
  },
  {
    sid: 'b007f319cff242d39bfbb5c62399bd47',
    id: 'b007f319cff242d39bfbb5c62399bd47',
    position: {
      x: -2.305072546005249,
      y: 1.536287784576416,
      z: -1.908913016319275,
    },
    rotation: {
      x: 0.6999466779714738,
      y: -126.78483619875033,
      z: -0.06811742997375549,
    },
  },
  {
    sid: '094336df363e4a11a40a036d7b990e4e',
    id: '094336df363e4a11a40a036d7b990e4e',
    position: {
      x: -2.5134880542755127,
      y: 1.5303713083267212,
      z: -0.262075811624527,
    },
    rotation: {
      x: 0.6235247105515718,
      y: -84.40047636948351,
      z: 0.45286744275645463,
    },
  },
  {
    sid: 'f371b56c5e574af0bbd7303e3fc3332f',
    id: 'f371b56c5e574af0bbd7303e3fc3332f',
    position: {
      x: -2.5685391426086426,
      y: 1.5268563032150269,
      z: 1.0649768114089966,
    },
    rotation: {
      x: 0.8315068411868756,
      y: -29.184765175050195,
      z: 0.6261035850558936,
    },
  },
  {
    sid: '61c98927ecbc4e0a987c5d1965c7b1b8',
    id: '61c98927ecbc4e0a987c5d1965c7b1b8',
    position: {
      x: -2.60497784614563,
      y: 1.5265921354293823,
      z: 2.228170156478882,
    },
    rotation: {
      x: 0.22439019193502457,
      y: -19.875540932885247,
      z: 1.0821637600075362,
    },
  },
  {
    sid: '87305abc69b84699822ec90cd124c02a',
    id: '87305abc69b84699822ec90cd124c02a',
    position: {
      x: -2.6959609985351562,
      y: 1.5270471572875977,
      z: 4.110782146453857,
    },
    rotation: {
      x: -0.07422353095126115,
      y: -44.27803467945607,
      z: 0.48093687886262415,
    },
  },
  {
    sid: '837f77fa1dca48228138ea5f3e18038e',
    id: '837f77fa1dca48228138ea5f3e18038e',
    position: {
      x: -2.557748556137085,
      y: 1.527701735496521,
      z: 3.334834575653076,
    },
    rotation: {
      x: 0.1624993365008482,
      y: -36.34842448180901,
      z: 0.6197523966109438,
    },
  },
  {
    sid: 'cc37a535c6e048b2a8acbafbea4d57e5',
    id: 'cc37a535c6e048b2a8acbafbea4d57e5',
    position: {
      x: -4.2694807052612305,
      y: 1.5383483171463013,
      z: 4.183131694793701,
    },
    rotation: {
      x: -0.047038785800215,
      y: -19.162091802183344,
      z: 0.36747502284949174,
    },
  },
  {
    sid: 'bb1f2cb3125b4e1098e8ddc7439cae2f',
    id: 'bb1f2cb3125b4e1098e8ddc7439cae2f',
    position: {
      x: -5.358227252960205,
      y: 1.5329582691192627,
      z: 4.031017780303955,
    },
    rotation: {
      x: 0.05286944359193937,
      y: -5.394819473510121,
      z: 0.14841387309169918,
    },
  },
  {
    sid: '78679555b22c410bb71d2e3aa3fcb060',
    id: '78679555b22c410bb71d2e3aa3fcb060',
    position: {
      x: -5.190554141998291,
      y: 1.5283896923065186,
      z: 2.1351253986358643,
    },
    rotation: {
      x: -0.30041746459854135,
      y: -67.271567215107,
      z: 0.7083305258606274,
    },
  },
  {
    sid: 'b7ea49afcc564618a4fd5b4b56e2fe43',
    id: 'b7ea49afcc564618a4fd5b4b56e2fe43',
    position: {
      x: -5.176259994506836,
      y: 1.530185580253601,
      z: 1.0418986082077026,
    },
    rotation: {
      x: -0.20758524242441506,
      y: -62.15538565691792,
      z: 0.5121415156026883,
    },
  },
  {
    sid: '58bca89dbbeb448ab2315aebe151f559',
    id: '58bca89dbbeb448ab2315aebe151f559',
    position: {
      x: -5.272850036621094,
      y: 1.5286188125610352,
      z: 0.21970272064208984,
    },
    rotation: {
      x: -0.1806041310050424,
      y: -77.2780231229424,
      z: 0.20642618236593577,
    },
  },
  {
    sid: 'f4d7b058a80c4d62adb6a6ba5f1a0287',
    id: 'f4d7b058a80c4d62adb6a6ba5f1a0287',
    position: {
      x: -4.9921722412109375,
      y: 1.5325714349746704,
      z: -0.3335261940956116,
    },
    rotation: {
      x: -0.25183621219900265,
      y: -69.51529632935608,
      z: 0.5267073006550022,
    },
  },
  {
    sid: '5412695d1a774b3a869bbe3fa37d97f0',
    id: '5412695d1a774b3a869bbe3fa37d97f0',
    position: {
      x: -5.214544296264648,
      y: 1.5332562923431396,
      z: -2.3104283809661865,
    },
    rotation: {
      x: 0.1796631299669583,
      y: -121.38265907376822,
      z: 1.2677204129759725,
    },
  },
  {
    sid: '91bebe6d3e634af39959741a1f042334',
    id: '91bebe6d3e634af39959741a1f042334',
    position: {
      x: -5.1608052253723145,
      y: 1.5328724384307861,
      z: -0.2937469184398651,
    },
    rotation: {
      x: -0.48595711379475265,
      y: -100.93243324850604,
      z: -0.04578519629839967,
    },
  },
  {
    sid: '71c5f9add0d44b2fb352ce4f7d279950',
    id: '71c5f9add0d44b2fb352ce4f7d279950',
    position: {
      x: -5.1730875968933105,
      y: 1.5349751710891724,
      z: 3.2212555408477783,
    },
    rotation: {
      x: -0.31552459072310624,
      y: -97.2248739871346,
      z: 0.026372591826066053,
    },
  },
  {
    sid: 'f3bc22a9e4a84f43bb0652bf88cf319f',
    id: 'f3bc22a9e4a84f43bb0652bf88cf319f',
    position: {
      x: -3.487917423248291,
      y: 1.5317803621292114,
      z: -2.117368698120117,
    },
    rotation: {
      x: -0.16686343120274802,
      y: -115.69253356357484,
      z: 0.32434444131125345,
    },
  },
  {
    sid: 'f32ed5633c6d4c99a532ecc880d2c73f',
    id: 'f32ed5633c6d4c99a532ecc880d2c73f',
    position: {
      x: -2.2641963958740234,
      y: 1.538809061050415,
      z: -3.4360740184783936,
    },
    rotation: {
      x: 0.3910690970479278,
      y: -133.33650675129942,
      z: 0.6284421502744637,
    },
  },
  {
    sid: '94d2fa09a1494686a2e32aa7b448e447',
    id: '94d2fa09a1494686a2e32aa7b448e447',
    position: {
      x: -3.8267946243286133,
      y: 1.5279598236083984,
      z: -3.337777853012085,
    },
    rotation: {
      x: 0.06494895339636456,
      y: -123.67094909619827,
      z: 0.47323635570712225,
    },
  },
  {
    sid: '9618a0bd020c4e7fa2eed6b54c4f2500',
    id: '9618a0bd020c4e7fa2eed6b54c4f2500',
    position: {
      x: -5.344790935516357,
      y: 1.5293208360671997,
      z: -3.4600884914398193,
    },
    rotation: {
      x: 0.27007415197490575,
      y: -133.67286691200013,
      z: 0.2923949061746782,
    },
  },
  {
    sid: 'ffb0419d184a4370ad81b087e34b7227',
    id: 'ffb0419d184a4370ad81b087e34b7227',
    position: {
      x: -4.484297752380371,
      y: 1.5336476564407349,
      z: -2.0599238872528076,
    },
    rotation: {
      x: 0.08675427710251041,
      y: -41.254543978460845,
      z: -0.12874796625978072,
    },
  },
  {
    sid: '2b8960001db14cbd850991336de9b592',
    id: '2b8960001db14cbd850991336de9b592',
    position: {
      x: -5.463080883026123,
      y: 1.5287545919418335,
      z: -2.6094353199005127,
    },
    rotation: {
      x: -0.04440530021257121,
      y: -8.730605899397355,
      z: 0.42741561445736725,
    },
  },
  {
    sid: '67d30888119a401c8034c4e61ee3a42b',
    id: '67d30888119a401c8034c4e61ee3a42b',
    position: {
      x: -7.353616237640381,
      y: 1.5391767024993896,
      z: -2.351147174835205,
    },
    rotation: {
      x: 0.040708637681338374,
      y: -57.98628737880346,
      z: -0.046079923070787614,
    },
  },
  {
    sid: '117532231e8647098de9acbbb92dda63',
    id: '117532231e8647098de9acbbb92dda63',
    position: {
      x: -2.3529326915740967,
      y: 1.5310877561569214,
      z: -4.562646865844727,
    },
    rotation: {
      x: 0.5704444505267898,
      y: -145.58998104796217,
      z: 0.2685106056222501,
    },
  },
  {
    sid: 'e74588b5f26044169163084f7abf0f7b',
    id: 'e74588b5f26044169163084f7abf0f7b',
    position: {
      x: -2.453171730041504,
      y: 1.5286167860031128,
      z: -5.922457218170166,
    },
    rotation: {
      x: 0.2542081424057888,
      y: -155.3010513019855,
      z: 0.2589788503214629,
    },
  },
  {
    sid: '0d57dd343c6343a18cd03ae0c6438512',
    id: '0d57dd343c6343a18cd03ae0c6438512',
    position: {
      x: -2.1708221435546875,
      y: 1.5388221740722656,
      z: -7.782495975494385,
    },
    rotation: {
      x: 0.7650605344635647,
      y: -137.1576902287651,
      z: 0.5043929198905681,
    },
  },
  {
    sid: '53946d43bc0a4c78ac8c10484d9c865d',
    id: '53946d43bc0a4c78ac8c10484d9c865d',
    position: {
      x: -2.3428893089294434,
      y: 1.5435521602630615,
      z: -9.156474113464355,
    },
    rotation: {
      x: 0.4919761564127911,
      y: -129.51073244052552,
      z: -0.4084083002194602,
    },
  },
  {
    sid: '4caf270329b84c99a6b42b30abcbc853',
    id: '4caf270329b84c99a6b42b30abcbc853',
    position: {
      x: -4.463242530822754,
      y: 1.5403921604156494,
      z: -9.48620319366455,
    },
    rotation: {
      x: 0.1236761194185056,
      y: -135.4206382140051,
      z: 0.36020842371383416,
    },
  },
  {
    sid: '4dd2ca72d6044ae7bdc387bf80f752b5',
    id: '4dd2ca72d6044ae7bdc387bf80f752b5',
    position: {
      x: -4.438591957092285,
      y: 1.5407309532165527,
      z: -7.602064609527588,
    },
    rotation: {
      x: -0.3032747929744831,
      y: -103.29283592199913,
      z: 0.02583072395516142,
    },
  },
  {
    sid: 'd0d331d78928432e99b7cd386e4c8f25',
    id: 'd0d331d78928432e99b7cd386e4c8f25',
    position: {
      x: -4.447798728942871,
      y: 1.5347309112548828,
      z: -5.9587016105651855,
    },
    rotation: {
      x: 0.06283512678188358,
      y: -152.4223885694179,
      z: 0.7036802139576073,
    },
  },
  {
    sid: 'f0e43ce18b5f4b9ab0ecaaba1d2b9cc1',
    id: 'f0e43ce18b5f4b9ab0ecaaba1d2b9cc1',
    position: {
      x: -3.4011664390563965,
      y: 1.5326735973358154,
      z: -5.624192714691162,
    },
    rotation: {
      x: -0.3598189125430575,
      y: -161.33991157457203,
      z: 0.7995635296864885,
    },
  },
  {
    sid: '91fe5911f7f648419881d0f9c6579380',
    id: '91fe5911f7f648419881d0f9c6579380',
    position: {
      x: -5.9928812980651855,
      y: 1.54345703125,
      z: -5.757989406585693,
    },
    rotation: {
      x: 0.32732183265365944,
      y: -126.85405081688714,
      z: 0.18023103727247425,
    },
  },
  {
    sid: '555b0330bf1540e4959aa62261404cbf',
    id: '555b0330bf1540e4959aa62261404cbf',
    position: {
      x: -3.345674753189087,
      y: 1.5301815271377563,
      z: -5.844696044921875,
    },
    rotation: {
      x: 0.03279707454457382,
      y: -101.29399527969274,
      z: 0.40892734813179343,
    },
  },
  {
    sid: '0418d4ec011446f6adabd9ad1f2fa375',
    id: '0418d4ec011446f6adabd9ad1f2fa375',
    position: {
      x: -6.161561489105225,
      y: 1.5437806844711304,
      z: -6.645995140075684,
    },
    rotation: {
      x: 0.1204166588347927,
      y: -79.92754956715193,
      z: 0.5845642216069952,
    },
  },
  {
    sid: '316a2944b28b4deaa286aafb17aa7fc6',
    id: '316a2944b28b4deaa286aafb17aa7fc6',
    position: {
      x: -5.734457969665527,
      y: 1.5468120574951172,
      z: -7.934713840484619,
    },
    rotation: {
      x: -0.274185521290553,
      y: -82.3265303697083,
      z: 0.6615408895667381,
    },
  },
  {
    sid: '6e97716af4a8476d9ac88e934b3ef30a',
    id: '6e97716af4a8476d9ac88e934b3ef30a',
    position: {
      x: -5.076043128967285,
      y: 1.5416061878204346,
      z: -8.923315048217773,
    },
    rotation: {
      x: -0.313474314507124,
      y: -92.77485357189776,
      z: -0.7989330156853239,
    },
  },
  {
    sid: '1a2872d5e98141cca36a1a994c95eeca',
    id: '1a2872d5e98141cca36a1a994c95eeca',
    position: {
      x: -3.2307753562927246,
      y: 1.536991834640503,
      z: -9.355100631713867,
    },
    rotation: {
      x: -0.21010990928102363,
      y: -108.34344092394814,
      z: -0.7101655441574265,
    },
  },
  {
    sid: 'f2409390498244e4bcc5990bae8bc86a',
    id: 'f2409390498244e4bcc5990bae8bc86a',
    position: {
      x: -6.011570453643799,
      y: 1.5442911386489868,
      z: -10.171989440917969,
    },
    rotation: {
      x: 0.09313148065675393,
      y: -84.60368288942882,
      z: -0.22555525453198808,
    },
  },
  {
    sid: '8d52b19384a9442790ffa1e097f986a5',
    id: '8d52b19384a9442790ffa1e097f986a5',
    position: {
      x: -7.489105701446533,
      y: 1.5447553396224976,
      z: -10.532431602478027,
    },
    rotation: {
      x: 0.5152405264582907,
      y: -82.96032631512416,
      z: -0.3317608728361795,
    },
  },
  {
    sid: '44a53e9738da40078d9aa8b1c4afeb7d',
    id: '44a53e9738da40078d9aa8b1c4afeb7d',
    position: {
      x: -8.849596977233887,
      y: 1.544915795326233,
      z: -10.67375373840332,
    },
    rotation: {
      x: 0.3773759413583902,
      y: -137.89255305265002,
      z: -0.46333295677518016,
    },
  },
  {
    sid: 'f972d3a33ddd4cd6ae334cb9a212994b',
    id: 'f972d3a33ddd4cd6ae334cb9a212994b',
    position: {
      x: -10.39106559753418,
      y: 1.5467479228973389,
      z: -11.211566925048828,
    },
    rotation: {
      x: 0.3983922817956365,
      y: -128.99914485454065,
      z: -0.7876393666049963,
    },
  },
  {
    sid: '07553ab90a22499095eb42c4aec66617',
    id: '07553ab90a22499095eb42c4aec66617',
    position: {
      x: -11.844074249267578,
      y: 1.542628526687622,
      z: -11.672547340393066,
    },
    rotation: {
      x: 0.016219101780025218,
      y: -103.22982605730677,
      z: -0.7954418163440371,
    },
  },
  {
    sid: 'b243785a86af43e3957ee488547bd90a',
    id: 'b243785a86af43e3957ee488547bd90a',
    position: {
      x: -13.232758522033691,
      y: 1.5434490442276,
      z: -11.096863746643066,
    },
    rotation: {
      x: -0.2880392292493133,
      y: -101.97419272644366,
      z: -0.7740217059321645,
    },
  },
  {
    sid: 'e5ad5bfd892a4a608c7bbf6cb0e5b6fb',
    id: 'e5ad5bfd892a4a608c7bbf6cb0e5b6fb',
    position: {
      x: -13.108064651489258,
      y: 1.5416465997695923,
      z: -9.364846229553223,
    },
    rotation: {
      x: -0.16714765130680512,
      y: 144.67102901457525,
      z: 0.05180856765961477,
    },
  },
  {
    sid: '64003a43d24c4395b76614445f9b0436',
    id: '64003a43d24c4395b76614445f9b0436',
    position: {
      x: -12.942625999450684,
      y: 1.5500558614730835,
      z: -8.044777870178223,
    },
    rotation: {
      x: -0.295715736664905,
      y: 130.21802461212505,
      z: 0.42485011737506095,
    },
  },
  {
    sid: 'f52bfbf20b974795905a623646093f3d',
    id: 'f52bfbf20b974795905a623646093f3d',
    position: {
      x: -11.58818244934082,
      y: 1.547073245048523,
      z: -9.139126777648926,
    },
    rotation: {
      x: -0.5418058077899004,
      y: 135.16339594350808,
      z: -0.30549168412010635,
    },
  },
  {
    sid: '0e778af92b6748df94be2fec994ffc89',
    id: '0e778af92b6748df94be2fec994ffc89',
    position: {
      x: -10.294539451599121,
      y: 1.5530434846878052,
      z: -8.788166046142578,
    },
    rotation: {
      x: -0.7004623907128538,
      y: -139.8255668435896,
      z: 0.13072255418623835,
    },
  },
  {
    sid: 'c91c42caabc44128830c9ba59fcc5504',
    id: 'c91c42caabc44128830c9ba59fcc5504',
    position: {
      x: -9.33590316772461,
      y: 1.5462391376495361,
      z: -8.511056900024414,
    },
    rotation: {
      x: -0.16555660255860571,
      y: 120.79793920185229,
      z: 0.17601321491069913,
    },
  },
  {
    sid: 'c7b5901305f04f1597fdae1de625cd7d',
    id: 'c7b5901305f04f1597fdae1de625cd7d',
    position: {
      x: -8.061440467834473,
      y: 1.545913815498352,
      z: -8.145795822143555,
    },
    rotation: {
      x: -0.589940002959817,
      y: 113.8203356903385,
      z: 0.07962584120061052,
    },
  },
  {
    sid: '94d8a126512240cb94b93f81d917241c',
    id: '94d8a126512240cb94b93f81d917241c',
    position: {
      x: -6.867426872253418,
      y: 1.5461180210113525,
      z: -8.038288116455078,
    },
    rotation: {
      x: -0.019884861531673577,
      y: 77.83036108729236,
      z: -0.2904945468365979,
    },
  },
  {
    sid: '034b050536a04cb9b3c1092d8c3e3974',
    id: '034b050536a04cb9b3c1092d8c3e3974',
    position: {
      x: -7.532681941986084,
      y: 1.545041561126709,
      z: -6.749185562133789,
    },
    rotation: {
      x: -0.20862330822541278,
      y: -121.07394596894468,
      z: -0.6425119358113265,
    },
  },
  {
    sid: '26f74575dd394fa7a5aef63d0529852e',
    id: '26f74575dd394fa7a5aef63d0529852e',
    position: {
      x: -8.615612983703613,
      y: 1.5474745035171509,
      z: -6.806938171386719,
    },
    rotation: {
      x: 0.4625278339706464,
      y: -119.07492912679469,
      z: 0.06562082698079191,
    },
  },
  {
    sid: '5dbb3f5126c64a99b55a07f1f22a4b35',
    id: '5dbb3f5126c64a99b55a07f1f22a4b35',
    position: {
      x: -9.785848617553711,
      y: 1.5496042966842651,
      z: -7.129477024078369,
    },
    rotation: {
      x: 0.1594852845434814,
      y: -137.47897341432764,
      z: -0.4149678150009725,
    },
  },
  {
    sid: '68780672ba284a22a0d087b9dd18b899',
    id: '68780672ba284a22a0d087b9dd18b899',
    position: {
      x: -10.593220710754395,
      y: 1.5507640838623047,
      z: -5.9688401222229,
    },
    rotation: {
      x: 0.09592243537658696,
      y: -102.6124827294534,
      z: -0.2480252444479919,
    },
  },
  {
    sid: 'b691c1b6825849968d5e8ed8ed73085a',
    id: 'b691c1b6825849968d5e8ed8ed73085a',
    position: {
      x: -11.852807998657227,
      y: 1.5503242015838623,
      z: -6.628452777862549,
    },
    rotation: {
      x: 1.0208106678428792,
      y: -96.27247650214156,
      z: -0.42203421342570974,
    },
  },
  {
    sid: 'd81b774e45d64dd9abd1c20dbeb75e74',
    id: 'd81b774e45d64dd9abd1c20dbeb75e74',
    position: {
      x: -10.699654579162598,
      y: 1.5449116230010986,
      z: -4.269476890563965,
    },
    rotation: {
      x: 0.39273654831615973,
      y: -57.695264647370415,
      z: -0.6874132616499814,
    },
  },
  {
    sid: 'f1fc19a7f76b4a12a54aee31d7b75e34',
    id: 'f1fc19a7f76b4a12a54aee31d7b75e34',
    position: {
      x: -8.360800743103027,
      y: 1.539313793182373,
      z: -2.67684006690979,
    },
    rotation: {
      x: 0.3649713068237968,
      y: -100.861189086102,
      z: -0.2612364553590462,
    },
  },
  {
    sid: '325e566301bc44e58e385ae1ed18b071',
    id: '325e566301bc44e58e385ae1ed18b071',
    position: {
      x: -6.703165531158447,
      y: 1.5344136953353882,
      z: -2.7245655059814453,
    },
    rotation: {
      x: 0.06254531657379073,
      y: -91.77650269226856,
      z: -0.11387505810446223,
    },
  },
  {
    sid: '626cb1594f1440809ce174408447044c',
    id: '626cb1594f1440809ce174408447044c',
    position: {
      x: -10.69137954711914,
      y: 1.5418390035629272,
      z: -2.6597721576690674,
    },
    rotation: {
      x: 0.6290486693714409,
      y: -92.51992193965785,
      z: -0.45293953256932074,
    },
  },
  {
    sid: '2851be834d264abb94a1e0cbc1e57ac6',
    id: '2851be834d264abb94a1e0cbc1e57ac6',
    position: {
      x: -6.640894412994385,
      y: 1.5381673574447632,
      z: -4.728163719177246,
    },
    rotation: {
      x: -0.062178041502169906,
      y: -107.24299301361724,
      z: -0.7736059028789227,
    },
  },
  {
    sid: '09ca8d3134434ff2ae1624fbd5f8a202',
    id: '09ca8d3134434ff2ae1624fbd5f8a202',
    position: {
      x: -9.350948333740234,
      y: 1.5393153429031372,
      z: -2.713752031326294,
    },
    rotation: {
      x: 0.4176827035542599,
      y: -26.379572039915242,
      z: -0.4343418447178241,
    },
  },
  {
    sid: 'c26b608b79ef4d29949e515de924b3cb',
    id: 'c26b608b79ef4d29949e515de924b3cb',
    position: {
      x: -7.4987263679504395,
      y: 1.5361541509628296,
      z: -0.6736528873443604,
    },
    rotation: {
      x: 0.5496991071887707,
      y: -19.79754238865334,
      z: -0.6331851560088569,
    },
  },
  {
    sid: 'c0ac29887fc04e0e89c21546ed04ee16',
    id: 'c0ac29887fc04e0e89c21546ed04ee16',
    position: {
      x: -7.479970455169678,
      y: 1.5394718647003174,
      z: 0.10420398414134979,
    },
    rotation: {
      x: 0.8635697610821553,
      y: -12.743851376833959,
      z: -0.3593725401462964,
    },
  },
  {
    sid: '90033e1ec88644868965d9aed2689a7c',
    id: '90033e1ec88644868965d9aed2689a7c',
    position: {
      x: -6.414412975311279,
      y: 1.53696608543396,
      z: 0.215997576713562,
    },
    rotation: {
      x: 0.6546878132287526,
      y: 7.545300732390234,
      z: -1.0240123440522633,
    },
  },
  {
    sid: '773c3d158e944afe8cbf7a717fd8dca2',
    id: '773c3d158e944afe8cbf7a717fd8dca2',
    position: {
      x: -7.869256496429443,
      y: 1.5364652872085571,
      z: 1.5106443166732788,
    },
    rotation: {
      x: 0.4489578747709605,
      y: -24.11325824497018,
      z: -0.11144536383442236,
    },
  },
  {
    sid: '4e5e498ad5304927a63dc2606ca3b4c3',
    id: '4e5e498ad5304927a63dc2606ca3b4c3',
    position: {
      x: -7.978059768676758,
      y: 1.5391045808792114,
      z: 2.6734459400177,
    },
    rotation: {
      x: 0.003908244618852962,
      y: -38.1942813200192,
      z: -0.5444388235023081,
    },
  },
  {
    sid: '955d0590bbd54319bf2af9d622dc227d',
    id: '955d0590bbd54319bf2af9d622dc227d',
    position: {
      x: -7.859622001647949,
      y: 1.5410029888153076,
      z: 3.7932519912719727,
    },
    rotation: {
      x: 0.09386792693031036,
      y: -59.61503257563392,
      z: -0.3295499813993322,
    },
  },
  {
    sid: 'bd493fbba65b4983b353d4801f353cc9',
    id: 'bd493fbba65b4983b353d4801f353cc9',
    position: {
      x: -6.497447967529297,
      y: 1.5405482053756714,
      z: 3.907043218612671,
    },
    rotation: {
      x: -0.4778434928119634,
      y: -32.36020394682523,
      z: -0.22626587671069315,
    },
  },
  {
    sid: '7205b06d4ef144d39ecde355f473c956',
    id: '7205b06d4ef144d39ecde355f473c956',
    position: {
      x: -9.043353080749512,
      y: 1.5437335968017578,
      z: 3.8536901473999023,
    },
    rotation: {
      x: 0.5424406844907173,
      y: -66.04809474850586,
      z: -0.4526783238771437,
    },
  },
  {
    sid: 'd8b02c8bc6304d0691585d9eaec28a7e',
    id: 'd8b02c8bc6304d0691585d9eaec28a7e',
    position: {
      x: -10.587014198303223,
      y: 1.5421674251556396,
      z: 3.6467463970184326,
    },
    rotation: {
      x: 0.8268512398769454,
      y: -62.566980959984406,
      z: -0.7632112978453038,
    },
  },
  {
    sid: '1d31d13b184b40eeb8ebfaa22e4fe267',
    id: '1d31d13b184b40eeb8ebfaa22e4fe267',
    position: {
      x: -10.300801277160645,
      y: 1.5498493909835815,
      z: 1.368864893913269,
    },
    rotation: {
      x: 0.3257989973476484,
      y: -89.55762052678914,
      z: 0.346359893065527,
    },
  },
  {
    sid: '04002dff94c54e90b32f2cb8f55da246',
    id: '04002dff94c54e90b32f2cb8f55da246',
    position: {
      x: -10.153032302856445,
      y: 1.5429667234420776,
      z: -0.2789548337459564,
    },
    rotation: {
      x: 0.49155813969697565,
      y: -92.79199615289568,
      z: -0.33112842418551897,
    },
  },
  {
    sid: 'a8c917ab476f4fb791a165de917c96ac',
    id: 'a8c917ab476f4fb791a165de917c96ac',
    position: {
      x: -10.411672592163086,
      y: 1.5496114492416382,
      z: 2.6087586879730225,
    },
    rotation: {
      x: 1.0428315300528987,
      y: 14.473441564056252,
      z: 0.48956139215753314,
    },
  },
  {
    sid: '631e5ce7bfbb48bda49250861f16ee1c',
    id: '631e5ce7bfbb48bda49250861f16ee1c',
    position: {
      x: -9.147818565368652,
      y: 1.5435391664505005,
      z: -0.3608483672142029,
    },
    rotation: {
      x: -0.3567742295052135,
      y: -59.48536371357084,
      z: 0.11361213364227364,
    },
  },
  {
    sid: 'bd8916b371bb44baadd200f2c235e387',
    id: 'bd8916b371bb44baadd200f2c235e387',
    position: {
      x: -11.981454849243164,
      y: 1.5514421463012695,
      z: -0.4951113760471344,
    },
    rotation: {
      x: 0.08280419345012116,
      y: -1.2043933815319667,
      z: 0.11427242871519633,
    },
  },
  {
    sid: 'a8c322e5da044c1cb31f5866c1ee0b77',
    id: 'a8c322e5da044c1cb31f5866c1ee0b77',
    position: {
      x: -11.923934936523438,
      y: 1.5507279634475708,
      z: -1.5207889080047607,
    },
    rotation: {
      x: 0.07816611059899506,
      y: 44.237399996751385,
      z: 0.348078469395147,
    },
  },
  {
    sid: '208a022c9c144cd19cfdf01c7d728095',
    id: '208a022c9c144cd19cfdf01c7d728095',
    position: {
      x: -12.175483703613281,
      y: 1.5522068738937378,
      z: 1.6924445629119873,
    },
    rotation: {
      x: 0.16052132298530628,
      y: 41.27488239143986,
      z: 1.0467952783600203,
    },
  },
  {
    sid: '89563c941ba340379bbf8fb209a439f9',
    id: '89563c941ba340379bbf8fb209a439f9',
    position: {
      x: -12.24492359161377,
      y: 1.5452728271484375,
      z: 3.6064274311065674,
    },
    rotation: {
      x: 0.004311844156149052,
      y: -31.33388433378767,
      z: 0.27860089045131214,
    },
  },
  {
    sid: 'fcdacea7f605452b8789bd79d6b14141',
    id: 'fcdacea7f605452b8789bd79d6b14141',
    position: {
      x: -13.216094970703125,
      y: 1.5487184524536133,
      z: 3.4472246170043945,
    },
    rotation: {
      x: -0.22155345336685972,
      y: 118.76555093188497,
      z: 0.5089366417731369,
    },
  },
  {
    sid: 'd473c17c68214a099b86b81a23b8508d',
    id: 'd473c17c68214a099b86b81a23b8508d',
    position: {
      x: -14.74984073638916,
      y: 1.546764850616455,
      z: 3.328350305557251,
    },
    rotation: {
      x: 0.10844789871770288,
      y: 158.17068925832947,
      z: -0.38146044629842507,
    },
  },
  {
    sid: '5f545916ff8b4b03a99c46f28dc7ca47',
    id: '5f545916ff8b4b03a99c46f28dc7ca47',
    position: {
      x: -14.768948554992676,
      y: 1.5441956520080566,
      z: 2.130453109741211,
    },
    rotation: {
      x: -0.1962361048607626,
      y: 165.6036559363348,
      z: 0.34077223546758295,
    },
  },
  {
    sid: 'adb677d49ffd429d9b59e2ab403fbd6e',
    id: 'adb677d49ffd429d9b59e2ab403fbd6e',
    position: {
      x: -14.520391464233398,
      y: 1.5498027801513672,
      z: -0.4303782880306244,
    },
    rotation: {
      x: 0.321835728048085,
      y: -176.8105652790781,
      z: 0.40457151070158476,
    },
  },
  {
    sid: 'cc87591a0b794671a8a8688ccc058f4b',
    id: 'cc87591a0b794671a8a8688ccc058f4b',
    position: {
      x: -14.622237205505371,
      y: 1.5451124906539917,
      z: -2.2197155952453613,
    },
    rotation: {
      x: -0.10536258928461241,
      y: -171.94945700989348,
      z: -0.1191109740874028,
    },
  },
  {
    sid: 'c04128cda64f420f916ded75e4e222a8',
    id: 'c04128cda64f420f916ded75e4e222a8',
    position: {
      x: -12.595539093017578,
      y: 1.5466822385787964,
      z: -2.4986560344696045,
    },
    rotation: {
      x: -0.49872117864572135,
      y: 33.862962431963474,
      z: 0.6660761563159668,
    },
  },
  {
    sid: '7072d6edc9724fc98bf7543c4e22b8de',
    id: '7072d6edc9724fc98bf7543c4e22b8de',
    position: {
      x: -14.289953231811523,
      y: 1.5562067031860352,
      z: -5.710796356201172,
    },
    rotation: {
      x: 0.2393266627543933,
      y: 56.22483005789339,
      z: 0.08680017303511066,
    },
  },
  {
    sid: '4d87ab5c51254e76b3716c6297419141',
    id: '4d87ab5c51254e76b3716c6297419141',
    position: {
      x: -14.213406562805176,
      y: 1.5510978698730469,
      z: -8.680994033813477,
    },
    rotation: {
      x: 0.17651782285098563,
      y: 62.986817198753066,
      z: -0.1643093582912801,
    },
  },
  {
    sid: '06120fe8b1c34aa7ab716267a63559ef',
    id: '06120fe8b1c34aa7ab716267a63559ef',
    position: {
      x: -14.653786659240723,
      y: 1.5443720817565918,
      z: -3.6174066066741943,
    },
    rotation: {
      x: 0.016728033146866602,
      y: 95.54092833683482,
      z: 0.8181712022416047,
    },
  },
  {
    sid: '07879c81af0a4a53908efe4057aa45b4',
    id: '07879c81af0a4a53908efe4057aa45b4',
    position: {
      x: -5.447481632232666,
      y: 1.5365560054779053,
      z: -4.424246788024902,
    },
    rotation: {
      x: 0.5450653472687619,
      y: -2.271408112588646,
      z: 0.5295866033853711,
    },
  },
  {
    sid: '68507067e7514bb39576b4b1767ea623',
    id: '68507067e7514bb39576b4b1767ea623',
    position: {
      x: -14.597586631774902,
      y: 1.5599346160888672,
      z: -11.41598892211914,
    },
    rotation: {
      x: 0.7087179529315745,
      y: -118.23310144488647,
      z: 0.5553833372051982,
    },
  },
  {
    sid: '3e15c9b2e69747cba707a229c93ed470',
    id: '3e15c9b2e69747cba707a229c93ed470',
    position: {
      x: -16.480552673339844,
      y: 1.5577365159988403,
      z: -11.462725639343262,
    },
    rotation: {
      x: -0.4306567194581991,
      y: 109.96807314283535,
      z: -0.7763641238061629,
    },
  },
  {
    sid: 'd5a671cfae2243c9bc4573b1f3b4b272',
    id: 'd5a671cfae2243c9bc4573b1f3b4b272',
    position: {
      x: -18.533855438232422,
      y: 1.5526437759399414,
      z: -11.461605072021484,
    },
    rotation: {
      x: -0.6015132942059215,
      y: 68.83292460430921,
      z: 0.03401802121002374,
    },
  },
  {
    sid: 'd37ed4878bc8458d995b1f46d082cd02',
    id: 'd37ed4878bc8458d995b1f46d082cd02',
    position: {
      x: -21.055879592895508,
      y: 1.5571430921554565,
      z: -11.677780151367188,
    },
    rotation: {
      x: -0.6282067973287444,
      y: 16.815871565597604,
      z: -0.833968258151582,
    },
  },
  {
    sid: 'ec43d4531f044c9c999cbaf0bbff9eda',
    id: 'ec43d4531f044c9c999cbaf0bbff9eda',
    position: {
      x: -22.30362892150879,
      y: 1.5604376792907715,
      z: -12.023714065551758,
    },
    rotation: {
      x: 0.3576227908487301,
      y: 65.91136606858704,
      z: -0.4111101335856597,
    },
  },
  {
    sid: '3d9f7b633a4a4238ad82bf64d55e38e2',
    id: '3d9f7b633a4a4238ad82bf64d55e38e2',
    position: {
      x: -23.573535919189453,
      y: 1.5630004405975342,
      z: -12.010917663574219,
    },
    rotation: {
      x: 0.304004841749225,
      y: -175.50741889091194,
      z: -0.629565551873521,
    },
  },
  {
    sid: 'c90583d35f52411d95d42005c91d757a',
    id: 'c90583d35f52411d95d42005c91d757a',
    position: {
      x: -23.473777770996094,
      y: 1.5630279779434204,
      z: -10.832201957702637,
    },
    rotation: {
      x: 0.6926336770180443,
      y: 174.79600078682023,
      z: -0.22506825631483168,
    },
  },
  {
    sid: '2820cff1691e4b489f2e1b110fe3c143',
    id: '2820cff1691e4b489f2e1b110fe3c143',
    position: {
      x: -21.067174911499023,
      y: 1.5673091411590576,
      z: -8.783352851867676,
    },
    rotation: {
      x: 0.5306602739478256,
      y: 38.23827623388939,
      z: -0.8906713245695038,
    },
  },
  {
    sid: '36722351e3b1400fb4796c3c55ca2d52',
    id: '36722351e3b1400fb4796c3c55ca2d52',
    position: {
      x: -23.26169204711914,
      y: 1.5776400566101074,
      z: -8.994402885437012,
    },
    rotation: {
      x: -0.40944910134267215,
      y: 29.09785412236936,
      z: -0.9007030256034833,
    },
  },
  {
    sid: 'aab3cefb0b02426c95b3fb9abcc43311',
    id: 'aab3cefb0b02426c95b3fb9abcc43311',
    position: {
      x: -18.419578552246094,
      y: 1.54843270778656,
      z: -8.791296005249023,
    },
    rotation: {
      x: -0.043727907527248804,
      y: 115.73000688691576,
      z: -0.12909939422840044,
    },
  },
  {
    sid: 'afe51706b30d4f0fa9e6c5221791dabe',
    id: 'afe51706b30d4f0fa9e6c5221791dabe',
    position: {
      x: -15.913783073425293,
      y: 1.5592905282974243,
      z: -8.504785537719727,
    },
    rotation: {
      x: 0.04317243089130827,
      y: 141.69468415679015,
      z: -0.026315173276587316,
    },
  },
  {
    sid: 'd7f2b0fd670a43869d371c71efe18492',
    id: 'd7f2b0fd670a43869d371c71efe18492',
    position: {
      x: -16.116540908813477,
      y: 1.5553784370422363,
      z: -6.612441062927246,
    },
    rotation: {
      x: -0.15835642004695638,
      y: 171.13109952337194,
      z: -0.024395052167389596,
    },
  },
  {
    sid: '4507c7af1f1643d5b11c3fc01df47159',
    id: '4507c7af1f1643d5b11c3fc01df47159',
    position: {
      x: -18.30377960205078,
      y: 1.546724796295166,
      z: -6.8970255851745605,
    },
    rotation: {
      x: -0.006633819660731426,
      y: -179.91791191533204,
      z: -0.6150338541646642,
    },
  },
  {
    sid: 'bc7a1e65d41440edbfab152a301df0c8',
    id: 'bc7a1e65d41440edbfab152a301df0c8',
    position: {
      x: -20.661718368530273,
      y: 1.5567220449447632,
      z: -6.8561577796936035,
    },
    rotation: {
      x: -0.4482935474862652,
      y: -162.49061332182458,
      z: 0.22790551901031444,
    },
  },
  {
    sid: '514384fc6a6c40a890f6f60c0a9764d8',
    id: '514384fc6a6c40a890f6f60c0a9764d8',
    position: {
      x: -20.77947998046875,
      y: 1.5566602945327759,
      z: -5.44994592666626,
    },
    rotation: {
      x: -0.3338535879568649,
      y: -157.93828784128112,
      z: 0.2611436936487429,
    },
  },
  {
    sid: '92e0484934c44780bf5003a04a7349b8',
    id: '92e0484934c44780bf5003a04a7349b8',
    position: {
      x: -21.075742721557617,
      y: 1.557976245880127,
      z: -4.319469928741455,
    },
    rotation: {
      x: -0.485510174675663,
      y: -86.23264755600626,
      z: -0.48956696812180217,
    },
  },
  {
    sid: '3787117ce55c44748f8a11b6ff08f512',
    id: '3787117ce55c44748f8a11b6ff08f512',
    position: {
      x: -22.714981079101562,
      y: 1.5824964046478271,
      z: -4.320718765258789,
    },
    rotation: {
      x: 0.043389178971542666,
      y: -174.2817041033611,
      z: -1.1255869003479524,
    },
  },
  {
    sid: '65a4876356ff43eeb2c0218c8dd3e7e3',
    id: '65a4876356ff43eeb2c0218c8dd3e7e3',
    position: {
      x: -19.612943649291992,
      y: 1.545236587524414,
      z: -5.3718132972717285,
    },
    rotation: {
      x: -0.14320274131165253,
      y: 142.4839213204218,
      z: 0.6575983344518683,
    },
  },
  {
    sid: 'cad154a9f6c84474bb23b73b91ec7d10',
    id: 'cad154a9f6c84474bb23b73b91ec7d10',
    position: {
      x: -19.5039005279541,
      y: 1.5473928451538086,
      z: -3.824080228805542,
    },
    rotation: {
      x: -0.6338186839088715,
      y: -161.15901017124855,
      z: 0.5720483572250908,
    },
  },
  {
    sid: 'ebe0bac4970b4d06be0ea031f6d64c9b',
    id: 'ebe0bac4970b4d06be0ea031f6d64c9b',
    position: {
      x: -19.576011657714844,
      y: 1.548142910003662,
      z: -2.1940362453460693,
    },
    rotation: {
      x: -1.0648976687716343,
      y: -138.67752989565588,
      z: 0.6047227302148181,
    },
  },
  {
    sid: 'ccac7cedb18846cab3e51bd05496c374',
    id: 'ccac7cedb18846cab3e51bd05496c374',
    position: {
      x: -20.974157333374023,
      y: 1.5577375888824463,
      z: -2.0830676555633545,
    },
    rotation: {
      x: -0.8108589274825828,
      y: -103.45075567321103,
      z: 0.5102560969687298,
    },
  },
  {
    sid: 'a41c1fe0807747f49829242373d8622b',
    id: 'a41c1fe0807747f49829242373d8622b',
    position: {
      x: -18.31043815612793,
      y: 1.538802146911621,
      z: -2.171962261199951,
    },
    rotation: {
      x: -0.36054725248170766,
      y: -96.07245585205226,
      z: 0.3560242972041333,
    },
  },
  {
    sid: 'fb423bae1dc04808a5ab270916582275',
    id: 'fb423bae1dc04808a5ab270916582275',
    position: {
      x: -18.101675033569336,
      y: 1.5471516847610474,
      z: -3.851329803466797,
    },
    rotation: {
      x: 0.2919636044583603,
      y: -152.56931949792312,
      z: 0.5928808791591047,
    },
  },
  {
    sid: '9603b084fbe84f4fa73893441f24052c',
    id: '9603b084fbe84f4fa73893441f24052c',
    position: {
      x: -18.222640991210938,
      y: 1.5515216588974,
      z: -5.353902816772461,
    },
    rotation: {
      x: -0.6704742537342965,
      y: -179.570244310529,
      z: -0.22901931331519415,
    },
  },
  {
    sid: '3cf557b78cf849cbbbcf78e735e7b555',
    id: '3cf557b78cf849cbbbcf78e735e7b555',
    position: {
      x: -16.766111373901367,
      y: 1.546874761581421,
      z: -5.1175432205200195,
    },
    rotation: {
      x: -0.42048952703417003,
      y: -111.79865524943104,
      z: 0.12092412321470895,
    },
  },
  {
    sid: '719afbfcc7344a328051b23f509289c4',
    id: '719afbfcc7344a328051b23f509289c4',
    position: {
      x: -16.82554817199707,
      y: 1.545790195465088,
      z: -3.5845725536346436,
    },
    rotation: {
      x: -0.5371302698151362,
      y: -97.89719968735433,
      z: 0.1985297815200939,
    },
  },
  {
    sid: '4c070e8b29664249af568374bf403152',
    id: '4c070e8b29664249af568374bf403152',
    position: {
      x: -16.817668914794922,
      y: 1.5374560356140137,
      z: -2.138328790664673,
    },
    rotation: {
      x: -0.06338908506599089,
      y: -96.90134466776122,
      z: 0.22732064297502796,
    },
  },
  {
    sid: '585a8f80c64b4c83b7218b324ed5a4fa',
    id: '585a8f80c64b4c83b7218b324ed5a4fa',
    position: {
      x: -16.612070083618164,
      y: 1.5435433387756348,
      z: -0.131485715508461,
    },
    rotation: {
      x: -0.033570324821075664,
      y: -52.292247350930175,
      z: 0.28894515138912497,
    },
  },
  {
    sid: '730dcb8882c5471b9f824715aa4650c3',
    id: '730dcb8882c5471b9f824715aa4650c3',
    position: {
      x: -16.746389389038086,
      y: 1.5385373830795288,
      z: 1.5322654247283936,
    },
    rotation: {
      x: -0.012621820771971711,
      y: -83.38575007035001,
      z: 0.321137568004352,
    },
  },
  {
    sid: '23ee5842720c4b608145da834e2aa8fa',
    id: '23ee5842720c4b608145da834e2aa8fa',
    position: {
      x: -16.828969955444336,
      y: 1.5382803678512573,
      z: 3.4277427196502686,
    },
    rotation: {
      x: 0.20727271007242787,
      y: -17.371824775671957,
      z: 0.6143606946237942,
    },
  },
  {
    sid: '47572549c18d4b6d9c5ac4fe3c7944b7',
    id: '47572549c18d4b6d9c5ac4fe3c7944b7',
    position: {
      x: -15.28779125213623,
      y: 1.5530035495758057,
      z: 5.469554424285889,
    },
    rotation: {
      x: -0.44169586831564106,
      y: -20.225804145315344,
      z: 0.1437493620049317,
    },
  },
  {
    sid: '2d0056b3dcf345a0b58f4819bed47188',
    id: '2d0056b3dcf345a0b58f4819bed47188',
    position: {
      x: -15.388648986816406,
      y: 1.5457102060317993,
      z: 7.1567158699035645,
    },
    rotation: {
      x: -0.49302367470987407,
      y: -92.15946834435542,
      z: 0.29056818684486657,
    },
  },
  {
    sid: '19e6c8be2da34cf9b97a146d5251328b',
    id: '19e6c8be2da34cf9b97a146d5251328b',
    position: {
      x: -17.90793228149414,
      y: 1.542676329612732,
      z: 7.045993804931641,
    },
    rotation: {
      x: 0.3121271139472036,
      y: -35.29210315508087,
      z: 0.43484268661478165,
    },
  },
  {
    sid: '1ef18a3735e245ec9730827c8c65770f',
    id: '1ef18a3735e245ec9730827c8c65770f',
    position: {
      x: -20.18553352355957,
      y: 1.5441830158233643,
      z: 7.0676679611206055,
    },
    rotation: {
      x: -0.19282272901277192,
      y: -27.406872676089645,
      z: 0.5309448665047158,
    },
  },
  {
    sid: '48d3f3a36833447bbdb9611ec1962c7a',
    id: '48d3f3a36833447bbdb9611ec1962c7a',
    position: {
      x: -21.762752532958984,
      y: 1.5546666383743286,
      z: 6.970022201538086,
    },
    rotation: {
      x: 0.3439858708620868,
      y: 85.24871253787742,
      z: -0.746603638997065,
    },
  },
  {
    sid: 'a62572d11ae14112928d06594ddcb023',
    id: 'a62572d11ae14112928d06594ddcb023',
    position: {
      x: -21.591594696044922,
      y: 1.5526938438415527,
      z: 4.850356578826904,
    },
    rotation: {
      x: 0.30509617027196845,
      y: 122.93248764621202,
      z: 0.5449599799468142,
    },
  },
  {
    sid: '7eec76be9fa742898d1fa7d9d1f06490',
    id: '7eec76be9fa742898d1fa7d9d1f06490',
    position: {
      x: -21.265514373779297,
      y: 1.5411463975906372,
      z: 4.085788249969482,
    },
    rotation: {
      x: -0.021748252331658825,
      y: 164.55130837159518,
      z: 0.8043039488799139,
    },
  },
  {
    sid: 'b268faa3a4204e00bb8c21ff3c826533',
    id: 'b268faa3a4204e00bb8c21ff3c826533',
    position: {
      x: -19.3831844329834,
      y: 1.5398164987564087,
      z: 4.135011672973633,
    },
    rotation: {
      x: 0.0040137374619607915,
      y: 142.95659771176545,
      z: 0.1948964420532097,
    },
  },
  {
    sid: '5d6612a4fd9c4004aa8ba25b736b023d',
    id: '5d6612a4fd9c4004aa8ba25b736b023d',
    position: {
      x: -18.066688537597656,
      y: 1.5391654968261719,
      z: 4.1412811279296875,
    },
    rotation: {
      x: 0.5261731934731987,
      y: 90.08802804404432,
      z: 0.033503743861922015,
    },
  },
  {
    sid: 'f9564b86e92e427f9377e88cf66a6295',
    id: 'f9564b86e92e427f9377e88cf66a6295',
    position: {
      x: -18.289493560791016,
      y: 1.5395328998565674,
      z: 1.4086323976516724,
    },
    rotation: {
      x: -0.14338931141272665,
      y: -42.21525254431544,
      z: -0.399288572043242,
    },
  },
  {
    sid: '151dd01bdb29422eb2f9a607bcd00194',
    id: '151dd01bdb29422eb2f9a607bcd00194',
    position: {
      x: -19.283531188964844,
      y: 1.5381089448928833,
      z: 1.669793725013733,
    },
    rotation: {
      x: 0.041572417511970924,
      y: -35.15854713968577,
      z: -0.6497751259176361,
    },
  },
  {
    sid: '08bdb000f8d74bfa9dc9d02ceb0dd951',
    id: '08bdb000f8d74bfa9dc9d02ceb0dd951',
    position: {
      x: -21.359521865844727,
      y: 1.5538173913955688,
      z: 0.4470714330673218,
    },
    rotation: {
      x: -0.6777681288730585,
      y: -153.29524097287694,
      z: 0.795438892879672,
    },
  },
  {
    sid: '5b27943c05b64dc0b50830e2d5227ee1',
    id: '5b27943c05b64dc0b50830e2d5227ee1',
    position: {
      x: -21.199567794799805,
      y: 1.5490503311157227,
      z: 1.7810378074645996,
    },
    rotation: {
      x: 0.5455214863310828,
      y: 115.11603027369428,
      z: 0.9245699693928696,
    },
  },
  {
    sid: '421a4c3174b14cb286e259fc664979e5',
    id: '421a4c3174b14cb286e259fc664979e5',
    position: {
      x: -23.715940475463867,
      y: 1.5589736700057983,
      z: 6.948770046234131,
    },
    rotation: {
      x: 0.07856493944200564,
      y: -76.79226672488623,
      z: -0.7626465403046436,
    },
  },
  {
    sid: '5c33dde4894c47a38da7f77f0217332f',
    id: '5c33dde4894c47a38da7f77f0217332f',
    position: {
      x: -26.53923797607422,
      y: 1.5585061311721802,
      z: 6.6441240310668945,
    },
    rotation: {
      x: 0.47573537104402114,
      y: -64.93297648379043,
      z: -0.3102657720831253,
    },
  },
  {
    sid: '317620c9fdc24019984f92d41f1a628a',
    id: '317620c9fdc24019984f92d41f1a628a',
    position: {
      x: -29.57500457763672,
      y: 1.5590423345565796,
      z: 6.63051700592041,
    },
    rotation: {
      x: 0.49125524072571597,
      y: -58.356689041530124,
      z: -0.727817339876237,
    },
  },
  {
    sid: 'b5544c1f35854fb8a3a4a6bb8fbe4e94',
    id: 'b5544c1f35854fb8a3a4a6bb8fbe4e94',
    position: {
      x: -31.917333602905273,
      y: 1.5564615726470947,
      z: 6.319116592407227,
    },
    rotation: {
      x: 0.27482504788628526,
      y: -2.845915759116057,
      z: -0.14024972491338122,
    },
  },
  {
    sid: 'b522f6e8028342eca8ed3b00d08e84dc',
    id: 'b522f6e8028342eca8ed3b00d08e84dc',
    position: {
      x: -34.12874221801758,
      y: 1.5506062507629395,
      z: 6.4024338722229,
    },
    rotation: {
      x: 0.6889963177287433,
      y: -3.7155708069906765,
      z: -0.31087876655485064,
    },
  },
  {
    sid: 'e611a7f7784b4ea99eafb96d4bf7c40a',
    id: 'e611a7f7784b4ea99eafb96d4bf7c40a',
    position: {
      x: -35.850948333740234,
      y: 1.553947925567627,
      z: 6.17487907409668,
    },
    rotation: {
      x: -0.10086552804537427,
      y: 11.679807759083502,
      z: -0.7919623464740885,
    },
  },
  {
    sid: '70dfc94f90244f6791308c678940e626',
    id: '70dfc94f90244f6791308c678940e626',
    position: {
      x: -37.4350700378418,
      y: 1.5620379447937012,
      z: 6.089262962341309,
    },
    rotation: {
      x: -0.3097173408761721,
      y: -22.14668847283431,
      z: 0.095669041704605,
    },
  },
  {
    sid: 'fb69664ecb6f4412bb9b2a4b0fe733df',
    id: 'fb69664ecb6f4412bb9b2a4b0fe733df',
    position: {
      x: -35.82206344604492,
      y: 1.5601061582565308,
      z: 4.678590774536133,
    },
    rotation: {
      x: 0.36696017914205237,
      y: -104.03514684060397,
      z: -0.43564041092802125,
    },
  },
  {
    sid: '90759a5170f04886853d9367fc74bdb3',
    id: '90759a5170f04886853d9367fc74bdb3',
    position: {
      x: -36.04747772216797,
      y: 1.5549365282058716,
      z: 3.240697145462036,
    },
    rotation: {
      x: 0.03358243524033323,
      y: -123.81843148415847,
      z: -0.2508593647655083,
    },
  },
  {
    sid: '0cda20742782483f99d0c7a017575943',
    id: '0cda20742782483f99d0c7a017575943',
    position: {
      x: -37.29661178588867,
      y: 1.565466284751892,
      z: 3.7543108463287354,
    },
    rotation: {
      x: 0.1176981208837711,
      y: -46.28991185571292,
      z: -0.9863546556282003,
    },
  },
  {
    sid: '4c1efe74d60248109ff7529101b93344',
    id: '4c1efe74d60248109ff7529101b93344',
    position: {
      x: -35.87159729003906,
      y: 1.5521705150604248,
      z: 2.3527681827545166,
    },
    rotation: {
      x: 0.030446930001538745,
      y: -108.25455388548546,
      z: -0.013682842230530573,
    },
  },
  {
    sid: '5182447b9f3943e9838819fcb5ba5750',
    id: '5182447b9f3943e9838819fcb5ba5750',
    position: {
      x: -33.61552047729492,
      y: 1.5564676523208618,
      z: 2.412564992904663,
    },
    rotation: {
      x: -0.02004133579694162,
      y: -102.83084531626987,
      z: -0.3703420913121604,
    },
  },
  {
    sid: '6e28d2e05cd14b8a81ae8cd5af27c17e',
    id: '6e28d2e05cd14b8a81ae8cd5af27c17e',
    position: {
      x: -31.636241912841797,
      y: 1.5612974166870117,
      z: 2.6228079795837402,
    },
    rotation: {
      x: -0.1068617892660119,
      y: -82.10252528467927,
      z: 0.48665965314874315,
    },
  },
  {
    sid: '687a4344de5345edb22e984c13ad5e31',
    id: '687a4344de5345edb22e984c13ad5e31',
    position: {
      x: -31.77935028076172,
      y: 1.5616456270217896,
      z: 3.7981624603271484,
    },
    rotation: {
      x: -0.005030113446708041,
      y: -48.688419059452,
      z: 0.11607684323657795,
    },
  },
  {
    sid: '73bd85cef6b3454592917cd5b7667fcf',
    id: '73bd85cef6b3454592917cd5b7667fcf',
    position: {
      x: -32.03984832763672,
      y: 1.5599193572998047,
      z: 4.81469202041626,
    },
    rotation: {
      x: 0.18554024316761691,
      y: -81.90817383617183,
      z: -0.6860151602821045,
    },
  },
  {
    sid: 'fc5adfbc4b964382a143478e2d3aef7c',
    id: 'fc5adfbc4b964382a143478e2d3aef7c',
    position: {
      x: -33.70540237426758,
      y: 1.5596169233322144,
      z: 4.76989221572876,
    },
    rotation: {
      x: -0.2828813500883531,
      y: 142.95366687255714,
      z: -0.3627599673541763,
    },
  },
  {
    sid: '9ed5d8ec26ce468281d29b765f31aaa6',
    id: '9ed5d8ec26ce468281d29b765f31aaa6',
    position: {
      x: -29.80299949645996,
      y: 1.5611612796783447,
      z: 2.7558999061584473,
    },
    rotation: {
      x: 0.34852414947217475,
      y: 140.45581280325314,
      z: 0.5614722931745452,
    },
  },
  {
    sid: '7cf3e6a2ddda4dd48d188cc218448b3a',
    id: '7cf3e6a2ddda4dd48d188cc218448b3a',
    position: {
      x: -29.286636352539062,
      y: 1.5586698055267334,
      z: 4.669561862945557,
    },
    rotation: {
      x: 0.36204178833801454,
      y: 17.35484936432528,
      z: 0.4811419927559679,
    },
  },
  {
    sid: '4f316ebeba6047a4bca39601f42e1dea',
    id: '4f316ebeba6047a4bca39601f42e1dea',
    position: {
      x: -27.668745040893555,
      y: 1.5633338689804077,
      z: 2.8706161975860596,
    },
    rotation: {
      x: -0.09732326676150793,
      y: 91.42946737018848,
      z: 0.4729917769441483,
    },
  },
  {
    sid: 'eb994242751042118a7e6e117e982a2e',
    id: 'eb994242751042118a7e6e117e982a2e',
    position: {
      x: -26.373092651367188,
      y: 1.5632628202438354,
      z: 3.120734930038452,
    },
    rotation: {
      x: 0.051597302401444646,
      y: 102.30810429200172,
      z: 0.21896736222213664,
    },
  },
  {
    sid: '933e166bd60f4008923c5cc193a3e779',
    id: '933e166bd60f4008923c5cc193a3e779',
    position: {
      x: -26.387624740600586,
      y: 1.5619803667068481,
      z: 4.751771450042725,
    },
    rotation: {
      x: -0.11111987512570075,
      y: 80.62489507241172,
      z: 0.2864881347392196,
    },
  },
  {
    sid: 'f4699d1caddb49d19f61206c0688b51d',
    id: 'f4699d1caddb49d19f61206c0688b51d',
    position: {
      x: -23.405412673950195,
      y: 1.5664618015289307,
      z: 3.196709632873535,
    },
    rotation: {
      x: -0.16833054457601734,
      y: 164.82143899098202,
      z: 0.9392792574281428,
    },
  },
  {
    sid: '6d64487950c04fe88a44970e6dd6aa6d',
    id: '6d64487950c04fe88a44970e6dd6aa6d',
    position: {
      x: -23.53011131286621,
      y: 1.5658462047576904,
      z: 4.758659362792969,
    },
    rotation: {
      x: 0.44764199760854023,
      y: 133.340237873368,
      z: 0.47007232067850113,
    },
  },
  {
    sid: '46ca8ca00ddb40028f34611aba13f3a5',
    id: '46ca8ca00ddb40028f34611aba13f3a5',
    position: {
      x: -23.11289405822754,
      y: 1.5746824741363525,
      z: 0.7350903153419495,
    },
    rotation: {
      x: -0.00871888420803827,
      y: 104.04217268805178,
      z: 0.818976278638665,
    },
  },
  {
    sid: 'e944a5deadfc4938a325b1f4c33758c2',
    id: 'e944a5deadfc4938a325b1f4c33758c2',
    position: {
      x: -23.579103469848633,
      y: 1.5713965892791748,
      z: -0.3950660526752472,
    },
    rotation: {
      x: -0.4368494729312647,
      y: -33.387203217905935,
      z: -0.5175850588972403,
    },
  },
  {
    sid: 'c7bfda277cfc464e9967f3d40466b488',
    id: 'c7bfda277cfc464e9967f3d40466b488',
    position: {
      x: -25.239219665527344,
      y: 1.5769705772399902,
      z: -0.3870241641998291,
    },
    rotation: {
      x: -0.21141705506015557,
      y: -25.643948715388515,
      z: 0.17552197486263096,
    },
  },
  {
    sid: '22229677102840cc97671a327eb6375f',
    id: '22229677102840cc97671a327eb6375f',
    position: {
      x: -27.485538482666016,
      y: 1.5672038793563843,
      z: -0.49181410670280457,
    },
    rotation: {
      x: 0.09621625056681979,
      y: 18.015526224932746,
      z: -0.10484467336712967,
    },
  },
  {
    sid: '0ea75a5fef8e4db88aab672a38f8dbe0',
    id: '0ea75a5fef8e4db88aab672a38f8dbe0',
    position: {
      x: -27.398773193359375,
      y: 1.5618109703063965,
      z: 0.7828345894813538,
    },
    rotation: {
      x: -0.16914286811062357,
      y: -16.19606816297083,
      z: -0.19960736794810094,
    },
  },
  {
    sid: 'aad43739a0e84595916ef7cf8d598583',
    id: 'aad43739a0e84595916ef7cf8d598583',
    position: {
      x: -25.72856903076172,
      y: 1.5695412158966064,
      z: 0.7769529819488525,
    },
    rotation: {
      x: -0.03848877957278265,
      y: 7.484198394384443,
      z: 0.2655348002073309,
    },
  },
  {
    sid: 'bbe4ff8ff49c4c4e96a79119fd632e03',
    id: 'bbe4ff8ff49c4c4e96a79119fd632e03',
    position: {
      x: -29.692594528198242,
      y: 1.5688209533691406,
      z: 0.5724917054176331,
    },
    rotation: {
      x: 0.2129358138769946,
      y: 64.04629076192994,
      z: -0.9109890643859434,
    },
  },
  {
    sid: '584818508f87481ba59c60a433129b18',
    id: '584818508f87481ba59c60a433129b18',
    position: {
      x: -29.601430892944336,
      y: 1.568902611732483,
      z: -0.6032775044441223,
    },
    rotation: {
      x: -0.08180124592372567,
      y: 73.66511386800607,
      z: 0.062176924111994096,
    },
  },
  {
    sid: 'e98771f016664df0b49d0aac552c6f9e',
    id: 'e98771f016664df0b49d0aac552c6f9e',
    position: {
      x: -31.725587844848633,
      y: 1.5709067583084106,
      z: -0.04092000797390938,
    },
    rotation: {
      x: -0.37473672866066876,
      y: 3.9003376483998258,
      z: -0.20313570823092364,
    },
  },
  {
    sid: 'cb8ea259eb49484ca1f58f48911f8e96',
    id: 'cb8ea259eb49484ca1f58f48911f8e96',
    position: {
      x: -31.80817222595215,
      y: 1.5572375059127808,
      z: 1.3218590021133423,
    },
    rotation: {
      x: -0.12631009026976292,
      y: 17.41252468157849,
      z: 0.1786202589727539,
    },
  },
  {
    sid: 'a292801497ff46098e51a9b9646dd5a7',
    id: 'a292801497ff46098e51a9b9646dd5a7',
    position: {
      x: -33.49425506591797,
      y: 1.5649003982543945,
      z: -0.013385125435888767,
    },
    rotation: {
      x: 0.001077128595474294,
      y: -57.43414727840674,
      z: -0.38403924000990136,
    },
  },
  {
    sid: '628b712f230f43709f6f07d1e515fbf9',
    id: '628b712f230f43709f6f07d1e515fbf9',
    position: {
      x: -34.278751373291016,
      y: 1.5662996768951416,
      z: -1.1244267225265503,
    },
    rotation: {
      x: -0.501546366264377,
      y: -121.02380370764249,
      z: -0.3547558259942379,
    },
  },
  {
    sid: '8f7d4aebe7a04f61b993d357b87741dc',
    id: '8f7d4aebe7a04f61b993d357b87741dc',
    position: {
      x: -34.541221618652344,
      y: 1.564990758895874,
      z: -3.3766682147979736,
    },
    rotation: {
      x: -0.35044696817191634,
      y: -66.10611675762082,
      z: -0.14680779638199581,
    },
  },
  {
    sid: 'e4d0abccf34a40f797454297925056a4',
    id: 'e4d0abccf34a40f797454297925056a4',
    position: {
      x: -36.355613708496094,
      y: 1.5621434450149536,
      z: -2.4431209564208984,
    },
    rotation: {
      x: -0.6366286758109017,
      y: -79.11166049747186,
      z: -0.834149099957548,
    },
  },
  {
    sid: 'a2b32488e953409ca3b09ac40f9b1f2b',
    id: 'a2b32488e953409ca3b09ac40f9b1f2b',
    position: {
      x: -36.84309005737305,
      y: 1.5493896007537842,
      z: 0.8447076082229614,
    },
    rotation: {
      x: -0.025297226586431874,
      y: -5.428937468990575,
      z: -0.2986773464057315,
    },
  },
  {
    sid: 'c5683e9713854cdaac0f4eeaa139f01c',
    id: 'c5683e9713854cdaac0f4eeaa139f01c',
    position: {
      x: -37.73139572143555,
      y: 1.5548479557037354,
      z: -1.3774750232696533,
    },
    rotation: {
      x: -0.5021879869455445,
      y: -53.211768983893904,
      z: -0.263206092767468,
    },
  },
  {
    sid: 'a295f0beb74943d2a808187c3102f742',
    id: 'a295f0beb74943d2a808187c3102f742',
    position: {
      x: -32.05523681640625,
      y: 1.566632866859436,
      z: -3.2987098693847656,
    },
    rotation: {
      x: -0.5077758072139346,
      y: -17.761023178070456,
      z: -0.053542046093192124,
    },
  },
  {
    sid: '9195472f591a4180a8e076cef2611e84',
    id: '9195472f591a4180a8e076cef2611e84',
    position: {
      x: -31.98235321044922,
      y: 1.5712997913360596,
      z: -1.766208529472351,
    },
    rotation: {
      x: -0.3522462776636943,
      y: -16.19515203060543,
      z: -0.05965287455013311,
    },
  },
  {
    sid: '77f3151cab5c4370a17213164908d642',
    id: '77f3151cab5c4370a17213164908d642',
    position: {
      x: -29.474720001220703,
      y: 1.572872519493103,
      z: -2.807278871536255,
    },
    rotation: {
      x: 0.13832718901899205,
      y: -158.0234849999868,
      z: -0.32399160328621407,
    },
  },
  {
    sid: '4585f744f6f94cd69ca5f5da1af6cd5d',
    id: '4585f744f6f94cd69ca5f5da1af6cd5d',
    position: {
      x: -29.63231658935547,
      y: 1.571501612663269,
      z: -1.1167974472045898,
    },
    rotation: {
      x: 0.5678906947071897,
      y: 31.170762200683228,
      z: 0.6447469778934454,
    },
  },
  {
    sid: '333c05f7f7864358bd8e5105e6e2f34d',
    id: '333c05f7f7864358bd8e5105e6e2f34d',
    position: {
      x: -27.411808013916016,
      y: 1.570153832435608,
      z: -2.628847122192383,
    },
    rotation: {
      x: 0.21886889687151861,
      y: 43.395345160133346,
      z: 0.11920996754847644,
    },
  },
  {
    sid: '7a5fc50540fd47afa840b0edec2c9dba',
    id: '7a5fc50540fd47afa840b0edec2c9dba',
    position: {
      x: -25.48592185974121,
      y: 1.562508225440979,
      z: -2.5763661861419678,
    },
    rotation: {
      x: 0.44863150779240024,
      y: -66.32928585447883,
      z: -0.8302834401041657,
    },
  },
  {
    sid: 'c564da115b014bf0b385f08b4ca274cd',
    id: 'c564da115b014bf0b385f08b4ca274cd',
    position: {
      x: -23.60796356201172,
      y: 1.5721135139465332,
      z: -2.1912145614624023,
    },
    rotation: {
      x: 0.6088523124332733,
      y: -56.819565517970496,
      z: -0.1679605150239943,
    },
  },
  {
    sid: '0f3986b1bcd14975a451ebe7ad701950',
    id: '0f3986b1bcd14975a451ebe7ad701950',
    position: {
      x: -25.306196212768555,
      y: 1.5756933689117432,
      z: -4.051004409790039,
    },
    rotation: {
      x: 0.5161476730159597,
      y: -176.80153010735728,
      z: 0.02701352243954839,
    },
  },
  {
    sid: '472436d51a594060836df5d8e9c9e6c2',
    id: '472436d51a594060836df5d8e9c9e6c2',
    position: {
      x: -25.360240936279297,
      y: 1.5828094482421875,
      z: -6.682213306427002,
    },
    rotation: {
      x: 0.6919109939710149,
      y: -143.31095290430633,
      z: -0.5549536718285975,
    },
  },
  {
    sid: '4c112441febc4facaf79199c8842bd5b',
    id: '4c112441febc4facaf79199c8842bd5b',
    position: {
      x: -22.8206787109375,
      y: 1.582287073135376,
      z: -6.480708599090576,
    },
    rotation: {
      x: 0.8504524578946354,
      y: 55.09365113453841,
      z: -0.33580450502346554,
    },
  },
  {
    sid: '625c8d58e1094cf3a4c46f24d1e66b20',
    id: '625c8d58e1094cf3a4c46f24d1e66b20',
    position: {
      x: -26.667739868164062,
      y: 1.5755503177642822,
      z: -6.938339710235596,
    },
    rotation: {
      x: 0.41187495304746957,
      y: -18.107563996689137,
      z: -0.1561544614775145,
    },
  },
  {
    sid: 'f879a1a77be742b0b9cc578d2735eb47',
    id: 'f879a1a77be742b0b9cc578d2735eb47',
    position: {
      x: -29.35955810546875,
      y: 1.5784202814102173,
      z: -6.941747188568115,
    },
    rotation: {
      x: 0.6494946115863223,
      y: -19.370093355403416,
      z: -0.5675003672722336,
    },
  },
  {
    sid: '6e495af456e541d4a8f4bed1d648f3cf',
    id: '6e495af456e541d4a8f4bed1d648f3cf',
    position: {
      x: -29.576932907104492,
      y: 1.574653148651123,
      z: -4.9557366371154785,
    },
    rotation: {
      x: 0.3638187285793437,
      y: -18.583246525477946,
      z: -0.3949858623915279,
    },
  },
  {
    sid: '3ca2c7de96514cd6aff6131124fc3a6a',
    id: '3ca2c7de96514cd6aff6131124fc3a6a',
    position: {
      x: -31.582645416259766,
      y: 1.57050621509552,
      z: -7.051761627197266,
    },
    rotation: {
      x: 0.8032366859933149,
      y: -29.979145625803557,
      z: 0.9558969143987456,
    },
  },
  {
    sid: 'e80da88cb4c04c73991e94f7bdac1b27',
    id: 'e80da88cb4c04c73991e94f7bdac1b27',
    position: {
      x: -31.709754943847656,
      y: 1.5716208219528198,
      z: -5.022528171539307,
    },
    rotation: {
      x: 0.6363518632099182,
      y: -35.57981958002873,
      z: 0.20399107839575703,
    },
  },
  {
    sid: '97ea35e92a9042b3be13ce7254f3db41',
    id: '97ea35e92a9042b3be13ce7254f3db41',
    position: {
      x: -36.21876525878906,
      y: 1.5588732957839966,
      z: -3.8567967414855957,
    },
    rotation: {
      x: -0.03182187013021383,
      y: -46.81516352425515,
      z: 0.4175193157855189,
    },
  },
  {
    sid: 'b9081d6c7d124331a5de77ffb93c22c2',
    id: 'b9081d6c7d124331a5de77ffb93c22c2',
    position: {
      x: -36.35641860961914,
      y: 1.5684850215911865,
      z: -6.412211894989014,
    },
    rotation: {
      x: 0.48664630754910554,
      y: -60.80331793969105,
      z: 0.19603395310141894,
    },
  },
  {
    sid: 'afe6c25cc02a47af93e3602b7872bdc4',
    id: 'afe6c25cc02a47af93e3602b7872bdc4',
    position: {
      x: -36.308021545410156,
      y: 1.5725741386413574,
      z: -8.824786186218262,
    },
    rotation: {
      x: 0.12551185159628384,
      y: -55.69380290003606,
      z: 0.9436466628672977,
    },
  },
  {
    sid: 'f7ea941ee7dc40fcae8dec17ec6f309f',
    id: 'f7ea941ee7dc40fcae8dec17ec6f309f',
    position: {
      x: -34.25704574584961,
      y: 1.5705413818359375,
      z: -8.31346321105957,
    },
    rotation: {
      x: 0.42928131100347716,
      y: -44.09934107193368,
      z: 0.09212445953893413,
    },
  },
  {
    sid: '9c72f5f20fa4447494f5f8fe5cfc16bf',
    id: '9c72f5f20fa4447494f5f8fe5cfc16bf',
    position: {
      x: -34.14754867553711,
      y: 1.5675586462020874,
      z: -5.9715681076049805,
    },
    rotation: {
      x: 0.23240972438541183,
      y: -71.92751074594825,
      z: -0.021044265492032983,
    },
  },
  {
    sid: '047eb5a4fbee4302ab7f3298525b56e1',
    id: '047eb5a4fbee4302ab7f3298525b56e1',
    position: {
      x: -32.75190353393555,
      y: 1.5693199634552002,
      z: -7.503605842590332,
    },
    rotation: {
      x: 0.09327509677426822,
      y: -169.99971836213203,
      z: 0.5653957585270106,
    },
  },
  {
    sid: '68b5d6d9fef640b497271b74ba3e6f50',
    id: '68b5d6d9fef640b497271b74ba3e6f50',
    position: {
      x: -23.613525390625,
      y: 1.5579577684402466,
      z: -13.11605167388916,
    },
    rotation: {
      x: 0.37800029564003695,
      y: -165.6238684535337,
      z: 0.561594713529337,
    },
  },
  {
    sid: '54d1544b16564ac1868d52b9e6ca328c',
    id: '54d1544b16564ac1868d52b9e6ca328c',
    position: {
      x: -23.726675033569336,
      y: 1.5530569553375244,
      z: -14.820652961730957,
    },
    rotation: {
      x: 0.2588953041390452,
      y: -160.47522566220675,
      z: 0.76733566933099,
    },
  },
  {
    sid: 'dfc382b2501f4a62a8b0d89179e0d022',
    id: 'dfc382b2501f4a62a8b0d89179e0d022',
    position: {
      x: -25.750530242919922,
      y: 1.5668141841888428,
      z: -15.106542587280273,
    },
    rotation: {
      x: 0.7093465153980739,
      y: -159.32218948596048,
      z: -0.0724821140023823,
    },
  },
  {
    sid: '72d57e32bd584d12b27e08261a7fbf60',
    id: '72d57e32bd584d12b27e08261a7fbf60',
    position: {
      x: -26.028789520263672,
      y: 1.5721828937530518,
      z: -13.832207679748535,
    },
    rotation: {
      x: 0.24991886463309534,
      y: -108.74553399223281,
      z: 0.4213614082041276,
    },
  },
  {
    sid: '2b33def3bde84803a514d104eb664b6a',
    id: '2b33def3bde84803a514d104eb664b6a',
    position: {
      x: -26.414960861206055,
      y: 1.5775177478790283,
      z: -11.751603126525879,
    },
    rotation: {
      x: 0.8137679970460305,
      y: -82.66554894067214,
      z: 0.07412588026489003,
    },
  },
  {
    sid: 'bb8aff2f2048421dbd168427b70f587a',
    id: 'bb8aff2f2048421dbd168427b70f587a',
    position: {
      x: -26.49284553527832,
      y: 1.5690916776657104,
      z: -9.45875358581543,
    },
    rotation: {
      x: 0.08111749345910822,
      y: -91.71098562656215,
      z: 0.41921529006264685,
    },
  },
  {
    sid: '64df962984254834acb71989d9f141fe',
    id: '64df962984254834acb71989d9f141fe',
    position: {
      x: -28.732866287231445,
      y: 1.5701673030853271,
      z: -9.324568748474121,
    },
    rotation: {
      x: 0.3220754600583586,
      y: -73.46520352412479,
      z: 0.9220372979949253,
    },
  },
  {
    sid: 'b294642bf4244ae09c1fc998f5d8a6ad',
    id: 'b294642bf4244ae09c1fc998f5d8a6ad',
    position: {
      x: -29.153432846069336,
      y: 1.5680477619171143,
      z: -10.961007118225098,
    },
    rotation: {
      x: 0.68123143242941,
      y: -104.43345617148921,
      z: 0.4077350782952979,
    },
  },
  {
    sid: '76ebc9883fa6405eb2ce8fb61840576a',
    id: '76ebc9883fa6405eb2ce8fb61840576a',
    position: {
      x: -28.76754379272461,
      y: 1.575335144996643,
      z: -12.312400817871094,
    },
    rotation: {
      x: -0.014943843547412102,
      y: -120.47294903043424,
      z: 0.554237437003351,
    },
  },
  {
    sid: '6d176d422aae4913b940bd7fce76498e',
    id: '6d176d422aae4913b940bd7fce76498e',
    position: {
      x: -28.434335708618164,
      y: 1.5722397565841675,
      z: -13.953317642211914,
    },
    rotation: {
      x: -0.02293270629917387,
      y: -132.20915578614546,
      z: -0.1228276978258312,
    },
  },
  {
    sid: '4401f29b1c2e4a85b595b75324de9626',
    id: '4401f29b1c2e4a85b595b75324de9626',
    position: {
      x: -28.050378799438477,
      y: 1.5766137838363647,
      z: -15.626873016357422,
    },
    rotation: {
      x: 0.326631657796528,
      y: -144.61825060431968,
      z: 0.74073819317536,
    },
  },
  {
    sid: 'bc0466b66ee543c2b9951a0d0c70871e',
    id: 'bc0466b66ee543c2b9951a0d0c70871e',
    position: {
      x: -29.702835083007812,
      y: 1.5753536224365234,
      z: -15.965265274047852,
    },
    rotation: {
      x: 0.5091643585248038,
      y: -138.63154311389255,
      z: -0.18019665944940036,
    },
  },
  {
    sid: '170486058b194a12af718a91cb775b3a',
    id: '170486058b194a12af718a91cb775b3a',
    position: {
      x: -30.946075439453125,
      y: 1.5713449716567993,
      z: -15.986342430114746,
    },
    rotation: {
      x: -0.26308639750405055,
      y: -146.5070236740489,
      z: 0.28552284390887084,
    },
  },
  {
    sid: 'c2b0370a386046ad9f0dd0953ae833f2',
    id: 'c2b0370a386046ad9f0dd0953ae833f2',
    position: {
      x: -32.79134750366211,
      y: 1.583876132965088,
      z: -16.216266632080078,
    },
    rotation: {
      x: -0.4452050623167326,
      y: -160.72451901009154,
      z: 0.19660565788379586,
    },
  },
  {
    sid: '26389e57e2904091bb48e3b458a14d0e',
    id: '26389e57e2904091bb48e3b458a14d0e',
    position: {
      x: -31.12381362915039,
      y: 1.5663503408432007,
      z: -14.108593940734863,
    },
    rotation: {
      x: 0.003975668035920045,
      y: -148.67736642388027,
      z: 0.6060982075393174,
    },
  },
  {
    sid: '216d6c787d974d399f2e612af37aaff6',
    id: '216d6c787d974d399f2e612af37aaff6',
    position: {
      x: -31.782495498657227,
      y: 1.5714833736419678,
      z: -11.732295036315918,
    },
    rotation: {
      x: -0.21675262990702182,
      y: -105.00749349717175,
      z: 0.21010484142470465,
    },
  },
  {
    sid: 'e1a2821c9f3843b39215781e42654b1d',
    id: 'e1a2821c9f3843b39215781e42654b1d',
    position: {
      x: -32.19336700439453,
      y: 1.578403353691101,
      z: -9.831114768981934,
    },
    rotation: {
      x: -0.22653142443986743,
      y: -84.22101205864884,
      z: -0.2749252368330731,
    },
  },
  {
    sid: '1965dcedeb8a447785d0a9626cb9bec2',
    id: '1965dcedeb8a447785d0a9626cb9bec2',
    position: {
      x: -30.88119125366211,
      y: 1.5670164823532104,
      z: -8.987655639648438,
    },
    rotation: {
      x: -0.1586622565675163,
      y: -80.43504103589454,
      z: 0.43152186722840136,
    },
  },
  {
    sid: '9090af14e6b446b8bda6b09ceaee0ba3',
    id: '9090af14e6b446b8bda6b09ceaee0ba3',
    position: {
      x: -33.736907958984375,
      y: 1.5777738094329834,
      z: -11.722809791564941,
    },
    rotation: {
      x: 0.27746322447615435,
      y: -129.61786659115728,
      z: 0.19717910346658774,
    },
  },
  {
    sid: 'bad52f69f59041cda98635bfe3f70a21',
    id: 'bad52f69f59041cda98635bfe3f70a21',
    position: {
      x: -35.19746780395508,
      y: 1.572076439857483,
      z: -11.177892684936523,
    },
    rotation: {
      x: -0.0631373904528828,
      y: -154.27486158921553,
      z: -0.5693384804886954,
    },
  },
  {
    sid: '7afa0efcbd004f20abf89682806e89b4',
    id: '7afa0efcbd004f20abf89682806e89b4',
    position: {
      x: -33.911720275878906,
      y: 1.5846476554870605,
      z: -13.642760276794434,
    },
    rotation: {
      x: 0.28927742720268,
      y: -145.87063086108446,
      z: 0.6938312538844842,
    },
  },
  {
    sid: '64be784a629d439685e3d90eaebe3c59',
    id: '64be784a629d439685e3d90eaebe3c59',
    position: {
      x: -33.025413513183594,
      y: 1.5802149772644043,
      z: -15.314663887023926,
    },
    rotation: {
      x: -0.11425240969036986,
      y: -145.92932151495938,
      z: 0.7039046140989796,
    },
  },
  {
    sid: 'e603339e97604b86aff82abed574c967',
    id: 'e603339e97604b86aff82abed574c967',
    position: {
      x: -34.710594177246094,
      y: 1.5807623863220215,
      z: -12.287445068359375,
    },
    rotation: {
      x: 0.3883981833806805,
      y: -99.95866774942918,
      z: -0.45618616292508596,
    },
  },
  {
    sid: '38c27cc1d46547d18ee18592bf584f6e',
    id: '38c27cc1d46547d18ee18592bf584f6e',
    position: {
      x: -32.07801055908203,
      y: 1.5726186037063599,
      z: -11.050043106079102,
    },
    rotation: {
      x: -0.14184652551043708,
      y: -124.9466456012743,
      z: 0.37777082459288613,
    },
  },
  {
    sid: '25d8c5b083824dfb99c1361e6e54c4d0',
    id: '25d8c5b083824dfb99c1361e6e54c4d0',
    position: {
      x: -38.080684661865234,
      y: 1.5541424751281738,
      z: -1.6904810667037964,
    },
    rotation: {
      x: 0.19814430734381638,
      y: 37.94416319545396,
      z: 0.19604144642985974,
    },
  },
  {
    sid: 'fc03b8e0692e43b1adaaf5a43ae36a49',
    id: 'fc03b8e0692e43b1adaaf5a43ae36a49',
    position: {
      x: -38.400821685791016,
      y: 1.5469743013381958,
      z: 0.06223924085497856,
    },
    rotation: {
      x: 0.6789890699639879,
      y: 64.3838251883292,
      z: 0.36928449277443987,
    },
  },
  {
    sid: 'd677a7c60ce7459894deec5c598ba619',
    id: 'd677a7c60ce7459894deec5c598ba619',
    position: {
      x: -38.44529724121094,
      y: 1.554907202720642,
      z: 1.8027899265289307,
    },
    rotation: {
      x: 0.3860643130297522,
      y: 44.56817742974762,
      z: 0.45421234110187203,
    },
  },
  {
    sid: '17fb3f381d2d463ab86c143f0292db3a',
    id: '17fb3f381d2d463ab86c143f0292db3a',
    position: {
      x: -40.16373825073242,
      y: 1.5644420385360718,
      z: 3.3752684593200684,
    },
    rotation: {
      x: -0.46456586048340665,
      y: 82.27540466241736,
      z: 0.1989317317178041,
    },
  },
  {
    sid: '8242a5c912604175a05c4412c3186e4f',
    id: '8242a5c912604175a05c4412c3186e4f',
    position: {
      x: -40.41127014160156,
      y: 1.5701512098312378,
      z: 6.470914363861084,
    },
    rotation: {
      x: 0.16842621306926991,
      y: 68.81452176422353,
      z: 0.7390011433660799,
    },
  },
  {
    sid: '208534e6491a4c60ac1632c51e7975f6',
    id: '208534e6491a4c60ac1632c51e7975f6',
    position: {
      x: -42.97487258911133,
      y: 1.5542796850204468,
      z: 5.4473347663879395,
    },
    rotation: {
      x: -0.23249018272154112,
      y: 79.58738122574414,
      z: 0.1430993754805215,
    },
  },
  {
    sid: '524c08e361a749429cdae5e2618b180f',
    id: '524c08e361a749429cdae5e2618b180f',
    position: {
      x: -42.813697814941406,
      y: 1.5414814949035645,
      z: 1.9965596199035645,
    },
    rotation: {
      x: -0.17330134713865714,
      y: 77.38865719283712,
      z: -0.031291076470627496,
    },
  },
  {
    sid: '01653eec74af4665aa83ccef92ae88c5',
    id: '01653eec74af4665aa83ccef92ae88c5',
    position: {
      x: -42.67827224731445,
      y: 1.5439010858535767,
      z: -0.65156090259552,
    },
    rotation: {
      x: 0.1766712297145323,
      y: 76.77695038095378,
      z: 0.46075137333477967,
    },
  },
  {
    sid: '93f4324d129d43f095a612c16410edc0',
    id: '93f4324d129d43f095a612c16410edc0',
    position: {
      x: -42.5702018737793,
      y: 1.5427658557891846,
      z: -4.046053886413574,
    },
    rotation: {
      x: -0.0004703420408169275,
      y: 74.83129721833362,
      z: -0.4476296384640101,
    },
  },
  {
    sid: '43236a293f254fa29063d21c3832ffe4',
    id: '43236a293f254fa29063d21c3832ffe4',
    position: {
      x: -42.48554611206055,
      y: 1.5377753973007202,
      z: -6.698917388916016,
    },
    rotation: {
      x: 0.05493939988036905,
      y: 78.7688530993413,
      z: 0.38849655670210664,
    },
  },
  {
    sid: 'bcd1bdd9d360418a953dea9fbe90f896',
    id: 'bcd1bdd9d360418a953dea9fbe90f896',
    position: {
      x: -40.73520278930664,
      y: 1.5560081005096436,
      z: -10.648777961730957,
    },
    rotation: {
      x: 0.04364010348727956,
      y: 32.78894605126126,
      z: 0.9018717698859816,
    },
  },
  {
    sid: '9ad4782529d244b5913d99ba4df45bb4',
    id: '9ad4782529d244b5913d99ba4df45bb4',
    position: {
      x: -38.93206024169922,
      y: 1.5677990913391113,
      z: -14.416319847106934,
    },
    rotation: {
      x: 0.43798369764620254,
      y: 26.462023482729812,
      z: -0.17229529787677358,
    },
  },
  {
    sid: '3c609a52fb6a4d9691eb351e19c65160',
    id: '3c609a52fb6a4d9691eb351e19c65160',
    position: {
      x: -37.308624267578125,
      y: 1.5749815702438354,
      z: -17.43138885498047,
    },
    rotation: {
      x: 0.043308110220288416,
      y: 121.2050425335536,
      z: -0.11436795260529198,
    },
  },
  {
    sid: 'b9be4fb95547415999a31262369a0183',
    id: 'b9be4fb95547415999a31262369a0183',
    position: {
      x: -36.2657470703125,
      y: 1.5618284940719604,
      z: -20.335765838623047,
    },
    rotation: {
      x: -0.11728985147213994,
      y: 97.37095974844841,
      z: -0.34898758101726673,
    },
  },
  {
    sid: '8f3b1158c4f84d60b76f951825ccee75',
    id: '8f3b1158c4f84d60b76f951825ccee75',
    position: {
      x: -32.2375373840332,
      y: 1.5724830627441406,
      z: -20.73025894165039,
    },
    rotation: {
      x: 0.46217611616339777,
      y: 118.96515124644377,
      z: 0.3360515932006211,
    },
  },
  {
    sid: '7a74e00be8834b1ca4a65171ac35f68b',
    id: '7a74e00be8834b1ca4a65171ac35f68b',
    position: {
      x: -33.75117874145508,
      y: 1.5660942792892456,
      z: -21.348552703857422,
    },
    rotation: {
      x: 0.11245351197952834,
      y: 77.40450020656492,
      z: 0.4003536130230456,
    },
  },
  {
    sid: '244615dd64be42f3800d07d5c891e6cd',
    id: '244615dd64be42f3800d07d5c891e6cd',
    position: {
      x: -39.264705657958984,
      y: 1.5619995594024658,
      z: -8.409975051879883,
    },
    rotation: {
      x: 0.1280291220591407,
      y: 102.735527463931,
      z: 0.1988525313502181,
    },
  },
  {
    sid: '70c49f554a8944d095ef62ee91279df8',
    id: '70c49f554a8944d095ef62ee91279df8',
    position: {
      x: -39.7709846496582,
      y: 1.551263689994812,
      z: -4.904734134674072,
    },
    rotation: {
      x: 0.4067029687913718,
      y: 52.63072615678312,
      z: 0.7670982226474458,
    },
  },
  {
    sid: '38039484eac74ef4bff3440560722918',
    id: '38039484eac74ef4bff3440560722918',
    position: {
      x: -40.28762435913086,
      y: 1.5540902614593506,
      z: -0.832344114780426,
    },
    rotation: {
      x: 0.32031462937938565,
      y: 93.95843021359201,
      z: 0.7939862785981503,
    },
  },
  {
    sid: 'b10c58e9dfa34549b80f289129de0617',
    id: 'b10c58e9dfa34549b80f289129de0617',
    position: {
      x: -40.385440826416016,
      y: 1.551716923713684,
      z: 2.526663303375244,
    },
    rotation: {
      x: -0.13163108559410816,
      y: 73.79877689505558,
      z: -0.16706473729436064,
    },
  },
  {
    sid: '5b6b1cd024bd41c287fa806f4f123198',
    id: '5b6b1cd024bd41c287fa806f4f123198',
    position: {
      x: -40.467384338378906,
      y: 1.5642303228378296,
      z: -12.935185432434082,
    },
    rotation: {
      x: 0.068904356807413,
      y: 173.4008178494864,
      z: 0.39986381060262016,
    },
  },
];

export default locations;
