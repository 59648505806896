import locationService from '@apis/services/location.service';
import navigationManager from '@utils/navigation.manager';
import pointerManager from '@utils/pointer.manager';
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { RingGeometry } from 'three';

import { LocationImageZoomDetailed } from './LocationImageZoomDetailed';
import { LocationPoint } from './LocationPoint';
import { SplashLocation } from './SplashLocation';

export const Locations = memo((props) => {
  const { locations, handleClickPoint, currentPoint } = props;

  const tmpTextureUrl = './assets/blank.jpg';
  const ringGeometry = useMemo(() => new RingGeometry(0.5, 1, 4), []);
  const stepNumber = useRef(0);

  const [currentLocationData, setCurrentLocationData] = useState();

  const currentEvenImageDomeSettingRef = useRef({
    locationData: {
      id: '',
      img: tmpTextureUrl,
      position: [0, 0, 0],
      degrees: 0,
    },
    showImage: false,
  });
  const [evenImageDomeSetting, setEvenImageDomeSetting] = useState(
    currentEvenImageDomeSettingRef.current
  );

  const currentOddImageDomeSettingRef = useRef({
    locationData: {
      id: '',
      img: tmpTextureUrl,
      position: [0, 0, 0],
      degrees: 0,
    },
    showImage: false,
  });
  const [oddImageDomeSetting, setOddImageDomeSetting] = useState(
    currentOddImageDomeSettingRef.current
  );

  const evenImageDome = useMemo(() => <SplashLocation {...evenImageDomeSetting} />, [
    evenImageDomeSetting,
  ]);

  const oddImageDome = useMemo(() => <SplashLocation {...oddImageDomeSetting} />, [
    oddImageDomeSetting,
  ]);

  const internalHandleClickPoint = useCallback((locationData) => {
    navigationManager.requestNewLocation(locationData, true);
  }, []);

  useEffect(() => {
    const handleRequestNewLocation = (locationData) => {
      let imageDomeSetting = {
        locationData: { ...locationData },
        showImage: false,
        enabledTransition: true,
      };

      if (stepNumber.current % 2 == 0) {
        setOddImageDomeSetting(imageDomeSetting);
      } else {
        setEvenImageDomeSetting(imageDomeSetting);
      }
    };

    const handleDestinationLocationReady = (destinationLocationData) => {
      if (handleClickPoint) {
        // console.log('destinationLocationData !!!!', destinationLocationData);
        handleClickPoint(destinationLocationData);
      }
    };

    const handleOnNavigationArrived = (locationData, enabledTransition) => {
      stepNumber.current += 1;
      let imageDomeSetting = {
        locationData: locationData,
        showImage: true,
        enabledTransition: enabledTransition,
      };
      if (stepNumber.current % 2 == 0) {
        setEvenImageDomeSetting(imageDomeSetting);
        setOddImageDomeSetting({
          ...currentOddImageDomeSettingRef.current,
          showImage: false,
          enabledTransition: enabledTransition,
        });
      } else {
        setOddImageDomeSetting(imageDomeSetting);
        setEvenImageDomeSetting({
          ...currentEvenImageDomeSettingRef.current,
          showImage: false,
          enabledTransition: enabledTransition,
        });
      }
      setCurrentLocationData(locationData);
    };

    const handleFloorClicked = (point) => {
      let nearestLocation = locationService.getNearestLocation(point);
      navigationManager.requestNewLocation(nearestLocation, true);
    };

    navigationManager.onRequestNewLocation(handleRequestNewLocation);
    navigationManager.onDestinationLocationReady(handleDestinationLocationReady);
    navigationManager.onArrived(handleOnNavigationArrived);
    pointerManager.onFloorClicked(handleFloorClicked);

    return () => {
      navigationManager.offRequestNewLocation(handleRequestNewLocation);
      navigationManager.offDestinationLocationReady(handleDestinationLocationReady);
      pointerManager.offFloorClicked(handleFloorClicked);
    };
  }, []);

  useEffect(() => {
    if (currentPoint) {
      navigationManager.arrived(currentPoint);
    }
  }, [currentPoint]);

  useEffect(() => {
    // console.log('oddImageDomeSetting>> ', oddImageDomeSetting);
    currentOddImageDomeSettingRef.current = oddImageDomeSetting;
  }, [oddImageDomeSetting]);

  useEffect(() => {
    // console.log('evenImageDomeSetting>> ', evenImageDomeSetting);
    currentEvenImageDomeSettingRef.current = evenImageDomeSetting;
  }, [evenImageDomeSetting]);

  return (
    <>
      {locations &&
        locations.length > 0 &&
        locations.map((item, index) => {
          return (
            <LocationPoint
              key={`location${item.id}`}
              ringGeometry={ringGeometry}
              handleClickPoint={internalHandleClickPoint}
              locationData={item}
            />
          );
        })}
      {evenImageDome}
      {oddImageDome}
      <LocationImageZoomDetailed locationData={currentLocationData} />
    </>
  );
});
