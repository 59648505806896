import React, { Suspense, memo, useEffect, useLayoutEffect, useRef, useState } from 'react';

// import { Shape } from 'three';
import { useTexture } from '@react-three/drei';

// import resourceManager from 'src/services/resource.manager';

export const ImagePlane = memo((props) => {
  const { rotation, position, source, scale, color, shape, planeArgs, storeId } = props;
  const shapeRef = useRef();
  // const texture = useTexture(source);
  // const [imageSource, setImageSource] = useState();

  useLayoutEffect(() => {
    function recalcUVs() {
      if (shapeRef.current) {
        shapeRef.current.geometry.computeBoundingBox();

        let max = shapeRef.current.geometry.boundingBox.max;
        let min = shapeRef.current.geometry.boundingBox.min;

        for (let i = 0; i < shapeRef.current.geometry.attributes.uv.count; i++) {
          let u = shapeRef.current.geometry.attributes.uv.getX(i);
          let v = shapeRef.current.geometry.attributes.uv.getY(i);

          shapeRef.current.geometry.attributes.uv.setXY(
            i,
            0.5 - u / (max.x - min.x),
            0.5 + v / (max.y - min.y)
          );
        }
        shapeRef.current.geometry.uvsNeedUpdate = true;
      }
    }

    recalcUVs();

    // for some reason, the UVs reset on resize.
    window.addEventListener('resize', recalcUVs);
    console.log('render Image', source);

    return () => {
      window.removeEventListener('resize', recalcUVs);
    };
  }, []);

  // const getMediaSource = async (mediaSource) => {
  //   const url = await resourceManager.getResource(storeId, mediaSource);
  //   if (url) {
  //     return url;
  //   }
  // };

  // useEffect(() => {
  //   const cacheResource = async (source) => {
  //     console.log('source', source);
  //     let tmpImage = await getMediaSource(source);
  //     setImageSource(tmpImage);
  //   };

  //   if (source) {
  //     cacheResource(source);
  //   }
  // }, [source]);
  // console.log('storeId', storeId);

  return (
    <group rotation={[0, rotation.y, 0]} position={position} scale={scale}>
      <group rotation={[rotation.x, 0, 0]}>
        <mesh ref={shapeRef} renderOrder={2}>
          <shapeGeometry args={[shape]} />
          {/* <meshBasicMaterial map={texture} transparent toneMapped={false} /> */}
          <Suspense fallback={<FallbackMaterial url="./assets/imgs/fidelity-load.jpg" />}>
            <ImageMaterial url={source && source} />
          </Suspense>
        </mesh>
        <mesh renderOrder={1}>
          <planeGeometry args={[planeArgs.width + 0.1, planeArgs.height + 0.1]} />
          <meshBasicMaterial color={color || '#e0cbac'} depthWrite={false} transparent />
        </mesh>
      </group>
    </group>
  );
});

function ImageMaterial({ url }) {
  const texture = useTexture(url);
  return <meshBasicMaterial map={texture} transparent toneMapped={false} />;
}

function FallbackMaterial({ url }) {
  const texture = useTexture(url);
  return <meshBasicMaterial map={texture} transparent toneMapped={false} />;
}
