import storesService from '@apis/services/stores.service';
import useStoreContext from '@hooks/useStoreContext';
import React, { useMemo } from 'react';

import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  logoBox: {
    position: 'absolute',
    zIndex: 1302,
    maxHeight: 24,
    maxWidth: 157,
    width: '100%',
    height: '100%',
    transition: `all .5s ease-in-out`,
  },
  imageLogo: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
  },
  blackBox: {
    width: 240,
    height: 44,
    position: 'absolute',
    zIndex: 1301,
    left: 'calc( 50% - 120px )',
    transition: `all .5s ease-in-out`,
  },
}));

export const StoreLogo = ({ animateLogo }) => {
  const classes = useStyles();
  const { storeId } = useStoreContext();
  const storeData = useMemo(() => storesService.getStore(), [storeId]);

  const style = {
    center: {
      top: '40%',
      left: '50%',
      transform: `translate(-50%, 50%) scale(1.75)`,
    },
    top: {
      top: 10,
      left: '50%',
      transform: `translate(-50%, 0) scale(1)`,
    },
    logoBoxHidden: {
      top: -44,
    },
    logoBoxShow: {
      top: 0,
    },
  };

  return (
    <div>
      <div className={classes.logoBox} style={animateLogo ? style.top : style.center}>
        <img src={'/assets/imgs/logo.svg'} alt="logo" className={classes.imageLogo} />
      </div>
      <div
        className={classes.blackBox}
        style={animateLogo ? style.logoBoxShow : style.logoBoxHidden}
      >
        <img src={'/assets/imgs/logo-box.svg'} alt="logo-box" />
      </div>
    </div>
  );
};
