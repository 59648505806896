import React, { useEffect } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';

import { Grid, Paper, Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  productSeries: {
    fontSize: 24,
    fontWeight: 700,
    marginRight: 12,
  },
  productName: {
    fontSize: 24,
  },
  imageBox: {
    height: 280,
    width: '100%',
    objectFit: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: 'center',
  },
}));

const DetailsModalBody = (props) => {
  const classes = useStyles();
  const { onLoad } = props;

  useEffect(() => {
    onLoad();
  }, []);

  return (
    <Paper elevation={0} style={{ height: '100%' }}>
      <PerfectScrollbar>
        <Grid container spacing={1}>
          <Grid item container>
            <Grid item>
              <span className={classes.productSeries}>Rolls-Royce</span>
            </Grid>
            <Grid item>
              <span className={classes.productName}>Cullinan</span>
            </Grid>
          </Grid>
          <Grid item>
            <Typography variant="subtitle1">
              Rolls-Royce luxury in any and every setting, wherever your adventure takes you.
            </Typography>
          </Grid>
          <Grid item container spacing={1}>
            <Grid item xs={6}>
              <img
                src="https://hqfidelitystorageprod.blob.core.windows.net/prestige-car-connect/default/store-images/rr-cullinan/Prestige%20Car%20Dealership-2.jpg"
                className={classes.imageBox}
              />
            </Grid>
            <Grid item xs={6}>
              <img
                src="https://hqfidelitystorageprod.blob.core.windows.net/prestige-car-connect/default/store-images/rr-cullinan/Prestige%20Car%20Dealership-13.jpg"
                className={classes.imageBox}
              />
            </Grid>
          </Grid>
          <Grid item container>
            <Typography variant="subtitle1">
              Presenting the most capable Rolls-Royce ever — an imposing force with unforeseen
              finesse. A conduit for absolute control. Behind its wheel, the velvet night welcomes
              you in and the city becomes your personal playground.
            </Typography>
          </Grid>
          <Grid item container xs>
            <img
              src="https://hqfidelitystorageprod.blob.core.windows.net/prestige-car-connect/default/store-images/rr-cullinan/Prestige%20Car%20Dealership-3.jpg"
              className={classes.imageBox}
            />
          </Grid>
          <Grid item container>
            <Typography variant="subtitle1">
              Every last element, designed with unrelenting perfection.
            </Typography>
          </Grid>
          <Grid item container spacing={1}>
            <Grid item xs={6}>
              <img
                src="https://hqfidelitystorageprod.blob.core.windows.net/prestige-car-connect/default/store-images/rr-cullinan/Prestige%20Car%20Dealership-4.jpg"
                className={classes.imageBox}
              />
            </Grid>
            <Grid item xs={6}>
              <img
                src="https://hqfidelitystorageprod.blob.core.windows.net/prestige-car-connect/default/store-images/rr-cullinan/Prestige%20Car%20Dealership-18.jpg"
                className={classes.imageBox}
              />
            </Grid>
          </Grid>
        </Grid>
      </PerfectScrollbar>
    </Paper>
  );
};

export default DetailsModalBody;
