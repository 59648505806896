const tags = [
  {
    id: '1',
    type: 'price',
    position: { x: 6.59455100201123, y: 1.0184428089309278, z: 15.093020320417676 },
    face: { x: -0.29814376741425075, y: 0.745353251660034, z: -0.5962875348285015 },
    shape: {
      width: 3,
      height: 2,
      borderRadius: 0.08,
    },
    seriesName: 'Rolls-Royce',
    name: 'Cullinan',
    description: 'Rolls-Royce luxury in any and every setting, wherever your adventure takes you.',
    productId: 'cullinan',
    media: {
      src: '',
      // 'https://hqfidelitystorageprod.blob.core.windows.net/cottonon-vr-store-3d/linen-house/test-product.jpg',
      slide: true,
    },
  },
];

export default tags;
