import { useBreakpoint } from '@hooks/useBreakpoint';
import useWindowLandscape from '@hooks/useWindowLandscape';
import clsx from 'clsx';
import React, { useLayoutEffect, useState } from 'react';
import 'swiper/css/bundle';
import { Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import { Box, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  imageBox: {
    height: '100%',
    width: '100%',
    objectFit: 'contain',
  },
  imageSelectorContainer: {},
  selectedImage: {
    padding: 0,
    marginBottom: 0,
    fontSize: 14,
    '& .title': {
      fontWeight: 700,
      marginRight: theme.spacing(0.5),
    },
  },
  imageSelector: {
    position: 'relative',
    height: '100%',
    '& .swiper-button-prev, .swiper-button-next': {
      color: '#fff',
    },
  },
  imageSelectorItem: {
    padding: 0,
    cursor: 'pointer',
    display: 'flex !important',
    alignItems: 'center',
    justifyContent: 'center',
    '& img': {
      width: '90%',
      height: '90%',
      objectFit: 'cover',
      border: '2px solid #FFF',
    },
  },
  imageSelectorItemSelected: {
    '& img': {
      border: '2px solid #00BA3D',
    },
  },
}));

const ImageSelector = (props) => {
  const classes = useStyles();
  const { imageList } = props;
  const isLandscape = useWindowLandscape();
  const breakpoint = useBreakpoint();

  useLayoutEffect(() => {
    if (imageList) {
      setSelectedImage(imageList[0].source);
    }
  }, []);

  useLayoutEffect(() => {
    console.log('breakpoint', breakpoint);
  }, [breakpoint]);

  const handleSelectedImage = (source) => {
    setSelectedImage(source);
  };
  const [selectedImage, setSelectedImage] = useState('');

  const slideSize = (breakpoint) => {
    switch (breakpoint) {
      case 'xl':
        return 5.5;
      case 'lg':
        return 4.5;
      case 'md':
        return 3.5;
      case 'sm':
        return 4.5;
      case 'xs':
        return 4;
      default:
        return 5.5;
    }
  };
  return (
    <Box
      mx={0}
      p={isLandscape ? 2 : 0}
      sx={{
        height: isLandscape ? 'calc(100% - 32px)' : '100%',
        width: isLandscape ? 'calc(100% - 32px)' : '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <Box
        my={1}
        sx={{
          height: 'calc(75% - 16px)',
          width: '100%',
          position: 'relative',
        }}
      >
        <img src={selectedImage} className={classes.imageBox} />
      </Box>
      <Box
        my={1}
        sx={{
          width: '100%',
          height: 'calc(25% - 16px)',
          position: 'relative',
        }}
      >
        <Swiper
          navigation={true}
          modules={[Navigation]}
          // spaceBetween={4}
          slidesPerView={slideSize(breakpoint)}
          className={clsx('mySwiper', classes.imageSelector)}
        >
          {imageList &&
            imageList.map((image, index) => {
              return (
                <SwiperSlide
                  key={`image${index}`}
                  onClick={() => handleSelectedImage(image.source)}
                  className={clsx(
                    classes.imageSelectorItem,
                    image.source == selectedImage && classes.imageSelectorItemSelected
                  )}
                >
                  <img src={image.source} loading="lazy" />
                </SwiperSlide>
              );
            })}
        </Swiper>
      </Box>
    </Box>
  );
};

export default ImageSelector;
